<div class="flex justify-between">
  <div class="flex items-center mb-4">
    <aux-input
      class="w-64 mr-4 mt-5"
      placeholder="Search"
      icon="Search"
      [(ngModel)]="quickFilterText"
      (change)="setTotalRowData()"
    />

    <form class="flex" [formGroup]="investigatorSummaryFiltersForm">
      <aux-multi-select-dropdown
        placeholder="Select"
        label="Site:"
        formControlName="site_ids"
        class="w-64 mr-3"
        bindLabel="textToSearch"
        bindValue="value"
        [items]="$any(siteOptions$ | async)"
        [searchable]="true"
        [customOption]="true"
      />

      <aux-multi-select-dropdown
        placeholder="Select"
        label="Patient ID:"
        formControlName="patient_ids"
        class="w-52 mr-3"
        bindLabel="external_patient_id"
        bindValue="external_patient_id"
        [items]="$any(patientOptions$ | async)"
      />
    </form>
  </div>
  <div class="flex items-center">
    <aux-export-excel-button
      [gridAPI]="gridAPI"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
      className="h-[35px]"
      [svgSize]="20"
    />
  </div>
</div>
<div class="relative">
  <ag-grid-angular
    class="ag-theme-aux"
    style="height: 400px"
    [gridOptions]="$any(gridOptions$ | async)"
    [rowData]="$any(gridData$ | async)"
    [quickFilterText]="quickFilterText"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="sizeColumnsToFit()"
    (sortChanged)="sortChanged$.next(null)"
  />

  <ng-container *ngIf="(gridRefresh$ | async) === true">
    <div class="absolute inset-0 bg-white flex">
      <div class="border-8 h-32 m-auto spinner w-32"></div>
    </div>
  </ng-container>
</div>
