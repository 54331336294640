import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { GridApi } from '@ag-grid-community/core';

@Component({
  selector: 'aux-pagination-panel',
  templateUrl: './pagination-panel.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PaginationPanelComponent implements OnInit {
  @Input() gridApi?: GridApi;

  @Input() totalItems?: number;

  @Input() paginationPageSize?: number;

  @Output() paginationChange = new EventEmitter<void>();

  pageSize = 10;

  ngOnInit() {
    if (
      this.paginationPageSize &&
      Number.isInteger(this.paginationPageSize) &&
      this.paginationPageSize > 0
    ) {
      this.pageSize = this.paginationPageSize;
    }
  }

  getFirstRowOnPageNumber = () => {
    return this.gridApi
      ? this.gridApi.paginationGetPageSize() * this.gridApi.paginationGetCurrentPage() + 1
      : 0;
  };

  getLastRowOnPageNumber = () => {
    if (this.gridApi) {
      return this.gridApi.paginationGetCurrentPage() + 1 === this.gridApi.paginationGetTotalPages()
        ? this.gridApi.paginationGetRowCount()
        : this.gridApi.paginationGetPageSize() * (this.gridApi.paginationGetCurrentPage() + 1);
    }

    return 0;
  };

  getRowCount = () => this.totalItems || this.gridApi?.paginationGetRowCount();

  isLastPage = () => {
    this.updateServerSideRowCount();

    return this.gridApi
      ? this.gridApi.paginationGetCurrentPage() + 1 === this.getTotalPages()
      : true;
  };

  isFirstPage = () => this.gridApi?.paginationGetCurrentPage() === 0;

  getCurrentPage = () => (this.gridApi?.paginationGetCurrentPage() || 0) + 1;

  getTotalPages = () =>
    this.totalItems
      ? Math.ceil(this.totalItems / this.pageSize)
      : this.gridApi?.paginationGetTotalPages();

  private updateServerSideRowCount() {
    if (this.totalItems) {
      this.gridApi?.setRowCount(this.totalItems, false);
    }
  }

  paginationSizeChange(event: number) {
    this.gridApi?.paginationSetPageSize(event);
    this.paginationChange.emit();
  }

  paginateToNextPage() {
    this.gridApi?.paginationGoToNextPage();
    this.paginationChange.emit();
  }

  paginateToLastPage() {
    this.gridApi?.paginationGoToLastPage();
    this.paginationChange.emit();
  }

  paginationGoToFirstPage() {
    this.gridApi?.paginationGoToFirstPage();
    this.paginationChange.emit();
  }

  paginationGoToPreviousPage() {
    this.gridApi?.paginationGoToPreviousPage();
    this.paginationChange.emit();
  }
}
