import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  IProvidedColumn,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { TableConstants } from '@constants/table.constants';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.type';
import { Utils } from '@services/utils';
import { AuxExcelStyleKeys } from '@shared/utils';
import { BehaviorSubject } from 'rxjs';
import { AgExpandableGroupHeaderComponent } from '../../../closing-page/tabs/quarter-close-adjustments/ag-expandable-group-header.component';
import { PatientTrackerRow, Visit } from '../types';
import { isVisitMonthEqualWithCurrentOpenMonth } from './visit-date.utils';

const MAX_WIDTH = 150;
const MIN_WIDTH_PATIENT_COST_COLUMN = 200;

const multipleCurrencyFormatter = (
  params: ValueFormatterParams,
  selectedVisibleCurrency$: BehaviorSubject<CurrencyToggle>,
  contractedFieldName: keyof PatientTrackerRow
) => {
  const isContracted = selectedVisibleCurrency$.getValue() === CurrencyToggle.CONTRACTED;

  return Utils.agMultipleCurrencyFormatter(selectedVisibleCurrency$)({
    ...params,
    value: isContracted ? params.data[contractedFieldName] : params.value,
  });
};

const highlightPlannedVisitCell = (
  params: CellClassParams,
  showPlannedVisits$: BehaviorSubject<boolean>,
  protocolId: string
) => {
  return showPlannedVisits$.getValue() && params.data[`${protocolId}::shouldHighlight`];
};

const hideValueForNonCurrentMonthCells = (
  shouldHighlight: boolean,
  value: string,
  currentOpenMonth: string,
  completed: boolean,
  showPlannedVisits$: BehaviorSubject<boolean>,
  isTotalRow: boolean,
  formatterCallback: () => string
): string => {
  if (isTotalRow) {
    return formatterCallback();
  }

  const isCurrentMonth = isVisitMonthEqualWithCurrentOpenMonth(value, currentOpenMonth);

  if ((shouldHighlight && !showPlannedVisits$.getValue()) || (!completed && !isCurrentMonth)) {
    return Utils.zeroHyphen;
  }

  return formatterCallback();
};

export const getPatientsColumn = (openSiteDialog: ColDef['onCellClicked']): ColGroupDef => ({
  headerName: 'Patients',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  children: [
    {
      field: 'site_id',
      hide: true,
    },
    {
      headerName: 'Patient ID',
      field: 'external_patient_id',
      cellClass: 'ag-cell-align-right',
      tooltipField: 'external_patient_id',
      pinned: 'left',
      maxWidth: MAX_WIDTH,
    },
    {
      headerName: 'Site No.',
      tooltipField: 'site_no',
      field: 'site_no',
      onCellClicked: (event) => (openSiteDialog ? openSiteDialog(event) : undefined),
      cellClass: 'aux-link cursor-pointer',
      pinned: 'left',
      maxWidth: MAX_WIDTH,
      sort: 'asc',
    },
  ],
});

export const getPatientCostColumns = (
  getFormattedTooltip: ColDef['tooltipValueGetter'],
  selectedVisibleCurrency$: BehaviorSubject<CurrencyToggle>,
  showForecastCostThroughEOT: boolean
): ColGroupDef => ({
  headerName: 'Patient Costs',
  headerGroupComponent: AgExpandableGroupHeaderComponent,
  groupId: 'patientCosts',
  headerGroupComponentParams: {
    collapsedByDefault: true,
    localStorageKey: 'patient_tracker_page_patient_costs',
    filterCols(column: IProvidedColumn) {
      return !['totalLTDCosts'].includes(column.getId());
    },
    expandableCols: [
      'totalVisitCostsToDate',
      'totalCostsFromOtherVersions',
      'totalInvoiceablesToDate',
      'totalForecastCostThroughEot',
      'forecastRemaining',
    ],
  },
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  children: [
    {
      headerName: 'Total Visit Costs to Date',
      field: 'totalVisitCostsToDate',
      valueFormatter: (params) =>
        multipleCurrencyFormatter(
          params,
          selectedVisibleCurrency$,
          'totalVisitCostsToDateContracted'
        ),
      cellClass: [AuxExcelStyleKeys.CELL_RIGHT, TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
      minWidth: MIN_WIDTH_PATIENT_COST_COLUMN,
      maxWidth: MAX_WIDTH * 2,
    },
    {
      headerName: 'Total Costs from Other Versions',
      field: 'totalCostsFromOtherVersions',
      valueFormatter: Utils.agMultipleCurrencyFormatter(selectedVisibleCurrency$),
      cellClass: [AuxExcelStyleKeys.CELL_RIGHT, TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
      minWidth: MIN_WIDTH_PATIENT_COST_COLUMN,
      maxWidth: MAX_WIDTH * 2,
    },
    {
      headerName: 'Total Invoiceables to Date',
      field: 'totalInvoiceablesToDate',
      valueFormatter: (params) =>
        multipleCurrencyFormatter(
          params,
          selectedVisibleCurrency$,
          'totalInvoiceablesToDateContracted'
        ),
      cellClass: [AuxExcelStyleKeys.CELL_RIGHT, TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
      minWidth: MIN_WIDTH_PATIENT_COST_COLUMN,
      maxWidth: MAX_WIDTH * 2,
    },
    {
      headerName: 'Total LTD Costs',
      field: 'totalLTDCosts',
      valueFormatter: (params) =>
        multipleCurrencyFormatter(params, selectedVisibleCurrency$, 'totalLTDCostsContracted'),
      cellClass: [AuxExcelStyleKeys.CELL_RIGHT, TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
      minWidth: MIN_WIDTH_PATIENT_COST_COLUMN,
      maxWidth: MAX_WIDTH * 2,
    },
    showForecastCostThroughEOT
      ? {
          headerName: 'Total Forecast Cost through EOT',
          field: 'totalForecastCostThroughEot',
          valueFormatter: (params) =>
            multipleCurrencyFormatter(
              params,
              selectedVisibleCurrency$,
              'totalForecastCostThroughEotContracted'
            ),
          cellClass: [AuxExcelStyleKeys.CELL_RIGHT, TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
          tooltipValueGetter: getFormattedTooltip,
          minWidth: MIN_WIDTH_PATIENT_COST_COLUMN,
          maxWidth: MAX_WIDTH * 2,
        }
      : {
          hide: true,
        },
    {
      headerName: 'Forecasted Remaining',
      field: 'forecastRemaining',
      valueFormatter: (params) =>
        multipleCurrencyFormatter(params, selectedVisibleCurrency$, 'forecastRemainingContracted'),
      cellClass: [AuxExcelStyleKeys.CELL_RIGHT, TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
      minWidth: MIN_WIDTH_PATIENT_COST_COLUMN,
      maxWidth: MAX_WIDTH * 2,
    },
  ],
});

export const getPatientVisitColumns = (
  protocols: Visit[],
  getFormattedTooltip: ColDef['tooltipValueGetter'],
  selectedVisibleCurrency$: BehaviorSubject<CurrencyToggle>,
  showPlannedVisits$: BehaviorSubject<boolean>,
  currentOpenMonth: string
): ColGroupDef => ({
  headerName: 'Visits',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  children: protocols
    .map((protocol) => [
      {
        ...TableConstants.dynamicColumnProps(protocol.name),
        field: `${protocol.id}::dates`,
        cellRenderer: AgCellWrapperComponent,
        cellRendererParams: { customLocator: 'dates' },
        type: 'date',
        tooltipValueGetter: getFormattedTooltip,
        valueFormatter: (params: ValueFormatterParams) =>
          hideValueForNonCurrentMonthCells(
            params.data[`${protocol.id}::shouldHighlight`],
            params.data[`${protocol.id}::dates`],
            currentOpenMonth,
            params.data[`${protocol.id}::completed`],
            showPlannedVisits$,
            params.data.external_patient_id === 'Total',
            () => Utils.agDateFormatter(params)
          ),
        cellClassRules: {
          '!bg-aux-green-2': (params: CellClassParams) =>
            highlightPlannedVisitCell(params, showPlannedVisits$, protocol.id),
        },
        maxWidth: MAX_WIDTH,
      },
      {
        ...TableConstants.dynamicColumnProps(protocol.name),
        field: `${protocol.id}::costs`,
        hide: true,
        cellRenderer: AgCellWrapperComponent,
        cellRendererParams: { customLocator: 'costs' },
        valueFormatter: (params: ValueFormatterParams) =>
          hideValueForNonCurrentMonthCells(
            params.data[`${protocol.id}::shouldHighlight`],
            params.data[`${protocol.id}::dates`],
            currentOpenMonth,
            params.data[`${protocol.id}::completed`],
            showPlannedVisits$,
            params.data.external_patient_id === 'Total',
            () =>
              multipleCurrencyFormatter(
                params,
                selectedVisibleCurrency$,
                `${protocol.id}::costs::contracted`
              )
          ),
        tooltipValueGetter: getFormattedTooltip,
        cellClassRules: {
          '!bg-aux-green-2': (params: CellClassParams) =>
            highlightPlannedVisitCell(params, showPlannedVisits$, protocol.id),
        },
        cellClass: [AuxExcelStyleKeys.CELL_RIGHT, TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        maxWidth: 500,
      },
    ])
    .flat(),
});
