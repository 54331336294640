<button
  [class]="classNames"
  [type]="type"
  [disabled]="(isHandlingClick$ | async) || disabled"
  (click)="handleClick()"
>
  <span
    *ngIf="(isHandlingClick$ | async) || loading"
    [ngClass]="{
      'flex mr-2': true,
      'absolute left-1/2 transform -translate-x-1/2':
        !icon && ((isHandlingClick$ | async) || loading)
    }"
  >
    <span [class]="spinnerClassNames"></span>
  </span>

  <aux-icon
    *ngIf="icon && (isHandlingClick$ | async) === false && !loading"
    [name]="icon"
    class="mr-2 flex-shrink-0"
    [class]="svgAttributes"
    [size]="iconSize"
  />

  <span
    [class]="{ 'button-label': true, invisible: !icon && ((isHandlingClick$ | async) || loading) }"
  >
    {{ label }}
  </span>
</button>
