import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { PeriodCloseComponent, QuarterDate } from '../../period-close.component';
import { FormControl } from '@angular/forms';
import { QuarterCloseChecklistPeriodCloseService } from '../quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, Observable, of } from 'rxjs';
import { first, map, startWith, switchMap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { OverlayService } from '@services/overlay.service';
import { HowItWorksModalComponent } from './how-it-works-modal/how-it-works-modal.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ManageBalanceSheetAccountsModalComponent } from './manage-balance-sheet-accounts-modal/manage-balance-sheet-accounts-modal.component';
import { GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { ExportType, Utils } from '@services/utils';
import { EntityType, EventType, GqlService } from '@services/gql.service';
import { isEmpty, merge } from 'lodash-es';
import { JournalEntriesService } from './journal-entries.service';
import { MainQuery } from '../../../../layouts/main-layout/state/main.query';
import { StickyElementService } from '@services/sticky-element.service';
import { JournalEntriesGridRow, JournalEntriesGridTotalRow } from './journal-entries.model';
import { decimalAdd } from '@shared/utils';
import { calcColumns } from '../../../budget-page/tabs/budget-enhanced/column-defs';

@UntilDestroy()
@Component({
  selector: 'aux-journal-entries',
  templateUrl: './journal-entries.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JournalEntriesComponent implements OnInit, OnDestroy {
  months: (QuarterDate & { label: string })[] = [];

  selectedMonthControl = new FormControl('');

  isHowItWorksVisible$ = this.launchDarklyService.select$(
    (flags) => flags.journal_entries_how_it_works_link
  );

  gridData$ = new BehaviorSubject<JournalEntriesGridRow[]>([]);

  gridOptions$ = new BehaviorSubject<GridOptions>({});

  loadingGridData = true;

  loadingGridColumns = false;

  private gridAPI!: GridApi;

  private selectedQuarterMonth = '';

  constructor(
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private overlayService: OverlayService,
    private launchDarklyService: LaunchDarklyService,
    private gqlService: GqlService,
    private journalEntriesService: JournalEntriesService,
    private mainQuery: MainQuery,
    private stickyElementService: StickyElementService,
    public periodCloseComponent: PeriodCloseComponent
  ) {}

  ngOnInit(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.loadingGridColumns = true;
          this.changeDetectorRef.detectChanges();
          return this.periodCloseComponent.gridDataMergedDiscount$;
        }),
        untilDestroyed(this)
      )
      .subscribe((result) => {
        const attributes = calcColumns({
          attributes: result.map((z) => z.attributes || []),
        });

        this.gridOptions$.next(this.journalEntriesService.getUpdatedGridOptions(attributes));
        this.loadingGridColumns = false;
        this.changeDetectorRef.detectChanges();
      });

    this.periodCloseComponent.quartersObjUpdated$
      .pipe(
        startWith(null),
        switchMap(() => {
          if (!this.months.length) {
            const bool = this.updateMonths();
            if (!bool) {
              return EMPTY;
            }
          }

          const persistedMonth = this.periodCloseService.getSelectedQuarterMonth(this.router.url);
          const persistedQuarterMonth = dayjs(persistedMonth).date(3).format('YYYY-MM-DD');

          return of(persistedQuarterMonth);
        }),
        untilDestroyed(this)
      )
      .subscribe((month) => {
        this.selectedMonthControl.setValue(month);
        this.periodCloseService.selectedQuarterMonthChanged$.next(null);
      });

    combineLatest([
      this.periodCloseComponent.quartersObjUpdated$.pipe(startWith(null)),
      this.periodCloseComponent.selectedQuarter.valueChanges.pipe(
        startWith(this.periodCloseComponent.selectedQuarter.value)
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateMonths();
      });

    this.selectedMonthControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((selectedQuarterMonth) => {
        this.configureSelectedMonthChanges(selectedQuarterMonth || '');
        this.updateGridData();
      });

    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.gridAPI) {
        this.updatePinnedBottomRowData();
      }
    });

    // Need to update initial month title render in the page header
    setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
  }

  ngOnDestroy(): void {
    this.reset();
    this.stickyElementService.reset();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  monthTitle$(): Observable<string> {
    return combineLatest([
      this.periodCloseService.isCurrentQuarterSelected.pipe(first()),
      this.periodCloseService.selectedQuarterMonthChanged$.pipe(startWith(null)),
    ]).pipe(
      map(() => {
        const date = dayjs(this.periodCloseService.getInMonthAdjustmentsMonth());

        return date.isValid() ? date.format('MMMM YYYY') : '';
      })
    );
  }

  onGridReady({ api }: GridReadyEvent): void {
    this.gridAPI = api;
    this.updateGridData();
  }

  autosize(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  openHowItWorksModal(): void {
    this.overlayService.open({
      content: HowItWorksModalComponent,
      closeButton: false,
      data: {
        header: 'Journal Entry Rules & Logic',
        useDesignSystemStyling: true,
      },
    });
  }

  openManageBalanceSheetAccountsModal(): void {
    const modalRef = this.overlayService.open({
      content: ManageBalanceSheetAccountsModalComponent,
      closeButton: false,
      data: {
        header: 'Manage Balance Sheet Accounts',
        useDesignSystemStyling: true,
      },
    });

    modalRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(({ data }) => {
      if (data) {
        this.updateGridData();
      }
    });
  }

  private reset(): void {
    const latestMonth = this.periodCloseService.getLatestMonth();

    this.configureSelectedMonthChanges(latestMonth, true);
  }

  private updateMonths(): boolean {
    if (this.periodCloseComponent.selectedQuarter.value) {
      const months =
        this.periodCloseComponent.quartersObj[this.periodCloseComponent.selectedQuarter.value];

      const currentMonth = dayjs(this.periodCloseComponent.currentMonth);
      this.months = months.map((month: QuarterDate) => {
        const isClosed = month.parsedDate.isBefore(currentMonth);
        const isOpen = month.parsedDate.date(1).isSame(currentMonth);
        const label = `${month.parsedDate.format('MMMM YYYY')}${isClosed ? ' (Closed)' : ''}${
          isOpen ? ' (Open)' : ''
        }`;
        return { ...month, label };
      });
      return true;
    }
    return false;
  }

  private configureSelectedMonthChanges(selectedQuarterMonth: string, onDestroy = false): void {
    if (!selectedQuarterMonth || selectedQuarterMonth === this.selectedQuarterMonth) {
      return;
    }

    this.selectedQuarterMonth = selectedQuarterMonth;
    this.periodCloseService.selectedQuarterMonth = selectedQuarterMonth;

    if (!onDestroy) {
      this.periodCloseService.persistedQuarterMonth = selectedQuarterMonth;
    }

    this.periodCloseService.selectedQuarterMonthChanged$.next(null);
  }

  private isMultipleCurrencies(): boolean {
    const obj = this.periodCloseComponent.bottomRowDataForMergedDiscount$.getValue();

    return (
      isEmpty(obj.eom_accruals) &&
      isEmpty(obj.net_accruals) &&
      isEmpty(obj.monthly_wp_obj) &&
      isEmpty(obj.initial_wp_obj)
    );
  }

  private generatePinnedBottomData(): JournalEntriesGridTotalRow {
    const columnsWithAggregation = ['debit_amount', 'credit_amount'] as const;

    return this.gridData$.getValue().reduce(
      (acc, val) => {
        for (const key of columnsWithAggregation) {
          const currentVal = acc[key];
          const additionalVal = val[key];
          if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
            acc[key] = decimalAdd(currentVal, additionalVal);
          }
        }

        return acc;
      },
      {
        debit_amount: 0,
        credit_amount: 0,
      }
    );
  }

  private updatePinnedBottomRowData(): void {
    if (this.isMultipleCurrencies()) {
      this.gridAPI.setPinnedBottomRowData([]);
    } else {
      this.gridAPI.setPinnedBottomRowData([
        merge(
          {
            activity_name: 'Total',
          },
          this.generatePinnedBottomData()
        ),
      ]);
    }
  }

  private updateGridData(): void {
    this.loadingGridData = true;
    this.changeDetectorRef.detectChanges();

    const { fromDate, toDate } = this.getPeriodDates();

    this.gqlService
      .listJournalEntries$(fromDate, toDate, '')
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result.success && result.data) {
          this.gridData$.next(this.journalEntriesService.getJournalEntriesGridData(result.data));
        } else {
          this.gridData$.next([]);
        }
        this.loadingGridData = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  private getPeriodDates(): {
    fromDate: string;
    toDate: string;
  } {
    const date = dayjs(this.selectedMonthControl.value);

    const fromDate = date
      .month(date.month())
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .format('YYYY-MM-DD');

    const toDate = date
      .month(date.month() + 1)
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .format('YYYY-MM-DD');

    return {
      fromDate: fromDate,
      toDate: toDate,
    };
  }

  async onExport() {
    const trialShortName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const trialId = this.mainQuery.getSelectedTrial()?.id || '';
    const monthYear = dayjs(this.periodCloseService.getInMonthAdjustmentsMonth()).format('MMMYYYY');

    const result = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: trialId,
        payload: JSON.stringify({
          export_type: ExportType.JOURNAL_ENTRIES,
          filename: `${trialShortName}_auxilius_${monthYear}_Journal_Entries_${dayjs().format(
            'YYYY.MM.DD-HHmmss'
          )}`,
          export_entity_id: trialId || '',
          json_properties: {
            month: dayjs(this.periodCloseService.getInMonthAdjustmentsMonth()).format('MM/DD/YY'),
          },
        }),
      })
    );
    if (result.success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(result.errors);
    }
  }
}
