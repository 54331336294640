import { ChangeDetectionStrategy, Component, HostListener, OnDestroy } from '@angular/core';
import {
  ColumnApi,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom } from 'rxjs';
import { TableConstants } from '@constants/table.constants';
import { ExpenseDefaultsService } from './state/expense-defaults.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExpenseDefaultsQuery } from './state/expense-defaults.query';
import { AgExpenseDefaultsDragComponent } from './state/ag-expense-defaults-drag/ag-expense-defaults-drag.component';
import { Utils } from '@services/utils';
import { AgExpenseDefaultsHeaderComponent } from './state/ag-expense-defaults-header.component';
import { map, switchMap } from 'rxjs/operators';
import { DefaultExpenseSource } from '@services/gql.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { TrialsQuery } from '@models/trials/trials.query';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';
import {
  AgQuarterCloseGroupHeaderComponent,
  AgQuarterCloseGroupHeaderComponentParams,
} from '../../closing-page/tabs/quarter-close/ag-quarter-close-group-header.component';
import * as dayjs from 'dayjs';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { OverlayService } from '@services/overlay.service';
import { StickyElementService } from '@services/sticky-element.service';
import { ExpenseSourceItems } from './state/expense-defaults.store';
import { AuxExcelStyles } from '@shared/utils';

@UntilDestroy()
@Component({
  selector: 'aux-expense-defaults',
  templateUrl: './expense-defaults.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseDefaultsComponent implements OnDestroy {
  gridOptions: GridOptions = {
    ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      sortable: false,
    },
    suppressCellFocus: true,
    groupDefaultExpanded: 1,
    suppressColumnVirtualisation: true,
    animateRows: true,
    autoGroupColumnDef: {
      maxWidth: 532,
      minWidth: 250,
      width: 250,
      field: 'cost_category',
      tooltipField: 'cat',
      resizable: true,
      headerName: 'Vendor',
      headerComponent: AgQuarterCloseGroupHeaderComponent,
      headerComponentParams: {
        template: `Vendor`,
        headerPage: 'expense_defaults',
      } as AgQuarterCloseGroupHeaderComponentParams,
      headerClass: ['ag-header-align-center'],
      cellRendererParams: {
        suppressCount: true,
      },
      tooltipValueGetter: (params) => {
        return params?.value || '';
      },
      showRowGroup: true,
      pinned: 'left',
      cellRenderer: TableConstants.AG_SYSTEM.AG_GROUP_CELL_RENDERER,
      valueFormatter: (x: ValueFormatterParams) => {
        return this.expenseDefaultsService.mapExpenseCategory.get(x?.value) || '';
      },
    },
    columnDefs: [
      {
        headerName: 'Cost Category',
        field: 'cat',
        tooltipField: 'cat',
        hide: true,
        valueFormatter: (x: ValueFormatterParams) => {
          return this.expenseDefaultsService.mapExpenseCategory.get(x?.value) || '';
        },
      },
      {
        headerName: 'Vendor',
        field: 'co_name',
        tooltipField: 'co_name',
        rowGroup: true,
        hide: true,
      },
      TableConstants.SPACER_COLUMN_NO_PARENT,
      {
        headerComponent: AgExpenseDefaultsHeaderComponent,
        cellRendererParams: {
          dragDropChangeFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.compareToRow(rowNode.data);
          },
        },
        cellRenderer: AgExpenseDefaultsDragComponent,
        minWidth: 430,
      },
      {
        field: 'primary',
        headerName: 'Primary Source',
        hide: true,
      },
      {
        field: 'secondary',
        headerName: 'Secondary Source',
        hide: true,
      },
      {
        field: 'tertiary',
        headerName: 'Tertiary Source',
        hide: true,
      },
    ],
    excelStyles: [...AuxExcelStyles],
  };

  excelOptions = {
    author: 'Auxilius',
    sheetName: 'Expense Defaults',
    fontSize: 11,
    shouldRowBeSkipped(params) {
      return !params.node?.data?.organization_id;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'co_name':
          return 300;
        default:
          return 200;
      }
    },
  } as ExcelExportParams;

  exportButtonVariant = ExcelButtonVariant.OUTLINE;

  gridAPI!: GridApi;

  columnAPI?: ColumnApi;

  gridData$ = new BehaviorSubject<ExpenseSourceItems[]>([]);

  saveCheck$ = new BehaviorSubject(false);

  cloneRowData: ExpenseSourceItems[] = [];

  edits = new Set<DefaultExpenseSource>();

  loading$ = combineLatest([
    this.organizationQuery.selectLoading(),
    this.expenseDefaultsQuery.selectLoading(),
  ]).pipe(
    map((x) => {
      return x.some((y) => y);
    })
  );

  width$ = new BehaviorSubject(0);

  constructor(
    public expenseDefaultsQuery: ExpenseDefaultsQuery,
    public organizationQuery: OrganizationQuery,
    private expenseDefaultsService: ExpenseDefaultsService,
    private organizationService: OrganizationService,
    private trialsQuery: TrialsQuery,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private stickyElementService: StickyElementService
  ) {
    this.organizationService.get().pipe(untilDestroyed(this)).subscribe();

    this.expenseDefaultsService.getExpenseDefaults().pipe(untilDestroyed(this)).subscribe();

    this.organizationQuery
      .selectLoading()
      .pipe(
        untilDestroyed(this),
        switchMap((bool) => {
          if (bool) {
            return EMPTY;
          }
          return this.expenseDefaultsQuery.select();
        })
      )
      .subscribe(({ items }) => {
        if (items.length > 0) {
          const nItems = items
            .map((x) => {
              return {
                ...x,
                co_name: this.organizationQuery.getEntity(x.organization_id)?.name || '',
              };
            })
            .sort((x, y) => Utils.alphaNumSort(y?.cost_category, x?.cost_category))
            .sort((x, y) => Utils.alphaNumSort(x.co_name, y.co_name));
          this.gridData$.next(nItems || []);
          this.cloneRowData = Utils.clone(nItems);
        }
      });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI = api;
    this.columnAPI = columnApi;
  }

  saveChanges = async () => {
    const saveData: DefaultExpenseSource[] = [];
    for (const edit of this.edits) {
      saveData.push(edit);
    }
    const success = await this.expenseDefaultsService.updateExpenseDefaults(saveData);
    if (success) {
      this.saveCheck$.next(false);
      this.edits.clear();
      this.cloneRowData = Utils.clone(this.gridData$.getValue());
    }
  };

  onDiscardChanges() {
    this.saveCheck$.next(false);
    this.gridData$.next(Utils.clone(this.cloneRowData));
    this.edits.clear();
  }

  getDynamicExcelParams(): ExcelExportParams {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return {};
    }
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    return {
      ...this.excelOptions,
      fileName: `${trial.short_name}_expense_defaults_${dateStr}.xlsx`,
      columnKeys: ['co_name', 'cat', 'primary', 'secondary', 'tertiary'],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: 4,
              styleId: 'first_row',
            },
          ],
        },
      ],
    };
  }

  compareToRow(row: DefaultExpenseSource) {
    if (this.edits.size > 0) {
      let att = 0;
      this.edits.forEach((edit) => {
        const changed = this.cloneRowData.find(
          (x) =>
            x.cost_category === edit.cost_category && x.organization_id === edit.organization_id
        );

        if (JSON.stringify(edit.sources) !== JSON.stringify(changed?.sources)) {
          att = att + 1;
        }

        if (
          edit.cost_category === row.cost_category &&
          edit.organization_id === row.organization_id
        ) {
          this.edits.delete(edit);
        }
      });
      if (att > 0) {
        this.saveCheck$.next(true);

        this.edits.add({
          organization_id: row.organization_id,
          cost_category: row.cost_category,
          sources: row.sources,
        } as DefaultExpenseSource);
      } else {
        this.saveCheck$.next(false);
        this.edits.clear();
      }
    } else {
      this.edits.add({
        organization_id: row.organization_id,
        cost_category: row.cost_category,
        sources: row.sources,
      } as DefaultExpenseSource);
      this.saveCheck$.next(true);
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.saveCheck$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);

      return !!event.data;
    }

    return true;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  onResize() {
    this.refreshGridWidth();
  }

  refreshGridWidth() {
    const columnContainer = document.querySelector('.ag-header-container') as HTMLDivElement;
    const pinnedColumnContainer = document.querySelector(
      '.ag-pinned-left-header'
    ) as HTMLDivElement;
    if (columnContainer && pinnedColumnContainer) {
      const paddingOffset = 0;
      const width =
        columnContainer.getBoundingClientRect().width +
        pinnedColumnContainer.getBoundingClientRect().width +
        paddingOffset;
      this.width$.next(width);
    } else {
      this.width$.next(0);
    }
  }
}
