import { Injectable } from '@angular/core';
import { each } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import {
  QuarterCloseChecklistRow,
  QuarterCloseChecklistRowToggle,
} from '../models/quarter-close-checklist.model';
import { Workflow } from '../../quarter-close/close-quarter-check-list/store/workflow.store';

@Injectable()
export class QuarterCloseChecklistToggleService {
  private rowToggle: QuarterCloseChecklistRowToggle;

  constructor() {
    this.rowToggle = this.createRowToggle();
  }

  createRowToggle(): QuarterCloseChecklistRowToggle {
    return {
      GatherContracts: new BehaviorSubject<boolean>(false),
      GatherInvoices: new BehaviorSubject<boolean>(false),
      GatherPatients: new BehaviorSubject<boolean>(false),
      GatherEstimates: new BehaviorSubject<boolean>(false),
      ForecastTimeline: new BehaviorSubject<boolean>(false),
      ForecastCurves: new BehaviorSubject<boolean>(false),
      ForecastMethodology: new BehaviorSubject<boolean>(false),
      CloseExpenses: new BehaviorSubject<boolean>(false),
      CloseDiscounts: new BehaviorSubject<boolean>(false),
    };
  }

  closeDropdowns(workflowList: Workflow[]): void {
    if (workflowList.length !== 9) {
      this.reset();
    }
  }

  reset(): void {
    each(this.rowToggle, (toggle$) => {
      toggle$.next(false);
    });
  }

  destroy(): void {
    each(this.rowToggle, (toggle$) => {
      toggle$.complete();
    });

    this.rowToggle = this.createRowToggle();
  }

  isRowOpen(id: keyof typeof QuarterCloseChecklistRow): BehaviorSubject<boolean> {
    return this.rowToggle[id];
  }

  toggleRow(id: keyof typeof QuarterCloseChecklistRow): void {
    const isToggled = this.rowToggle[id].getValue();

    this.rowToggle[id].next(!isToggled);
  }
}
