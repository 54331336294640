import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import {
  combineLatest,
  EMPTY,
  firstValueFrom,
  forkJoin,
  from,
  Observable,
  of,
  retry,
  throwError,
  timer,
} from 'rxjs';
import {
  AnalyticsCardType,
  Approval,
  BudgetType,
  CreateUserCustomViewInput,
  ExpenseType,
  GqlService,
  listBudgetGridQuery,
  listBudgetGridV2Query,
  RemoveVendorEstimateInput,
  UpdateUserCustomViewInput,
  ViewLocation,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { groupBy, round } from 'lodash-es';
import { ApiService } from '@services/api.service';
import { Utils } from '@services/utils';
import { EventService } from '@services/event.service';
import { BudgetStore } from './budget.store';
import { BudgetQuery } from './budget.query';
import { ApprovalStore } from './approval.store';
import { ApprovalState } from './approval.model';
import { BudgetState, createInitialState, ExtendedBudgetData } from './budget.model';
import { BudgetGridService } from './budget-grid.service';
import { BudgetCurrencyQuery } from './budget-currency.query';
import { BudgetCurrencyType } from '../budget-type';

@Injectable({ providedIn: 'root' })
export class BudgetService {
  constructor(
    private budgetStore: BudgetStore,
    private approvalStore: ApprovalStore,
    private mainQuery: MainQuery,
    private vendorsQuery: OrganizationQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private budgetQuery: BudgetQuery,
    private apiService: ApiService,
    private eventService: EventService,
    private budgetCurrencyQuery: BudgetCurrencyQuery,
    private budgetGridService: BudgetGridService
  ) {}

  getBudgetDataForEBGV2() {
    return combineLatest([
      this.budgetQuery.select('budget_type'),
      this.vendorsQuery.selectLoading(),
      this.vendorsQuery.selectActive(),
      this.budgetCurrencyQuery.select('currency'),
      this.mainQuery.select('trialKey'),
    ]).pipe(
      switchMap(([budget_type, isVendorsLoading]) => {
        if (isVendorsLoading) {
          return EMPTY;
        }

        return combineLatest([of(budget_type)]);
      }),
      switchMap(([budget_type]) => {
        this.budgetStore.setLoading(true);

        const requestsInputList = [
          {
            budget_type,
            in_month: false,
            budget_version_id: null,
            vendor_id: null,
          },
        ];

        return forkJoin(requestsInputList.map((input) => this.gqlService.listBudgetGridV2$(input)));
      }),
      this.budgetsCacheMechanism<listBudgetGridV2Query>(),
      this.cacheRetry(),
      map(([{ budget_data: raw_data, budget_info, header_data }]: listBudgetGridV2Query[]) => {
        this.budgetStore.update(
          createInitialState(budget_info?.[0]?.budget_type || BudgetType.BUDGET_PRIMARY)
        );

        const vendors = this.vendorsQuery.getAllVendors();
        let currencySelection = BudgetCurrencyType.USD;
        if (this.budgetCurrencyQuery.getValue() !== undefined) {
          currencySelection = this.budgetCurrencyQuery.getValue().currency;
        }

        const budget_data = this.budgetGridService.getBudgetGridWithBaseLineForVendorsV2(
          raw_data,
          vendors,
          currencySelection
        );

        this.budgetStore.update({
          header_data: header_data || [],
          budget_info: this.budgetGridService.getBudgetInfo(budget_info, vendors) || [],
          budget_data,
          current_data: budget_data,
        } as unknown as Partial<BudgetState>);

        this.budgetStore.setLoading(false);
      })
    );
  }

  getMonthCloseApprovals() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.approvalStore.setLoading(true);
        this.approvalStore.remove(() => true);
        return this.gqlService.listMonthCloseApprovals$();
      }),
      map((listMonthCloseApprovals) => {
        const listOfApprovals: Approval[] = [];
        listMonthCloseApprovals.data?.forEach((a) => {
          const approval = {
            category: a.category,
            vendor_id: a.vendor_id,
            aux_user_id: a.aux_user_id,
            permission: a.permission,
          } as Approval;

          listOfApprovals.push(approval);
        });
        const approvalState: ApprovalState = {
          approvals: listOfApprovals,
        };
        this.approvalStore.update(approvalState);
        this.approvalStore.setLoading(false);
        return true;
      })
    );
  }

  getInMonthBudgetData(quarter_start_month: string | null): Observable<Partial<BudgetState>> {
    this.budgetStore.setLoading(true);

    return combineLatest([
      this.gqlService.listBudgetGrid$({
        budget_version_id: null,
        vendor_id: null,
        budget_type: BudgetType.BUDGET_PRIMARY,
        in_month: true,
        cache_enabled: true,
        quarter_start_month,
      }),
    ]).pipe(
      this.budgetsCacheMechanism<listBudgetGridQuery>(),
      this.cacheRetry(),
      map(([primary]: listBudgetGridQuery[]) => {
        if (!primary) {
          const state: Partial<BudgetState> = {
            header_data: [],
            budget_info: [],
            budget_data: [],
          };
          this.budgetStore.update(state);
          return state;
        }

        this.budgetStore.setLoading(false);

        const { header_data, budget_data: primary_data, budget_info } = primary;
        const date_headers = header_data?.filter(
          (data) => data.expense_type === ExpenseType.EXPENSE_FORECAST
        )[0]?.date_headers;

        const budget_data = (primary_data || []).map((bd) => {
          const groupedExpenses = groupBy(bd.expenses, 'expense_type');

          const forecast: Record<string, number> = {};
          // In case of no forecast, populate all dates with 0 since it caused issues with total rows
          date_headers?.forEach((x) => {
            forecast[x] = round(0, 2);
          });

          (groupedExpenses[ExpenseType.EXPENSE_FORECAST] || []).forEach((x) => {
            forecast[x.period || ''] = round(x.contract_amount || 0, 2);
          });

          const exchange_rate: Record<string, number> = {};
          (groupedExpenses[ExpenseType.EXPENSE_FORECAST] || []).forEach((x) => {
            exchange_rate[x.period || ''] = round(
              x.contract_amount ? (x.amount || 0) / x.contract_amount : 0,
              6
            );
          });

          // Override the forecast numbers if at close exists
          (groupedExpenses[ExpenseType.EXPENSE_FORECAST_AT_CLOSE] || []).forEach((x) => {
            forecast[x.period || ''] = round(x.contract_amount || 0, 2);
          });

          const accrual: Record<string, number> = {};
          (groupedExpenses[ExpenseType.EXPENSE_ACCRUAL] || []).forEach((x) => {
            accrual[x.period || ''] = round(x.contract_amount || 0, 2);
          });

          const accrual_override: Record<string, number> = {};
          (groupedExpenses[ExpenseType.EXPENSE_ACCRUAL_OVERRIDE] || []).forEach((x) => {
            accrual_override[x.period || ''] = round(x.contract_amount || 0, 2);
          });

          const accrual_adjusted: Record<string, number> = {};
          (groupedExpenses[ExpenseType.EXPENSE_ACCRUAL_ADJUSTED] || []).forEach((x) => {
            accrual_adjusted[x.period || ''] = round(x.contract_amount || 0, 2);
          });

          const adjustment: Record<string, number> = {};
          for (const period of Object.keys(forecast)) {
            adjustment[period] = Object.prototype.hasOwnProperty.call(accrual_adjusted, period)
              ? (accrual_adjusted[period] || 0) -
                (Object.prototype.hasOwnProperty.call(accrual, period)
                  ? accrual[period]
                  : forecast[period] || 0)
              : 0;
          }

          const wp_ltd = round(
            (groupedExpenses[ExpenseType.EXPENSE_WP] || []).filter((r) => r.period === 'TO_DATE')[0]
              ?.contract_amount || 0,
            2
          );

          return <ExtendedBudgetData>{
            ...bd,
            remaining_cost: 0,
            remaining_percentage: 0,
            wp_percentage: 0,
            wp_cost: 0,
            wp_unit_num: 0,
            remaining_unit_num: 0,
            accrual: 0,
            forecast: 0,
            total_monthly_accrual: 0,
            adjustment: 0,
            wp_ltd,
            forecast_obj: forecast,
            accrual_obj: accrual,
            exchange_rate,
            accrual_adjusted_obj: accrual_adjusted,
            adjustment_obj: adjustment,
            accrual_override,
          };
        });

        const state: Partial<BudgetState> = {
          header_data: header_data || [],
          budget_info: budget_info || [],
          budget_data,
        };
        this.budgetStore.update(state);

        return state;
      })
    );
  }

  cacheRetry<T>() {
    return retry<T>({
      delay: (error, retryCount) => {
        const maxRetryAttempts = 10;
        const scalingDuration = 1000;
        let shouldRetry = false;
        if (
          error &&
          error.message &&
          error.name &&
          (error.message === 'no cache' || error.name === 'SyntaxError')
        ) {
          shouldRetry = true;
        }

        if (shouldRetry && retryCount <= maxRetryAttempts) {
          return timer(retryCount * scalingDuration);
        }

        return throwError(() => {
          this.overlayService.error(error?.message || 'Unexpected Error');
          return error;
        });
      },
    });
  }

  getBudgetWorkPerformed() {
    return this.eventService
      .selectAnalyticsCard$({
        analytics_card_type: AnalyticsCardType.BUDGET_WORK_PERFORMED,
      })
      .pipe(
        switchMap(({ data, success, errors }) => {
          if (success && data) {
            this.budgetStore.update({
              work_performed: this.parseWPResponse(data.data),
            });
          }
          return of({ success: false, errors, data: null });
        })
      );
  }

  getInvoicesTotal() {
    return this.eventService
      .selectAnalyticsCard$({
        analytics_card_type: AnalyticsCardType.BUDGET_INVOICES,
      })
      .pipe(
        switchMap(({ data, success, errors }) => {
          if (success && data) {
            this.budgetStore.update({
              invoices: this.parseInvoicesResponse(data.data),
            });
          }
          return of({ success: false, errors, data: null });
        })
      );
  }

  getPendingChanges() {
    return this.eventService
      .selectAnalyticsCard$({
        analytics_card_type: AnalyticsCardType.BUDGET_PENDING_CHANGES,
      })
      .pipe(
        switchMap(({ data, success, errors }) => {
          if (success && data) {
            this.budgetStore.update({
              pendingChanges: this.parsePendingChangesResponse(data.data),
            });
          }
          return of({ success: false, errors, data: null });
        })
      );
  }

  parseWPResponse(str: string) {
    const obj = JSON.parse(str) as { [key: string]: { [key: string]: string } };
    const resp: { [key: string]: { [key: string]: string } } = {};

    Object.keys(obj).forEach((key: string) => {
      if (!resp[key]) {
        resp[key] = {
          wp_total: '',
          wp_percentage: '',
        };
      }
      resp[key].wp_total = Utils.currencyFormatter(parseFloat(obj[key].wp_total));
      resp[key].wp_percentage = Utils.percentageFormatter(parseFloat(obj[key].wp_percentage));
    });

    return resp;
  }

  parseInvoicesResponse(str: string) {
    const obj = JSON.parse(str) as { [key: string]: { [key: string]: string } };

    const resp: { [key: string]: { [key: string]: string } } = {};

    Object.keys(obj).forEach((key: string) => {
      if (!resp[key]) {
        resp[key] = {
          invoice_total: '',
          direct_cost_total: '',
          invoice_percentage: '',
        };
      }
      resp[key].invoice_total = Utils.currencyFormatter(parseFloat(obj[key].invoice_total));
      resp[key].direct_cost_total = Utils.currencyFormatter(parseFloat(obj[key].direct_cost_total));
      resp[key].invoice_percentage = Utils.percentageFormatter(
        parseFloat(obj[key].invoice_percentage)
      );
    });

    return resp;
  }

  parsePendingChangesResponse(str: unknown) {
    const obj = str as { [key: string]: string };

    const resp: { [key: string]: string } = {};

    Object.keys(obj).forEach((key: string) => {
      resp[key] = Utils.currencyFormatter(parseFloat(obj[key]));
    });

    return resp;
  }

  budgetsCacheMechanism<
    T extends {
      cache_info?: {
        __typename: 'CacheInfo';
        cache_file: string;
      } | null;
    },
  >() {
    return switchMap(
      (
        gridDataList: {
          success: boolean;
          data: T | null;
          errors: string[];
        }[]
      ) => {
        if (!gridDataList.length) {
          return of([] as T[]);
        }
        return forkJoin(
          gridDataList
            .filter(({ data }) => data?.cache_info)
            .map(({ data }) => {
              if (!data?.cache_info?.cache_file) {
                return throwError(() => new Error('no cache'));
              }
              return from(
                this.apiService.getFileAsJsonWithErrors<T>(data.cache_info.cache_file)
              ).pipe(map((x) => x || ({} as T)));
            })
        );
      }
    );
  }

  budgetCacheMechanism() {
    return switchMap(
      ({
        data,
        success,
        errors,
      }: {
        success: boolean;
        data: listBudgetGridQuery | null;
        errors: string[];
      }) => {
        if (data && success && data.cache_info?.cache_file) {
          return from(
            this.apiService.getFileAsJson<listBudgetGridQuery>(data.cache_info?.cache_file)
          ).pipe(
            map((x) => {
              if (x) {
                return { data: x, success: true, errors: [] };
              }

              return { data, success, errors };
            })
          );
        }
        return of({ data, success, errors });
      }
    );
  }

  async removeVendorEstimate(input: RemoveVendorEstimateInput) {
    const { success, errors } = await firstValueFrom(
      this.gqlService.removeVendorEstimate$({
        vendor_id: input.vendor_id,
        target_month: input.target_month,
      })
    );

    if (errors.length) {
      this.overlayService.error(errors);
    }

    return success;
  }

  async setBudgetVersionAsBaseline(id: string, organization_id: string) {
    const { success, errors } = await firstValueFrom(
      this.gqlService.setBudgetVersionAsBaseline$(id, organization_id)
    );
    if (errors.length) {
      this.overlayService.error(errors);
    }

    return success;
  }

  async removeBudgetVersion(id: string) {
    const { success, errors } = await firstValueFrom(this.gqlService.removeBudgetVersion$(id));
    if (errors.length) {
      this.overlayService.error(errors);
    }
    return success;
  }

  async saveUserCustomView(data: CreateUserCustomViewInput) {
    const { success, errors } = await firstValueFrom(this.gqlService.createUserCustomView$(data));
    if (errors.length) {
      this.overlayService.error(errors);
    }
    return success;
  }

  async listUserCustomView() {
    const { success, data, errors } = await firstValueFrom(
      this.gqlService.listUserCustomViews$(ViewLocation.VIEW_LOCATION_BUDGET_GRID)
    );
    if (success && data) {
      return data;
    }
    this.overlayService.error(errors);
    return null;
  }

  async updateUserCustomView(item: UpdateUserCustomViewInput) {
    const { success, errors } = await firstValueFrom(this.gqlService.updateUserCustomView$(item));
    if (errors.length) {
      this.overlayService.error(errors);
      return false;
    }
    return success;
  }

  async removeUserCustomView(id: string) {
    if (id) {
      const { success, errors } = await firstValueFrom(this.gqlService.removeUserCustomView$(id));

      if (errors.length) {
        this.overlayService.error(errors);
        return false;
      }

      return success;
    }
    this.overlayService.error(`This view doesn't have id!`);
    return false;
  }
}
