import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[auxClickOutside]',
})
export class ClickOutsideDirective {
  @Output() auxClickOutside = new EventEmitter<HTMLElement>();

  flag = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.flag) {
        this.auxClickOutside.emit(target);
      }
      this.flag = true;
    }
  }
}
