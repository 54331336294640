import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { WorkflowStep } from '@services/gql.service';
import { WorkflowState, WorkflowStore } from './workflow.store';
import { WorkflowService } from './workflow.service';

@Injectable({ providedIn: 'root' })
export class WorkflowQuery extends QueryEntity<WorkflowState> {
  isQuarterCloseEnabled$ = this.launchDarklyService.select$(
    ({ client_preference_quarter_close_workflow_enabled }) =>
      client_preference_quarter_close_workflow_enabled
  );

  isWorkflowAvailable$ = combineLatest([
    this.isQuarterCloseEnabled$,
    this.workflowService.trialMonthClose$,
  ]).pipe(
    map(([isQuarterCloseEnabled]) => {
      return isQuarterCloseEnabled;
    })
  );

  invoiceLockTooltip$ = this.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  ).pipe(
    map((isInvoiceFinalized) => {
      return isInvoiceFinalized ? 'Invoices are Locked for Period Close' : '';
    })
  );

  constructor(
    protected store: WorkflowStore,
    private launchDarklyService: LaunchDarklyService,
    private workflowService: WorkflowService
  ) {
    super(store);
  }

  selectWorkflowList() {
    return this.selectAll();
  }

  getWorkflowByStepType(stepType: string) {
    return this.selectAll({
      filterBy: [(workFlow) => workFlow.step_type === stepType],
    }).pipe(map((workflowList) => (workflowList.length ? workflowList[0] : null)));
  }

  getInMonthAdjustmentAvailability() {
    return this.selectWorkflowList().pipe(
      map((workflowList) => {
        return workflowList
          .filter(
            ({ step_type }) => step_type !== WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS
          )
          .every(({ properties: { locked } }) => locked);
      })
    );
  }

  getMonthCloseButtonAvailability() {
    return combineLatest([
      this.isQuarterCloseEnabled$,
      this.isWorkflowAvailable$,
      this.selectWorkflowList(),
    ]).pipe(
      map(([isQuarterCloseEnabled, isWorkflowAvailable, workflowList]) => {
        if (!isQuarterCloseEnabled) {
          return true;
        }
        return isWorkflowAvailable
          ? workflowList.every(({ properties: { locked } }) => locked)
          : isWorkflowAvailable;
      })
    );
  }

  getLockStatusByWorkflowStepType(stepType: string): Observable<boolean> {
    return combineLatest([this.isWorkflowAvailable$, this.getWorkflowByStepType(stepType)]).pipe(
      map(([isWorkflowPanelAvailable, workflow]) => {
        if (!isWorkflowPanelAvailable) {
          return false;
        }

        return workflow ? workflow.properties.locked : false;
      })
    );
  }
}
