import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaymentSchedulesQuery } from '@models/payment-schedules/payment-schedules.query';
import { SitesService } from '@models/sites/sites.service';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { SitesQuery } from '@models/sites/sites.query';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ColumnApi,
  ExcelExportParams,
  ExcelStyle,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import { EventService } from '@services/event.service';
import { SitesStore } from '@models/sites/sites.store';
import {
  EventType,
  Currency,
  WorkflowStep,
  PatientProtocolType,
  listPatientProtocolsQuery,
  PermissionType,
} from '@services/gql.service';
import { isEqual } from 'lodash-es';
import { TableConstants } from '@constants/table.constants';
import { AuxExcelFormats, AuxExcelStyles, AgSetColumnsVisible } from '@shared/utils';
import { AuthQuery } from '@models/auth/auth.query';
import { TableService } from '@services/table.service';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.type';
import { PatientTrackerService } from './state/patient-tracker.service';
import { SiteDialogComponent } from '../sites/site-dialog/site-dialog.component';
import { PatientTrackerUploadComponent } from './components';
import { WorkflowQuery } from '../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { ROUTING_PATH } from '../../../app-routing-path.const';
import { FormControl } from '@angular/forms';
import { PatientGroupsService } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { Option } from '@components/components.type';
import { getPatientCostColumns, getPatientVisitColumns, getPatientsColumn } from './utils';
import { PatientTrackerScheduleService } from './services';
import { PatientTrackerRow } from './types';
import { AuthService } from '@models/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'aux-patient-tracker',
  templateUrl: './patient-tracker.component.html',
  styleUrls: ['patient-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientTrackerComponent {
  gridOptions: ExcelExportParams = {
    fileName: 'auxilius-patient-tracker.xlsx',
    sheetName: 'Patient Tracker',
  };

  showForecastCostThroughEOT = new BehaviorSubject(false);

  siteOptions$ = this.patientTrackerService.siteOptions$;

  selectedSiteOptions = new FormControl<string[]>([]);

  groupFormControl = new FormControl('');

  protocolVersionControl = new FormControl('');

  showPlannedVisitsControl = new FormControl(false);

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER;

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  currencyToggle = CurrencyToggle;

  patientBudgetLink = `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET.INDEX}`;

  isTrackerFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(this.workflowName);

  currentOpenMonth$ = this.mainQuery.select('currentOpenMonth');

  columnDefs$ = new BehaviorSubject<(ColDef | ColGroupDef)[]>([]);

  cards$ = new BehaviorSubject<
    {
      header: string;
      data: string;
      firstProp: { status?: string; label: string };
      secondProp: { status?: string; label: string };
    }[]
  >([
    {
      header: 'Average Cost, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 1 month',
      },
      secondProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
    },
    {
      header: 'Forecasted Average Cost through EOT, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'high',
        label: 'vs. Current Budget',
      },
    },
    {
      header: 'Budgeted Average Cost through EOT',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'high',
        label: 'Prior 6 months',
      },
    },
  ]);

  display$ = new BehaviorSubject<'dates' | 'costs'>('dates');

  isContractedCurrency = false;

  gridData$ = new BehaviorSubject<PatientTrackerRow[]>([]);

  totalPatientVisitsLTD$ = new BehaviorSubject(0);

  plannedThroughCurrentMonth$ = new BehaviorSubject(0);

  selectedVisibleCurrency$ = new BehaviorSubject<CurrencyToggle>(CurrencyToggle.PRIMARY);

  showPlannedVisits$ = new BehaviorSubject(false);

  gridDataLoading$ = new BehaviorSubject(true);

  gridOptions$ = new BehaviorSubject<GridOptions>({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      minWidth: 120,
      cellRenderer: AgCellWrapperComponent,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    columnTypes: Utils.columnTypes,
    onFilterChanged: (params) => {
      const rows: PatientTrackerRow[] = [];
      params.api?.forEachNodeAfterFilter((r) => r.data && rows.push(r.data));

      this.renderPinnedRow(this.selectedVisibleCurrency$.getValue(), rows);
    },
  });

  private visitCostsOptionValue = 'visitCosts';

  gridAPI!: GridApi;

  gridColumnApi!: ColumnApi;

  analyticsCardsLoading = new BehaviorSubject(false);

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  selectedVersion: string | undefined = '';

  isDisplayCosts = false;

  showAnalyticsSection$: Observable<boolean>;

  isQuarterCloseEnabled$: Observable<boolean>;

  isClosingPanelEnabled$: Observable<boolean>;

  isPlannedVisitsEnabled$: Observable<boolean>;

  patientGroupOptions$ = new BehaviorSubject<Option[]>([]);

  protocolVersionOptions$ = new BehaviorSubject<Option[]>([]);

  isAdminUser = false;

  isHandlingUpload$ = new BehaviorSubject(false);

  isLoadingData$ = new BehaviorSubject(true);

  showGrid$ = new BehaviorSubject(false);

  showForecastCostThroughEOT$: Observable<boolean>;

  userHasLockPatientDataPermission = false;

  constructor(
    private patientProtocolService: PatientProtocolService,
    private patientProtocolQuery: PatientProtocolQuery,
    private patientTrackerService: PatientTrackerService,
    private patientGroupService: PatientGroupsService,
    private paymentSchedulesQuery: PaymentSchedulesQuery,
    private sitesService: SitesService,
    private sitesStore: SitesStore,
    private mainQuery: MainQuery,
    public sitesQuery: SitesQuery,
    private overlayService: OverlayService,
    private workflowQuery: WorkflowQuery,
    private authQuery: AuthQuery,
    launchDarklyService: LaunchDarklyService,
    private eventService: EventService,
    private patientTrackerScheduleService: PatientTrackerScheduleService,
    private authService: AuthService
  ) {
    launchDarklyService
      .select$((flags) => flags.visit_costs)
      .pipe(untilDestroyed(this))
      .subscribe((isEnabled) => {
        const optionsWithoutVisitCostOption = this.patientGroupOptions$
          .getValue()
          .filter(({ value }) => value !== this.visitCostsOptionValue);

        if (isEnabled) {
          this.patientGroupOptions$.next([
            ...optionsWithoutVisitCostOption,
            {
              value: this.visitCostsOptionValue,
              label: 'Visit Costs',
            },
          ]);
        } else {
          this.patientGroupOptions$.next([...optionsWithoutVisitCostOption]);
        }
      });

    this.setUserPermissions();

    this.showForecastCostThroughEOT$ = launchDarklyService.select$(
      (flags) => flags.section_forecast_cost_through_eot
    );

    this.isClosingPanelEnabled$ = launchDarklyService.select$(
      (flags) => flags.closing_checklist_toolbar
    );

    this.isPlannedVisitsEnabled$ = launchDarklyService.select$(
      (flags) => flags.patient_tracker_planned_visits
    );

    this.showForecastCostThroughEOT$.subscribe((flag) => {
      this.showForecastCostThroughEOT.next(flag);
    });

    this.showPlannedVisitsControl.valueChanges.pipe(untilDestroyed(this)).subscribe((show) => {
      this.showPlannedVisits$.next(!!show);
      this.gridAPI?.refreshCells();
      this.renderPinnedRow(this.selectedVisibleCurrency$.getValue(), this.gridData$.getValue());
    });

    // Trigger valueFormatter for multiple currencies
    this.selectedVisibleCurrency$
      .pipe(untilDestroyed(this))
      .subscribe((selectedVisibleCurrency) => {
        this.gridAPI?.refreshCells();
        this.renderPinnedRow(selectedVisibleCurrency, this.gridData$.getValue());
      });

    this.eventService
      .select$(EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED)
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.getGridData())
      )
      .subscribe(() => {
        this.isHandlingUpload$.next(false);
      });

    combineLatest([
      this.patientProtocolService.getPatientProtocolVersions(),
      this.patientGroupService.getPatientGroupOptions$(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([protocolVersions, patientGroups]) => {
        this.patientGroupOptions$.next([...patientGroups, ...this.patientGroupOptions$.getValue()]);

        this.protocolVersionOptions$.next(
          protocolVersions.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );

        if (this.patientGroupOptions$.getValue().length) {
          this.groupFormControl.setValue(this.patientGroupOptions$.getValue()[0].value);
        }

        if (this.protocolVersionOptions$.getValue().length) {
          this.protocolVersionControl.setValue(this.protocolVersionOptions$.getValue()[0].value);
        }
      });

    this.fetchVisits();

    this.patientTrackerService.getSiteOptions().pipe(untilDestroyed(this)).subscribe();

    combineLatest([
      this.groupFormControl.valueChanges,
      this.selectedSiteOptions.valueChanges,
      this.protocolVersionControl.valueChanges,
    ])
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(isEqual),
        filter(([, selectedSites]) => !!selectedSites),
        switchMap(() => this.getGridData())
      )
      .subscribe(() => {
        this.gridAPI?.onFilterChanged();
      });

    this.columnDefs$.next([
      getPatientsColumn((event) => this.openSiteDialog(event)),
      getPatientCostColumns(
        PatientTrackerComponent.getFormattedTooltip,
        this.selectedVisibleCurrency$,
        this.showForecastCostThroughEOT.value
      ),
    ]);

    combineLatest([
      this.sitesService.get().pipe(
        tap(({ success, data }) => {
          if (success && data && data[0]) {
            this.showGrid$.next(data.length > 0);
            this.sitesStore.setActive([data[0].id]);
            this.selectedSiteOptions.setValue([data[0].id]);
          } else {
            this.showGrid$.next(false);
            this.gridDataLoading$.next(false);
          }
        })
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingData$.next(false);
      });

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_patient_tracker_analytics
    );

    this.isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.analyticsCardsLoading.next(true);
            return this.patientTrackerService.getAnalyticsCards();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((val) => {
        this.analyticsCardsLoading.next(false);
        this.cards$.next(val);
      });

    this.gridColumnApi$
      .pipe(
        switchMap(() => this.display$),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.setVisibilityVisitColumns();
        this.renderPinnedRow(this.selectedVisibleCurrency$.getValue(), this.gridData$.getValue());
      });

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });
  }

  private getGroupId(): string | null {
    return this.groupFormControl.value !== this.visitCostsOptionValue
      ? this.groupFormControl.value
      : null;
  }

  private fetchVisits() {
    combineLatest([this.groupFormControl.valueChanges, this.protocolVersionControl.valueChanges])
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          const protocols = this.patientProtocolService.get(
            [
              PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
              PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
              PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
            ],
            this.getGroupId(),
            false,
            this.protocolVersionControl.value ?? '',
            true
          ) as Observable<GraphqlResponse<listPatientProtocolsQuery[]>>;

          return this.groupFormControl.value
            ? protocols.pipe(
                tap(() => {
                  const columns = Object.values(this.patientProtocolQuery.getValue().entities || {})
                    .map(({ id, name, order_by, patient_protocol_type }) => {
                      let visitSortIndex: number;
                      switch (patient_protocol_type) {
                        case PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL:
                          visitSortIndex = -3;
                          break;
                        case PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED:
                          visitSortIndex = -2;
                          break;
                        default:
                          visitSortIndex = order_by || -1;
                      }
                      return {
                        id,
                        name,
                        visitSortIndex,
                      };
                    })
                    .sort(({ visitSortIndex }, { visitSortIndex: visitSortIndex2 }) =>
                      Utils.alphaNumSort(visitSortIndex, visitSortIndex2)
                    );

                  this.columnDefs$.next([
                    ...this.columnDefs$.getValue().filter((col) => col.headerName !== 'Visits'),
                    getPatientVisitColumns(
                      columns ?? [],
                      PatientTrackerComponent.getFormattedTooltip,
                      this.selectedVisibleCurrency$,
                      this.showPlannedVisits$,
                      this.mainQuery.getValue().currentOpenMonth
                    ),
                  ]);
                })
              )
            : of();
        })
      )
      .subscribe();
  }

  private setVisibilityVisitColumns() {
    const columnDefs: ColDef[] | undefined = this.gridOptions$
      .getValue()
      ?.columnDefs?.map((columns) => ('children' in columns ? columns.children : []))
      .flat();

    const colIds: string[] = [];

    columnDefs?.forEach((x) => {
      if ('field' in x && x.field?.includes('::')) {
        colIds.push(x.field);
      }
    });

    const dateColIds = colIds.filter((x) => x.includes('::dates'));
    const costsColIds = colIds.filter((x) => x.includes('::costs'));

    const value = this.display$.getValue();

    AgSetColumnsVisible({
      columnApi: this.gridColumnApi,
      keys: dateColIds,
      visible: value === 'dates',
    });
    AgSetColumnsVisible({
      columnApi: this.gridColumnApi,
      keys: costsColIds,
      visible: value === 'costs',
    });
    this.sizeColumnsToFit();
  }

  openSiteDialog(event: CellClickedEvent) {
    const { site_id } = event.data;
    const site = this.sitesQuery.getEntity(site_id);
    if (site) {
      this.overlayService.open({ content: SiteDialogComponent, data: { site } });
    }
  }

  private clearPinnedBottomRow() {
    this.gridAPI.setPinnedBottomRowData([]);
  }

  private renderPinnedRow(selectedCurrency: CurrencyToggle, rows: PatientTrackerRow[]) {
    if (selectedCurrency === this.currencyToggle.PRIMARY) {
      this.generatePinnedBottomData(rows);
    } else {
      this.clearPinnedBottomRow();
    }
  }

  shouldIncludeHiddenCellsFromCalculation(column: string, val: PatientTrackerRow) {
    const suffix = this.display$.getValue() === 'costs' ? '::costs' : '::dates';
    const isVisitCost = column.endsWith(suffix);
    const isCompleted = isVisitCost
      ? val[`${column.replace(suffix, '')}::completed`] === true
      : false;

    return !isVisitCost || isCompleted;
  }

  generatePinnedBottomData(rows: PatientTrackerRow[]) {
    const data = rows.reduce(
      (acc, val) => {
        Object.entries(val).forEach(([column, value]) => {
          const shouldCalculate = this.shouldIncludeHiddenCellsFromCalculation(column, val);

          if (typeof value === 'number' && shouldCalculate) {
            acc[column] ||= 0;
            acc[column] += value || 0;
          }
        });

        return acc;
      },
      { actual_cost: 0, forecast_cost: 0, visit: 0, other: 0 } as Record<string, number>
    );

    this.gridAPI?.setPinnedBottomRowData([
      { ...data, external_patient_id: 'Total', currency: Currency.USD },
    ]);
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridColumnApi = columnApi;
    this.gridColumnApi$.next(columnApi);
    this.updateGridLayout();
    this.renderPinnedRow(this.selectedVisibleCurrency$.getValue(), this.gridData$.getValue());
  }

  private getGridData() {
    this.gridDataLoading$.next(true);

    if (!this.selectedSiteOptions.value?.length) {
      this.gridData$.next([]);
      this.gridDataLoading$.next(false);
      this.generatePinnedBottomData([]);
      return of();
    }

    return this.patientTrackerScheduleService
      .getPatientVisitSchedules$(
        this.protocolVersionControl.value ?? '',
        this.mainQuery.getValue().currentOpenMonth,
        this.selectedSiteOptions.value,
        this.getGroupId(),
        {
          limit: 10_000, // ! Mock until server side pagination would be implemented
          offset: 0,
        }
      )
      .pipe(
        tap((values) => {
          if (values.metaData) {
            const excelStyles = this.generateExcelStyle(this.columnDefs$.getValue());
            this.gridOptions$.next({
              ...this.gridOptions$.getValue(),
              excelStyles,
            });

            this.totalPatientVisitsLTD$.next(values.metaData.total);
            this.plannedThroughCurrentMonth$.next(values.metaData.currentMonthTotal);
          }

          // TODO Need to remove if condition when add server side pagination
          if (this.selectedSiteOptions.value?.length) {
            this.gridData$.next(values.rowData);
          } else {
            this.gridData$.next([]);
          }

          this.gridDataLoading$.next(false);
          this.updateGridLayout();
          this.sizeColumnsToFit();
        })
      );
  }

  onPatientTrackerUploadClick() {
    this.overlayService.open({
      content: PatientTrackerUploadComponent,
      data: { onSuccess: () => this.isHandlingUpload$.next(true) },
    });
  }

  generateExcelStyle(columnDefs: ColDef[]): ExcelStyle[] {
    const styles = columnDefs.map((cd) => {
      let dataType = 'string';
      let format;
      if (cd.field?.endsWith('::dates')) {
        dataType = 'DateTime';
      } else if (cd.field?.endsWith('::costs')) {
        dataType = 'Number';
        format = AuxExcelFormats.Cost;
      }
      return { id: cd.field, dataType, numberFormat: { format } } as ExcelStyle;
    });
    return [...AuxExcelStyles, ...styles];
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    if (!this.gridAPI) {
      return {};
    }
    const name = this.mainQuery.getSelectedTrial()?.short_name;
    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;
    const columns = totals
      ? (Object.entries(totals)
          .map(([key, value]) => (typeof value === 'number' ? key : null))
          .filter((key) => key) as string[])
      : [
          'totalVisitCostsToDate',
          'totalCostsFromOtherVersions',
          'totalInvoiceablesToDate',
          'totalLTDCosts',
          'totalForecastCostThroughEot',
          'forecastRemaining',
        ];

    this.gridColumnApi.getAllDisplayedColumns().forEach((column) => {
      if (
        totals &&
        column.getColId().endsWith('::costs') &&
        columns.indexOf(column.getColId()) === -1
      ) {
        totals[column.getColId()] = 0;
        columns.push(column.getColId());
      }
    });

    const appendContent: ExcelExportParams['appendContent'] = totals
      ? [
          {
            cells: [
              {
                data: { value: `Total`, type: 'String' },
                styleId: 'total_row_header',
              },
              ...TableService.getTotalRowForExcel(
                totals,
                this.gridColumnApi,
                ['external_patient_id', 'currency'],
                columns
              ),
            ],
          },
        ]
      : [
          {
            cells: [],
          },
        ];

    const version =
      this.protocolVersionOptions$
        .getValue()
        .find(({ value }) => value === this.protocolVersionControl.value)?.label ?? '';

    const patientGroup =
      this.patientGroupOptions$
        .getValue()
        .find(({ value }) => value === this.groupFormControl.value)?.label ?? '';

    return {
      prependContent: [
        {
          cells: [
            {
              data: {
                value: `Trial: ${name}`,
                type: 'String',
              },
              styleId: 'first_row',
              mergeAcross: 1,
            },
          ],
        },
        {
          cells: [
            {
              data: {
                value: `Version: ${version}`,
                type: 'String',
              },
              styleId: 'first_row',
              mergeAcross: 1,
            },
          ],
        },
        {
          cells: [
            {
              data: {
                value: `Patient Group: ${patientGroup}`,
                type: 'String',
              },
              styleId: 'first_row',
              mergeAcross: 1,
            },
          ],
        },
      ],
      appendContent,
      processCellCallback: (params) => {
        if (
          !this.shouldIncludeHiddenCellsFromCalculation(
            params.column.getColId() ?? '',
            params.node?.data || {}
          )
        ) {
          return Utils.zeroHyphen;
        }

        return TableService.processCellForExcel(
          this.selectedVisibleCurrency$,
          columns,
          '::costs'
        )(params);
      },
      shouldRowBeSkipped(params) {
        return params.node?.data?.external_patient_id === 'Total';
      },
    };
  };

  sizeColumnsToFit(): void {
    this.gridAPI?.sizeColumnsToFit();
  }

  updateGridLayout(): void {
    Utils.updateGridLayout(this.gridAPI, 'patientTrackerGrid', true);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static getFormattedTooltip = ({ valueFormatted }: ITooltipParams): string | undefined | null =>
    valueFormatted !== Utils.zeroHyphen ? valueFormatted : '';

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasLockPatientDataPermission]) => {
        this.userHasLockPatientDataPermission = userHasLockPatientDataPermission;
      });
  }
}
