import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface FormValuesState {
  patientBudget: {
    site_ids: string[];
    selectedCurrencies: {
      isContractCurrency: boolean;
      isPrimaryCurrency: boolean;
    };
  };
}

export function createInitialState(): FormValuesState {
  const formValuesFromStorage = localStorage.getItem('formValues');

  const initialValues: FormValuesState = formValuesFromStorage
    ? JSON.parse(formValuesFromStorage)
    : {
        patientBudget: {
          site_ids: [],
          selectedCurrencies: {
            isContractCurrency: true,
            isPrimaryCurrency: true,
          },
        },
      };

  return initialValues;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form-values' })
export class FormValuesStore extends Store<FormValuesState> {
  constructor() {
    super(createInitialState());
  }
}
