import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ColumnApi, GridReadyEvent, IGetRowsParams, GridApi } from '@ag-grid-community/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { EventType, PermissionType, WorkflowStep } from '@services/gql.service';
import { EventService } from '@services/event.service';
import { AuthQuery } from '@models/auth/auth.query';
import { getInvestigatorTransactionCols } from './investigator-transactions-grid-cols';
import {
  InvestigatorTransactionsFilterForm,
  InvestigatorTransactionsService,
} from './investigator-transactions.service';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';
import { ImportPatientDataModalComponent } from './import-patient-data-modal/import-patient-data-modal.component';
import { WorkflowQuery } from '../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { MessagesConstants } from '@constants/messages.constants';
import { StickyElementService } from '@services/sticky-element.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AuthService } from '@models/auth/auth.service';

const LOADING_ROW = 1;

@UntilDestroy()
@Component({
  selector: 'aux-investigator-transactions',
  templateUrl: './investigator-transactions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorTransactionsComponent implements OnInit, OnDestroy {
  readonly workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER;

  isPatientsFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  isAdminUser$ = this.authQuery.adminUser$.pipe(untilDestroyed(this));

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  isCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  gridAPI!: GridApi;

  columnAPI!: ColumnApi;

  transactionFilters?: InvestigatorTransactionsFilterForm;

  isContractSelected$ = new BehaviorSubject(false);

  gridOptions$ = combineLatest([
    this.isContractSelected$,
    this.launchDarklyService.select$((flags) => flags.investigator_transactions_edc_created_date),
  ]).pipe(
    map(([isSelected, isEDCColumnBeShown]) =>
      getInvestigatorTransactionCols(isSelected, isEDCColumnBeShown, this.gridAPI)
    )
  );

  totalItems$ = this.investigatorTransactionsService.totalItems$;

  filterLoading$ = new BehaviorSubject(true);

  gridRefresh$ = new BehaviorSubject(true);

  userHasLockPatientDataPermission = false;

  constructor(
    private investigatorTransactionsService: InvestigatorTransactionsService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private eventService: EventService,
    private authQuery: AuthQuery,
    private workflowQuery: WorkflowQuery,
    private stickyElementService: StickyElementService,
    private launchDarklyService: LaunchDarklyService,
    private authService: AuthService
  ) {
    this.setUserPermissions();
  }

  ngOnInit() {
    this.mainQuery
      .select('trialKey')
      .pipe(
        untilDestroyed(this),
        tap(() => this.toggleLoaders(true)),
        switchMap(() => this.investigatorTransactionsService.getTransactionFilters()),
        tap(() => this.toggleLoaders(false))
      )
      .subscribe();

    this.eventService
      .select$(EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.setDatasource());
  }

  ngOnDestroy() {
    this.stickyElementService.reset();
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI = api;
    this.columnAPI = columnApi;

    this.setDatasource();

    this.launchDarklyService
      .select$((flags) => flags.investigator_transactions_site_address)
      .subscribe((enabled) => {
        this.columnAPI.setColumnVisible('siteAddress', enabled);
        this.gridAPI.sizeColumnsToFit();
      });
  }

  private setDatasource(): void {
    this.gridAPI.setDatasource({
      getRows: (params: IGetRowsParams) => {
        this.investigatorTransactionsService
          .getTransactionList(
            this.transactionFilters as InvestigatorTransactionsFilterForm,
            params.sortModel,
            {
              offset: params.startRow,
              limit: params.endRow - params.startRow,
            }
          )
          .subscribe((transactionObj) => {
            const rowsRendered = (this.gridAPI.getInfiniteRowCount() || 0) - LOADING_ROW;

            if (transactionObj.data.length) {
              params.successCallback(
                transactionObj.data,
                transactionObj.next_offset === -1 ? rowsRendered + transactionObj.data.length : -1
              );
              this.gridAPI?.hideOverlay();
            } else {
              params.successCallback([], 0);
              this.gridAPI?.showNoRowsOverlay();
            }

            // Update Filters (performed within Investigator Transactions Filters)
            this.investigatorTransactionsService.inMonthAdjustmentsFilter.next(true);
          });
      },
    });

    this.gridRefresh$.next(false);
    this.gridAPI.sizeColumnsToFit();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }

  private toggleLoaders(isLoading: boolean) {
    this.filterLoading$.next(isLoading);
    this.gridRefresh$.next(isLoading);
  }

  transactionFilterChange(filers: InvestigatorTransactionsFilterForm) {
    this.transactionFilters = filers;
    this.gridAPI?.onFilterChanged();
  }

  openImportPatientDataModal(): void {
    this.overlayService.open({
      content: ImportPatientDataModalComponent,
      data: { onSuccess: () => this.gridRefresh$.next(true) },
    });
  }

  importPatientDataTooltip(isPatientsFinalized: boolean | null): string {
    return isPatientsFinalized ? MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : '';
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasLockPatientDataPermission]) => {
        this.userHasLockPatientDataPermission = userHasLockPatientDataPermission;
      });
  }
}
