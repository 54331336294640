import { ChangeLogItemStatus } from '@services/gql.service';

export class ChangeLogItemStatusConstants {
  static readonly config = [
    {
      value: ChangeLogItemStatus.STATUS_PENDING_REVIEW,
      circleClass: 'bg-aux-gray-darkest',
      textClass: 'text-aux-gray-darkest',
      text: 'Pending Review',
    },
    {
      value: ChangeLogItemStatus.STATUS_PENDING_APPROVAL,
      circleClass: 'bg-aux-blue-light-200',
      textClass: 'text-aux-blue-light-200',
      text: 'Pending Approval',
    },
    {
      value: ChangeLogItemStatus.STATUS_APPROVED,
      circleClass: 'bg-aux-green-dark',
      textClass: 'text-aux-green-dark',
      text: 'Approved',
    },
    {
      value: ChangeLogItemStatus.STATUS_DECLINED,
      circleClass: 'bg-aux-red-dark',
      textClass: 'text-aux-red-dark',
      text: 'Declined',
    },
    {
      value: ChangeLogItemStatus.STATUS_ON_HOLD,
      circleClass: 'bg-aux-warn',
      textClass: 'text-aux-warn',
      text: 'On Hold',
    },
  ];
}
