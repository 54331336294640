<div
  class="fixed left-64 bottom-0 right-0 flex justify-between items-center px-4 py-[9px] shadow-banner"
  [ngClass]="{
    'bg-aux-green-light-1': !disabled,
    'bg-aux-gray-light-1': disabled
  }"
>
  <div *ngIf="!disabled; else unCompleted" class="text-aux-blue-dark-1 font-semibold">
    You completed the Closing Checklist! Click here to continue to Month & Quarter Close to close
    the month.
  </div>

  <ng-template #unCompleted>
    <div class="text-aux-gray-darkest font-semibold">
      Once Checklist is complete, click here to continue to Month & Quarter Close to close the
      month.
    </div>
  </ng-template>

  <span [auxTooltip]="disabled ? 'To continue, complete the checklist above.' : ''">
    <span
      class="btn min-w-[206px] h-[40px] ml-4 text-sm font-bold whitespace-nowrap"
      type="button"
      [ngClass]="{
        'btn--success bg-aux-green-1 cursor-pointer': !disabled,
        'bg-aux-gray-light-2 text-aux-gray-dark-1 pointer-events-none border-aux-gray-light-2':
          disabled
      }"
      [routerLink]="routerLink"
    >
      Continue to Close Month
    </span>
  </span>
</div>
