import { Component, ChangeDetectionStrategy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { OrganizationService } from '@models/organization/organization.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { FileMetadata } from '@services/api.service';
import { DocumentType, EntityType, EventType, GqlService } from '@services/gql.service';
import { AuthQuery } from '@models/auth/auth.query';
import { CustomValidators } from '@components/form-inputs/custom-validators';
import { Utils } from '@services/utils';
import { LaunchDarklyService } from '@services/launch-darkly.service';

export interface OrganizationDialogData {
  id?: string;
  fetchFilesOnInit?: boolean;
}

@Component({
  selector: 'aux-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationDialogComponent implements OnInit {
  readonly currencyOptions = Utils.CURRENCY_OPTIONS;

  @ViewChild('fileManager') fileManager: FileManagerComponent | undefined;

  @ViewChild('fileManager2') fileManager2: FileManagerComponent | undefined;

  @ViewChild('fileManager3') fileManager3: FileManagerComponent | undefined;

  fg = this.formBuilder.group({
    contact_id: '',
    currency: '',
    given_name: '',
    family_name: '',
    email: ['', CustomValidators.emailValidator()],
    title: '',
    phone_number: '',
    parent_organization_id: '',
    costs_included_in_parent_wo: false,
    is_third_party: false,
  });

  vendorFc = new UntypedFormControl();

  mode: 'edit' | 'add' = 'edit';

  loading$ = new BehaviorSubject(false);

  vendorId: string | null = null;

  contractHasBeenUploaded = false;

  proposalHasBeenUploaded = false;

  otherHasBeenUploaded = false;

  ORGANIZATION = EntityType.ORGANIZATION;

  DOCUMENT_VENDOR_CONTRACT = DocumentType.DOCUMENT_VENDOR_CONTRACT;

  DOCUMENT_PROPOSAL = DocumentType.DOCUMENT_PROPOSAL;

  DOCUMENT_OTHER = DocumentType.DOCUMENT_OTHER;

  vendorCurrencyEnabled$: Observable<boolean>;

  fetchFilesOnInit = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: CustomOverlayRef<unknown, OrganizationDialogData>,
    private overlayService: OverlayService,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private gqlService: GqlService,
    private authQuery: AuthQuery,
    private ld: LaunchDarklyService,
    private mainQuery: MainQuery
  ) {
    this.fetchFilesOnInit = this.ref.data?.fetchFilesOnInit ?? this.fetchFilesOnInit;
    this.vendorCurrencyEnabled$ = this.ld.select$((flags) => flags.vendor_currency);
  }

  async ngOnInit() {
    this.mode = 'add';

    if (this.ref.data?.id) {
      this.mode = 'edit';
      this.vendorId = this.ref.data.id;

      const org = this.organizationQuery.getEntity(this.vendorId);
      if (org) {
        const { id, name, currency, parent_organization } = org;
        this.loading$.next(true);
        const { success, data } = await firstValueFrom(this.organizationService.getContacts(id));
        if (success && data) {
          this.fg.patchValue({
            ...data,
            name,
            currency,
            contact_id: data.id,
            parent_organization_id: parent_organization?.id,
            costs_included_in_parent_wo: org.costs_included_in_parent_wo,
            is_third_party: !!parent_organization?.id,
          });
          this.fg.markAsUntouched({ onlySelf: true });
          this.refreshVendorValidators();
        } else {
          this.fg.patchValue({
            name,
            currency,
            parent_organization_id: parent_organization?.id,
            costs_included_in_parent_wo: org.costs_included_in_parent_wo,
            is_third_party: !!parent_organization?.id,
          });
        }
        this.vendorFc.setValue(org.name);
        if (org.baseline_budget_version) {
          this.fg.get('currency')?.disable();
        }

        this.loading$.next(false);
      } else {
        this.overlayService.error('User not found!');
      }
    }
  }

  getFc(str: string) {
    return this.fg.get(str);
  }

  refreshVendorValidators(): void {
    const controlNames = ['given_name', 'family_name', 'email', 'title', 'phone_number'];
    const vendorFieldsEdited =
      controlNames.some((name) => {
        const ctl = this.fg.get(name);
        ctl?.markAsTouched({ onlySelf: true });
        return ctl?.value;
      }) || this.fg.get('contact_id')?.value;
    for (const name of ['given_name', 'family_name']) {
      const ctl = this.fg.get(name);
      const validators = vendorFieldsEdited ? [Validators.required] : [];
      ctl?.setValidators(validators);
      ctl?.updateValueAndValidity();
    }
  }

  onCancel() {
    this.ref.close();
  }

  getMetadata(): () => FileMetadata {
    return () => ({});
  }

  getFilePaths(type: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return () => (this.vendorId ? `trials/${trialId}/vendors/${this.vendorId}/${type}/` : '');
  }

  async onSubmit() {
    if (this.vendorFc.value) {
      this.loading$.next(true);
      if (this.mode === 'add') {
        const {
          currency,
          given_name,
          family_name,
          email,
          title,
          phone_number,
          parent_organization_id,
          costs_included_in_parent_wo,
        } = this.fg.value;
        const { success, data, errors } = await this.organizationService.add(
          this.vendorFc.value,
          {
            given_name,
            family_name,
            email,
            title,
            phone_number,
            parent_organization_id,
            costs_included_in_parent_wo,
          },
          currency || null
        );
        if (success && data) {
          this.vendorId = data.id;
          await this.processFiles(this.vendorId);
          this.overlayService.success('Vendor successfully added!');
          this.ref.close();
        } else {
          this.overlayService.error(errors);
        }
      } else if (this.vendorId) {
        const {
          currency,
          given_name,
          family_name,
          email,
          title,
          phone_number,
          parent_organization_id,
          costs_included_in_parent_wo,
        } = this.fg.value;

        const { success, errors } = await this.organizationService.update(
          this.vendorId,
          this.vendorFc.value,
          {
            given_name,
            family_name,
            email,
            title,
            phone_number,
            parent_organization_id,
            costs_included_in_parent_wo,
          },
          currency || null
        );
        await this.processFiles(this.vendorId);
        if (success) {
          this.overlayService.success('Vendor successfully updated!');
          this.ref.close();
        } else {
          this.overlayService.error(errors);
        }
      }
    }
    this.loading$.next(false);
  }

  async processFiles(vendor_id: string) {
    await this.upload(this.fileManager, 'contracts', vendor_id);
    await this.upload(this.fileManager2, 'proposals', vendor_id);
    await this.upload(this.fileManager3, 'other', vendor_id);
  }

  upload = async (
    fileManager: FileManagerComponent | undefined,
    fileType: string,
    vendor_id: string
  ) => {
    if (fileManager) {
      const path = this.getFilePaths(fileType);
      const files = fileManager.fileQuery.getAll();
      let fileNames = '';
      for (const file of files) {
        fileNames += `${file.fileName} `;
        fileManager.fileStore.update(file.id, {
          ...file,
          bucket_key: `${path()}${file.bucket_key}`,
        });
      }
      const currentFiles = fileManager.fileStore.getValue().entities;
      let documentType = DocumentType.DOCUMENT_OTHER;
      let aNewFileWasUploaded = false;
      if (fileType === 'contracts') {
        documentType = DocumentType.DOCUMENT_VENDOR_CONTRACT;
        if (currentFiles) {
          aNewFileWasUploaded = this.contractHasBeenUploaded;
        }
      }
      if (fileType === 'proposals') {
        documentType = DocumentType.DOCUMENT_PROPOSAL;
        if (currentFiles) {
          aNewFileWasUploaded = this.proposalHasBeenUploaded;
        }
      }
      if (fileType === 'other') {
        documentType = DocumentType.DOCUMENT_OTHER;
        if (currentFiles) {
          aNewFileWasUploaded = this.otherHasBeenUploaded;
        }
      }

      const success = await fileManager.fileService.uploadFiles(
        {
          vendor: vendor_id,
          documentType,
          entity_id: vendor_id,
          entity_type_id: EntityType.ORGANIZATION,
        },
        false,
        true
      );

      if (success && !this.authQuery.isAuxAdmin() && aNewFileWasUploaded && fileNames.length > 0) {
        await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.VENDOR_DOCUMENT_UPLOADED_NOTIFICATION,
            entity_type: EntityType.ORGANIZATION,
            entity_id: vendor_id,
            payload: JSON.stringify({
              document_type: documentType,
              uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
              file_name: fileNames,
            }),
          })
        );
      }
    }
  };

  onFilesAdded(fileType: string) {
    if (this.fileManager && fileType === 'contracts') {
      this.contractHasBeenUploaded = true;
    }
    if (this.fileManager2 && fileType === 'proposals') {
      this.proposalHasBeenUploaded = true;
    }
    if (this.fileManager3 && fileType === 'other') {
      this.otherHasBeenUploaded = true;
    }
  }
}
