<ng-container [ngSwitch]="variant">
  <ng-template [ngSwitchCase]="excelButtonVariants.OUTLINE">
    <button class="btn--secondary" [ngClass]="className" (click)="exportExcel()">
      <aux-icon name="FileExport" [class]="svgAttributes.class" [size]="svgSize" />
      <span class="ml-2">{{ title }}</span>
    </button>
  </ng-template>

  <ng-template [ngSwitchCase]="excelButtonVariants.FILLED">
    <button
      type="button"
      class="text-sm btn btn--blue"
      [ngClass]="className"
      (click)="exportExcel()"
    >
      <aux-icon name="FileExport" [class]="svgAttributes.class" [size]="svgSize" />
      <span class="ml-2">{{ title }}</span>
    </button>
  </ng-template>
</ng-container>
