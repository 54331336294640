<div *ngVar="uploadedFiles$ | async as uploadedFiles">
  <div *ngIf="uploadedFiles.length" style="max-height: 25vh" [class]="classNames">
    <p *ngIf="!titleLess">Uploaded files</p>
    <div
      *ngFor="let file of uploadedFiles; let i = index"
      class="flex justify-between items-center py-3"
      [ngClass]="{ 'border-t border-aux-gray-dark': i !== 0 }"
    >
      <p class="break-words" style="max-width: 85%">{{ file.fileName }}</p>
      <aux-icon name="Trash" class="text-aux-error" (click)="onRemoveFile(file)" />
    </div>
  </div>
</div>
