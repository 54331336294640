import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { UntypedFormControl } from '@angular/forms';
import { BudgetSnapshotType, EventType } from '@services/gql.service';
import { EventService } from '@services/event.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap, tap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { SnapshotService } from './snapshot.service';
import { SnapshotModalComponent } from '../snapshot-modal/snapshot-modal.component';
import { BudgetStore } from '../state/budget.store';
import { Overlay } from '@angular/cdk/overlay';

@UntilDestroy()
@Component({
  selector: 'aux-compare-dropdown',
  templateUrl: './compare-dropdown.component.html',
  styles: [
    `
      ::ng-deep .ng-option-selected::after {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareDropdownComponent implements OnInit {
  @Input() initialValue?: string;

  @Input() disabled?: boolean;

  formControl = new UntypedFormControl();

  loading$ = this.snapshotService.loading$;

  snapshots$ = this.snapshotService.getSnapShotVersions();

  BUDGET_SNAPSHOT_USER_CREATED = BudgetSnapshotType.BUDGET_SNAPSHOT_USER_CREATED;

  @Input() refreshTable!: VoidFunction;

  @Output() valueChange = new EventEmitter<string>();

  constructor(
    private snapshotService: SnapshotService,
    private overlayService: OverlayService,
    private eventService: EventService,
    private budgetStore: BudgetStore,
    public overlay: Overlay
  ) {}

  ngOnInit(): void {
    this.eventService
      .select$(EventType.CREATE_TRIAL_BUDGET_SNAPSHOT)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.overlayService.showFieldTooltip(
          'compare-snapshot-dropdown',
          'Snapshot successfully created'
        );
        firstValueFrom(this.snapshotService.getSnapshotList());
      });
    this.formControl.setValue(this.initialValue);

    this.snapshots$
      .pipe(
        untilDestroyed(this),
        tap((options) => {
          const isValueExist = options.find(({ value }) => value === this.formControl.value);

          if (!isValueExist) {
            this.formControl.setValue(undefined);
          }
        })
      )
      .subscribe();

    this.loading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      if (loading || this.disabled) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    });

    this.formControl.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap((snapshotName) => {
          this.valueChange.emit(snapshotName);
          if (!snapshotName) {
            this.snapshotService.setOriginalBudgetData();
            return of();
          }

          return this.snapshotService.getBudgetSnapshots(snapshotName);
        })
      )
      .subscribe();
  }

  groupByFn(option: {
    label: string;
    value: string;
    create_date: string;
    snapshot_type: string;
    id: string;
  }) {
    switch (option.snapshot_type) {
      case BudgetSnapshotType.BUDGET_SNAPSHOT_USER_CREATED:
        return 'MANUAL SNAPSHOTS';
      default:
        return 'AUTOMATIC SNAPSHOTS ';
    }
  }

  async editSnapshot(
    event: Event,
    snapshot: {
      label: string;
      value: string;
      create_date: string;
      snapshot_type: string;
      id: string;
    }
  ) {
    event.stopPropagation();
    const name = snapshot.label;
    const response = await firstValueFrom(
      this.overlayService.open<{
        name?: string;
      }>({
        content: SnapshotModalComponent,
        data: {
          name,
        },
      }).afterClosed$
    );
    if (response.data?.name) {
      const n = response.data.name;
      await this.emitUpdatedValue(
        name,
        n,
        () => {
          return this.snapshotService.updateSnapshots(snapshot, n);
        },
        () => this.formControl.setValue(n)
      );
    }
  }

  private async emitUpdatedValue(
    value: string,
    newName: string | undefined,
    callback: () => Promise<void>,
    updateControlCallback?: VoidFunction
  ) {
    if (value === this.formControl.value) {
      this.valueChange.emit(newName);
      this.budgetStore.setLoading(true);

      if (updateControlCallback) {
        updateControlCallback();
      }

      await callback();
      this.refreshTable();
    } else {
      await callback();
    }

    this.budgetStore.setLoading(false);
  }

  async deleteSnapshot(
    event: Event,
    snapshot: {
      label: string;
      value: string;
      create_date: string;
      snapshot_type: string;
      id: string;
    }
  ) {
    event.stopPropagation();

    const response = await firstValueFrom(
      this.overlayService.openConfirmDialog({
        header: 'Remove Plan',
        message: `Are you sure you want to remove ${snapshot.label} plan?`,
        okBtnText: 'Remove',
      }).afterClosed$
    );

    if (response.data?.result) {
      await this.emitUpdatedValue(
        snapshot.label,
        undefined,
        () => this.snapshotService.removeBudgetSnapshots(snapshot),
        () => {
          if (this.formControl.value === snapshot.label) {
            this.snapshotService.setOriginalBudgetData();
          }
        }
      );
    }
  }
}
