import Amplify from '@aws-amplify/core';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  stage: 'dev',
  restApiKey: 'gDiFbEHb3G5lLHbLQTTdF8DhUXpHsfyI1EIjzEJe',
  restApiEndpoint: 'https://flwuou2ej0.execute-api.us-east-1.amazonaws.com/yusuf',
  cognito: {
    identityPoolId: 'us-east-1:5a6f7980-07cd-45c7-ba13-1fb2ae84b246',
    region: 'us-east-1',
    userPoolId: 'us-east-1_k7BiMPxnE',
    userPoolWebClientId: '3btitg4np79masifrh93cbsfj4',
  },
  appSyncApiId: 'zph756tukzdwtmu52rsbsm5mvy',
  appSync: {
    aws_appsync_graphqlEndpoint:
      'https://26457yckjbgohn2tmt5zw43ef4.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    graphql_headers: async () => {
      return { Authorization: (await Amplify.Auth.currentSession()).idToken.jwtToken as never };
    },
  },
  s3: {
    AWSS3: {
      bucket: 'yusuf-auxilius-documents',
      identityPoolId: 'us-east-1:5a6f7980-07cd-45c7-ba13-1fb2ae84b246',
      region: 'us-east-1',
    },
  },
  launchDarkly: {
    clientId: '62f41b9d67be3b1153482f1a',
    clientName: 'dev',
  },
  analytics: {
    Pendo: {
      accountId: 'yusuf-local',
    },
  },
};
