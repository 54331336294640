<ng-container *ngIf="sitesQuery.selectLoading() | async">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(sitesQuery.selectLoading() | async) && !$any(loadingTable | async)">
  <div class="my-4 overflow-auto">
    <section class="mb-4 flex items-end justify-between flex-wrap">
      <form class="flex flex-wrap space-x-2" [formGroup]="transactionsForm">
        <aux-input
          class="w-52 mt-4"
          placeholder="Search"
          formControlName="name_filter"
          label=" "
          icon="Search"
        />
        <aux-multi-select-dropdown
          placeholder="Select"
          label="Country:"
          formControlName="country"
          class="w-52"
          bindLabel="name"
          bindValue="name"
          [items]="$any(countryOptions$ | async)"
          [loading]="(dataLoading$ | async)!"
        />
        <aux-multi-select-dropdown
          placeholder="Select"
          label="Currency:"
          formControlName="currency"
          class="w-32"
          bindLabel="name"
          bindValue="name"
          [items]="$any(currencyOptions$ | async)"
          [loading]="(dataLoading$ | async)!"
        />
        <aux-multi-select-dropdown
          placeholder="Select"
          label="Vendor:"
          formControlName="managed_by_name"
          class="w-52"
          bindLabel="name"
          bindValue="name"
          [items]="$any(organizationQuery.allVendors$ | async)"
          [loading]="(dataLoading$ | async)!"
        />
        <span
          class="text-sm font-normal aux-link cursor-pointer mt-7.5 mr-3 whitespace-nowrap"
          (click)="resetFilter()"
          >Reset Filters</span
        >
      </form>
      <div class="flex items-center space-x-2">
        <aux-button
          *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
          variant="secondary"
          label="Add New Site"
          icon="CirclePlus"
          (click)="onNewSite()"
        />
        <aux-button
          *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
          variant="secondary"
          label="Add Multiple Sites"
          icon="CirclePlus"
          (click)="onAddMultipleSites()"
        />
      </div>
    </section>
    <ag-grid-angular
      class="ag-theme-alpine sites-table tabular-nums w-full h-full"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="gridData$ | async"
      [domLayout]="'autoHeight'"
      [quickFilterText]="transactionsForm.getRawValue()?.name_filter"
      (gridReady)="onGridReady($event)"
      (columnResized)="autoSize()"
      (viewportChanged)="onWindowScroll()"
    />
  </div>
</ng-container>
