import { Injectable } from '@angular/core';
import { MainQuery } from '../layouts/main-layout/state/main.query';
import { ApiService } from './api.service';
import { GqlService, Document, UserAuditLog } from './gql.service';
import {
  ServerSideDatasource,
  ServerSideFilterInfo,
  applyFilterTransformationsToFormGroup,
} from '@shared/utils';
import { FormGroup } from '@angular/forms';
import { ColumnApi } from '@ag-grid-community/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatasourceService {
  private _documentDatasource = new ServerSideDatasource<Document>({
    endpoint: 'FetchDocuments',
    fetchFunction: (input) => firstValueFrom(this.gqlService.fetchDocumentsDataStream$(input)),
    mainQuery: this.mainQuery,
    apiService: this.apiService,
    gqlService: this.gqlService,
  });

  private _userAuditLogDatasource = new ServerSideDatasource<UserAuditLog>({
    endpoint: 'FetchUserAuditLogs',
    fetchFunction: (input) => firstValueFrom(this.gqlService.fetchUserAuditLogsDataStream$(input)),
    mainQuery: this.mainQuery,
    apiService: this.apiService,
    gqlService: this.gqlService,
  });

  constructor(
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private gqlService: GqlService
  ) {}

  get documentDatasource() {
    return this._documentDatasource;
  }

  get userAuditLogDatasource() {
    return this._userAuditLogDatasource;
  }

  /**
   * Prepares and returns an observable derived from the "formGroup.valueChanges" observable. It also returns the
   * current value of the form group. Both the current form value and the values that will be signalled by the
   * returned observable shall reflect the effect of the transformations applied for the corresponding input controls.
   */
  applyTransformations<T>(formGroup: FormGroup | unknown, filters: Array<ServerSideFilterInfo<T>>) {
    return applyFilterTransformationsToFormGroup(formGroup, filters);
  }

  /**
   * Retrieves the current sort model for the grid to which the columnApi is attached.
   */
  getCurrentSortModel<T>(columnApi: ColumnApi | null | undefined) {
    const result: Array<{ column: keyof T; descending: boolean }> = [];

    if (columnApi instanceof ColumnApi) {
      const sortedColumnStates = columnApi
        .getColumnState()
        .filter((cs) => cs.sort && Number.isInteger(cs.sortIndex));

      sortedColumnStates.sort((a, b) => (a.sortIndex || 0) - (b.sortIndex || 0));
      for (const columnState of sortedColumnStates) {
        const col = columnApi.getColumn(columnState.colId);
        if (col) {
          result.push({
            column: (col.getColDef().field || '') as keyof T,
            descending: columnState.sort === 'desc',
          });
        }
      }
    }

    return result;
  }
}
