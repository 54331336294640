import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { gridData } from './documentation-grid-data';
import { gridOptions } from '../documentation-grid-options';
import { AuxIconName } from '@components/icon/icon';

@Component({
  selector: 'aux-buttons',
  templateUrl: './buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ButtonsComponent {
  documentationData = gridData;

  documentationOptions = gridOptions;

  buttons: {
    variant: 'success' | 'primary' | 'negative' | 'secondary' | 'custom';
    variantName: string;
    icon: AuxIconName;
    classList?: string;
    description?: string;
  }[] = [
    {
      variant: 'primary',
      variantName: 'Primary',
      icon: 'Download',
    },
    {
      variant: 'secondary',
      variantName: 'Secondary',
      icon: 'FileExport',
    },
    {
      variant: 'negative',
      variantName: 'Negative',
      icon: 'X',
    },
    {
      variant: 'success',
      variantName: 'Success',
      icon: 'Check',
    },
    {
      variant: 'custom',
      variantName: 'Custom',
      icon: 'Lock',
      classList: 'btn--white btn',
      description: "Is used if other variants don't suit. Doesn't use any button classes.",
    },
  ];

  autoSize() {
    this.documentationOptions.api?.sizeColumnsToFit();
  }

  getClassListString(classList = '') {
    return classList ? ` classList="${classList}"` : '';
  }
}
