import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';
import { formatDate, NgClass, NgIf, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { IconComponent } from '@components/icon/icon.component';

export const QuarterCloseBannerHeight = 58;

@Component({
  selector: 'aux-quarter-close-banner',
  templateUrl: './quarter-close-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, TooltipDirective, NgIf, NgClass, NgStyle, IconComponent],
})
export class QuarterCloseBannerComponent {
  @Input() disabled = false;

  @Input() currentMonth = '';

  @Output() closeMonth = new EventEmitter();

  bannerHeight = QuarterCloseBannerHeight;

  get closeMonthText(): string {
    return this.currentMonth ? `Close ${formatDate(this.currentMonth, 'MMMM YYYY', 'en-US')}` : '';
  }

  get closeMonthBtnText(): string {
    return this.currentMonth ? `Close ${formatDate(this.currentMonth, 'MMMM', 'en-US')}` : '';
  }

  onCloseMonth(): void {
    this.closeMonth.emit();
  }

  readonly routerLink = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`;
}
