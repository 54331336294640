import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { FormControl, Validators } from '@angular/forms';
import { Note, User } from '@services/gql.service';
import { Utils } from '@services/utils';

export type NoteModalResponseType = { note: string } | undefined;

export type NoteModalDataType = {
  notes: Note[];
  users: Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>;
};

@Component({
  selector: 'aux-note-modal',
  template: `
    <div class="w-screen max-w-md">
      <div class="space-y-2.5 p-4">
        <aux-input
          label="Note"
          class="text-xs"
          [textArea]="true"
          [rows]="2"
          [validators]="'required'"
          [formControl]="textarea"
          [showRequiredAsterisk]="true"
        />

        <div class="max-h-60 overflow-auto space-y-2.5">
          <div *ngFor="let note of notes" class="border-l-4 border-aux-blue-dark pl-3">
            <div class="italic">
              {{ note.username }} - {{ note.create_date | date: 'dd MMMM y' }}:
            </div>
            <div>"{{ note.message }}"</div>
          </div>
        </div>
      </div>
    </div>
    <hr class="pt-5 bg-white" />
    <div class="bg-white pt-5 flex justify-between">
      <button class="focus:outline-none" type="button" (click)="ref.close()">Cancel</button>
      <button
        class="btn--success"
        data-pendo-id="add-note-in-month-adjustments"
        (click)="close(true)"
      >
        Save
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteModalComponent {
  textarea = new FormControl('', Validators.required);

  notes: (Note & {
    username: string;
  })[] = [];

  constructor(public ref: CustomOverlayRef<NoteModalResponseType, NoteModalDataType>) {
    this.notes = (this.ref.data?.notes || []).map((note) => {
      const user = this.ref.data?.users.get(note.created_by);
      return {
        ...note,
        message: Utils.unscrubUserInput(note.message),
        username: `${user?.given_name} ${user?.family_name}`,
      };
    });
  }

  close(bool: boolean) {
    const note = this.textarea.value;
    if (bool && !note) {
      this.textarea.markAsTouched();
      this.textarea.markAsDirty();
      this.textarea.updateValueAndValidity();
      return;
    }

    this.ref.close({
      note: note as string,
    });
  }
}
