import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { SitesService } from '@models/sites/sites.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AuthQuery } from '@models/auth/auth.query';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { EventType, PermissionType, WorkflowStep } from '@services/gql.service';
import { WorkflowQuery } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { AuthService } from '@models/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'aux-forecast-site-drivers',
  templateUrl: './forecast-site-drivers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastSiteDriversComponent {
  tabs: TabGroupConfig[] = [
    { label: 'Site Curves', route: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES },
    {
      label: 'Site Groups',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_site_groups),
      route: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.GROUPS,
    },
  ];

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES;

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  rightSideContainer = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  isAdminUser = false;

  userHasLockPatientSiteCurvePermission = false;

  constructor(
    private authQuery: AuthQuery,
    private sitesService: SitesService,
    private workflowQuery: WorkflowQuery,
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery,
    private authService: AuthService
  ) {
    this.setUserPermissions();
    this.sitesService.get().pipe(untilDestroyed(this)).subscribe();

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_SITE_CURVES],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasLockPatientSiteCurvePermission]) => {
        this.userHasLockPatientSiteCurvePermission = userHasLockPatientSiteCurvePermission;
      });
  }
}
