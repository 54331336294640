<div class="flex flex-row justify-between items-center">
  <div class="text-sm mr-1">Vendor</div>

  <ng-select
    class="w-64"
    [clearable]="false"
    [formControl]="vendorFormControl"
    [loading]="vendorLoading"
    (change)="vendorChangeFn($event)"
  >
    <ng-container>
      <ng-option *ngIf="vendorList.length > 1" [value]="''">All</ng-option>
      <ng-option *ngFor="let vendor of vendorList" [value]="vendor.id">
        <span [title]="vendor.name">{{ vendor.name }}</span>
      </ng-option>
    </ng-container>
  </ng-select>
</div>
