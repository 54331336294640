import { Injectable } from '@angular/core';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { mapValues } from 'lodash-es';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PermissionType } from '@directives/authorize.directive';
import { PeriodType } from './utils';
import type { LDUser } from 'launchdarkly-js-sdk-common';

export interface Flags {
  change_order_success_modal: boolean;
  adjustments_unit_totals: boolean;
  cronjob_bill_com_integration: string;
  cronjob_coupa_integration: string;
  cronjob_dynamics365_integration: string;
  cronjob_netsuite_integration: string;
  cronjob_oracle_fusion_integration: string;
  cronjob_quickbooks_online_integration: string;
  cronjob_sage_intacct_integration: string;
  clin_ops_dashboard: boolean;
  design_system: boolean;
  finance_dashboard: boolean;
  import_site_cost_data: boolean;
  finance_dash_remaining_investigator_spend: boolean;
  investigator_transactions_edc_created_date: boolean;
  invoices_payment_date: boolean;
  invoices_payment_status: boolean;
  invoice_detail: boolean;
  nav_home: boolean;
  nav_audit_history: boolean;
  nav_budget: boolean;
  nav_change: boolean;
  nav_design_system: boolean;
  nav_forecast: boolean;
  nav_invoices: boolean;
  nav_patient: boolean;
  nav_risk: boolean;
  nav_scenarios: boolean;
  nav_portfolio: boolean;
  nav_investigator: boolean;
  nav_trial_insights: boolean;
  nav_ops_admin: boolean;
  nav_system_maintenance_with_messages: {
    messages?: {
      message: string;
      timestamp: string;
    }[];
  };
  patient_drivers_edc: boolean;
  request_journal_entry_report_button: boolean;
  section_budget_analytics: boolean;
  section_compare_analytics: boolean;
  section_budget_snapshots: boolean;
  section_footer_roadmap: boolean;
  section_reconciliation_analytics: boolean;
  section_budget_type: boolean;
  section_profile: boolean;
  section_forecast_cost_through_eot: boolean;
  closing_checklist_toolbar: boolean;
  tab_profile_settings: boolean;
  tab_budget_changelog: boolean;
  tab_budget_payment_schedule: boolean;
  tab_budget_cash_management: boolean;
  tab_forecast_investigator_forecast: boolean;
  tab_forecast_in_month: boolean;
  tab_compare: boolean;
  tab_forecast_investigator_detail: boolean;
  tab_forecast_drivers: boolean;
  tab_forecast_passthrough: boolean;
  tab_patient_groups: boolean;
  tab_forecast_site_groups: boolean;
  client_preference_quarter_close_workflow_enabled: boolean;
  tab_trial_settings_patient_protocol: boolean;
  tab_investigator_transactions: boolean;
  section_forecast_analytics: boolean;
  section_patient_tracker_analytics: boolean;
  section_task_list: boolean;
  netsuite_integration_month_close: boolean;
  section_change_analytics: boolean;
  section_change_order_chart: boolean;
  client_preference_budget_period_type: PeriodType;
  client_preference_session_timeout: number;
  section_investigator_details_analytics: boolean;
  section_patient_driver_enrollment_settings: boolean;
  section_portfolio_analytics_avg_month_close: boolean;
  section_site_driver_assumptions: boolean;
  tab_change_order_review: boolean;
  tab_compare_scenario_manager: boolean;
  tab_budget_budget_library: boolean;
  tab_exchange_rates: boolean;
  tab_integrations: boolean;
  expense_defaults: boolean;
  tab_investigator_sites: boolean;
  tab_forecast_studyspecifications: boolean;
  tab_protocol_entry: boolean;
  adjustments_unit_of_measure: boolean;
  approval_rule_invoice: { rule: { name: string; permission_type: PermissionType }[] };
  approval_rule_in_month: { rule: { name: string; permission_type: PermissionType }[] };
  approval_rule_change_order: { rule: { name: string; permission_type: PermissionType }[] };
  budget_unit_of_measure: boolean;
  patient_groups_curve_only: boolean;
  tab_investigator_summary: boolean;
  vendor_currency: boolean;
  visit_costs: boolean;
  unpaid_invoices_ltd_column: boolean;
  investigator_transactions_site_address: boolean;
  super_user: { domains: string[] };
  tab_user_permissions: boolean;
  nav_document_library: boolean;
  tab_journal_entries: boolean;
  journal_entries_how_it_works_link: boolean;
  patient_tracker_planned_visits: boolean;
}

@Injectable()
export class LaunchDarklyService {
  ldClient!: LDClient;

  flags$ = new BehaviorSubject<Flags>({
    change_order_success_modal: false,
    adjustments_unit_totals: false,
    cronjob_bill_com_integration: '',
    cronjob_coupa_integration: '',
    cronjob_dynamics365_integration: '',
    cronjob_netsuite_integration: '',
    cronjob_oracle_fusion_integration: '',
    cronjob_quickbooks_online_integration: '',
    cronjob_sage_intacct_integration: '',
    closing_checklist_toolbar: false,
    clin_ops_dashboard: false,
    finance_dashboard: false,
    import_site_cost_data: false,
    finance_dash_remaining_investigator_spend: false,
    investigator_transactions_edc_created_date: false,
    invoices_payment_date: false,
    invoices_payment_status: false,
    invoice_detail: false,
    nav_home: false,
    nav_audit_history: false,
    nav_budget: false,
    nav_change: false,
    nav_design_system: false,
    nav_investigator: false,
    nav_forecast: false,
    nav_invoices: false,
    nav_patient: false,
    nav_risk: false,
    nav_scenarios: false,
    nav_portfolio: false,
    nav_trial_insights: false,
    nav_ops_admin: false,
    nav_system_maintenance_with_messages: {},
    netsuite_integration_month_close: false,
    client_preference_quarter_close_workflow_enabled: false,
    design_system: false,
    patient_drivers_edc: false,
    request_journal_entry_report_button: false,
    section_forecast_cost_through_eot: false,
    section_budget_analytics: false,
    section_compare_analytics: false,
    section_budget_snapshots: false,
    section_change_order_chart: false,
    section_reconciliation_analytics: false,
    section_profile: false,
    tab_profile_settings: false,
    tab_budget_payment_schedule: false,
    tab_budget_changelog: false,
    tab_compare: false,
    tab_budget_cash_management: false,
    tab_forecast_investigator_forecast: false,
    tab_forecast_in_month: false,
    tab_forecast_investigator_detail: false,
    tab_forecast_drivers: false,
    tab_forecast_studyspecifications: false,
    tab_forecast_passthrough: false,
    tab_patient_groups: false,
    tab_forecast_site_groups: false,
    tab_trial_settings_patient_protocol: false,
    tab_investigator_transactions: false,
    tab_investigator_sites: false,
    tab_exchange_rates: false,
    tab_integrations: false,
    expense_defaults: false,
    section_forecast_analytics: false,
    section_change_analytics: false,
    section_patient_tracker_analytics: false,
    section_task_list: false,
    client_preference_budget_period_type: PeriodType.PERIOD_QUARTER,
    client_preference_session_timeout: 1800,
    section_investigator_details_analytics: false,
    section_budget_type: false,
    section_footer_roadmap: false,
    section_patient_driver_enrollment_settings: false,
    section_portfolio_analytics_avg_month_close: false,
    section_site_driver_assumptions: false,
    tab_change_order_review: false,
    tab_compare_scenario_manager: false,
    tab_budget_budget_library: false,
    tab_protocol_entry: false,
    adjustments_unit_of_measure: false,
    approval_rule_invoice: { rule: [] },
    approval_rule_in_month: { rule: [] },
    approval_rule_change_order: { rule: [] },
    budget_unit_of_measure: false,
    patient_groups_curve_only: false,
    tab_investigator_summary: false,
    vendor_currency: false,
    visit_costs: false,
    unpaid_invoices_ltd_column: false,
    investigator_transactions_site_address: false,
    super_user: { domains: [] },
    tab_user_permissions: true,
    nav_document_library: true,
    tab_journal_entries: true,
    journal_entries_how_it_works_link: true,
    patient_tracker_planned_visits: false,
  });

  loaded$ = new BehaviorSubject(false);

  async initLaunchDarkly() {
    this.ldClient = initialize(environment.launchDarkly.clientId, {
      anonymous: true,
      custom: {
        clientName: environment.launchDarkly.clientName,
      },
    });

    await this.ldClient.waitForInitialization();

    this.flags$.next({ ...this.flags$.getValue(), ...this.ldClient.allFlags() });
    console.log('FLAGS ::: ', this.flags$.getValue());

    this.ldClient.on('change', (changedFlags) => {
      const flags = this.flags$.getValue();

      console.log('changedFlags:: ', changedFlags);
      const newFlags = {
        ...flags,
        ...mapValues(changedFlags, 'current'),
      };

      this.flags$.next(newFlags);
      console.log('FLAGS ::: ', this.flags$.getValue());
    });
    this.loaded$.next(true);
  }

  select$<R>(fn: (flags: Flags) => R): Observable<R> {
    return this.flags$.pipe(map(fn));
  }

  changeUser(user: LDUser) {
    return this.ldClient.identify(user);
  }
}
