import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { Column, IHeaderGroupParams, IProvidedColumn } from '@ag-grid-community/core';
import { AgSetColumnsVisible } from '@shared/utils';

interface IEnhanceHeaderGroupParams extends IHeaderGroupParams {
  dataId?: string;
  localStorageKey: string;
  expandableCols?: string[];
}

@Component({
  template: `
    <div class="flex items-center justify-center space-x-2">
      <span [attr.data-id]="params.dataId">{{ params.displayName }}</span>
      <button class="flex items-center no-underline aux-link" (click)="toggleExpand()">
        <aux-icon
          class="text-white"
          [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
          [size]="20"
        />
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderExpandComponent implements IHeaderGroupAngularComp {
  params!: IEnhanceHeaderGroupParams;

  visible = false;

  agInit(params: IEnhanceHeaderGroupParams): void {
    this.params = params;
  }

  filterCols(column: IProvidedColumn, index: number, columns: IProvidedColumn[]) {
    return index && index !== columns.length - 1;
  }

  initializeExpandCols() {
    if (this.params?.expandableCols) {
      let lsVisible = false;

      this.visible = this.params.expandableCols.some(
        (colId) => this.params.columnApi.getColumn(colId)?.isVisible()
      );

      if (localStorage.getItem(this.params.localStorageKey)) {
        lsVisible = localStorage.getItem(this.params.localStorageKey) === 'true';

        if (this.visible !== lsVisible) {
          this.toggleExpand();
        }
      }
    }
  }

  // gets called whenever the user gets the cell to refresh
  refresh() {
    return false;
  }

  toggleExpand() {
    const fields = this.params?.columnGroup
      .getProvidedColumnGroup()
      .getChildren()
      .filter(this.filterCols)
      .map((e) => (e as Column).getColDef().field || '');
    AgSetColumnsVisible({
      columnApi: this.params.columnApi,
      keys: fields,
      visible: !this.visible,
    });
    this.visible = !this.visible;
    if (this.params.localStorageKey)
      localStorage.setItem(this.params.localStorageKey, this.visible.toString());
  }
}
