<div class="flex p-4 bg-aux-gray-light border border-aux-gray-dark">
  <div class="w-[250px]">
    <div class="text-sm mb-2">Document Type:</div>

    <ng-select
      class="select select__big"
      [formControl]="documentTypeFormControl"
      [clearable]="false"
      [searchable]="false"
    >
      <ng-option *ngFor="let documentType of documentTypesOptions" [value]="documentType.value">
        {{ documentType.label }}
      </ng-option>
    </ng-select>

    <ng-container *ngIf="isSiteFormControlVisible()">
      <div class="text-sm mb-2 mt-4">Site (optional):</div>

      <ng-select
        class="select select__big"
        [formControl]="siteFormControl"
        [clearable]="true"
        [searchable]="false"
        placeholder="Select"
      >
        <ng-option *ngFor="let site of siteOptions" [value]="site.value">
          {{ site.label }}
        </ng-option>
      </ng-select>
    </ng-container>
  </div>

  <div class="self-stretch bg-aux-gray-dark w-px mx-4"></div>

  <div class="flex-grow">
    <div class="text-sm mb-2">Select from Document Library (if trial selected):</div>

    <ng-select
      class="select select__big multi-select"
      [formControl]="documentLibraryFormControl"
      [clearable]="false"
      [searchable]="false"
      [multiple]="true"
      placeholder="Select"
      (add)="addDocLibraryItem($event)"
      (remove)="removeDocLibraryItem($event)"
    >
      <ng-option *ngFor="let document of documentLibraryOptions" [value]="document.value">
        {{ document.label }}
      </ng-option>
    </ng-select>

    <div class="text-sm my-4">or</div>

    <aux-file-manager
      #fileManager
      class="h-28 bg-aux-gray-light"
      [fetchFilesOnInit]="false"
      [pathFn]="getBucketKey"
      [eager]="false"
      [showSuccessOnUpload]="true"
      [accept]="'.pdf'"
      [extensions]="['application/pdf']"
      [showSpecificError]="true"
      [entityIdS3Uploading]="true"
    >
      <div class="flex items-center justify-center text-aux-gray-dark-100">
        <aux-icon name="Upload" [size]="42" />
        <div class="ml-3">
          <p class="text-lg">Drag & Drop Documents Here or <span class="aux-link">Browse</span></p>
        </div>
      </div>
    </aux-file-manager>
  </div>

  <div class="pl-8 w-[300px] flex flex-col">
    <div class="text-sm border-b border-aux-gray-dark">Selected Files</div>

    <aux-file-manager-uploaded-files
      [fileManager]="fileManager"
      class="flex-grow"
      [titleLess]="true"
      filesContainerClassNames="!mt-0"
      (removeFile)="removeFromDocLibraryControl($event)"
    />

    <div class="flex justify-between mt-2">
      <aux-button variant="secondary" label="Clear All" (click)="clearAll()" />

      <aux-button
        variant="primary"
        label="Extract"
        [disabled]="uploadedFiles.length < 1"
        [onClick]="extract.bind(this)"
      />
    </div>
  </div>
</div>
