import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PermissionType } from '@services/gql.service';

export interface AuthState {
  sub: string;
  given_name: string;
  family_name: string;
  email: string;
  is_admin: boolean;
  trial_id: string;
  permissions: PermissionType[];
}

export function createInitialState(): AuthState {
  return {
    sub: '',
    given_name: '',
    family_name: '',
    email: '',
    is_admin: false,
    trial_id: '',
    permissions: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
