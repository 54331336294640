import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';
import {
  TICPCChartLabel,
  TICPCChartType,
  TrialInsightsClinicalPatientCostChartService,
} from './patient-cost-chart.service';
import { TrialInsightsClinicalPatientCostQueryService } from './patient-cost-query.service';
import { TrialInsightsClinicalPatientCostStoreService } from './patient-cost-store.service';
import { TrialInsightsClinicalPatientCostTableService } from './patient-cost-table.service';
import { ChartConfiguration } from 'chart.js';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-clinical-patient-cost',
  templateUrl: './patient-cost.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrialInsightsClinicalPatientCostQueryService],
})
export class TrialInsightsClinicalPatientCostComponent extends GenericTrialInsightsComponent<
  TICPCChartType,
  TICPCChartLabel
> {
  constructor(
    public chartService: TrialInsightsClinicalPatientCostChartService,
    public tableService: TrialInsightsClinicalPatientCostTableService,
    public storeService: TrialInsightsClinicalPatientCostStoreService,
    public queryService: TrialInsightsClinicalPatientCostQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'Average Patient Cost (Total)',
      color: '#094673',
      exceedMessage: 'Patients Enrolled (actual) has exceeded Patients Enrolled (expected)',
      chartService,
      tableService,
      queryService,
      cdr,
    });
  }

  subscribeToData = () => {
    this.queryService.processPatientCostSummary$().pipe(untilDestroyed(this)).subscribe();
    this.storeService.getPatientCostSummary$().pipe(untilDestroyed(this)).subscribe();
  };

  subscribeToOptions = () => {
    combineLatest([
      this.queryService.chartOptions,
      this.queryService.legendOptions,
      this.queryService.tableOptions,
      this.queryService.totalAmount,
      this.queryService.expectedEnrolled,
      this.queryService.expectedEnrolledExceeded,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          chartOptions,
          legendOptions,
          tableOptions,
          totalAmount,
          expectedEnrolled,
          expectedEnrolledExceeded,
        ]) => {
          this.setChartData(chartOptions);
          this.legendOptions = legendOptions;
          this.tableOptions = tableOptions;
          this.total = totalAmount;
          this.expectedEnrolled = expectedEnrolled;
          this.expectedEnrolledExceeded = expectedEnrolledExceeded;

          this.cdr.markForCheck();
        }
      );
  };

  // Prevent chart from redrawing when
  // data is identical
  setChartData(chartOptions: ChartConfiguration<'bar'>) {
    const dataset = this.chartOptions.data.datasets;
    const nextDataset = chartOptions.data.datasets;

    if (!dataset.length || !nextDataset.length) {
      this.chartOptions = chartOptions;
      return;
    }

    const data = dataset[0].data;
    const nextData = nextDataset[0].data;

    if (!data?.length || !nextData?.length) {
      this.chartOptions = chartOptions;
      return;
    }

    const value = data[0];
    const nextValue = nextData[0];

    if (value !== nextValue) {
      this.chartOptions = chartOptions;
      return;
    }
  }
}
