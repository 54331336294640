<div
  *ngVar="labelDirection === 'horizontal' as isHorizontalLabel"
  class="flex justify-between items-center"
  [ngClass]="className"
>
  <form
    class="flex items-center space-x-4"
    [formGroup]="form"
    [ngClass]="{ invisible: !alwaysShowSection && !versionOptions.length }"
  >
    <div
      class="flex text-sm relative"
      [ngClass]="{ 'items-center': isHorizontalLabel, 'flex-col': !isHorizontalLabel }"
    >
      <div
        [ngClass]="{
          'mr-2': isHorizontalLabel,
          'mb-2': !isHorizontalLabel
        }"
      >
        Protocol Version
      </div>
      <div class="flex items-center text-sm">
        <ng-select
          *ngIf="!isVersionControlEditable"
          #versionDropdown
          id="protocolVersion"
          class="w-56 tabular-nums custom-dropdown-options"
          placeholder="Select Protocol Version"
          formControlName="protocolVersion"
          bindLabel="label"
          bindValue="value"
          [ngClass]="{ 'editable-protocol-version': isVersionControlEditable }"
          [clearable]="false"
          [searchable]="false"
          [items]="versionOptions"
        >
          <ng-option
            *ngFor="let item of versionOptions; index as index; let first = first"
            [value]="item.value"
          >
            {{ item.label }}
          </ng-option>

          <ng-template ng-footer-tmp class="flex flex-col">
            <div class="items-wrapper grow">
              <ng-option *ngFor="let item of versionOptions; index as index; let first = first">
                <div
                  *ngIf="!first && index === 1"
                  class="border-b h-1 border-aux-gray-darkest mb-2 mt-1 mr-2 ml-2"
                ></div>

                <div
                  class="relative flex justify-between text-gray-900 cursor-default select-none py-2 pl-2 hover:bg-aux-gray-light"
                  [ngClass]="{ 'ng-option-selected': form.value.protocolVersion === item.value }"
                  (click)="
                    form.controls.protocolVersion.setValue(item.value); versionDropdown.close()
                  "
                >
                  <div class="flex-1">
                    {{ item.label }}
                  </div>
                </div>
              </ng-option>
            </div>
          </ng-template>
        </ng-select>

        <aux-editable-list-dropdown
          *ngIf="isVersionControlEditable && form.controls?.protocolVersion"
          [dropdownFormControl]="form.controls.protocolVersion"
          [id]="'protocolVersion'"
          [items]="convertOptionToEditableState()"
          [classList]="'w-56 tabular-nums'"
          (edit)="editProtocolVersion.emit($event)"
          (delete)="deleteProtocolVersion.emit($event)"
        />
      </div>
    </div>

    <aux-button
      *ngIf="!hideAmendment"
      label="Amendment"
      variant="secondary"
      [disabled]="isAmendmentDisabled"
      [onClick]="onCreateAmendment"
      icon="CirclePlus"
    />

    <div
      class="flex items-center text-sm"
      [ngClass]="{ 'items-center': isHorizontalLabel, 'flex-col': !isHorizontalLabel }"
    >
      <div
        [ngClass]="{
          'mr-2': isHorizontalLabel,
          'mb-2': !isHorizontalLabel
        }"
      >
        Patient Group/Invoiceables
      </div>
      <ng-select
        class="w-72 tabular-nums"
        placeholder="Select Patient Group/Invoiceables"
        formControlName="patientGroup"
        [clearable]="false"
        [searchable]="false"
      >
        <div *ngFor="let option of patientOptions">
          <ng-option *ngIf="getOptionWithVisibility(option).show | async" [value]="option.value">
            {{ option.label }}
          </ng-option>
        </div>
      </ng-select>
    </div>
  </form>

  <div *ngIf="template">
    <ng-container *ngTemplateOutlet="template" />
  </div>
</div>
