import { Column, ColumnApi } from '@ag-grid-community/core';

// If the column visibility is true and the code is trying to make that column visibility true
// sometimes ag-grid enters infinite loop. This happened on prod env.
// This method fixes ag-grid infinite re-render issue.
export function AgSetColumnsVisible({
  columnApi,
  keys,
  visible,
}: {
  columnApi: ColumnApi;
  keys: (string | Column)[];
  visible: boolean;
}) {
  const fields = keys
    .map((key) => (typeof key === 'string' ? columnApi.getColumn(key) : key))
    .filter((k): k is Column => !!k && k.isVisible() === !visible);
  if (fields.length) {
    columnApi.setColumnsVisible(fields, visible);
  }
}
