<ng-container
  *ngIf="(periodCloseComponent.loading$ | async) === true || (loading$ | async) === true"
>
  <div class="border-8 h-32 m-auto my-40 spinner w-32"></div>
</ng-container>

<ng-container
  *ngIf="(periodCloseComponent.loading$ | async) === false && (loading$ | async) === false"
>
  <div class="pt-4 pb-6 px-7">
    <div class="flex justify-between mb-4">
      <div class="flex items-center space-x-2">
        <ng-select class="w-52" [formControl]="selected_month" [clearable]="false">
          <ng-option *ngFor="let m of months" [value]="m.iso">
            {{ m.label }}
          </ng-option>
        </ng-select>
        <ng-select
          class="w-44"
          bindLabel="label"
          bindValue="value"
          [formControl]="selected_vendor"
          [clearable]="false"
          [items]="$any(vendors$ | async)"
        >
          <ng-template let-item="item" ng-option-tmp>
            <div class="w-32 overflow-hidden text-ellipsis" title="{{ item.label }}">
              {{ item.label }}
            </div>
          </ng-template>
        </ng-select>
        <ng-select
          class="w-44"
          bindLabel="label"
          bindValue="value"
          [formControl]="selected_category"
          [clearable]="false"
          [items]="categories"
        />
        <ng-select
          class="w-44"
          placeholder="Materiality: All"
          bindLabel="label"
          bindValue="value"
          [ngClass]="{
            'show-placeholder': selected_threshold.value === ''
          }"
          [formControl]="selected_threshold"
          [clearable]="false"
          [items]="materialityThresholds"
        />
      </div>
      <div class="flex items-center space-x-4">
        <div
          *auxAuthorize="{ permissions: ['PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS'] }"
          [auxTooltip]="$any(editButtonTooltip$ | async)"
        >
          <button
            *ngIf="(editMode$ | async) === false"
            class="btn btn--secondary bg-white"
            [disabled]="$any(editButtonDisabled$ | async)"
            (click)="onEditMode()"
          >
            <aux-icon name="Pencil" [size]="20" />
            <span class="ml-1">Edit</span>
          </button>
        </div>

        <ng-container *ngIf="(editMode$ | async) === true">
          <button type="button" class="btn btn--secondary" (click)="onCancel()">
            <aux-icon name="X" [size]="20" />
            <span class="ml-1">Cancel</span>
          </button>
          <aux-button
            *ngIf="(editMode$ | async) === true"
            label="Save"
            variant="success"
            classList="ml-1 btn"
            [disabled]="editedRows.size === 0"
            [auxTooltip]="
              editedRows.size === 0 ? 'You need to make one or more changes to save' : ''
            "
            [onClick]="onSave.bind(this)"
            [iconSize]="20"
            icon="Check"
          />
        </ng-container>
        <aux-button
          variant="secondary"
          label="Export"
          classList="ml-1 btn"
          icon="FileExport"
          [onClick]="this.onExport.bind(this)"
          [spinnerSize]="7"
          [iconSize]="20"
        />
      </div>
    </div>

    <ag-grid-angular
      style="min-width: 1000px; max-width: 100%"
      class="ag-theme-aux tabular-nums w-full adjustment-table"
      domLayout="autoHeight"
      fillHandleDirection="y"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="$any(filteredGridData$ | async)"
      [enableFillHandle]="(editMode$ | async) === true"
      [groupAllowUnbalanced]="true"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      (viewportChanged)="onWindowScroll()"
      (filterChanged)="onFilterChanged()"
    />
  </div>
</ng-container>
