import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { gridOptions } from '../documentation-grid-options';
import { gridData } from './documentation-grid-data';
import { BehaviorSubject } from 'rxjs';
import { ButtonToggleItem } from '@components/button-toggle-group/button-toggle-item.model';

@Component({
  selector: 'aux-button-toggle-group-demo',
  templateUrl: './button-toggle-component-demo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ButtonToggleComponentDemoComponent {
  documentationData = gridData;

  documentationOptions = gridOptions;

  activeTabId$ = new BehaviorSubject<string>('1');

  tabs: ButtonToggleItem[] = [
    {
      label: 'Value 1',
      value: '1',
    },
    {
      label: 'Value 2',
      value: '2',
    },
    {
      label: 'Value 3',
      value: '3',
    },
  ];

  autoSize() {
    this.documentationOptions.api?.sizeColumnsToFit();
  }
}
