import { WorkflowStep } from '@services/gql.service';

export const WORKFLOW_NAMES = {
  WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS: 'Open Month Adjustments',
  WF_STEP_MONTH_CLOSE_LOCK_TIMELINE: 'Trial Timeline',
  WF_STEP_MONTH_CLOSE_LOCK_CURVES: 'Site and Patient Curves',
  WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER: 'Confirm Patient Data',
  WF_STEP_MONTH_CLOSE_LOCK_FORECAST_METHODOLOGY: 'Forecast Methodology',
  WF_STEP_MONTH_CLOSE_LOCK_INVOICES: 'Invoices',
} as { [k in WorkflowStep]: string };

export const MONTH_ADJUSTMENT_TOOLTIP =
  'Prior Closing Checklist entries must all be confirmed before adjustments can be locked';
