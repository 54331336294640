import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConverterToolComponent } from './converter-tool/converter-tool.component';
import { ExtractorComponent } from './extractor/extractor.component';

const routes: Routes = [
  {
    path: 'edc-converter',
    component: ConverterToolComponent,
    data: {
      baseUrl: 'https://auxilius-patient-tracker.shinyapps.io/auxilius-edc-converter',
    },
  },
  {
    path: 'je-generator',
    component: ConverterToolComponent,
    data: {
      baseUrl: 'https://je-generator.shinyapps.io/je_app_v4',
    },
  },
  {
    path: 'extractor',
    component: ExtractorComponent,
  },
  { path: '**', redirectTo: 'edc-converter' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OpsAdminRoutingModule {}
