import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { UntypedFormControl } from '@angular/forms';
import { map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import {
  BudgetType,
  Currency,
  EntityType,
  EventType,
  GqlService,
  PermissionType,
  WorkflowStep,
} from '@services/gql.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { EventService } from '@services/event.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { PatientGroupsService } from 'src/app/pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@models/auth/auth.query';
import * as dayjs from 'dayjs';
import { CategoryService } from './category/category.service';
import { CategoryQuery } from './category/category.query';
import { ForecastSettingsStore } from './settings/forecast-settings.store';
import { PatientCurveService } from './patient-curve/patient-curve.service';
import { PatientCurveQuery } from './patient-curve/patient-curve.query';
import { WorkflowQuery } from '../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { ROUTING_PATH } from '../../../../app-routing-path.const';
import { CategoryStore } from './category/category.store';
import { SiteCurveQuery } from './drivers/forecast-sites/site-curve/site-curve.query';
import { ForecastTableGridComponent } from './forecast-table/forecast-table-grid.component';
import { ExportType } from '@services/utils';
import { ActivityQuery } from './activity/activity.query';
import { AuthService } from '@models/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'aux-forecast',
  templateUrl: './forecast.component.html',
  styles: [
    `
      ::ng-deep .ng-select.ng-select-disabled .ng-arrow-wrapper {
        display: none;
      }

      ::ng-deep .ng-select.ng-select-disabled > .ng-select-container {
        background-color: var(--aux-gray-light);
      }

      ::ng-deep .ng-select.ng-select-disabled .ng-value {
        color: var(--aux-gray-dark-100);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastComponent implements OnInit {
  @ViewChild(ForecastTableGridComponent) forecastTableGridComponent!: ForecastTableGridComponent;

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_FORECAST_METHODOLOGY;

  patientDriversLink = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`;

  siteDriversLink = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`;

  isPatientDriverAvailable = false;

  isSiteDriverAvailable = false;

  isPatientDriversLinkVisible = false;

  isSiteDriversLinkVisible = false;

  discountType$ = new BehaviorSubject('DISCOUNT_PERCENTAGE');

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  saveCheck$ = new BehaviorSubject(false);

  hideDiscounts$ = new BehaviorSubject(true);

  successForecast$ = new BehaviorSubject(true);

  changeDiscountTotalAmount = new UntypedFormControl(0);

  isAdminUser = false;

  selectedVendor = new UntypedFormControl('');

  orgCurrency = Currency.USD;

  userHasLockForecastMethodologyPermission = false;

  constructor(
    private route: ActivatedRoute,
    public organizationQuery: OrganizationQuery,
    public activityQuery: ActivityQuery,
    public categoryQuery: CategoryQuery,
    private overlayService: OverlayService,
    private categoryService: CategoryService,
    public categoryStore: CategoryStore,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private forecastSettingsStore: ForecastSettingsStore,
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery,
    private patientGroupsService: PatientGroupsService,
    private patientCurveService: PatientCurveService,
    private patientCurveQuery: PatientCurveQuery,
    private workflowQuery: WorkflowQuery,
    private authQuery: AuthQuery,
    private eventService: EventService,
    private siteCurveQuery: SiteCurveQuery,
    private gqlService: GqlService,
    private authService: AuthService
  ) {
    this.setUserPermissions();
  }

  isForecastFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(this.workflowName);

  changeDiscountTotalPercent = new UntypedFormControl({
    value: 0,
    disabled: this.isForecastFinalized$,
  });

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  showAnalyticsSection$: Observable<boolean> = this.launchDarklyService.select$(
    (flags) => flags.section_forecast_analytics
  );

  isSelectedVendorHasManualForecast$ = this.organizationQuery.selectActive().pipe(
    map((org) => {
      this.orgCurrency = org?.currency || Currency.USD;
      return !!this.organizationQuery.getPrimaryBudgetVersion(org?.id || '')?.manual_forecast;
    })
  );

  topLevelActivities$ = this.activityQuery.selectAll({
    filterBy: (a) => {
      return !a.category_id;
    },
  });

  ngOnInit(): void {
    this.patientGroupsService.get().pipe(untilDestroyed(this)).subscribe();

    this.patientCurveService.get().pipe(untilDestroyed(this)).subscribe();

    this.categoryService.getCategories().pipe(untilDestroyed(this)).subscribe();

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((isAdminUser) => {
      this.isAdminUser = isAdminUser;
    });

    this.initVendorFilter().subscribe(([{ vendor_id }]) => {
      this.processVendorFilter(vendor_id);
    });

    this.initForecastChanges().subscribe(([isFinalized, iCloseMonthsProcessing]) => {
      this.processForecastChanges(isFinalized, iCloseMonthsProcessing);
    });

    this.initTrialKey().subscribe();

    this.initTrialChanges().subscribe(() => {
      this.processTrialChanges();
    });
    const selectedVendorId = localStorage.getItem('forecastVendor');
    if (selectedVendorId) {
      this.setVendorId(selectedVendorId);
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.saveCheck$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);

      return !!event.data;
    }

    return true;
  }

  onOrganizationSelected(orgId: string) {
    this.organizationStore.setActive(orgId);
    this.saveCheck$.next(false);
    this.forecastTableGridComponent.initialSettings = {};
    this.forecastTableGridComponent.ToggleService.reset();
    this.processVendorFilter(orgId);
  }

  onHideActivitiesWithNoRemainingCost(bool: boolean) {
    this.categoryStore.hideActivitiesWithNoRemainingCost$.next(bool);
  }

  onShowOnlyUnforecasted(bool: boolean) {
    this.categoryStore.hideForecastedActivities$.next(bool);
  }

  clearFilters() {
    this.categoryStore.hideActivitiesWithNoRemainingCost$.next(false);
    this.categoryStore.hideForecastedActivities$.next(false);
  }

  setVendorId(vendorId: string) {
    this.selectedVendor.setValue(vendorId);
    this.organizationStore.setActive(vendorId);
    this.setDiscountType(vendorId);
    localStorage.setItem(`forecastVendor`, vendorId);
  }

  async setDiscountType(vendorId: string) {
    const primary = await firstValueFrom(
      this.gqlService.listBudgetVersions$([BudgetType.BUDGET_PRIMARY], vendorId)
    );

    this.discountType$.next((primary.data || [])?.[0]?.discount_type || '');
  }

  initVendorFilter() {
    return combineLatest([this.route.queryParams, this.organizationService.get()]).pipe(
      untilDestroyed(this)
    );
  }

  initForecastChanges() {
    return combineLatest([this.isForecastFinalized$, this.iCloseMonthsProcessing$]).pipe(
      untilDestroyed(this)
    );
  }

  initTrialKey() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.hideDiscounts$.next(true);
        this.forecastSettingsStore.ui.update(() => ({ showError: false }));
        return combineLatest([
          this.patientCurveQuery.selectAll(),
          this.siteCurveQuery.selectAll(),
        ]).pipe(
          tap(([patientCurveResults, siteCurveResults]) => {
            this.isPatientDriverAvailable = !!patientCurveResults?.length;
            this.isSiteDriverAvailable = !!siteCurveResults?.length;
          })
        );
      }),
      untilDestroyed(this)
    );
  }

  initTrialChanges() {
    return combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ]).pipe(untilDestroyed(this));
  }

  processVendorFilter(vendorId: string): void {
    const orgId = this.organizationQuery.getActive()?.id;

    if (orgId) {
      this.setVendorId(orgId);
    } else {
      const vendors = this.organizationQuery.getAllVendors();

      if (vendors.length) {
        const vendorFromQueryUrl = vendors.find(({ id }) => id === vendorId);

        this.setVendorId(vendorFromQueryUrl?.id || vendors[0].id);
      }
    }
  }

  processForecastChanges(isFinalized: boolean, iCloseMonthsProcessing: boolean): void {
    if (isFinalized || iCloseMonthsProcessing) {
      this.changeDiscountTotalPercent.disable();
    } else {
      this.changeDiscountTotalPercent.enable();
    }
  }

  processTrialChanges(): void {
    this.saveCheck$.next(false);
    this.forecastTableGridComponent.initialSettings = {};
    this.forecastTableGridComponent.ToggleService.reset();
  }

  onSaveCheck(newValue: boolean): void {
    this.saveCheck$.next(newValue);
  }

  onIsPatientDriversLinkVisible(newValue: boolean): void {
    this.isPatientDriversLinkVisible = newValue;
  }

  onIsSiteDriversLinkVisible(newValue: boolean): void {
    this.isSiteDriversLinkVisible = newValue;
  }

  onHideDiscounts(newValue: boolean): void {
    this.hideDiscounts$.next(newValue);
  }

  onSuccessForecast(newValue: boolean): void {
    this.successForecast$.next(newValue);
  }

  onChangeDiscountTotalAmount(newValue: number): void {
    this.changeDiscountTotalAmount.setValue(newValue);
  }

  onChangeDiscountTotalPercent(newValue: number): void {
    this.changeDiscountTotalPercent.setValue(newValue);
  }

  onChangeDiscountTotalAmountEnable(enable: boolean): void {
    if (enable) {
      this.changeDiscountTotalAmount.enable();
    } else {
      this.changeDiscountTotalAmount.disable();
    }
  }

  onChangeDiscountTotalPercentEnable(enable: boolean): void {
    if (enable) {
      this.changeDiscountTotalPercent.enable();
    } else {
      this.changeDiscountTotalPercent.disable();
    }
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  async onDownloadForecastSettings() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.FORECAST_SETTINGS,
          filename: `${trialName}_Forecast Methodology_${dateStr}`,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }

    this.btnLoading$.next(false);
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_FORECAST_METHODOLOGY],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasLockForecastMethodologyPermission]) => {
        this.userHasLockForecastMethodologyPermission = userHasLockForecastMethodologyPermission;
      });
  }
}
