import { Column, IHeaderParams } from '@ag-grid-community/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHeaderAngularComp } from '@ag-grid-community/angular/lib/interfaces';
import { AgSetColumnsVisible } from '@shared/utils';

@Component({
  templateUrl: './ag-header-collapse-columns-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderCollapseColumnsActionComponent implements IHeaderAngularComp {
  params!: IHeaderParams;

  change$ = new BehaviorSubject(true);

  patientGroupsColumns: string[] = [];

  get headerName(): string {
    return this.params?.displayName || '';
  }

  agInit(params: IHeaderParams): void {
    this.params = params;

    this.params?.columnApi.getColumns()?.forEach((column: Column) => {
      if (column.getColId().includes('patient_group::')) {
        this.patientGroupsColumns.push(column.getColId());
      }
    });
  }

  onChangeClick() {
    AgSetColumnsVisible({
      columnApi: this.params.columnApi,
      keys: this.patientGroupsColumns,
      visible: !this.change$.getValue(),
    });
    this.params?.api.sizeColumnsToFit();
    this.change$.next(!this.change$.getValue());
  }

  refresh(): boolean {
    return false;
  }
}
