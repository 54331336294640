import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  WorkflowModel,
  WorkflowQuery,
  WorkflowService,
} from '../../close-quarter-check-list/store';
import { AuxIconName } from '@components/icon/icon';
import { MessagesConstants } from '@constants/messages.constants';

export enum wfNames {
  INVOICES = 'Review Invoices',
  PATIENT_DATA = 'Confirm Patient Data',
  TRIAL_TIMELINE = 'Confirm Trial Timeline',
  PATIENT_SITE_DRIVERS = 'Site & Patient Curves',
  FORECAST_METHADOLOGY = 'Forecast Methodology',
}

@Component({
  selector: 'aux-workflow-panel-general-item',
  templateUrl: './workflow-panel-general-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPanelGeneralItemComponent implements OnInit {
  @Input() workflowName!: string;

  @Input() auditTextCallback!: (workflow: WorkflowModel) => string;

  @Input() headerTextCallback!: (workflow: WorkflowModel) => string;

  @Input() lockAvailable = true;

  @Input() isWorkflowNameVisible = true;

  @Input() isAdminUser = false;

  @Input() hasUserPermissions = false;

  currentWorkflow$?: Observable<WorkflowModel | null>;

  constructor(
    private workflowService: WorkflowService,
    private workflowQuery: WorkflowQuery
  ) {}

  ngOnInit(): void {
    this.currentWorkflow$ = this.workflowQuery.getWorkflowByStepType(this.workflowName);
  }

  parseWorkflowTitle(workflowName: string): string {
    if (!workflowName) {
      return '';
    }

    if (workflowName.includes('Confirm ')) {
      const finalNameIndex = 1;
      return workflowName.split('Confirm ')[finalNameIndex];
    }

    if (workflowName.includes('Review ')) {
      const finalNameIndex = 1;
      return workflowName.split('Review ')[finalNameIndex];
    }

    return workflowName;
  }

  onToggleLockWorkflow(currentWorkflow: WorkflowModel) {
    this.workflowService.changeLockStatus(
      !currentWorkflow.properties.locked,
      currentWorkflow,
      this.isAdminUser,
      false
    );
  }

  getBtnLabel(currentWorkflow: WorkflowModel): string {
    const text = currentWorkflow.properties.locked ? 'Unlock' : 'Lock';

    return `${text} ${this.parseWorkflowTitle(this.headerTextCallback(currentWorkflow))}`;
  }

  getBtnVariant(currentWorkflow: WorkflowModel): 'secondary' | 'primary' {
    return currentWorkflow.properties.locked ? 'secondary' : 'primary';
  }

  getBtnIcon(currentWorkflow: WorkflowModel): AuxIconName {
    return currentWorkflow.properties.locked ? 'LockOpen' : 'Lock';
  }

  getBtnTooltipText(): string {
    return !this.hasUserPermissions ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION : '';
  }

  getBtnDataPendoId(currentWorkflow: WorkflowModel): string {
    if (currentWorkflow.properties.locked) {
      return currentWorkflow.name === wfNames.INVOICES
        ? 'review-invoices-toolbar-unlock'
        : currentWorkflow.name === wfNames.FORECAST_METHADOLOGY
        ? 'forecast-methodology-toolbar-unlock'
        : currentWorkflow.name === wfNames.PATIENT_SITE_DRIVERS
        ? 'site-and-patient-curves-toolbar-unlock'
        : currentWorkflow.name === wfNames.TRIAL_TIMELINE
        ? 'confirm-trial-timeline-toolbar-unlock'
        : currentWorkflow.name === wfNames.PATIENT_DATA
        ? 'confirm-patient-data-toolbar-unlock'
        : '';
    }

    return currentWorkflow.name === wfNames.INVOICES
      ? 'review-invoices-toolbar-lock'
      : currentWorkflow.name === wfNames.FORECAST_METHADOLOGY
      ? 'forecast-methodology-toolbar-lock'
      : currentWorkflow.name === wfNames.PATIENT_SITE_DRIVERS
      ? 'site-and-patient-curves-toolbar-lock'
      : currentWorkflow.name === wfNames.TRIAL_TIMELINE
      ? 'confirm-trial-timeline-toolbar-lock'
      : currentWorkflow.name === wfNames.PATIENT_DATA
      ? 'confirm-patient-data-toolbar-lock'
      : '';
  }
}
