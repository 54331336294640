import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationModel } from '@models/organization/organization.store';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { BudgetType, Currency } from '@services/gql.service';
import {
  OrganizationDialogComponent,
  OrganizationDialogData,
} from './organization-dialog/organization-dialog.component';
import { MultipleOrganizationsDialogComponent } from './multiple-organizations-dialog/multiple-organizations-dialog';

@UntilDestroy()
@Component({
  selector: 'aux-vendors',
  templateUrl: './vendors.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorsComponent {
  constructor(
    private organizationService: OrganizationService,
    private overlayService: OverlayService,
    public vendorsQuery: OrganizationQuery
  ) {
    this.organizationService
      .getListWithTotalBudgetAmount(BudgetType.BUDGET_PRIMARY)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  currencyFormatter(amount: unknown, currency?: Currency) {
    if (amount !== undefined && amount && !Number.isNaN(amount)) {
      return Utils.currencyFormatter(amount, {}, currency);
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });

    return `${formatter.formatToParts(0).find((x) => x.type === 'currency')?.value}${
      Utils.zeroHyphen
    }`;
  }

  onEdit(org: OrganizationModel) {
    this.overlayService.open<unknown, OrganizationDialogData>({
      content: OrganizationDialogComponent,
      data: {
        id: org.id,
        fetchFilesOnInit: true,
      },
    });
  }

  removeVendor(vendor: OrganizationModel) {
    if (vendor) {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Vendor',
        message: `Are you sure that you want to remove ${vendor.name}?`,
        okBtnText: 'Remove',
      });

      resp.afterClosed$.subscribe(async (value) => {
        if (value.data?.result) {
          await this.organizationService.remove(vendor);
        }
      });
    }
  }

  onNewVendor = () => {
    this.overlayService.open<unknown, OrganizationDialogData>({
      content: OrganizationDialogComponent,
      data: {
        fetchFilesOnInit: false,
      },
    });
  };

  onAddMultipleVendors = () => {
    this.overlayService.open<unknown, null>({
      content: MultipleOrganizationsDialogComponent,
    });
  };
}
