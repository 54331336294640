import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';

type ChangeOrderParams = ICellRendererParams & {
  deleteClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
  downloadClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
  downloadPermission: boolean;
};

@Component({
  selector: 'aux-change-order-actions',
  template: `
    <div class="flex items-center justify-center space-x-2">
      <button
        *ngIf="this.params.downloadPermission"
        class="btn text-aux-blue rounded-full p-0 w-8 h-8"
        type="button"
        auxTooltip="Download"
        (click)="onDownloadClick()"
      >
        <aux-icon name="Download" [size]="16" />
      </button>

      <button
        class="btn rounded-full p-0 w-8 h-8"
        type="button"
        auxTooltip="Delete"
        (click)="onDeleteClick()"
      >
        <aux-icon name="Trash" [size]="16" class="text-aux-error" />
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderActionsComponent implements ICellRendererAngularComp {
  params!: ChangeOrderParams;

  refresh(): boolean {
    return false;
  }

  agInit(params: ChangeOrderParams): void {
    this.params = params;
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }
}
