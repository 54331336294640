import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ApprovalType,
  BudgetType,
  ChangeOrderStatus,
  Currency,
  EntityType,
  EventType,
  GqlService,
  TemplateType,
} from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OverlayService } from '@services/overlay.service';
import {
  CompareBudgetVersion,
  CompareComponent,
  CompareGridData,
} from '@components/compare/compare.component';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { OrganizationStore } from '@models/organization/organization.store';
import { AuthQuery } from '@models/auth/auth.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Utils } from '@services/utils';
import { memo } from 'helpful-decorators';
import { ApiService } from '@services/api.service';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

import { ExcelExportParams } from '@ag-grid-community/core';
import { EventService } from '@services/event.service';
import { ChangeOrderModel } from '../change-order/state/change-order.store';
import { ChangeOrderQuery } from '../change-order/state/change-order.query';
import { ChangeOrderService } from '../change-order/state/change-order.service';
import { ChangeOrderSharedService } from '../change-order/state/change-order-shared.service';
import { ChangeOrderUploadComponent } from '../change-order/change-order-upload/change-order-upload.component';
import { ChangeOrderBudgetUploadComponent } from './change-order-budget-upload.component';
import { ROUTING_PATH } from '../../../../app-routing-path.const';
import { OrganizationService } from '@models/organization/organization.service';
import { MainStore } from '../../../../layouts/main-layout/state/main.store';
import { ChangeOrderApprovedDialogComponent } from '@components/dialogs/change-order-approved-dialog/change-order-approved-dialog.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { Chart, ChartOptions } from 'chart.js';

@UntilDestroy()
@Component({
  templateUrl: './change-order-detail.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderDetailComponent {
  @ViewChild(FileManagerComponent) fileManager?: FileManagerComponent;

  @ViewChild(CompareComponent) compare!: CompareComponent | undefined;

  budgetLoading$ = new BehaviorSubject(false);

  myChart: Chart | null = null;

  changeOrdersLink = `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`;

  bypassValidation = false;

  analyticsCard = {
    co_count_activities_with_increased_units: 0,
    co_count_activities_with_increased_cost: 0,
    variance_total_cost_AC: '',
    // co_pie_cost_type_totals_data: '',
    services_total_AC: '',
    services_total_percent_AC_Formatted: '',
    investigator_total_AC: '',
    investigator_total_percent_AC_Formatted: '',
    pass_through_total_AC: '',
    pass_through_total_percent_AC_Formatted: '',
    co_category_amount_differences: [
      {
        amount: 0,
        amount_f: Utils.currencyFormatter(0),
        percent: Utils.percentageFormatter(0),
        name: 'All Other',
      },
    ],
  };

  loading$ = combineLatest([this.changeOrderQuery.selectLoading(), this.budgetLoading$]).pipe(
    map((arr) => arr.some((x) => x))
  );

  changeOrder$ = new BehaviorSubject<ChangeOrderModel | null>(null);

  zeroHyphen = Utils.zeroHyphen;

  show_preview$ = combineLatest([this.changeOrder$, this.authQuery.isUser$]).pipe(
    map(([change_order, is_user]) => {
      return (
        change_order?.change_order_status === ChangeOrderStatus.STATUS_PENDING_REVIEW && is_user
      );
    })
  );

  organization$ = this.changeOrder$.pipe(
    switchMap((co) => {
      return this.organizationQuery.selectEntity(co?.organization_id);
    })
  );

  primaryBudgetId$ = combineLatest([this.changeOrder$, this.organization$]).pipe(
    map(([co, org]) => {
      return (
        co?.approved_budget_version?.budget_version_id ||
        this.organizationQuery.getPrimaryBudgetVersion(org?.id || '')?.budget_version_id
      );
    })
  );

  changeOrderBudgetId$ = new BehaviorSubject<string | null>(null);

  status$ = this.changeOrder$.pipe(
    map((changeOrder) => {
      let status = '';
      if (changeOrder) {
        switch (changeOrder.change_order_status) {
          case ChangeOrderStatus.STATUS_PENDING_REVIEW:
            status = 'Pending Review';
            break;
          case ChangeOrderStatus.STATUS_PENDING_APPROVAL:
            status = 'Pending Approval';
            break;
          case ChangeOrderStatus.STATUS_APPROVED:
            status = 'Approved';
            break;
          case ChangeOrderStatus.STATUS_DECLINED:
            status = 'Declined';
            break;
          default:
            break;
        }
      }
      return status;
    })
  );

  approvedBy$ = this.changeOrder$.pipe(
    map((co) => {
      if (
        !co ||
        (co.change_order_status !== ChangeOrderStatus.STATUS_DECLINED &&
          co.change_order_status !== ChangeOrderStatus.STATUS_APPROVED)
      ) {
        return '';
      }

      const approval = co.approvals?.[0];

      const userFormat =
        this.changeOrderSharedService.userFormatter(approval?.aux_user_id || '') || '';
      const time = Utils.dateFormatter(approval?.approval_time || '');
      return `by ${userFormat} on ${time}`;
    })
  );

  isPendingReview$ = this.statusCheck(ChangeOrderStatus.STATUS_PENDING_REVIEW);

  isPendingApproval$ = this.statusCheck(ChangeOrderStatus.STATUS_PENDING_APPROVAL);

  isApproved$ = this.statusCheck(ChangeOrderStatus.STATUS_APPROVED);

  isDeclined$ = this.statusCheck(ChangeOrderStatus.STATUS_DECLINED);

  uploadLoading$ = this.mainQuery.selectProcessingEvent(
    EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED
  );

  btnLoading$ = new BehaviorSubject<
    | 'approval'
    | 'delete'
    | 'download'
    | 'approve'
    | 'decline'
    | 'admin-review'
    | 'upload'
    | 'replace'
    | 'lre-download'
    | false
  >(false);

  fromBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  toBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  coId$ = new BehaviorSubject('');

  constructor(
    private changeOrderQuery: ChangeOrderQuery,
    private changeOrderService: ChangeOrderService,
    private router: Router,
    private route: ActivatedRoute,
    public authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private changeOrderSharedService: ChangeOrderSharedService,
    private apiService: ApiService,
    private eventService: EventService,
    private launchDarklyService: LaunchDarklyService,
    private mainStore: MainStore
  ) {
    this.route.paramMap
      .pipe(
        map((params) => params.get('id')),
        switchMap((id) => {
          // trying to catch an issue where the query param ?trial= is included in the param 'id' value on very rare occassions
          let verifiedId = id;
          this.coId$.next(verifiedId || '');
          if (verifiedId?.includes('?')) {
            verifiedId = verifiedId.substring(0, verifiedId.indexOf('?'));
          } else if (verifiedId?.includes('%')) {
            verifiedId = verifiedId.substring(0, verifiedId.indexOf('%'));
          }

          return this.changeOrderService.getOne(verifiedId || '').pipe(
            switchMap(({ success, data }) => {
              if (success && data) {
                return this.changeOrderQuery.selectEntity(verifiedId);
              }
              return of(null);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((changeOrder) => {
        this.organizationStore.setActive(changeOrder?.organization_id || '');

        if (!changeOrder) {
          this.router.navigateByUrl(
            `${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`
          );
          return;
        }

        this.changeOrder$.next(changeOrder || null);
      });

    combineLatest([this.changeOrderBudgetId$, this.changeOrder$])
      .pipe(
        map(([id, co]) => {
          if (!(!!id && !!co)) {
            return null;
          }

          return {
            budget_version_id: id,
            budget_name: `Change Order ${co?.change_order_no}`,
            budget_type: BudgetType.BUDGET_CHANGE_ORDER,
          } as CompareBudgetVersion;
        }),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.toBudgetVersion$.next(data);
      });

    combineLatest([this.changeOrder$, this.organization$])
      .pipe(
        map(([co]) => {
          return this.organizationQuery.getBudgetVersion(
            co?.organization_id || '',
            BudgetType.BUDGET_CHANGE_ORDER,
            co?.id || '',
            EntityType.CHANGE_ORDER
          )?.budget_version_id;
        }),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.changeOrderBudgetId$.next(data as string);
      });

    this.primaryBudgetId$
      .pipe(
        filter((id) => !!id),
        map((id) => {
          return {
            budget_version_id: id,
            budget_name: 'Current Budget',
            budget_type: BudgetType.BUDGET_PRIMARY,
          } as CompareBudgetVersion;
        }),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.fromBudgetVersion$.next(data);
      });

    let lastUploadedState = false;
    let loadOrganizations = !this.organizationStore.getValue()?.ids?.length;
    this.mainQuery
      .selectProcessingEvent(EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED)
      .pipe(
        startWith(false),
        filter((isUploaded) => {
          const result = (lastUploadedState && !isUploaded) || loadOrganizations;
          loadOrganizations = false;
          lastUploadedState = isUploaded;
          return result || loadOrganizations;
        }),
        switchMap(() => this.organizationService.getListWithTotalBudgetAmount()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.changeOrder$.next(this.changeOrder$.getValue());
      });

    this.eventService
      .select$(EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.mainStore.setProcessingLoadingState(
          EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
          false
        );
      });
  }

  isApproveButtonDisableManually$ = new BehaviorSubject(false);

  isApproveButtonDisable$ = this.toBudgetVersion$.pipe(map((toBudgetVersion) => !toBudgetVersion));

  isUploadCOBudgetButtonDisable$ = this.fromBudgetVersion$.pipe(
    map((fromBudgetVersion) => !fromBudgetVersion)
  );

  pathFn: () => string = () => '';

  @memo()
  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  statusCheck(status: ChangeOrderStatus) {
    return this.changeOrder$.pipe(map((x) => x?.change_order_status === status));
  }

  async onDelete() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('delete');
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Change Order',
      message: `Are you sure you want to remove Change Order ${co.change_order_no}?`,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.result) {
      const { success } = await this.changeOrderService.remove(co, event.data.textarea);

      if (success) {
        this.router.navigateByUrl(this.changeOrdersLink);
      }
    }
    this.btnLoading$.next(false);
  }

  async onDownloadCO() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('download');
    const { success, data, errors } = await this.changeOrderQuery.downloadCO(co.id);

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  async onReturnToAdminReview() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const co = this.changeOrder$.getValue()!;

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('admin-review');
    const resp = this.overlayService.openConfirmDialog({
      header: `Return to Pending Review?`,
      message: `Are you sure you want to return Change Order ${co.change_order_no} to Pending Review?`,
      okBtnText: 'Return to Admin Review',
      textarea: {
        label: 'Reason for return to Admin Review',
        required: true,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.result) {
      const { success, data, errors } = await this.changeOrderService.update({
        id: co.id,
        organization_id: co.organization_id,
        change_order_status: ChangeOrderStatus.STATUS_PENDING_REVIEW,
        admin_review_reason: event.data.textarea,
      });

      if (success && data) {
        this.overlayService.success();
      } else {
        this.overlayService.error(errors);
      }
    }

    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.CHANGE_ORDER_BACK_TO_PENDING_REVIEW,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
    );

    this.btnLoading$.next(false);
  }

  async onDownloadLREBudget() {
    if (this.btnLoading$.getValue()) {
      return;
    }
    const vendor_id = this.changeOrder$.getValue()?.organization_id;
    if (!vendor_id) {
      return;
    }

    this.btnLoading$.next('lre-download');

    const { success, data } = await this.apiService.getTemplatePath(
      vendor_id,
      TemplateType.BUDGET_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }

    this.btnLoading$.next(false);
  }

  getDynamicExcelParams(): ExcelExportParams {
    const org = this.organizationQuery.getEntity(this.changeOrder$.value?.organization_id);

    const currentTrial = this.mainQuery.getSelectedTrial();

    const changeOrderNo = this.changeOrder$.value?.change_order_no;

    const coPendingApprovalDate = this.changeOrder$.value?.create_date?.slice(0, 10);

    return currentTrial && org
      ? {
          fileName: `${currentTrial.short_name}_${org.name}_Change Order ${changeOrderNo}_Compare_${coPendingApprovalDate}.xlsx`,
        }
      : {};
  }

  async onSendApproval() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('approval');

    const { success, data, errors } = await this.changeOrderService.update({
      id: co.id,
      organization_id: co.organization_id,
      change_order_status: ChangeOrderStatus.STATUS_PENDING_APPROVAL,
    });

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }

    this.mainStore.setProcessingLoadingState(EventType.CHANGE_ORDER_PENDING_APPROVAL, true);

    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.CHANGE_ORDER_PENDING_APPROVAL,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
    );

    this.btnLoading$.next(false);
  }

  async onDecline() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const co = this.changeOrder$.getValue()!;
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('decline');

    const resp = this.overlayService.openConfirmDialog({
      header: 'Decline Change Order?',
      message: `Are you sure you want to decline Change Order ${co.change_order_no}?`,
      okBtnText: 'Decline',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);

    if (!event.data?.result) {
      this.btnLoading$.next(false);
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await firstValueFrom(
      this.gqlService.approveRule$({
        approved: false,
        comments: '',
        permission: 'PERMISSION_APPROVE_CHANGE_ORDER',
        approval_type: ApprovalType.APPROVAL_CHANGE_ORDER,
        entity_id: co.id,
        entity_type: EntityType.CHANGE_ORDER,
        activity_details: '{}',
      })
    );
    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next(false);
      return;
    }

    const { success, data, errors } = await this.changeOrderService.update({
      id: co.id,
      organization_id: co.organization_id,
      change_order_status: ChangeOrderStatus.STATUS_DECLINED,
      decline_reason: event.data.textarea,
    });

    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.CHANGE_ORDER_DECLINED,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
    );

    if (!(success && data)) {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  async onApprove(force = false) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const co = this.changeOrder$.getValue()!;
    const org = await firstValueFrom(this.organization$.pipe(first()));
    if (!org) {
      return;
    }

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('approve');

    let resp!: CustomOverlayRef<{ result: boolean; textarea: string }>;

    if (force) {
      resp = this.overlayService.openConfirmDialog({
        header: `Force Approve Change Order?`,
        message: `Selecting Force Approve will replace the current ${org.name} budget with Change Order ${co.change_order_no}.  Do you want to proceed?`,
        okBtnText: 'Force Approve',
      });
    } else {
      resp = this.overlayService.openConfirmDialog({
        header: `Approve Change Order?`,
        message: `Selecting Approve will replace the current ${org.name} budget with Change Order ${co.change_order_no}. Do you want to proceed?`,
        okBtnText: 'Approve',
      });
    }

    const event = await firstValueFrom(resp.afterClosed$);

    if (!event.data?.result) {
      this.btnLoading$.next(false);
      return;
    }

    this.isApproveButtonDisableManually$.next(true);

    const { success: approveSuccess, errors: approveErrors } = await firstValueFrom(
      this.gqlService.approveRule$({
        approved: true,
        comments: '',
        permission: 'PERMISSION_APPROVE_CHANGE_ORDER',
        approval_type: ApprovalType.APPROVAL_CHANGE_ORDER,
        entity_id: co.id,
        entity_type: EntityType.CHANGE_ORDER,
        activity_details: '{}',
      })
    );
    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next(false);
      return;
    }

    const { success, data, errors } = await this.changeOrderService.approveChangeOrder({
      id: co.id,
      organization_id: co.organization_id,
    });

    this.mainStore.setProcessingLoadingState(EventType.CHANGE_ORDER_APPROVED, true);

    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.CHANGE_ORDER_APPROVED,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
    );

    if (!(success && data)) {
      this.overlayService.error(errors);
    } else {
      if (this.launchDarklyService.flags$.getValue().change_order_success_modal) {
        this.overlayService.open({
          content: ChangeOrderApprovedDialogComponent,
          data: { vendor_id: co.organization_id },
          closeButton: false,
        });
      }
    }

    this.btnLoading$.next(false);
  }

  getFilePath(org_id: string, co_id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/changeorders/${co_id}/`;
  }

  onChangeOrderBudgetUpload = async () => {
    if (this.btnLoading$.getValue()) {
      return;
    }

    const org = await firstValueFrom(this.organization$.pipe(first()));
    if (!org) {
      return;
    }

    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }

    if (this.fileManager) {
      const files = this.fileManager.fileQuery.getAll();

      const match = files[0].bucket_key.match(/\.([^.]+)$/);
      if (match?.[1] !== 'csv') {
        this.overlayService.error('File type must be a .csv!');
        return;
      }

      this.btnLoading$.next('upload');

      const file = files[0];
      const bucket_key = `${this.getFilePath(org.id, co.id)}${file.bucket_key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        this.mainStore.setProcessingLoadingState(
          EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
          true
        );

        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: org.id,
            bucket_key: `public/${bucket_key}`,
            payload: JSON.stringify({
              change_order_id: co.id,
              change_order_status: co.change_order_status,
              skip_activityno_check: this.bypassValidation,
            }),
          })
        );
        if (success) {
          this.overlayService.success(`Budget is processing. Please wait...`);
        } else {
          await this.apiService.removeFile(bucket_key);
          this.overlayService.error(errors, undefined, true);
          this.mainStore.setProcessingLoadingState(
            EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
            false
          );
        }
      }
    }
    this.btnLoading$.next(false);
  };

  async onReplaceBudget() {
    if (this.btnLoading$.getValue()) {
      return;
    }
    const org = await firstValueFrom(this.organization$.pipe(first()));
    if (!org) {
      return;
    }
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    this.btnLoading$.next('replace');
    const modalResult = this.overlayService.open<
      unknown,
      {
        change_order_id: string;
        change_order_status?: ChangeOrderStatus;
        organization_id: string;
      }
    >({
      content: ChangeOrderBudgetUploadComponent,
      data: {
        change_order_id: co.id,
        change_order_status: co.change_order_status,
        organization_id: org.id,
      },
    });

    await firstValueFrom(modalResult.afterClosed$);
    this.btnLoading$.next(false);
  }

  async onEditChangeOrder() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    const result = this.overlayService.open({
      content: ChangeOrderUploadComponent,
      data: {
        id: co.id,
      },
      overlayConfig: {
        hasBackdrop: false,
        panelClass: [
          'modal',
          'is-active',
          'cdk-global-overlay-wrapper',
          'cdk-overlay-dark-backdrop',
          'cdk-overlay-backdrop-showing',
          'justify-center',
          'items-center',
        ],
      },
      closeButton: false,
    });
    await firstValueFrom(result.afterClosed$);
  }

  acPercentFormat(v: number) {
    return Utils.agPercentageFormatterAbsolute(
      {
        value: v,
      },
      { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    );
  }

  async onBudgetData(arr: CompareGridData[]) {
    // do some calculations with array and update the totals in this component
    // this.totals.pass_through_total_percent_AC = 5;
    const diffs = Utils.getCompareDiffs(arr);

    const org = await firstValueFrom(this.organization$.pipe(first()));
    const org_currency = org ? this.organizationQuery.getEntity(org?.id)?.currency : Currency.USD;

    this.analyticsCard.co_count_activities_with_increased_units =
      diffs.count_activities_with_increased_units;
    this.analyticsCard.co_count_activities_with_increased_cost =
      diffs.count_activities_with_increased_cost;

    this.analyticsCard.variance_total_cost_AC = Utils.currencyFormatter(
      diffs.variance_total_cost,
      {},
      org_currency
    );
    // this.analyticsCard.co_pie_cost_type_totals_data = []
    this.analyticsCard.services_total_AC = Utils.currencyFormatter(
      diffs.delta_services,
      {},
      org_currency
    );

    this.analyticsCard.services_total_percent_AC_Formatted = this.acPercentFormat(
      diffs.delta_services / diffs.variance_total_cost
    );
    this.analyticsCard.investigator_total_AC = Utils.currencyFormatter(
      diffs.delta_investigator,
      {},
      org_currency
    );

    this.analyticsCard.investigator_total_percent_AC_Formatted = this.acPercentFormat(
      diffs.delta_investigator / diffs.variance_total_cost
    );
    this.analyticsCard.pass_through_total_AC = Utils.currencyFormatter(
      diffs.delta_pass_through,
      {},
      org_currency
    );

    this.analyticsCard.pass_through_total_percent_AC_Formatted = this.acPercentFormat(
      diffs.delta_pass_through / diffs.variance_total_cost
    );

    let total_remaining_diff = Object.values(diffs.category_amount_differences).reduce(
      (value, accumulator) => {
        return value + accumulator;
      },
      0
    );

    // Categories driviing increase
    const total_cat_diff = total_remaining_diff;
    const category_amount_differences = { ...diffs.category_amount_differences };
    const cat_amt_len = Object.keys(category_amount_differences).length;
    const categories_required = cat_amt_len >= 3 ? 3 : cat_amt_len;
    this.analyticsCard.co_category_amount_differences = [];

    for (let i = 0; i < categories_required; i++) {
      // grab highest one
      const highest_obj = Object.entries(category_amount_differences).sort(
        (a, b) => b[1] - a[1]
      )[0];
      if (highest_obj) {
        // subtract total_cat_diff
        total_remaining_diff -= highest_obj[1];
        this.analyticsCard.co_category_amount_differences.push({
          name: highest_obj[0],
          amount: highest_obj[1],
          amount_f: Utils.currencyToAccounting(
            Utils.currencyFormatter(
              highest_obj[1],
              {
                currencySign: 'accounting',
              },
              org_currency
            )
          ),
          percent: Utils.percentageFormatter(highest_obj[1] / total_cat_diff),
        });
        // remove from list
        delete category_amount_differences[highest_obj[0]];
      }
    }
    this.analyticsCard.co_category_amount_differences.push({
      name: 'All Other',
      amount: total_remaining_diff,
      amount_f: Utils.currencyToAccounting(
        Utils.currencyFormatter(
          total_remaining_diff,
          {
            currencySign: 'accounting',
          },
          org_currency
        )
      ),
      percent: Utils.percentageFormatter(total_remaining_diff / total_cat_diff),
    });
    this.createDiffChart(diffs);
  }

  async createDiffChart(diffs: {
    delta_services_positive: number;
    delta_pass_through_positive: number;
    delta_investigator_positive: number;
    delta_services_negative: number;
    delta_pass_through_negative: number;
    delta_investigator_negative: number;
    category_activity_amount_differences: {
      services_positive: { [key: string]: number };
      services_negative: { [key: string]: number };
      pass_through_positive: { [key: string]: number };
      pass_through_negative: { [key: string]: number };
      investigator_positive: { [key: string]: number };
      investigator_negative: { [key: string]: number };
    };
  }) {
    const org = await firstValueFrom(this.organization$.pipe(first()));
    const org_currency = org ? this.organizationQuery.getEntity(org?.id)?.currency : Currency.USD;
    const barOptions_stacked: ChartOptions<'bar'> = {
      indexAxis: 'y',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          displayColors: false,
          callbacks: {
            label(tooltipItem): string | string[] | void {
              const amt = parseFloat((tooltipItem.raw as string) || '');
              const totalValueString = Utils.currencyFormatter(
                parseFloat((tooltipItem.raw as string) || '0'),
                {},
                org_currency
              );
              const tooltipStrArr = [`Total: ${totalValueString}`, ''];
              let iterableValues = {} as { [key: string]: number };
              if (tooltipItem.label === 'Services' && amt > 0) {
                iterableValues = {
                  ...diffs.category_activity_amount_differences.services_positive,
                };
              } else if (tooltipItem.label === 'Services' && amt < 0) {
                iterableValues = {
                  ...diffs.category_activity_amount_differences.services_negative,
                };
              } else if (tooltipItem.label === 'Pass-Through' && amt > 0) {
                iterableValues = {
                  ...diffs.category_activity_amount_differences.pass_through_positive,
                };
              } else if (tooltipItem.label === 'Pass-Through' && amt < 0) {
                iterableValues = {
                  ...diffs.category_activity_amount_differences.pass_through_negative,
                };
              } else if (tooltipItem.label === 'Investigator' && amt > 0) {
                iterableValues = {
                  ...diffs.category_activity_amount_differences.investigator_positive,
                };
              } else if (tooltipItem.label === 'Investigator' && amt < 0) {
                iterableValues = {
                  ...diffs.category_activity_amount_differences.investigator_negative,
                };
              }
              let total_remaining = Object.values(iterableValues).reduce((x, v) => x + v, 0);
              const iterations =
                Object.keys(iterableValues).length > 3 ? 3 : Object.keys(iterableValues).length;

              for (let i = 0; i < iterations; i += 1) {
                let max_abs = -1;
                let max_value = 0;
                let max_activity = '';
                for (const z of Object.keys(iterableValues)) {
                  const amt2 = iterableValues[z];
                  if (Math.abs(amt2) > max_abs) {
                    max_abs = Math.abs(amt2);
                    max_value = amt2;
                    max_activity = z;
                  }
                }
                const currVal = Utils.currencyFormatter(max_value, {}, org_currency);
                if (currVal !== Utils.zeroHyphen) {
                  tooltipStrArr.push(`${max_activity}: ${currVal}`);
                }
                total_remaining -= max_value;
                delete iterableValues[max_activity];
              }
              if (total_remaining !== 0) {
                tooltipStrArr.push(
                  `All Others: ${Utils.currencyFormatter(total_remaining, {}, org_currency)}`
                );
              }
              return tooltipStrArr;
            },
          },
        },
        title: {
          display: false,
        },
      },
      animations: {
        active: {
          duration: 0,
        },
      },
      scales: {
        x: {
          stack: '0',
          beginAtZero: true,
          title: {
            display: false,
          },
          grid: {},
          ticks: {
            callback: (tickValue) => {
              try {
                if (tickValue === 0) {
                  return '0';
                }
                return Utils.currencyFormatter(tickValue, {}, org_currency);
              } catch (err) {
                console.log(`Error parsing chart axis: ${err}`);
              }
              return tickValue;
            },
          },
          stacked: true,
        },
        y: {
          grid: {
            display: false,
          },
          stacked: true,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    };

    const ctx = document.getElementById('barChart123');

    if (ctx) {
      if (this.myChart) {
        this.myChart.destroy();
      }
      this.myChart = new Chart(ctx as HTMLCanvasElement, {
        type: 'bar',
        data: {
          labels: ['Services', 'Pass-Through', 'Investigator'],
          datasets: [
            {
              data: [
                diffs.delta_services_positive,
                diffs.delta_pass_through_positive,
                diffs.delta_investigator_positive,
              ],
              backgroundColor: ['#094673', '#4f6d79', '#bacad0'],
            },
            {
              data: [
                diffs.delta_services_negative,
                diffs.delta_pass_through_negative,
                diffs.delta_investigator_negative,
              ],
              backgroundColor: ['#094673', '#4f6d79', '#bacad0'],
            },
          ],
        },
        options: barOptions_stacked,
      });
    }
  }
}
