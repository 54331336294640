import { FormValuesQuery } from '@models/form-values/form-values.query';
import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import {
  ContactInput,
  ContactType,
  CreateSiteInput,
  EntityType,
  GqlService,
  UpdateContactInput,
  UpdateSiteInput,
} from '@services/gql.service';

import { first } from 'lodash-es';
import { SiteModel, SitesStore } from './sites.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SitesService {
  constructor(
    private sitesStore: SitesStore,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private formValuesQuery: FormValuesQuery
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.sitesStore.setLoading(true);

        return this.gqlService.listSites$(ContactType.CONTACT_INVESTIGATOR).pipe(
          tap((x) => {
            if (x.data) {
              this.sitesStore.set(
                x.data.map((site) => ({
                  ...site,
                  managed_by_id: site?.managed_by?.id,
                  investigator: first(site.contacts),
                })) as unknown as SiteModel[]
              );
            }
            this.sitesStore.setLoading(false);
          })
        );
      })
    );
  }

  createSiteContact({
    entity_id,
    given_name,
    family_name,
  }: Omit<ContactInput, 'contact_type' | 'entity_type' | 'trial_id'>) {
    return this.gqlService.createSiteContact$({
      entity_id,
      given_name: given_name || '',
      entity_type: EntityType.SITE,
      contact_type: ContactType.CONTACT_INVESTIGATOR,
      family_name: family_name || '',
      title: '',
      email: null,
      phone_number: '',
    });
  }

  getContacts(entity_id: string) {
    return this.gqlService.listSiteContacts$(entity_id, ContactType.CONTACT_INVESTIGATOR).pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data[0] : null };
      })
    );
  }

  updateContact(entity_id: string, family_name: string, given_name: string) {
    const updateSiteContactInput = {
      id: entity_id,
      family_name,
      given_name,
    } as UpdateContactInput;
    return this.gqlService.updateContact$(updateSiteContactInput).pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data : null };
      })
    );
  }

  listSiteNames() {
    return this.gqlService.listSiteNames$().pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data : null };
      })
    );
  }

  async add({
    name,
    city,
    country,
    state,
    zip,
    site_no,
    investigator,
    target_patients,
    managed_by_id,
    site_activation,
    closeout_date,
    currency,
    address_line_1,
    address_line_2,
    address_line_3,
  }: Omit<CreateSiteInput, 'trial_id'> & {
    investigator: { given_name: string; family_name: string };
  }) {
    this.sitesStore.setLoading(true);
    const { data, errors, success } = await firstValueFrom(
      this.gqlService.createSite$({
        site_no,
        name,
        city,
        state,
        zip,
        country,
        managed_by_id,
        target_patients,
        site_activation,
        closeout_date,
        currency,
        address_line_1,
        address_line_2,
        address_line_3,
      })
    );

    if (success && data) {
      const {
        errors: cErrors,
        success: cSuccess,
        data: cData,
      } = await firstValueFrom(
        this.createSiteContact({
          entity_id: data.id,
          given_name: investigator.given_name,
          family_name: investigator.family_name,
        })
      );

      if (!cSuccess) {
        // todo(think later) what shall we do in this error case? Site created perfectly, but investigator is not.
        this.overlayService.error(cErrors);
      }

      this.sitesStore.add({
        __typename: 'Site',
        id: data.id,
        site_no,
        name,
        city,
        country,
        state,
        zip,
        managed_by_id,
        target_patients,
        site_activation,
        closeout_date,
        currency,
        address_line_1,
        address_line_2,
        address_line_3,
        investigator: {
          __typename: 'Contact',
          id: cData?.id || '',
          given_name: investigator.given_name,
          family_name: investigator.family_name,
        },
      });
      this.sitesStore.setLoading(false);
      return data.id;
    }
    this.overlayService.error(errors);
    this.sitesStore.setLoading(false);
    return false;
  }

  async update(
    id: string,
    site: UpdateSiteInput & { name?: string; site_no?: string },
    investigator: { given_name: string; family_name: string; id: string }
  ) {
    const { success, errors } = await firstValueFrom(this.gqlService.updateSite$({ ...site }));

    if (success) {
      this.sitesStore.update(id, () => {
        return {
          ...site,
          investigator: {
            __typename: 'Contact',
            ...investigator,
          },
        };
      });
      this.overlayService.success(`${site.name} successfully updated!`);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors };
  }

  async remove(site: SiteModel) {
    const { success, errors } = await firstValueFrom(this.gqlService.removeSite$(site.id));
    if (success) {
      this.overlayService.success(`${site.name} successfully removed!`);
      this.sitesStore.remove(site.id);
      this.formValuesQuery.removeSiteFromPatientBudget(site.id);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors };
  }

  listSitePatientTrackerVersions() {
    return this.gqlService.listSitePatientTrackerVersions$().pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data : null };
      })
    );
  }
}
