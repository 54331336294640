import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  CellEditingStartedEvent,
  ColumnApi,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  ProcessCellForExportParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueParserParams,
} from '@ag-grid-community/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
  merge,
  Observable,
  Subscription,
} from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { first, last, mean, omit, sortBy, sum } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { LaunchDarklyService } from '@services/launch-darkly.service';

import {
  CurveType,
  DistributionMode,
  EntityType,
  EventType,
  GqlService,
  listDriverSiteDistributionsQuery,
  PermissionType,
  WorkflowStep,
} from '@services/gql.service';
import { FormGroupTyped, Nullable, Utils } from '@services/utils';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { EventManager } from '@angular/platform-browser';
import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AuthService } from '@models/auth/auth.service';
import { ApiService } from '@services/api.service';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { TimelineService } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import {
  RemoveDialogComponent,
  RemoveDialogInput,
} from '@components/remove-dialog/remove-dialog.component';
import { DriverSiteService } from './state/driver-site.service';
import { DriverSiteQuery } from './state/driver-site.query';
import { DriverSiteSettingModel } from './state/driver-site.store';
import { SiteDriverUploadComponent } from './site-driver-upload/site-driver-upload.component';
import { ForecastAccrualsPageComponent } from '../../../../forecast-accruals-page.component';
import { SiteDistributionQuery } from './state/site-distribution.query';
import { SiteDistributionService } from './state/site-distribution.service';
import { WorkflowQuery } from '../../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { SiteBlendedCurveModalComponent } from './site-blended-curve-modal/site-blended-curve-modal.component';
import { SiteGroupsQuery } from './state/site-groups.query';
import { SiteGroupsService } from './state/site-groups.service';
import { SiteCurveService } from './site-curve/site-curve.service';
import { SiteCurveQuery } from './site-curve/site-curve.query';
import { MessagesConstants } from '@constants/messages.constants';
import { ROUTING_PATH } from '../../../../../../app-routing-path.const';
import { BlendedCurveModalDataModel } from '../models/blended-curve-modal-data.model';
import { TableService } from '@services/table.service';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';
import { PatientCurveState } from '../../patient-curve/patient-curve.store';
import { SiteCurvesConstants } from './site-curves.constants';
import { TableConstants } from '@constants/table.constants';
import {
  decimalAdd,
  decimalDifference,
  decimalEquality,
  decimalRoundingToNumber,
  decimalRoundingToString,
  batchPromises,
} from '@shared/utils';
import { StickyElementService } from '@services/sticky-element.service';
import { SiteCurveModel } from './site-curve/site-curve.store';
import { CanvasComponentOptions } from '@components/canvas-chart/canvas-chart.component';

export type TotalDriverSiteDistribution = {
  __typename: 'DriverSiteDistribution';
  distribution_mode: DistributionMode;
  distribution_month: string;
  id: string;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
  sites_activated?: number | null;
  sites_activated_percentage?: number | null;
  sites_closed?: number | null;
  sites_closed_percentage?: number | null;
  total_sites_activated?: number | null;
};

type ForecastSiteCurvesFormSetting = Nullable<
  Pick<
    DriverSiteSettingModel,
    | 'id'
    | 'cohort_name'
    | 'number_of_sites'
    | 'discontinued_date'
    | 'ramp_time_in_weeks'
    | 'target_patient_count'
    | 'first_initiated_date'
  >
>;

@UntilDestroy()
@Component({
  selector: 'aux-forecast-site-curves',
  templateUrl: './forecast-site-curves.component.html',
  styleUrls: ['forecast-site-curves.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastSiteCurvesComponent implements OnInit, OnDestroy {
  static readonly DISABLED_BUTTON_TITLE =
    'The site curve is unable to be saved until the following issue is resolved: Sites Closed should be equal to Sites Activated';

  static readonly LOCKED_EDIT_BUTTON_TITLE = 'This Page is Locked for Period Close';

  editMode$ = new BehaviorSubject(false);

  gridData$ = new BehaviorSubject<TotalDriverSiteDistribution[]>([]);

  isSitesFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES
  );

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  currentSiteGroupId = '';

  isFirstTimeInitialization = true;

  exportButtonVariant = ExcelButtonVariant.OUTLINE;

  multiChart$: Observable<CanvasComponentOptions> = this.gridData$.pipe(
    map((data) => {
      const labels = data.map(({ distribution_month }) =>
        Utils.dateFormatter(distribution_month, { day: undefined, year: '2-digit' })
      );
      return SiteCurvesConstants.multiChartOptions(data, labels);
    })
  );

  editCell = false;

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (!params.editing) {
          switch (params.event.key) {
            case 'Backspace':
            case 'Delete':
              if (this.editMode$.getValue()) {
                TableService.clearCellRange(params, () => this.calculateSiteCurves());
              }

              return true;
            default:
              return false;
          }
        }
        return false;
      },
      editable: () => {
        return this.editMode$.getValue();
      },
      cellClass: SiteCurvesConstants.getCellClasses(this.editMode$),
      headerClass: SiteCurvesConstants.getHeaderClasses(this.editMode$),
      cellClassRules: {
        '!bg-aux-error': (params) => {
          let ret = false;
          switch (params.colDef.field) {
            case 'sites_activated':
            case 'sites_closed':
              if (params.data.distribution_month === 'TOTAL' && !this.isBlended$.getValue()) {
                ret = !decimalEquality(params.data.sites_closed, params.data.sites_activated);
                this.isSaveBtnDisabled = ret;
                this.saveBtnTitle = ret ? ForecastSiteCurvesComponent.DISABLED_BUTTON_TITLE : '';
              }
              break;
            case 'net_sites_per_month':
              if (
                !this.isBlended$.getValue() &&
                Utils.isNegativeAndNotCloseEnoughToZero(params.data.net_sites_per_month)
              ) {
                ret = true;
                this.isNetSiteNegative$.next(true);
              }
              break;
            default:
              break;
          }

          return ret;
        },
      },
      cellStyle: (params) => {
        switch (params.colDef.field) {
          case 'net_sites_per_month_percentage':
          case 'sites_activated_percentage':
          case 'sites_closed_percentage':
            return this.editCell
              ? { opacity: 0.7, 'justify-items': 'end' }
              : { 'justify-item': 'end' };
          default:
            return undefined;
        }
      },
    },
    ...SiteCurvesConstants.GRID_OPTIONS,
    columnDefs: SiteCurvesConstants.columnDefs(
      this.valueFormatter,
      this.numberParser,
      this.editCell
    ),
    excelStyles: SiteCurvesConstants.GRID_OPTIONS_EXEL_STYLES,
  } as GridOptions;

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Site Curve',
    fileName: 'auxilius-site-curve.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.id;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'distribution_month':
          return 225;
        default:
          return 225;
      }
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      const coldId = params.column.getColId();
      if (coldId === 'id') {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      }

      return params.value;
    },
  } as ExcelExportParams;

  gridColumnApi!: ColumnApi;

  gridApi!: GridApi;

  loading$ = new BehaviorSubject(false);

  isBlended$ = new BehaviorSubject(false);

  driverSettingId$ = new BehaviorSubject<string | null>(null);

  showEnrollmentSettings$: Observable<boolean>;

  siteCurveGroups: SiteCurveModel[] = [];

  siteCurveGroups$ = new BehaviorSubject<SiteCurveModel[]>([]);

  monthList: { start_date: string; end_date: Date | string } = { start_date: '', end_date: '' };

  clonedSite: TotalDriverSiteDistribution[] = [];

  btnLoading$ = new BehaviorSubject(false);

  isFromScratch = false;

  userHasModifyPermissions = false;

  isSaveBtnDisabled = false;

  saveBtnTitle = '';

  editBtnTitle = '';

  fg = new FormGroup({
    settings: new FormArray<FormGroupTyped<Partial<ForecastSiteCurvesFormSetting>>>([]),
  });

  initialValue: TotalDriverSiteDistribution[] = [];

  editedRows = new Set<string>();

  removedRows: string[] = [];

  fgListener: Subscription | undefined;

  setSiteGroupSubscription!: Subscription;

  setTimelineSubscription!: Subscription;

  isNetSiteNegative$ = new BehaviorSubject(false);

  avg$ = this.driverSiteQuery.selectAll().pipe(
    debounceTime(200),
    map((siteSettings) => {
      const total_number_of_sites = sum(siteSettings.map((ss) => ss.number_of_sites));
      const first_initiated_date = siteSettings
        .map((ss) => ss.first_initiated_date)
        .sort((a, b) => Date.parse(a || '') - Date.parse(b || ''))[0];
      const discontinued_dates = siteSettings
        .map((ss) => ss.discontinued_date)
        .sort((a, b) => Date.parse(a || '') - Date.parse(b || ''));
      const discontinued_date = discontinued_dates[discontinued_dates.length - 1];
      const ramp_time_in_weeks = mean(siteSettings.map((ss) => ss.ramp_time_in_weeks));
      const target_patient_count = mean(siteSettings.map((ss) => ss.target_patient_count));

      return {
        total_number_of_sites,
        first_initiated_date,
        discontinued_date,
        ramp_time_in_weeks,
        target_patient_count,
      };
    })
  );

  curveControl = new FormControl('');

  siteCurveListOptions: EditableListDropdownItem[] = [];

  siteCurveGroupList: SiteCurveModel[] = [];

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private forecastPage: ForecastAccrualsPageComponent,
    private gqlService: GqlService,
    private siteGroupService: SiteGroupsService,
    private mainQuery: MainQuery,
    private siteDistributionService: SiteDistributionService,
    private cdr: ChangeDetectorRef,
    private vendorsService: OrganizationService,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private siteCurveService: SiteCurveService,
    private siteCurveQuery: SiteCurveQuery,
    private siteGroupQuery: SiteGroupsQuery,
    private driverSiteService: DriverSiteService,
    public driverSiteQuery: DriverSiteQuery,
    private fb: FormBuilder,
    private timelineService: TimelineService,
    private timelineQuery: TimelineQuery,
    private apiService: ApiService,
    private siteDistributionQuery: SiteDistributionQuery,
    private trialsQuery: TrialsQuery,
    private eventManager: EventManager,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery,
    private stickyElementService: StickyElementService
  ) {
    this.showEnrollmentSettings$ = this.launchDarklyService.select$(
      (flags) => flags.section_site_driver_assumptions
    );

    this.editMode$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.gridApi) {
        this.gridApi.refreshHeader();
      }
    });

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_SITE_CURVE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasModifyPermissions = x;
      });
    this.isSitesFinalized$.pipe(untilDestroyed(this)).subscribe((finalized) => {
      if (finalized) {
        this.editBtnTitle = ForecastSiteCurvesComponent.LOCKED_EDIT_BUTTON_TITLE;
      } else {
        this.editBtnTitle = '';
      }
    });
  }

  ngOnInit() {
    this.siteCurveGroups$.pipe(untilDestroyed(this)).subscribe((items) => {
      this.updateSiteCurveList(items);
    });

    this.curveControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      const driverPatientGroup =
        this.siteCurveGroupList?.find((item) => item.name === value) || null;
      this.selectCurve(driverPatientGroup);
    });

    this.vendorsService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
        }
      });

    this.mainQuery
      .select('trialKey')
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          this.loading$.next(true);
          return combineLatest([this.siteCurveService.get(), this.siteGroupService.get()]);
        })
      )
      .subscribe(() => {
        this.curveControl.setValue('');
        this.isFirstTimeInitialization = true;
        this.monthList.start_date = '';
        this.setSiteGroups();
        this.loading$.next(false);
      });

    // todo duplicate code 16 line 4 different places
    this.eventManager.addEventListener(document.body, 'keydown', (event: KeyboardEvent) => {
      switch (event.code) {
        case 'Tab':
          this.gridApi.stopEditing();
          setTimeout(() => {
            const getCellCor = this.gridApi.getFocusedCell();
            this.gridApi.startEditingCell({
              rowIndex: getCellCor?.rowIndex || 0,
              colKey: getCellCor?.column?.getColId() || '',
            });
          }, 0);
          break;
        default:
          break;
      }
    });

    this.showEnrollmentSettings$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            return this.driverSiteService.get();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {});

    this.driverSiteQuery
      .selectAll()
      .pipe(untilDestroyed(this), debounceTime(200))
      .subscribe((siteSettings) => {
        const fa = this.fg.controls.settings;
        fa.clear();
        siteSettings.map((ss) => this.createSiteSettingFB(ss)).forEach((x) => fa.push(x));
        this.listenForChanges();
        this.cdr.markForCheck();
      });
  }

  async canDeactivate(): Promise<boolean> {
    if (this.editMode$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  valueFormatter(val: ValueFormatterParams) {
    const newValue = val.value === '0' ? 0 : val.value;
    return newValue ? decimalRoundingToString(newValue) : Utils.zeroHyphen;
  }

  //don't allow entry of non-numeric or negative numbers
  numberParser(params: ValueParserParams): number | string {
    return Number.isNaN(Number(params.newValue))
      ? params.oldValue
      : Number(Math.abs(params.newValue));
  }

  onDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
  }

  calculatePinnedBottomData() {
    const columnsWithAggregation = [
      'sites_activated',
      'sites_closed',
      'net_sites_per_month',
      'net_sites_per_month_percentage',
      'total_sites_activated',
      'sites_activated_percentage',
      'sites_closed_percentage',
    ] as const;

    return this.gridData$.getValue().reduce(
      (acc, val) => {
        for (const key of columnsWithAggregation) {
          const currentVal = acc[key];
          const additionalVal = val[key];
          if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
            acc[key] = decimalAdd(currentVal, additionalVal);
          }
        }

        acc.net_sites_per_month = 0;
        acc.total_sites_activated = acc.sites_activated;
        return acc;
      },
      {
        sites_activated: 0,
        sites_closed: 0,
        net_sites_per_month: 0,
        net_sites_per_month_percentage: 0,
        total_sites_activated: 0,
        sites_activated_percentage: 0,
        sites_closed_percentage: 0,
      }
    );
  }

  onGridReady(e: GridReadyEvent) {
    this.gridColumnApi = e.columnApi;
    this.gridApi = e.api;
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      const pinnedBottomData = { ...this.calculatePinnedBottomData(), distribution_month: 'TOTAL' };
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    });
  }

  onSiteDriverUploadClick() {
    this.overlayService.open({ content: SiteDriverUploadComponent });
  }

  selectCurve(item: SiteCurveModel | null): void {
    this.loading$.next(true);
    this.currentSiteGroupId = item?.site_group_id || '';
    if (item) {
      if (this.editMode$.getValue()) {
        this.gridApi.stopEditing();
      }
      this.editMode$.next(false);
      this.editCell = false;
      if (!item?.driver_setting_id || item.driver_setting_id === '') {
        this.populateGridDataWithTimeline();
        this.isBlended$.next(false);
        this.isFromScratch = true;
      } else {
        this.isFromScratch = false;
        this.driverSettingId$.next(item.driver_setting_id);
        this.isBlended$.next(!!item?.is_blended);

        this.siteDistributionService
          .getSiteCurveDistributions(item?.site_group_id)
          .pipe(take(1))
          .subscribe(() => {
            if (!this.isFromScratch) {
              this.cloneSite();
            } else {
              this.isFromScratch = true;
              this.populateGridDataWithTimeline();
            }
          });
      }
    }

    this.loading$.next(false);
  }

  populateGridDataWithTimeline() {
    this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();
    this.timelineQuery
      .select()
      .pipe(take(2))
      .subscribe(({ items }) => {
        const start_date = first(items)?.contract_start_date;
        let end_date = Utils.dateParse(`${last(items)?.contract_end_date.slice(0, 8)}01`);
        items.forEach((i) => {
          const parsedDate = Utils.dateParse(`${i.contract_end_date.slice(0, 8)}01`);
          if (parsedDate > end_date) {
            end_date = parsedDate;
          }
        });
        if (start_date) {
          this.monthList.start_date = start_date;
          this.monthList.end_date = end_date || '';
          this.setGridData([]);
        }
      });
  }

  editCurve(siteCurveGroup: EditableListDropdownItem | null = null) {
    const driverSiteGroup = siteCurveGroup
      ? this.siteCurveGroupList.find((item) => item.name === siteCurveGroup.name) || null
      : null;

    const modalRef = this.overlayService.open<{
      data?: {
        name?: string;
      };
    }>({
      content: SiteBlendedCurveModalComponent,
      data: this.blendedCurveModalParams(driverSiteGroup),
    });
    modalRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(({ data }) => {
      // cancel modal will be null
      if (data?.data?.name) {
        this.curveControl.setValue(data.data.name);
      }
    });
  }

  async deleteCurve(siteCurveGroup: EditableListDropdownItem) {
    const driverSiteGroup = this.siteCurveGroupList.find(
      (item) => item.name === siteCurveGroup.name
    );

    if (!driverSiteGroup) {
      return;
    }

    if (driverSiteGroup.organizations_with_forecasts.length > 0) {
      const removeDialogInput: RemoveDialogInput = {
        header: 'Remove Site Curve',
        cannotDeleteMessage: `${driverSiteGroup.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${driverSiteGroup.name} can be successfully deleted.<br>`,

        routeInputs: [
          {
            componentName: 'Forecast Methodology:',
            name: [driverSiteGroup.name] || [],
            link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
          },
        ],
      };

      const resp = this.overlayService.open({
        content: RemoveDialogComponent,
        data: removeDialogInput,
      });

      await firstValueFrom(resp.afterClosed$);
    } else {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Site Curve',
        message: `Are you sure you want to remove Site Curve ${driverSiteGroup.name}?`,
        okBtnText: 'Remove',
      });
      const event = await firstValueFrom(resp.afterClosed$);
      if (event.data?.result) {
        await this.siteCurveService.removeSiteCurve(driverSiteGroup);
        this.unselectCurveAndResetView();
      }
    }
  }

  setSiteGroups() {
    if (this.setSiteGroupSubscription) {
      this.setSiteGroupSubscription.unsubscribe();
    }

    this.setSiteGroupSubscription = combineLatest([
      this.siteCurveQuery.selectAll(),
      this.siteGroupQuery.selectAll(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([curves, siteGroups]) => {
        this.siteCurveGroups = curves;
        const siteGroupIds: string[] = [];
        curves?.forEach((c) => {
          siteGroupIds.push(c?.site_group_id);
        });
        const uniqSiteGroups: SiteCurveModel[] = [];
        siteGroups.forEach((siteGroup) => {
          if (!siteGroupIds.includes(siteGroup.id)) {
            uniqSiteGroups.push({
              curve_type: CurveType.NET,
              __typename: 'DriverSiteGroup',
              site_group_ids: [],
              constituent_site_groups: [],
              driver_setting_id: '',
              is_blended: false,
              name: siteGroup.name,
              site_group_id: siteGroup.id,
              showLine: false,
              organizations_with_forecasts: [],
            });
          }
        });
        const siteCurveGroupList: SiteCurveModel[] = [...curves, ...uniqSiteGroups];
        this.siteCurveGroups$.next(siteCurveGroupList);

        if (this.isFirstTimeInitialization) {
          if (siteCurveGroupList?.length > 1 && this.curveControl.value !== '') {
            const selectedItem = siteCurveGroupList.find((y) => y.name === this.curveControl.value);
            if (selectedItem) {
              this.isFromScratch = false;
              this.curveControl.setValue(selectedItem.name);
            } else if (siteCurveGroupList?.[0]) {
              this.isFromScratch = false;
              this.curveControl.setValue(siteCurveGroupList[0].name);
            } else {
              this.isFromScratch = true;
              this.unselectCurveAndResetView();
            }
          } else if (siteCurveGroupList?.[0]) {
            this.isFromScratch = false;
            this.curveControl.setValue(siteCurveGroupList[0].name);
          } else {
            this.isFromScratch = true;
            this.unselectCurveAndResetView();
          }
        }

        this.isFirstTimeInitialization = false;
      });
  }

  getDynamicExcelParams(): ExcelExportParams {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return {};
    }
    const fieldsToCalc = [
      'sites_activated',
      'sites_closed',
      'net_sites_per_month_percentage',
      'net_sites_per_month',
      'sites_activated_percentage',
      'sites_closed_percentage',
    ] as const;

    const totals = this.gridData$.getValue().reduce(
      (acc, val) => {
        for (const key of fieldsToCalc) {
          acc[key] = decimalAdd(Number(acc[key]), Number(val[key]));
        }
        acc.total_sites_activated = acc.sites_activated;
        return acc;
      },
      {
        sites_activated: 0,
        sites_closed: 0,
        net_sites_per_month: 0,
        net_sites_per_month_percentage: 0,
        sites_activated_percentage: 0,
        sites_closed_percentage: 0,
        total_sites_activated: 0,
      }
    );

    const exportOptions = {
      ...this.excelOptions,
      columnKeys: [
        'distribution_month',
        'sites_activated',
        'sites_closed',
        'net_sites_per_month',
        'net_sites_per_month_percentage',
        'total_sites_activated',
        'sites_activated_percentage',
        'sites_closed_percentage',
      ],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: 4,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent: [
        {
          cells: [
            {
              data: { value: `Total`, type: 'String' },
              styleId: 'total_row_header',
            },
            {
              // sites activated
              data: { value: `${totals.sites_activated}`, type: 'Number' },
              styleId: 'total_row_extended_decimal',
            },
            {
              // sites closed
              data: { value: `${totals.sites_closed}`, type: 'Number' },
              styleId: 'total_row_extended_decimal',
            },
            {
              // net sites per month
              data: { value: `${totals.net_sites_per_month}`, type: 'Number' },
              styleId: 'total_row_extended_decimal',
            },
            {
              // net sites per month %
              data: {
                value: `${decimalRoundingToString(totals.net_sites_per_month_percentage, 2)}`,
                type: 'String',
              },
              styleId: 'total_row_percent',
            },
            {
              // sites activated
              data: { value: `${totals.total_sites_activated}`, type: 'Number' },
              styleId: 'total_row_extended_decimal',
            },
            {
              // sites activated %
              data: {
                value: `${decimalRoundingToString(totals.sites_activated_percentage, 2)}`,
                type: 'String',
              },
              styleId: 'total_row_percent',
            },
            {
              // sites closed %
              data: {
                value: `${decimalRoundingToString(totals.sites_closed_percentage, 2)}`,
                type: 'String',
              },
              styleId: 'total_row_percent',
            },
          ],
        },
      ],
    } as ExcelExportParams;

    this.gridApi.setPinnedBottomRowData([]);
    // this.gridApi?.exportDataAsExcel(exportOptions);
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      const pinnedBottomData = { ...this.calculatePinnedBottomData(), distribution_month: 'TOTAL' };
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    });

    return exportOptions;
  }

  calculateSiteCurves() {
    let totalNetSiteEnrolled = 0;
    let totalSiteActivated = 0;
    let totalSiteClosed = 0;
    let percentTotal = 0;
    let isNegativeSite = false;

    this.gridData$.getValue().forEach((x: TotalDriverSiteDistribution) => {
      x.sites_activated = decimalRoundingToNumber(x.sites_activated || 0, 8);
      x.sites_closed = decimalRoundingToNumber(x.sites_closed || 0, 8);

      const activeLessClosed = decimalDifference(x.sites_activated, x.sites_closed);
      totalNetSiteEnrolled = decimalAdd(totalNetSiteEnrolled, activeLessClosed);
      totalSiteActivated = decimalAdd(totalSiteActivated, x.sites_activated);
      totalSiteClosed = decimalAdd(totalSiteClosed, x.sites_closed);

      x.net_sites_per_month = decimalRoundingToNumber(totalNetSiteEnrolled || 0, 8);
      x.total_sites_activated = decimalRoundingToNumber(totalSiteActivated || 0, 8);

      if (Utils.isNegativeAndNotCloseEnoughToZero(x.net_sites_per_month)) {
        isNegativeSite = true;
      }

      percentTotal = decimalAdd(percentTotal, x.net_sites_per_month);
    });

    this.isNetSiteNegative$.next(isNegativeSite);

    this.gridData$.getValue().forEach((x: TotalDriverSiteDistribution) => {
      x.net_sites_per_month_percentage = Utils.calculateAsPercentage(
        percentTotal,
        x.net_sites_per_month || 0
      );

      x.sites_activated_percentage = Utils.calculateAsPercentage(
        totalSiteActivated,
        x.sites_activated || 0
      );

      x.sites_closed_percentage = Utils.calculateAsPercentage(totalSiteClosed, x.sites_closed || 0);
    });

    this.gridData$.next(this.gridData$.getValue());
    this.gridApi.refreshCells();
  }

  showErrors() {
    const rowNodes: IRowNode[] = [];
    let isThereAnyInvalidRow = false;
    const { total_sites_activated, sites_closed: total_closed_sites } =
      this.calculatePinnedBottomData();

    this.gridApi.forEachNode((node) => {
      const { sites_activated, sites_closed } = node.data as TotalDriverSiteDistribution;

      if (Utils.isNumber(sites_activated) && Utils.isNumber(sites_closed)) {
        if (node.data.showError) {
          // eslint-disable-next-line no-param-reassign
          node.data.showError = false;
          rowNodes.push(node);
        }
      } else {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
        rowNodes.push(node);
      }
    });
    if (!decimalEquality(total_sites_activated, total_closed_sites)) {
      isThereAnyInvalidRow = true;
    }

    this.gridApi.redrawRows({ rowNodes });
    return isThereAnyInvalidRow;
  }

  async onSaveAll() {
    this.btnLoading$.next(true);
    const isInvalid = this.showErrors();
    if (isInvalid) {
      this.btnLoading$.next(false);
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
      return;
    }

    if (this.isFromScratch) {
      this.processSitesCreatedFromScratch();
    } else if (this.fg.valid && !this.loading$.getValue()) {
      this.loading$.next(true);

      const settings = (this.fg.value.settings || []) as Partial<ForecastSiteCurvesFormSetting>[];

      const thingsToUpdate = settings.filter((x) => !x.id || this.editedRows.has(x.id));

      const allErrors: string[][] = [];

      const gData = this.gridData$.getValue();

      if (thingsToUpdate.length > 0) {
        const responses = await batchPromises(gData, (p) =>
          this.siteDistributionService.updateSiteDistribution(p)
        );
        if (!responses.every((x) => x)) {
          this.btnLoading$.next(false);
          this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
          await this.cancelEditMode();
          this.btnLoading$.next(false);
          return;
        }
        this.editedRows.clear();
        this.overlayService.success('Sites Table updated successfully!');
      }

      if (this.removedRows.length) {
        const errors = await this.driverSiteService.remove(this.removedRows);
        allErrors.push(...errors);
        this.removedRows = [];
        this.gridData$.next([]);
        this.gridOptions.api?.refreshCells({ force: true });
      }

      if (allErrors.length) {
        this.overlayService.error(...allErrors);
      } else {
        this.overlayService.success();
      }

      this.loading$.next(false);
      await this.cancelEditMode();
    }
    this.btnLoading$.next(false);
  }

  getFilePath(id: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/sites/${id}/site-driver/`;
  }

  editGrid() {
    this.editCell = true;
    this.gridOptions.api?.refreshCells({ force: true });
    this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: 'sites_activated',
    });
  }

  async cancelEditMode() {
    let isNewData = false;
    this.gridData$.next(this.initialValue);
    this.gridData$.getValue().forEach((row) => {
      isNewData ||= this.isNewDataInRow(row);
    });
    if (isNewData && this.isFromScratch) {
      this.populateGridDataWithTimeline();
    } else {
      this.setSiteGroups();
    }
    if (this.editMode$.getValue()) {
      this.gridApi.stopEditing(true);
    }
    this.editMode$.next(false);
    this.isNetSiteNegative$.next(false);
    this.editCell = false;
    this.gridOptions.api?.refreshCells({ force: true });
  }

  isNewDataInRow = (row: TotalDriverSiteDistribution) => {
    return (
      row.id === '' && (!!row.sites_activated || !!row.sites_closed || !!row.net_sites_per_month)
    );
  };

  saveEditMode = async () => {
    this.btnLoading$.next(true);
    const isInvalid = this.showErrors();
    if (isInvalid) {
      this.btnLoading$.next(false);
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
      return;
    }

    let isNewData = false;
    this.gridData$.getValue().forEach((row) => {
      isNewData ||= this.isNewDataInRow(row);
    });

    if (isInvalid) {
      return;
    }

    if (this.isFromScratch || isNewData) {
      this.processSitesCreatedFromScratch();
    } else {
      const sData = this.gridData$.getValue();
      const responses = await batchPromises(
        sData.filter((s) => s.id),
        (p) => this.siteDistributionService.updateSiteDistribution(p)
      );
      if (!responses.every((x) => x)) {
        this.btnLoading$.next(false);
        this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
        return;
      }
      this.overlayService.success('Sites Table updated successfully!');

      const { success, errors } = await firstValueFrom(
        this.gqlService.processEvent$({
          type: EventType.SITE_DRIVER_DISTRIBUTION_UPDATED,
          entity_type: EntityType.DRIVER,
          entity_id:
            this.currentSiteGroupId === null || this.currentSiteGroupId === ''
              ? ''
              : this.currentSiteGroupId.toString(),
        })
      );
      if (success) {
        this.overlayService.success();
      } else {
        this.overlayService.error(errors);
      }
      this.setSiteGroups();
      await this.cancelEditMode();
    }
    this.cloneSite();
    this.editMode$.next(false);
    this.btnLoading$.next(false);
  };

  cellValueChanged() {
    this.calculateSiteCurves();
  }

  rowPinnedCheck(event: CellEditingStartedEvent) {
    if (event.node.rowPinned) {
      this.gridOptions.api?.stopEditing();
    }
  }

  cloneSite() {
    const sData = sortBy(this.siteDistributionQuery.getAll(), 'distribution_month');
    if (!this.monthList.start_date) {
      if (this.setTimelineSubscription) {
        this.setTimelineSubscription.unsubscribe();
      }
      this.setTimelineSubscription = this.timelineService
        .getTimelineItems()
        .pipe(untilDestroyed(this))
        .subscribe();
      this.timelineQuery
        .select()
        .pipe(take(2))
        .subscribe(({ items }) => {
          const start_date = first(items)?.contract_start_date;
          let end_date = Utils.dateParse(`${last(items)?.contract_end_date.slice(0, 8)}01`);
          items.forEach((i) => {
            const parsedDate = Utils.dateParse(`${i.contract_end_date.slice(0, 8)}01`);
            if (parsedDate > end_date) {
              end_date = parsedDate;
            }
          });
          if (start_date) {
            this.monthList.start_date = start_date;
            this.monthList.end_date = end_date;
            this.setGridData(sData);
          }
        });
    } else {
      this.setGridData(sData);
    }
  }

  ngOnDestroy() {
    this.stickyElementService.reset();

    if (this.fgListener) {
      this.fgListener.unsubscribe();
    }
  }

  listenForChanges() {
    if (this.fgListener) {
      this.fgListener.unsubscribe();
    }
    const fa = this.fg.controls.settings;
    if (fa) {
      this.fgListener = merge(...fa.controls.map((x) => x.valueChanges)).subscribe((value) => {
        if (value.id) {
          this.editedRows.add(value.id);
        }
      });
    }
  }

  createSiteSettingFB({
    id,
    cohort_name,
    number_of_sites,
    first_initiated_date,
    discontinued_date,
    ramp_time_in_weeks,
    target_patient_count,
  }: Partial<DriverSiteSettingModel>) {
    return this.fb.group<Partial<ForecastSiteCurvesFormSetting>>({
      id,
      cohort_name,
      number_of_sites,
      first_initiated_date,
      discontinued_date,
      ramp_time_in_weeks,
      target_patient_count,
    });
  }

  onAddCohort() {
    const fa = this.fg.controls.settings;
    fa.push(this.createSiteSettingFB({}));
  }

  onRemoveCohort(i: number, set: FormGroup) {
    const { id } = set.value;
    if (id) {
      this.removedRows.push(id);
    }

    const fa = this.fg.controls.settings;
    fa.removeAt(i);
  }

  async processSitesCreatedFromScratch() {
    this.loading$.next(true);
    const thingsToUpdate: TotalDriverSiteDistribution[] = [];
    this.gridApi.forEachNode((node) => {
      thingsToUpdate.push(node.data);
    });
    const data = thingsToUpdate.map((row) => ({
      month: Utils.dateFormatter(row.distribution_month, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }),
      sites_activated: row.sites_activated,
      sites_closed: row.sites_closed,
    }));
    const key = `${this.getFilePath(
      this.curveControl.value !== '' ? this.currentSiteGroupId.toString() : 'Site-Curve'
    )}site-curve-from-scratch.csv`;
    const blob = new Blob([Utils.objectToCsv(data)], { type: 'text/csv' });
    const fileSuccess = await this.apiService.uploadFile(key, new File([blob], key));

    if (fileSuccess) {
      const { success, errors } = await firstValueFrom(
        this.gqlService.processEvent$({
          type: EventType.SITE_DRIVER_TEMPLATE_UPLOADED,
          entity_type: EntityType.SITE,
          entity_id: this.curveControl.value !== '' ? this.currentSiteGroupId.toString() : '',
          bucket_key: `public/${key}`,
          payload: JSON.stringify({
            should_show_on_document_library: false,
          }),
        })
      );
      if (success) {
        // allows time for SITE_DRIVER_TEMPLATE_UPLOADED event to complete
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await firstValueFrom(this.siteCurveService.get().pipe(take(1)));
        await firstValueFrom(this.siteGroupService.get().pipe(take(1)));
        const { data: sData } = await firstValueFrom(
          this.siteDistributionService
            .getSiteCurveDistributions(
              this.curveControl.value !== '' ? this.currentSiteGroupId.toString() : ''
            )
            .pipe(take(1))
        );
        this.isFromScratch = false;
        this.setSiteGroups();
        if (sData) {
          this.setGridData(sData);
        }
        if (this.editMode$.getValue()) {
          this.gridApi.stopEditing();
          this.editMode$.next(false);
        }
        this.editCell = false;
        this.gridOptions.api?.refreshCells({ force: true });
        this.overlayService.success();
      } else {
        this.overlayService.error(errors);
      }
    } else {
      this.overlayService.error('There was an error uploading the file. Please try again');
    }
    this.loading$.next(false);
  }

  autoSize() {
    this.gridOptions.api?.sizeColumnsToFit();
  }

  onEditClick(): void {
    this.initialValue = [];
    this.gridData$.value.forEach((data) => {
      this.initialValue.push({ ...data });
    });
    this.editMode$.next(true);
    this.editGrid();
  }

  setGridData(sData: listDriverSiteDistributionsQuery[]) {
    const { start_date } = this.monthList;
    const { end_date } = this.monthList;
    this.clonedSite = [];
    if (start_date) {
      const month_list: { label: string; value: string }[] = [];
      let date = Utils.dateParse(`${start_date.slice(0, 8)}01`);
      let index = 0;
      let totalSiteActivated = 0;
      while (date <= end_date) {
        const value = new Intl.DateTimeFormat('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(date);
        date = Utils.addMonths(date, 1);
        if (sData.length !== 0) {
          if (!sData.some((x) => x.distribution_month === value)) {
            const l: TotalDriverSiteDistribution = {
              __typename: 'DriverSiteDistribution',
              id: uuidv4(),
              distribution_month: value,
              sites_activated: 0,
              sites_closed: 0,
              net_sites_per_month: 0,
              total_sites_activated: 0,
              distribution_mode: DistributionMode.DISTRIBUTION_MODE_FORECAST,
            };
            this.siteDistributionService.createSiteDistribution({
              ...omit(l, ['__typename', 'total_sites_activated', 'id']),
              driver_site_distribution_id: l.id,
              driver_setting_id: this.driverSettingId$.getValue() || '',
            });
            this.clonedSite.push(l);
          } else {
            this.clonedSite.push(sData[index]);
          }
        } else {
          month_list.push({
            value,
            label: Utils.dateFormatter(value, { day: undefined, year: '2-digit' }),
          });
          const l: TotalDriverSiteDistribution = {
            distribution_month: value,
            sites_activated: 0,
            sites_closed: 0,
            net_sites_per_month: 0,
            total_sites_activated: 0,
            distribution_mode: DistributionMode.DISTRIBUTION_MODE_FORECAST,
            __typename: 'DriverSiteDistribution',
            id: uuidv4(),
          };
          if (this.clonedSite.length < month_list.length) {
            this.clonedSite.push(l);
          }
        }
        index += 1;
      }
      const newData = Utils.clone(this.clonedSite);
      newData.forEach((x) => {
        x.net_sites_per_month = decimalRoundingToNumber(x.net_sites_per_month || 0, 8);
        totalSiteActivated = decimalAdd(totalSiteActivated, x.sites_activated || 0, 8);
        x.total_sites_activated = totalSiteActivated;
      });
      this.gridData$.next(newData);
    }
  }

  private blendedCurveModalParams(
    item: SiteCurveModel | null
  ): BlendedCurveModalDataModel<SiteCurveModel> {
    return {
      availableGroups: this.siteCurveGroups
        .filter((group) => !group.is_blended && group.site_group_id)
        .filter(
          (value, index, self) =>
            index === self.findIndex((group) => group.driver_setting_id === value.driver_setting_id)
        ),
      text: {
        title: 'Blended Site Curve',
        subTitle: 'Select Site Groups',
      },
      blendedCurve: item,
    };
  }

  private unselectCurveAndResetView(): void {
    if (this.curveControl.value) {
      this.curveControl.setValue('');
      this.populateGridDataWithTimeline();
    } else {
      this.isFromScratch = true;
      this.populateGridDataWithTimeline();
    }
  }

  private updateSiteCurveList(items: SiteCurveModel[]): void {
    this.siteCurveGroupList = items || [];
    this.siteCurveListOptions =
      items?.map((item: PatientCurveState) => ({
        name: item.name,
        value: item.name,
        showLine: item.showLine,
        isEditable: item.is_blended,
        isDeletable: item.driver_setting_id.length > 1,
      })) || [];
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }
}
