import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { GqlService, AmountType } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { OverrideSettingsModel, OverrideSettingsStore } from './override-settings.store';
import { ActivityStore } from '../activity/activity.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OverrideSettingsService {
  constructor(
    private overrideSettingsStore: OverrideSettingsStore,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private activityStore: ActivityStore
  ) {}

  get() {}

  add(overrideSetting: OverrideSettingsModel) {
    this.overrideSettingsStore.add(overrideSetting);
  }

  async upsert({
    id,
    expense_detail,
    activity_id,
  }: {
    id: string | null;
    activity_id: string;
    expense_detail: string;
    units: number;
  }) {
    if (!id) {
      const { success, errors, data } = await firstValueFrom(
        this.gqlService.createOverrideSetting$({
          activity_id,
          expense_type_id: 'EXPENSE_OVERRIDE',
          expense_detail,
          period_start: '2021-09-01',
          period_end: '2021-09-01',
          source: 'BASE',
          amount_type: AmountType.AMOUNT_SERVICE,
          amount_curr: 'CURRENCY_USD',
          amount: null,
          details: null,
        })
      );
      if (success && data) {
        this.overrideSettingsStore.add(data);
        this.activityStore.update(activity_id, {
          forecast_override_id: data.id,
        });
      } else {
        this.overlayService.error(errors);
      }

      return {
        success,
      };
    }

    const { success, errors, data } = await firstValueFrom(
      this.gqlService.updateOverrideSetting$({
        id,
        activity_id,
        expense_type_id: 'EXPENSE_OVERRIDE',
        expense_detail,
        period_start: '2021-01-01',
        period_end: '2021-01-01',
        source: 'BASE',
        amount_type: AmountType.AMOUNT_SERVICE,
        amount_curr: 'CURRENCY_USD',
        amount: null,
        details: null,
      })
    );
    if (success && data) {
      this.overrideSettingsStore.update(id, data);
      this.activityStore.update(activity_id, {});
    } else {
      this.overlayService.error(errors);
    }
    return {
      success,
    };
  }

  remove(id: ID) {
    this.overrideSettingsStore.remove(id);
  }
}
