<div
  class="min-h-screen bg-aux-gray-light flex flex-col justify-between py-12 lg:px-8 my-0 mx-auto font-inter"
>
  <div class="flex flex-col justify-center">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="rounded shadow-md bg-white p-14 text-sm">
        <img class="mb-10 mx-auto w-3/4" src="assets/img/logo-black.png" alt="" />

        <div *ngIf="errorMessage" class="my-5 p-5 font-medium bg-aux-error text-white">
          {{ errorMessage }}
        </div>

        <form
          *ngIf="showLoginForm"
          novalidate
          autocomplete="off"
          [formGroup]="signInForm"
          (ngSubmit)="submitSignInForm()"
        >
          <div>
            <label for="email">Email:</label>
            <input
              type="text"
              class="input"
              id="email"
              placeholder="you@example.com"
              formControlName="email"
            />
          </div>

          <div class="mt-5">
            <label for="password">Password:</label>
            <input
              type="password"
              class="input"
              id="password"
              placeholder="Password"
              formControlName="password"
            />
          </div>

          <div class="mt-3">
            <a class="aux-link" [routerLink]="[forgotPasswordLink]"> Forgot password? </a>
          </div>

          <aux-checkbox class="mt-3 text-sm" [id]="'remember_me'"
            >Remember this device</aux-checkbox
          >

          <button class="btn btn--blue text-sm w-full h-10 mt-4" type="submit">
            <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
            <span>Log in</span>
          </button>

          <ng-container *ngIf="showSSOLoginButton">
            <hr class="my-4" />

            <div class="flex items-center dropdown-container">
              <button
                class="btn btn--blue text-sm w-full h-10"
                type="button"
                [ngClass]="{
                  'rounded-r-none': showSSODropdown
                }"
                (click)="onSSOClick()"
              >
                <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
                <ng-container *ngIf="(loading$ | async) === false">
                  <aux-icon name="Lock" class="mr-2" />
                  <span>Log in with SSO</span>
                </ng-container>
              </button>
              <div class="ml-1">
                <button
                  *ngIf="showSSODropdown"
                  type="button"
                  class="btn btn--blue w-full h-10 rounded-l-none pl-1 pr-1.5"
                  (click)="dropdown.open()"
                >
                  <aux-icon name="ChevronDown" />
                </button>
              </div>
            </div>
            <ng-select
              #dropdown
              appendTo="body"
              class="custom-dropdown w-full h-0 !border-0"
              (change)="onSSODropdown($event)"
            >
              <ng-option *ngFor="let provider of options" [value]="provider">
                {{ provider }}
              </ng-option>
            </ng-select>
            <div class="text-sm text-center mt-1">Identity Provider: {{ selectedProvider }}</div>
          </ng-container>
        </form>

        <form
          *ngIf="!showLoginForm"
          class="space-y-5"
          novalidate
          autocomplete="off"
          [formGroup]="newPasswordForm"
          (ngSubmit)="submitNewPasswordForm()"
        >
          <div>
            <label for="firstName">First Name</label>
            <input
              type="text"
              class="input"
              id="firstName"
              placeholder="First Name"
              formControlName="firstName"
            />
          </div>

          <div>
            <label for="lastName">Last Name</label>
            <input
              type="text"
              class="input"
              id="lastName"
              placeholder="Last Name"
              formControlName="lastName"
            />
          </div>

          <div>
            <label for="newPassword">New Password</label>
            <input
              type="password"
              class="input"
              id="newPassword"
              placeholder="New Password"
              formControlName="password"
            />
          </div>

          <button class="btn btn--blue w-full" type="submit">
            <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
            <span>Confirm</span>
          </button>
        </form>
      </div>

      <div class="mb-5 mt-10 space-x-2 text-center">
        <a href="https://www.auxili.us/privacy-policy" target="_blank" class="aux-link no-underline"
          >Privacy Policy</a
        >
        <a
          href="https://www.auxili.us/terms-and-conditions"
          target="_blank"
          class="aux-link no-underline"
          >Terms and Conditions</a
        >
      </div>

      <p class="text-center">©️{{ year }} Auxilius Inc., All rights reserved.</p>
    </div>
  </div>
  <div class="bg-aux-gray-light text-aux-gray-dark-100 flex items-center justify-end py-2 pr-4">
    App Version {{ appInitService.APP_VERSION }}
  </div>
</div>
