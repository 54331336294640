import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ExportType, KeysMatching, Utils } from '@services/utils';
import {
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnApi,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRichCellEditorParams,
  RowClassParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, from, of, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  ActivityType,
  AdjustmentType,
  AmountType,
  batchCreateBudgetExpensesMutation,
  batchCreateNotesMutation,
  BudgetExpenseData,
  BudgetExpenseInput,
  CreateNoteInput,
  Currency,
  DocumentType,
  EntityType,
  EventType,
  ExpenseNoteType,
  ExpenseSourceType,
  ExpenseType,
  GqlService,
  listDiscountExpensesQuery,
  listExpenseSourceSettingsQuery,
  listInMonthExpensesQuery,
  listUserNamesWithEmailQuery,
  Note,
  NoteType,
  updateAccrualsMutation,
  User,
  VendorEstimateSummary,
  WorkflowStep,
} from '@services/gql.service';
import * as dayjs from 'dayjs';
import { OverlayService } from '@services/overlay.service';
import { StickyElementService } from '@services/sticky-element.service';
import { AuthQuery } from '@models/auth/auth.query';
import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { formatDate } from '@angular/common';
import { OrganizationQuery } from '@models/organization/organization.query';

import { filter, includes, isEqual, isUndefined, map as _map, merge, some, uniq } from 'lodash-es';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.query';
import { BudgetService } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.service';
import { TableService } from '@services/table.service';
import {
  blankActivitiesHeaderClass,
  getActivitiesColumnDefs,
  getCurrentForecastColumnDefs,
  getEvidenceBasedColumnDefs,
  getPreviousMonthColumnDefs,
  getVendorEstimateColumnDefs,
  spacerColumn,
  uomHide$,
} from './column-defs';
import {
  InvestigatorEstimate,
  PeriodCloseComponent,
  QuarterDate,
} from '../../period-close.component';
import {
  AdjustmentModalComponent,
  AdjustmentModalResponseType,
} from './adjustment-modal/adjustment-modal.component';
import { MainQuery } from '../../../../layouts/main-layout/state/main.query';
import { AgAdjustmentColumnComponent } from './ag-adjustment-column.component';
import { WorkflowQuery } from '../quarter-close/close-quarter-check-list/store';
import { SupportModalComponent } from './support-modal/support-modal.component';
import { MessagesConstants } from '@constants/messages.constants';
import { AgAdjustmentPrevMonthHeaderComponent } from './ag-adjustment-prev-month-header.component';
import { NoteModalComponent, NoteModalResponseType } from './note-modal/note-modal.component';
import { AgAdjustmentVendorEstimateHeaderComponent } from './ag-adjustment-vendor-estimate-header.component';
import { QuarterCloseChecklistVendorService } from '../quarter-close-checklist/services/quarter-close-checklist-vendor.service';
import { QuarterCloseChecklistPeriodCloseService } from '../quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import {
  EvidenceBasedHeaderGetMonthVendor,
  EvidenceBasedHeaderGetVendorCurrency,
} from './ag-adjustment-evidence-based-header/ag-adjustment-evidence-based-header.model';
import { TableConstants } from '@constants/table.constants';
import { AgExpandableGroupHeaderComponent } from './ag-expandable-group-header.component';
import { SitesQuery } from '@models/sites/sites.query';
import { SitesService } from '@models/sites/sites.service';
import { DiscountExpenseDetail } from '../quarter-close-checklist/components/checklist-section-discount/checklist-section-discount.component';
import { QuarterCloseAdjustmentsService } from './quarter-close-adjustments.service';
import { VariationStatusComponent } from 'src/app/pages/design-system/tables';
import {
  DocumentLibraryFile,
  DocumentLibraryService,
} from 'src/app/pages/documents/document-library.service';
import { ApiService } from '@services/api.service';
import { AddVendorEstimateUploadComponent } from '../quarter-close/add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { EventService } from '@services/event.service';
import { decimalDifference, AgSetColumnsVisible, AgEditFirstRow } from '@shared/utils';
import { AgAdjustmentLinkHeaderComponent } from './ag-adjustment-link-header';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AgAdjustmentDiscountTooltipComponent } from './ag-adjustment-discount-tooltip.component';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { AgSelectEditorOptionRendererComponent } from '@components/ag-select-editor-option-renderer/ag-select-editor-option-renderer.component';

export enum Expense_Source {
  'Forecast' = 'Forecast',
  'Evidence Based' = 'Evidence Based',
  'Vendor Estimate' = 'Vendor Estimate',
  Manual = 'Manual',
  None = 'None',
}

const expenseSourceMapping = {
  [ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE]: Expense_Source['Vendor Estimate'],
  [ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED]: Expense_Source['Evidence Based'],
  [ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT]: Expense_Source.Manual,
  [ExpenseSourceType.EXPENSE_SOURCE_FORECAST]: Expense_Source.Forecast,
  [ExpenseSourceType.EXPENSE_SOURCE_NONE]: Expense_Source.None,
};

const expenseSourceReverseMapping = {
  [Expense_Source['Vendor Estimate']]: ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE,
  [Expense_Source['Evidence Based']]: ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED,
  [Expense_Source.Manual]: ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT,
  [Expense_Source.Forecast]: ExpenseSourceType.EXPENSE_SOURCE_FORECAST,
  [Expense_Source.None]: ExpenseSourceType.EXPENSE_SOURCE_NONE,
};

interface QuarterCloseAdjustmentGridData
  extends Omit<listInMonthExpensesQuery, 'total_monthly_expense'> {
  actuals_to_date: number;
  total_amount: number;
  total_remaining: number;

  units: number;

  prev_month_unit: number;
  prev_month_amount: number;

  current_forecast_percentage: number;
  current_forecast_unit: number;
  current_forecast_amount: number;

  vendor_estimate_percentage: number;
  vendor_estimate_unit: number;
  vendor_estimate_amount: number;

  evidence_based_percentage: number;
  evidence_based_unit: number;
  evidence_based_amount: number;

  evidence_based_exist: boolean;

  tma_unit_cost: number;
  tma_unit: number;
  tma_percentage: number;
  tma_amount: number;
  variance_to_forecast: number;
  historical_adjustment_amount: number;
  total_adjustment: number;
  total_monthly_expense: number;
  expense_ltd: number;

  tma_source: Expense_Source;

  total_documents: number;
}

type CalculableColumns = KeysMatching<QuarterCloseAdjustmentGridData, number>;

@UntilDestroy()
@Component({
  selector: 'aux-quarter-close-adjustments',
  templateUrl: './quarter-close-adjustments.component.html',
  styles: [
    `
      ::ng-deep .adjustment-table .note-cell {
        color: var(--aux-blue-light-200) !important;
        text-align: left !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseAdjustmentsComponent implements OnInit, OnDestroy {
  selected_month = new FormControl('');

  selected_vendor = new FormControl('');

  editMode$ = new BehaviorSubject(false);

  removeVendorEstimateLoading$ = new BehaviorSubject(false);

  selectedVendorCurrency = Currency.USD;

  showUnitTotals$ = new BehaviorSubject(false);

  vendorEstimates$ = new BehaviorSubject([] as VendorEstimateSummary[]);

  doesSelectedMonthHasVendorEstimate$ = combineLatest([
    this.vendorEstimates$,
    this.selected_vendor.valueChanges.pipe(startWith(this.selected_vendor.value)),
  ]).pipe(
    map(() => {
      return this.doesSelectedMonthHasVendorEstimate();
    })
  );

  doesSelectedMonthHasVendorEstimate = () => {
    const estimates = this.vendorEstimates$.getValue();
    const selectedVendor = this.selected_vendor.value || '';
    return !!estimates.filter(
      (estimate) => estimate.organization_id === selectedVendor && estimate.vendor_estimate_exists
    ).length;
  };

  vendorEstimateSupportingDocUploaded$ = new Subject<void>();

  vendorEstimateSupportingDoc$ = new BehaviorSubject<DocumentLibraryFile[]>([]);

  doesSelectedMonthHasVendorEstimateSupportingDoc$ = combineLatest([
    this.vendorEstimateSupportingDoc$,
    this.selected_month.valueChanges.pipe(startWith(this.selected_month.value)),
  ]).pipe(
    map(([estimates]) => {
      const selectedMonth = this.selected_month.value || '';
      return !!estimates.filter(
        (estimate) => estimate.target_date?.slice(0, 7) === selectedMonth.slice(0, 7)
      ).length;
    })
  );

  isSelectedMonthOpen$ = new BehaviorSubject<boolean>(false);

  isSelectedMonthOpenOrFuture$ = new BehaviorSubject<boolean>(false);

  private isSelectedMonthFuture = false;

  getNonEditableCellClasses = TableService.getNonEditableCellClasses(this.editMode$);

  getEditableCellClasses = TableService.getEditableCellClasses(this.editMode$);

  getEditableHeaderClasses = TableService.getEditableHeaderClasses(this.editMode$);

  currencyFormatter = (params: ValueFormatterParams) => {
    return Utils.agCurrencyFormatterAccounting(params, this.selectedVendorCurrency);
  };

  vendors$ = this.budgetQuery.select('budget_info').pipe(
    map((data) => {
      return data.map((info) => {
        return {
          label: info.name,
          value: info.vendor_id,
        };
      });
    })
  );

  months: (QuarterDate & { label: string })[] = [];

  selected_category = new FormControl('');

  selected_threshold = new FormControl('');

  defaultCategories: { label: string; value: ActivityType | '' }[] = [
    { label: 'All', value: '' },
    { label: 'Services', value: ActivityType.ACTIVITY_SERVICE },
    { label: 'Discount', value: ActivityType.ACTIVITY_DISCOUNT },
    { label: 'Pass-through', value: ActivityType.ACTIVITY_PASSTHROUGH },
    { label: 'Investigator', value: ActivityType.ACTIVITY_INVESTIGATOR },
  ];

  categories: { label: string; value: ActivityType | '' }[] = this.defaultCategories;

  materialityThresholds: { label: string; value: number | string }[] = [
    { label: 'All', value: '' },
    { label: '>$100,000', value: 100_000 },
    { label: '>$50,000', value: 50_000 },
    { label: '>$25,000', value: 25_000 },
    { label: '>$10,000', value: 10_000 },
    { label: '>$5,000', value: 5_000 },
  ];

  editedRows = new Set<string>();

  editedOldGridRow = new Map<
    string,
    {
      tma_amount: number;
      tma_unit_cost: number;
      historical_adjustment_amount: number;
      vendor_estimate_amount: number;
    }
  >();

  vendorEstimateChangedRows = new Set<string>();

  vendorEstimateEffectedRows = new Set<string>();

  vendorEstimateSelectableCategories = new Set<ActivityType>();

  unitChangedRows = new Set<string>();

  totalChangedRows = new Set<string>();

  sourceChangedRows = new Set<string>();

  manualsToBeDeleted = new Set<string>();

  historicalAdjustmentChangedRows = new Set<string>();

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  // Allows AgAdjustmentEvidenceBasedHeader to
  // get the currently selected values for the
  // Month and Vendor form control (filters)

  getSelectedMonthAndVendor: EvidenceBasedHeaderGetMonthVendor = () => {
    const month = this.selected_month.value || '';
    const vendor = this.selected_vendor.value || '';

    return [month, vendor];
  };

  getSelectedVendorCurrency: EvidenceBasedHeaderGetVendorCurrency = () => {
    return this.organizationQuery.getEntity(this.selected_vendor.value)?.currency || Currency.USD;
  };

  gridOptions$ = new BehaviorSubject<GridOptions>(this.getGridOptions(''));

  gridApi$ = new BehaviorSubject<GridApi | null>(null);

  gridColumnApi$ = new BehaviorSubject<ColumnApi | null>(null);

  loading$ = new BehaviorSubject(true);

  afterOnSave = new BehaviorSubject(false);

  inMonthExpenses$ = new BehaviorSubject<listInMonthExpensesQuery[]>([]);

  discountExpenses$ = new BehaviorSubject<listDiscountExpensesQuery[]>([]);

  estimate$ = new BehaviorSubject<Record<string, Record<string, InvestigatorEstimate>>>({});

  gridData$ = new BehaviorSubject<QuarterCloseAdjustmentGridData[]>([]);

  filteredGridData$ = this.selected_threshold.valueChanges.pipe(
    startWith(this.selected_threshold.value),
    switchMap((selectedThreshold) => {
      return this.gridData$.pipe(
        map((data) => {
          if (!selectedThreshold) {
            return data;
          }

          return data.filter((row) => {
            return (
              this.editedRows.has(row.activity_id) ||
              row.tma_amount > +selectedThreshold ||
              row.current_forecast_amount > +selectedThreshold ||
              row.vendor_estimate_amount > +selectedThreshold ||
              row.evidence_based_amount > +selectedThreshold
            );
          });
        })
      );
    }),
    shareReplay(1)
  );

  bottomRowData$ = new BehaviorSubject<Record<CalculableColumns, number>>(
    {} as Record<CalculableColumns, number>
  );

  saveCheck$ = new BehaviorSubject(false);

  exportButtonVariant = ExcelButtonVariant.OUTLINE;

  isWorkflowLocked$ = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS
  );

  isSelectedCategoryDiscount$ = this.selected_category.valueChanges.pipe(
    startWith(this.selected_category.value),
    map(() => this.selected_category.value === ActivityType.ACTIVITY_DISCOUNT)
  );

  editButtonDisabled$ = new BehaviorSubject(false);

  editButtonTooltip$ = new BehaviorSubject('');

  expenseSettings: Array<listExpenseSourceSettingsQuery> = [];

  constructor(
    public periodCloseComponent: PeriodCloseComponent,
    private budgetQuery: BudgetQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private workflowQuery: WorkflowQuery,
    private organizationQuery: OrganizationQuery,
    private budgetService: BudgetService,
    private vendorService: QuarterCloseChecklistVendorService,
    private stickyElementService: StickyElementService,
    public sitesQuery: SitesQuery,
    private sitesService: SitesService,
    private cdr: ChangeDetectorRef,
    private quarterCloseAdjustmentsService: QuarterCloseAdjustmentsService,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private documentLibraryService: DocumentLibraryService,
    private apiService: ApiService,
    private eventService: EventService,
    private launchDarklyService: LaunchDarklyService,
    private router: Router
  ) {
    this.filteredGridData$.pipe(untilDestroyed(this)).subscribe(async () => {
      await this.generatePinnedBottomData();
      this.updateBottomData();
    });

    combineLatest([
      this.quarterCloseAdjustmentsService.selectedMonthValue$,
      this.quarterCloseAdjustmentsService.selectedVendorValue$,
      this.quarterCloseAdjustmentsService.selectedCategoryValue$,
    ])
      .pipe(
        map(([selectedMonth, selectedVendor, category]) => {
          if (selectedMonth) {
            if (this.months.find((x) => x.iso === selectedMonth)) {
              this.selected_month.setValue(selectedMonth);
            }
          }
          if (selectedVendor) {
            this.selected_vendor.setValue(selectedVendor);
          }
          if (category) {
            this.selected_category.setValue(category);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();

    combineLatest([
      this.isWorkflowLocked$,
      this.isSelectedMonthOpen$,
      this.isSelectedCategoryDiscount$,
    ])
      .pipe(
        map(([isWorkflowLocked, isSelectedMonthOpen, isSelectedCategoryDiscount]) => {
          const disabled = isWorkflowLocked || !isSelectedMonthOpen || isSelectedCategoryDiscount;

          this.editButtonDisabled$.next(disabled);

          let message = '';
          if (!isSelectedMonthOpen) {
            message = MessagesConstants.CHANGES_UNABLE_SINCE_MONTH_CLOSED;
          } else if (isSelectedCategoryDiscount) {
            message = MessagesConstants.CANT_ADJUST_DISCOUNT;
          } else if (isWorkflowLocked) {
            message = MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE;
          }

          this.editButtonTooltip$.next(message);
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.isWorkflowLocked$.pipe(untilDestroyed(this)).subscribe((bool) => {
      if (bool && this.editMode$.getValue()) {
        this.onCancel();
      }
    });
    combineLatest([
      this.selected_category.valueChanges,
      this.sitesService.get(),
      this.gridColumnApi$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (
          this.selected_category.value === ActivityType.ACTIVITY_DISCOUNT &&
          this.editMode$.getValue()
        ) {
          this.onCancel();
        }
        this.updateCategoryFiltering();
      });

    this.mainQuery
      .select('userList')
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        users.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
      });

    this.selected_vendor.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        if (this.selected_vendor.value) {
          this.quarterCloseAdjustmentsService.updateFormControlValues(
            '',
            this.selected_vendor.value
          );
        }
      });

    this.selected_category.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.quarterCloseAdjustmentsService.updateSelectCategory(
          this.selected_category.value || ''
        );
      });

    combineLatest([
      this.selected_vendor.valueChanges.pipe(
        startWith(this.selected_vendor.value),
        distinctUntilChanged()
      ),
      this.vendorEstimateSupportingDocUploaded$.pipe(startWith(null)),
      this.eventService.select$(EventType.NEW_TASK).pipe(startWith(null)),
    ])
      .pipe(
        switchMap(([org_id]) => {
          if (org_id) {
            const filterModel = {
              document_type_id: {
                filterType: 'text',
                type: 'equals',
                filter: DocumentType.DOCUMENT_VENDOR_ESTIMATE_SUPPORT,
              },
              vendor_id: {
                filterType: 'text',
                type: 'equals',
                filter: org_id,
              },
            };
            return from(
              this.documentLibraryService.getDocumentLibraryList(JSON.stringify(filterModel))
            );
          }
          return of([]);
        }),
        tap((data: DocumentLibraryFile[]) => {
          this.vendorEstimateSupportingDoc$.next(data || []);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    combineLatest([
      this.selected_month.valueChanges.pipe(
        startWith(this.selected_month.value),
        distinctUntilChanged()
      ),
    ])
      .pipe(
        switchMap(([selected_month]) => {
          if (selected_month && !this.router.url.includes(ROUTING_PATH.CLOSING.CHECKLIST)) {
            this.quarterCloseAdjustmentsService.updateFormControlValues(selected_month);

            return from(
              this.gqlService.listVendorEstimateSummaries$(dayjs(selected_month).format('MMM-YYYY'))
            );
          }
          return of({ data: [] });
        }),
        tap(({ data }) => {
          this.vendorEstimates$.next(data || []);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    combineLatest([
      this.selected_month.valueChanges.pipe(
        startWith(this.selected_month.value),
        distinctUntilChanged()
      ),
      this.selected_vendor.valueChanges.pipe(
        startWith(this.selected_vendor.value),
        distinctUntilChanged()
      ),
    ])
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap(([month, vendor]) => {
          if (this.editMode$.getValue()) {
            this.onCancel();
          }
          this.selectedVendorCurrency =
            this.organizationQuery.getEntity(this.selected_vendor.value)?.currency || Currency.USD;
          this.loading$.next(true);
          if (month && vendor) {
            // Persist selected month to sync with quarter-close-checklist
            this.periodCloseService.persistedQuarterMonth = month;
            this.periodCloseService.selectedQuarterMonthChanged$.next(null);

            const formattedMonth = dayjs(month).format('MMM-YYYY').toUpperCase();
            return combineLatest([
              this.gqlService.listInMonthExpenses$({
                organization_id: vendor,
                period: formattedMonth,
              }),
              this.periodCloseComponent.groupedInvestigatorEstimate$,
              this.gqlService.listDiscountExpenses$({
                amount_types: [AmountType.AMOUNT_DISCOUNT],
                period: formattedMonth,
                organization_id: vendor,
              }),
              this.gqlService.listExpenseSourceSettings$({
                organization_id: vendor,
                period: dayjs(month).format('YYYY-MM-DD'),
              }),
            ]);
          }

          this.loading$.next(false);

          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(([{ data, errors }, estimate, discountExpenses, expenseSettings]) => {
        const err = errors || discountExpenses.errors;
        if (err) {
          this.overlayService.error(err);
        }

        this.expenseSettings = expenseSettings.data || [];
        this.estimate$.next(estimate);
        this.isSelectedMonthOpen$.next(
          dayjs(this.periodCloseComponent.currentMonth)
            .date(1)
            .isSame(dayjs(this.selected_month.value).date(1))
        );
        this.isSelectedMonthFuture = dayjs(this.periodCloseComponent.currentMonth)
          .date(1)
          .isBefore(dayjs(this.selected_month.value).date(1));
        this.isSelectedMonthOpenOrFuture$.next(
          dayjs(this.periodCloseComponent.currentMonth)
            .date(1)
            .isSameOrBefore(dayjs(this.selected_month.value).date(1))
        );
        this.inMonthExpenses$.next(data || []);
        this.discountExpenses$.next(discountExpenses.data || []);
        this.parseExpenses();
        // this.generatePinnedBottomData();

        this.loading$.next(false);
      });

    combineLatest([
      this.shouldShowEvidenceColumns$,
      this.selected_month.valueChanges.pipe(
        startWith(this.selected_month.value),
        distinctUntilChanged()
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([shouldShowEB, month]) => {
        this.gridOptions$.next(this.getGridOptions(dayjs(month).format('MMM YYYY'), !shouldShowEB));
      });

    combineLatest([
      this.periodCloseComponent.quartersObjUpdated$.pipe(startWith(null)),
      this.periodCloseComponent.selectedQuarter.valueChanges.pipe(
        startWith(this.periodCloseComponent.selectedQuarter.value)
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateMonths();
      });
    this.gridData$.pipe(untilDestroyed(this)).subscribe((gd) => {
      if (gd.length > 0) {
        const usedActivityTypes: ActivityType[] = [];
        gd.forEach((element) => {
          if (!usedActivityTypes.includes(element.activity_type)) {
            usedActivityTypes.push(element.activity_type);
          }
        });
        this.categories = this.defaultCategories.filter((category) =>
          usedActivityTypes.length !== 1
            ? category.value === '' || usedActivityTypes.includes(category.value)
            : usedActivityTypes.includes(category?.value as ActivityType)
        );
        //reset selected category to all if vendor does not have that category
        if (!this.categories.some((category) => category.value === this.selected_category.value)) {
          this.selected_category.setValue(
            usedActivityTypes.length !== 1 ? this.defaultCategories[0].value : usedActivityTypes[0]
          );
        }
      }
    });

    combineLatest([this.gridApi$.pipe(take(1), startWith(null)), this.mainQuery.select('trialKey')])
      .pipe(
        switchMap(() =>
          combineLatest([
            this.launchDarklyService.select$((flags) => flags.adjustments_unit_of_measure),
            this.launchDarklyService.select$((flags) => flags.adjustments_unit_totals),
          ])
        ),
        untilDestroyed(this)
      )
      .subscribe(([uom, showUnitTotals]) => {
        uomHide$.next(!uom);
        const columnApi = this?.gridColumnApi$?.getValue();
        if (columnApi) {
          AgSetColumnsVisible({
            columnApi,
            keys: ['uom'],
            visible: uom,
          });
        }
        this.showUnitTotals$.next(showUnitTotals);
      });
  }

  ngOnInit(): void {
    this.periodCloseComponent.selectedMonth$
      .pipe(
        distinctUntilChanged(),
        switchMap((data) => {
          if (data) {
            const { month, category, vendor } = data;
            return of({
              month: dayjs(month).date(3).format('YYYY-MM-DD'),
              category: category.replace('CATEGORY', 'ACTIVITY'),
              vendor,
            });
          }
          return combineLatest([
            this.vendors$,
            this.periodCloseComponent.quartersObjUpdated$.pipe(startWith(null)),
          ]).pipe(
            switchMap(([vendors]) => {
              if (!this.months.length) {
                const bool = this.updateMonths();
                if (!bool) {
                  return EMPTY;
                }
              }

              // If navigating from Period Close Checklist, we
              // need to filter by vendor id.

              // First, we need to confirm the incoming vendor id is available.
              // If it isn't, we'll default to the original functionality:
              // (selecting the first available vendor in the vendor array).

              const { filterByVendorId } = this.vendorService;
              let selectedVendorId = '';

              if (filterByVendorId) {
                const vendorIds = vendors.map((vendor) => vendor.value);

                if (vendorIds.includes(filterByVendorId)) {
                  selectedVendorId = filterByVendorId;
                }

                this.vendorService.filterByVendorId = '';
              }

              if (!selectedVendorId) {
                selectedVendorId = vendors[0].value || '';
              }

              const persistedMonth = this.periodCloseService.getSelectedQuarterMonth(
                this.router.url
              );
              const persistedQuarterMonth = dayjs(persistedMonth).date(3).format('YYYY-MM-DD');

              const obj = {
                month: persistedQuarterMonth,
                category: this.selected_category.value,
                vendor: selectedVendorId,
              } as { month: string; category: string; vendor: string };

              // find out if we have selected month, category, vendor
              const overrideDefaultIfNecessary = (
                field: keyof typeof obj,
                fc: FormControl,
                arr: string[]
              ) => {
                if (fc.value) {
                  const bool = !!arr.filter((val) => val === fc.value).length;
                  if (bool) {
                    obj[field] = fc.value;
                  }
                }
              };
              overrideDefaultIfNecessary(
                'month',
                this.selected_month,
                this.months.map((m) => m.iso)
              );
              overrideDefaultIfNecessary(
                'category',
                this.selected_category,
                this.categories.map((c) => c.value)
              );
              overrideDefaultIfNecessary(
                'vendor',
                this.selected_vendor,
                vendors.map((v) => v.value || '')
              );
              return of(obj);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(({ month, category, vendor }) => {
        this.selected_month.setValue(month);
        this.selected_category.setValue(category);
        this.selected_vendor.setValue(vendor);
        // This is here to prevent issues like AUXI-3632 and AUXI-3631
        this.periodCloseComponent.selectedMonth$.next(null);
      });

    this.mainQuery
      .select('trialKey')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.editMode$.next(false);
        this.saveCheck$.next(false);
      });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  getGridOptions(totalDate: string, hideEvidence = false) {
    return {
      ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
      suppressPropertyNamesCheck: true,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: true,
        suppressMovable: true,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (!params.editing) {
            switch (params.event.key) {
              // delete button code 46 - backspace button code 8
              case 'Backspace':
              case 'Delete':
                if (this.editMode$.getValue()) {
                  TableService.clearCellRange(params, (_, startIndex, endIndex) => {
                    let columnsToClear: string[] = [];
                    if (params.column.getColId() === 'vendor_estimate_amount') {
                      columnsToClear = [
                        'vendor_estimate_amount',
                        'vendor_estimate_percentage',
                        'vendor_estimate_unit',
                      ];
                    } else if (params.column.getColId() === 'historical_adjustment_amount') {
                      columnsToClear = ['historical_adjustment_amount'];
                    } else if (
                      ['tma_percentage', 'tma_unit', 'tma_amount'].includes(
                        params.column.getColId()
                      )
                    ) {
                      columnsToClear = ['tma_percentage', 'tma_unit', 'tma_amount'];
                    }
                    TableService.clearCells(
                      startIndex,
                      endIndex,
                      columnsToClear,
                      this.gridApi$.getValue() as GridApi
                    );

                    for (let i = startIndex; i <= endIndex; i++) {
                      const row = params.api.getModel().getRow(i);
                      const rowData = row?.data;

                      if (rowData) {
                        switch (params.column.getColId()) {
                          case 'vendor_estimate_amount':
                            this.vendorEstimateChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              rowData.historical_adjustment_amount, // historical adjustment
                              rowData.vendor_estimate_amount, // old value
                              0,
                              rowData.tma_unit,
                              rowData.tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                          case 'historical_adjustment_amount':
                            this.historicalAdjustmentChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              0, // historical adjustment
                              rowData.historical_adjustment_amount, // old value
                              rowData.vendor_estimate_amount,
                              rowData.tma_unit,
                              rowData.tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                          case 'tma_unit':
                            this.unitChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              rowData.historical_adjustment_amount,
                              rowData.vendor_estimate_amount,
                              rowData.tma_unit, // old value
                              0, // number of units
                              rowData.tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                          case 'tma_amount':
                          default:
                            this.totalChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              rowData.historical_adjustment_amount,
                              rowData.vendor_estimate_amount,
                              rowData.tma_amount, // old value
                              rowData.tma_unit,
                              0, // tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                        }
                        this.editedRows.add(rowData.activity_id);
                      }
                    }

                    this.saveCheck$.next(true);
                    this.cdr.detectChanges();
                  });
                }
                return true;
              default:
                return false;
            }
          }
          return false;
        },
      },
      groupIncludeTotalFooter: false,
      suppressAggFuncInHeader: true,
      // this is a deprecated option, but it's still works.
      rememberGroupStateWhenNewData: true,
      groupDefaultExpanded: 1,
      groupDisplayType: TableConstants.AG_SYSTEM.CUSTOM,
      suppressColumnVirtualisation: true,
      columnDefs: [
        ...getActivitiesColumnDefs(
          this.getNonEditableCellClasses,
          this.selectedVendorCurrency,
          this.showUnitTotals$
        ),
        spacerColumn(),
        {
          headerName: 'Previous Month',
          headerClass: ['ag-header-align-center'],
          headerGroupComponent: AgAdjustmentPrevMonthHeaderComponent,
          headerGroupComponentParams: {
            collapsedByDefault: true,
            selected_month: this.selected_month,
            localStorageKey: 'closing_page_adjustment_prev_month',
            expandableCols: ['prev_month_unit'],
          },
          children: getPreviousMonthColumnDefs(
            this.getNonEditableCellClasses,
            this.selectedVendorCurrency,
            this.showUnitTotals$
          ),
        },
        spacerColumn(),
        ...getCurrentForecastColumnDefs(
          this.getNonEditableCellClasses,
          this.selectedVendorCurrency,
          this.showUnitTotals$
        ),
        spacerColumn(),
        {
          headerName: 'Vendor Estimate',
          headerClass: ['ag-header-align-center'],
          headerGroupComponent: AgAdjustmentVendorEstimateHeaderComponent,
          headerGroupComponentParams: {
            collapsedByDefault: true,
            expandableCols: ['vendor_estimate_percentage', 'vendor_estimate_unit'],
            removeVendorEstimateLoading$: this.removeVendorEstimateLoading$,
            doesSelectedMonthHasVendorEstimate$: this.doesSelectedMonthHasVendorEstimate$,
            doesSelectedMonthHasVendorEstimateSupportingDoc$:
              this.doesSelectedMonthHasVendorEstimateSupportingDoc$,
            isSelectedMonthOpenOrFuture$: this.isSelectedMonthOpenOrFuture$,
            onDeleteVendorEstimate: () => {
              this.removeBudgetVendorEstimate();
            },
            onDownloadVendorEstimate: () => {
              this.onDownloadVendorEstimates();
            },
            onUploadVendorEstimate: () => {
              this.onUploadVendorEstimate();
            },
            localStorageKey: 'closing_page_vendor_estimate',
          },
          children: getVendorEstimateColumnDefs(
            this.getNonEditableCellClasses,
            this.getSelectedVendorCurrency,
            this.editMode$,
            this.showUnitTotals$
          ),
        },
        ...(hideEvidence
          ? []
          : getEvidenceBasedColumnDefs(
              this.getNonEditableCellClasses,
              this.editMode$,
              this.getSelectedMonthAndVendor,
              this.selectedVendorCurrency,
              this.showUnitTotals$
            )),
        spacerColumn(),
        ...([
          {
            headerName: 'Total Expense',
            headerClass: ['ag-header-align-center'],
            headerGroupComponent: AgExpandableGroupHeaderComponent,
            headerGroupComponentParams: {
              collapsedByDefault: true,
              localStorageKey: 'closing_page_total_monthly_accrual',
              filterCols: (column: Column) => {
                return ['total_monthly_expense'].indexOf(column.getColId()) === -1;
              },
              expandableCols: [
                'tma_percentage',
                'tma_unit',
                'tma_amount',
                'variance_to_forecast',
                'tma_source',
                'historical_adjustment_amount',
                'total_adjustment',
                'expense_ltd',
                'notes',
                'support',
              ],
            },
            children: [
              {
                headerName: '% Complete',
                field: 'tma_percentage',
                headerClass: ['ag-header-align-center'],
                cellClass: this.getNonEditableCellClasses([
                  'percent',
                  TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
                ]),
                minWidth: 100,
                width: 100,
                hide: true,
                valueGetter: (params) => {
                  // For Category/Group and Discount rows
                  if (!params.data) {
                    const { total_amount = 0, tma_amount = 0 } = params.node?.aggData || {};

                    return (tma_amount / total_amount) * 100;
                  }

                  return params.data.tma_percentage;
                },
                valueFormatter: ({ value }) => Utils.percentageFormatter((value || 0) / 100),
              },
              {
                headerName: 'Units',
                field: 'tma_unit',
                hide: true,
                headerClass: this.getEditableHeaderClasses(['ag-header-align-center']),
                cellClass: this.getEditableCellClasses(['budget-units', 'ag-cell-align-right']),
                width: 85,
                minWidth: 70,
                valueFormatter: ({ value }) => Utils.decimalFormatter(value),
                valueParser: (params) => Number(params.newValue),
                editable: (params: EditableCallbackParams) =>
                  TableService.isEditableCell(this.editMode$)(params),
                aggFunc: this.showUnitTotals$.getValue() ? 'sum' : '',
              },
              {
                headerName: `${totalDate} Expense`,
                field: 'tma_amount',
                headerClass: this.getEditableHeaderClasses(['ag-header-align-center']),
                cellClass: this.getEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                ]),
                hide: true,
                width: 145,
                minWidth: 100,
                cellRenderer: AgAdjustmentDiscountTooltipComponent,
                cellRendererParams: {
                  isInEditMode: this.editMode$,
                },
                valueFormatter: this.currencyFormatter,
                valueParser: (params) => Number(params.newValue),
                editable: (params) => TableService.isEditableCell(this.editMode$)(params),
                aggFunc: 'sum',
              },
              {
                headerName: 'Variance to Forecast',
                field: 'variance_to_forecast',
                width: 145,
                minWidth: 100,
                aggFunc: 'sum',
                hide: true,
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: this.getNonEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                ]),
                valueParser: (params) => Number(params.newValue),
                valueFormatter: this.currencyFormatter,
              },
              {
                headerName: 'Expense Source',
                field: 'tma_source',
                hide: true,
                headerClass: this.getEditableHeaderClasses(['ag-header-align-center']),
                width: 145,
                minWidth: 100,
                headerComponent: AgAdjustmentLinkHeaderComponent,
                cellClass: '!text-left',
                cellEditor: 'agRichSelectCellEditor',
                suppressFillHandle: true,
                cellRenderer: AgAdjustmentDiscountTooltipComponent,
                cellRendererParams: {
                  isInEditMode: this.editMode$,
                },
                cellEditorParams: (params: IRichCellEditorParams) => {
                  const isVendorEstimateAvailable =
                    !!params.data.vendor_estimate ||
                    this.vendorEstimateSelectableCategories.has(params.data.activity_type);
                  const shouldShowEB =
                    params.data.activity_type === ActivityType.ACTIVITY_INVESTIGATOR &&
                    params.data.evidence_based_exist;
                  const shouldShowManual = this.sourceChangedRows.has(params.data.activity_id)
                    ? false
                    : !!params.data.manual_adjustment ||
                      this.unitChangedRows.has(params.data.activity_id) ||
                      this.totalChangedRows.has(params.data.activity_id);
                  const shouldShowForecast = !!params.data.is_forecasted;
                  return {
                    values: [
                      shouldShowForecast ? Expense_Source.Forecast : null,
                      isVendorEstimateAvailable ? Expense_Source['Vendor Estimate'] : null,
                      shouldShowEB ? Expense_Source['Evidence Based'] : null,
                      shouldShowManual ? Expense_Source.Manual : null,
                    ].filter((x) => x),
                    cellRenderer: AgSelectEditorOptionRendererComponent,
                    cellRendererParams: {
                      getDataPendoId: (value: Expense_Source) => {
                        switch (value) {
                          case Expense_Source.Forecast:
                            return 'expense-source-forecast';
                          case Expense_Source['Vendor Estimate']:
                            return 'expense-source-vendor-estimate';
                          case Expense_Source['Evidence Based']:
                            return 'expense-source-evidence-based';
                          case Expense_Source.Manual:
                            return 'expense-source-manual';
                          default:
                            return '';
                        }
                      },
                    },
                  };
                },
                editable: (params) => TableService.isEditableCell(this.editMode$)(params),
                valueFormatter: (params: ValueFormatterParams) => {
                  if (!params.value) {
                    return Utils.zeroHyphen;
                  }
                  return params.value;
                },
              },
              {
                headerName: 'Historical Adjustment',
                field: 'historical_adjustment_amount',
                hide: true,
                headerClass: this.getEditableHeaderClasses(['ag-header-align-center']),
                cellClass: this.getEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                ]),
                width: 145,
                minWidth: 100,
                valueFormatter: this.currencyFormatter,
                valueParser: (params) => Number(params.newValue),
                editable: (params) => TableService.isEditableCell(this.editMode$)(params),
                aggFunc: 'sum',
              },
              {
                headerName: 'Total Adjustment',
                field: 'total_adjustment',
                headerClass: ['ag-header-align-center'],
                hide: true,
                cellClass: this.getNonEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                  'relative',
                ]),
                cellRenderer: AgAdjustmentColumnComponent,
                cellRendererParams: {
                  users: this.users,
                  selectedVendorCurrency: this.selectedVendorCurrency,
                  adjustmentDate: this.selected_month?.value || '',
                },
                width: 145,
                minWidth: 100,
                valueFormatter: this.currencyFormatter,
                valueParser: (params) => Number(params.newValue),
                aggFunc: 'sum',
              },
              {
                headerName: 'Total Monthly Expense',
                field: 'total_monthly_expense',
                headerClass: ['ag-header-align-center'],
                cellClass: this.getNonEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                  'relative',
                ]),
                width: 145,
                minWidth: 100,
                valueFormatter: this.currencyFormatter,
                aggFunc: 'sum',
              },
              {
                headerName: 'Expense LTD',
                field: 'expense_ltd',
                headerClass: ['ag-header-align-center'],
                hide: true,
                cellClass: this.getNonEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                  'relative',
                ]),
                width: 145,
                minWidth: 100,
                valueFormatter: this.currencyFormatter,
                aggFunc: 'sum',
              },
              {
                headerName: 'Notes',
                field: 'notes',
                width: 100,
                hide: true,
                minWidth: 70,
                valueFormatter: (params) => {
                  if (params.data && !params.node?.isRowPinned()) {
                    const { length } = params.data.notes || [];
                    return length ? `${length} Note${length > 1 ? 's' : ''}` : 'Add Note';
                  }
                  return '';
                },
                onCellClicked: (event) => this.openNoteDialog(event),
                headerClass: ['ag-header-align-center'],
                cellClass: ['note-cell'],
              },
              {
                headerName: 'Support',
                field: 'support',
                width: 100,
                minWidth: 70,
                hide: true,
                onCellClicked: (event) => this.openSupportDocumentsDialog(event),
                valueFormatter: (params) => {
                  if (params.data && !params.node?.isRowPinned()) {
                    const documentCount = params.data.total_documents || 0;
                    return documentCount
                      ? `${documentCount} Document${documentCount > 1 ? 's' : ''}`
                      : 'Add Document';
                  }

                  return '';
                },
                headerClass: ['ag-header-align-center'],
                cellClass: ['note-cell', '!block'],
              },
            ],
          },
        ] as ColGroupDef[]),
      ],
      getRowClass: (params: RowClassParams): string => {
        const childrenIndex = Utils.getParentIndex(params.node);

        return childrenIndex % 2
          ? TableConstants.STYLE_CLASSES.IS_EVEN
          : TableConstants.STYLE_CLASSES.IS_ODD;
      },
    } as GridOptions;
  }

  async canDeactivate(): Promise<boolean> {
    if (this.saveCheck$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  customColFilter = (cel: ColDef | ColGroupDef) => {
    //activities headerName is blank, but still needs to be shown
    if (Array.isArray(cel.headerClass) && cel.headerClass.includes(blankActivitiesHeaderClass))
      return true;
    return !!cel.headerName;
  };

  async openSupportDocumentsDialog(event: CellClickedEvent) {
    const resp = await firstValueFrom(
      this.overlayService.open<{
        total_documents?: number;
      }>({
        content: SupportModalComponent,
        data: {
          header: 'Upload Support',
          useDesignSystemStyling: true,
          displayX: true,
          current_month: `${dayjs(this.selected_month.value).format('YYYY-MM')}-01`,
          entity_id: event.data.activity_id,
          vendor_id: this.selected_vendor.value,
        },
      }).afterClosed$
    );

    if (isUndefined(resp.data?.total_documents)) {
      return;
    }

    this.gridData$.next(
      this.gridData$.getValue().map((row) => {
        return row.activity_id === event.data.activity_id
          ? {
              ...row,
              total_documents: resp.data?.total_documents || 0,
            }
          : row;
      })
    );
  }

  async openNoteDialog(event: CellClickedEvent) {
    const { notes, activity_id } = event.data;

    const resp = await firstValueFrom(
      this.overlayService.open<NoteModalResponseType>({
        content: NoteModalComponent,
        data: {
          header: 'Add Note',
          useDesignSystemStyling: true,
          displayX: true,
          notes,
          users: this.users,
        },
      }).afterClosed$
    );

    if (!resp.data?.note) {
      return;
    }

    const { errors, success, data } = await firstValueFrom(
      this.gqlService.createNote$({
        metadata: JSON.stringify({
          month: dayjs(this.selected_month.value).format('MMM-YYYY').toUpperCase(),
        }),
        entity_id: activity_id,
        entity_type: EntityType.ACTIVITY,
        note_type: NoteType.NOTE_TYPE_GENERAL,
        message: Utils.scrubUserInput(resp.data.note),
      })
    );
    if (success && data) {
      this.overlayService.success();
      this.gridData$.next(
        this.gridData$.getValue().map((row) => {
          if (row.activity_id === activity_id) {
            return {
              ...row,
              notes: [
                {
                  create_date: dayjs().toISOString(),
                  note_type: NoteType.NOTE_TYPE_GENERAL,
                  message: resp.data?.note,
                  __typename: 'Note',
                  created_by: this.authQuery.getValue().sub,
                  entity_id: activity_id,
                  entity_type: EntityType.ACTIVITY,
                  id: data.id,
                } as Note,
                ...row.notes,
              ],
            };
          }
          return row;
        })
      );
    } else {
      this.overlayService.error(errors);
    }
  }

  async generatePinnedBottomData(
    newVal: QuarterCloseAdjustmentGridData | null = null,
    _gridData: QuarterCloseAdjustmentGridData[] | null = null
  ) {
    const selectedCategory = this.selected_category.value;
    const columns = [
      'total_amount',
      'actuals_to_date',
      'total_remaining',
      'prev_month_amount',
      'current_forecast_amount',
      'vendor_estimate_amount',
      'evidence_based_amount',
      'tma_amount',
      'variance_to_forecast',
      'historical_adjustment_amount',
      'total_adjustment',
      'total_monthly_expense',
      'expense_ltd',
    ] as CalculableColumns[];

    if (this.showUnitTotals$.getValue()) {
      columns.push('units');
      columns.push('tma_unit');
      columns.push('prev_month_unit');
      columns.push('current_forecast_unit');
      columns.push('vendor_estimate_unit');
      columns.push('evidence_based_unit');
    }
    let gridData: QuarterCloseAdjustmentGridData[];
    if (_gridData) {
      gridData = _gridData;
    } else {
      gridData = await firstValueFrom(this.filteredGridData$.pipe(first()));
    }

    const totals = gridData
      .filter((row) => (selectedCategory ? row.activity_type === selectedCategory : true))
      .reduce(
        (acc, val) => {
          const rowValue = newVal?.activity_id === val.activity_id ? newVal : val;
          for (const col of columns) {
            acc[col] = Utils.roundToNumber(acc[col] || 0) + Utils.roundToNumber(rowValue[col] || 0);
          }
          return acc;
        },
        {} as Record<CalculableColumns, number>
      );
    this.bottomRowData$.next(totals);
    this.updateBottomData();
  }

  parseExpenses() {
    const rows = this.inMonthExpenses$.getValue() || [];
    const doesSelectedMonthHasVendorEstimate = some(rows, (row) => row.vendor_estimate !== null);
    const monthEstimate = this.estimate$.getValue()[this.selected_vendor.value || ''];
    const estimate =
      monthEstimate?.[dayjs(this.selected_month.value).format('MMM-YYYY').toUpperCase()];

    const gridData = rows.map((row) => {
      const total_amount = row.direct_cost?.amount || 0;
      const uom = row.direct_cost?.uom || '';
      const actuals_to_date = row.work_performed?.amount || 0;
      const total_remaining = total_amount - actuals_to_date;
      const isDiscountRow = row.activity_type === ActivityType.ACTIVITY_DISCOUNT;
      // const isInvestigatorRow = row.activity_type === ActivityType.ACTIVITY_INVESTIGATOR;
      const vendor_name =
        this.organizationQuery.getVendor(this.selected_vendor?.value || '')?.[0]?.name || '';

      const getColumnValuesForExpense = (
        exp?: Pick<BudgetExpenseData, 'amount' | 'unit_cost' | 'expense_source' | 'unit_num'> | null
      ) => {
        const amount = exp?.amount || 0;
        const unit_cost = exp?.unit_cost || 0;

        const perc = (amount / total_amount) * 100 || 0;
        const unit = exp?.unit_num || (amount && unit_cost ? amount / unit_cost : 0);

        return {
          amount,
          perc,
          unit: isDiscountRow ? 0 : unit,
          unit_cost: isDiscountRow ? 0 : unit_cost,
          expense_source: exp?.expense_source,
        };
      };

      const current_forecast = getColumnValuesForExpense(row.forecast);

      const vendor_estimate = getColumnValuesForExpense(row.vendor_estimate);

      if (row.vendor_estimate) {
        if (row.activity_type === ActivityType.ACTIVITY_SERVICE) {
          this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_DISCOUNT);
        }
        if (row.activity_type === ActivityType.ACTIVITY_DISCOUNT) {
          this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_SERVICE);
        }
        this.vendorEstimateSelectableCategories.add(row.activity_type);
      }

      const direct_cost = getColumnValuesForExpense(row.direct_cost);

      const tma_source_obj = doesSelectedMonthHasVendorEstimate
        ? vendor_estimate
        : current_forecast;

      let tma_source = doesSelectedMonthHasVendorEstimate
        ? Expense_Source['Vendor Estimate']
        : Expense_Source.Forecast;

      const prev_month = getColumnValuesForExpense(row.prev_month_work_performed);

      const evidence_based_exist = !!estimate;

      let evidence_based_amount = 0;
      if (row.activity_name === 'Patient Visits') {
        evidence_based_amount = estimate?.patient || 0;
      } else if (row.activity_name === 'Overhead') {
        evidence_based_amount = estimate?.overhead || 0;
      } else if (row.activity_name === 'Other') {
        evidence_based_amount = estimate?.other || 0;
      }

      const eba = getColumnValuesForExpense({
        amount: evidence_based_amount,
        unit_cost: tma_source_obj.unit_cost,
      });

      const total_monthly_expense = getColumnValuesForExpense(row.total_monthly_expense);

      /*
        row.manual_adjustment?.amount is just the manual adjustment and does not include historical adjustment,
        so if the monthly expense amount was originally $1000 and the user adjusted it to be $1500,
        manual_adjustment_amount would be $1500. If there was no manual adjustment made, this number will be 0.
      */
      const manual_adjustment_amount = row.manual_adjustment?.amount || 0;

      if (row.manual_adjustment) {
        tma_source = Expense_Source.Manual;
      }

      if (isDiscountRow) {
        current_forecast.perc = 0;
        vendor_estimate.perc = 0;
        const exp = this.discountExpenses$.getValue();
        const { discount_type } = JSON.parse(
          exp[0].manual_adjustment?.expense_detail || '{}'
        ) as DiscountExpenseDetail;
        switch (discount_type) {
          case 'contracted_calculated_discount':
            tma_source = Expense_Source.Forecast;
            break;
          case 'vendor_estimate_discount':
            tma_source = Expense_Source['Vendor Estimate'];
            break;
          case 'custom_calculated_discount':
          case 'custom_discount':
          case 'none':
            tma_source = Expense_Source.Manual;
            break;
        }
      }

      const historical_adjustment_amount = row.historical_adjustment?.amount || 0;

      const accrual = getColumnValuesForExpense(row.accrual);

      // subtracting old amount only if there was a manual adjustment because otherwise total adjustment would just be the historical adjustment
      const total_adjustment = total_monthly_expense.amount - accrual.amount;

      const monthly_expense = row.monthly_expense?.amount || 0;

      const expense_to_date = row.work_performed_to_date?.amount || 0;

      const prev_months_accruals = row.prev_months_accruals?.amount || 0;
      // Backend adds total monthly expense to the expense ltd,
      // so don't we need to add total_monthly_expense when displaying expense ltd for past months
      const expense_ltd_amount =
        expense_to_date +
        (this.isSelectedMonthFuture ? prev_months_accruals : 0) +
        (this.isSelectedMonthOpenOrFuture$.getValue() ? total_monthly_expense.amount : 0);

      const variance_to_forecast = decimalDifference(monthly_expense, current_forecast.amount, 2);

      const data = {
        ...row,
        units: direct_cost.unit,
        uom,
        total_amount,
        actuals_to_date,
        total_remaining,
        vendor_name,
        cost_category_name:
          this.defaultCategories.find(({ value }) => value === row.activity_type)?.label || '',
        prev_month_unit: prev_month.unit,
        prev_month_amount: prev_month.amount,

        current_forecast_percentage: current_forecast.perc,
        current_forecast_unit: current_forecast.unit,
        current_forecast_amount: current_forecast.amount,

        vendor_estimate_percentage: vendor_estimate.perc,
        vendor_estimate_unit: vendor_estimate.unit,
        vendor_estimate_amount: vendor_estimate.amount,

        tma_percentage:
          row.manual_adjustment || row.historical_adjustment
            ? ((manual_adjustment_amount || monthly_expense) / total_amount) * 100
            : total_monthly_expense.perc,
        tma_unit:
          row.manual_adjustment || row.historical_adjustment
            ? (manual_adjustment_amount || monthly_expense) /
              (total_monthly_expense.unit_cost || direct_cost?.unit_cost)
            : total_monthly_expense.unit,

        tma_amount: monthly_expense,
        variance_to_forecast,
        total_adjustment,
        historical_adjustment_amount,
        tma_unit_cost:
          row.forecast || row.vendor_estimate
            ? total_monthly_expense.unit_cost || direct_cost?.unit_cost || 0
            : direct_cost?.unit_cost || 0,
        total_monthly_expense: total_monthly_expense.amount,
        expense_ltd: expense_ltd_amount,
        tma_source: accrual.expense_source
          ? expenseSourceMapping[accrual.expense_source]
          : tma_source,

        evidence_based_amount: eba.amount,
        evidence_based_percentage: eba.perc,
        evidence_based_unit: eba.unit,
        evidence_based_exist,

        total_documents: row.documents.length,
      } as QuarterCloseAdjustmentGridData;

      if (isDiscountRow) {
        data.tma_unit = 0;
      }
      return data;
    });

    this.gridData$.next(gridData);

    setTimeout(() => {
      this.updateCategoryFiltering();
    }, 0);
  }

  removeBudgetVendorEstimate() {
    const vendors = this.budgetQuery.getValue().budget_info.map((info) => {
      return {
        label: info.name || '',
        value: info.vendor_id || '',
      };
    });

    const vendor = vendors.filter((x) => x.value === this.selected_vendor.value)[0];

    if (!vendor) {
      this.overlayService.error('No vendor found');
      return;
    }

    const selected_month = dayjs(this.selected_month.value).date(1).format('YYYY-MM-DD');

    const formatted_selected_month = formatDate(selected_month, 'MMMM-y', 'en-US');
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Vendor Estimate',
      message: `Are you sure you want to remove the ${formatted_selected_month} vendor estimate for ${vendor.label}?`,
      okBtnText: 'Remove',
    });

    resp.afterClosed$.subscribe(async (value) => {
      if (!this.removeVendorEstimateLoading$.getValue() && value.data?.result) {
        this.removeVendorEstimateLoading$.next(true);
        const vendorEstimate = this.vendorEstimates$
          .getValue()
          .find((estimate) => estimate.organization_id === this.selected_vendor.value);
        if (!vendorEstimate) {
          this.removeVendorEstimateLoading$.next(false);
          this.overlayService.error('No vendor forecast found');
          return;
        }

        this.loading$.next(true);

        const success = await this.budgetService.removeVendorEstimate({
          vendor_id: vendor.value,
          target_month: selected_month,
        });
        if (success) {
          this.overlayService.success(`Successfully removed vendor estimate`);
        }
        this.removeVendorEstimateLoading$.next(false);
      }
    });
  }

  async onDownloadVendorEstimates() {
    const { trialKey } = this.mainQuery.getValue();
    const currentMonth = dayjs(this.selected_month.value).format('MMMM-YYYY');
    const currentVendor = this.selected_vendor.value;
    const { success, data } = await this.apiService.getS3ZipFile(
      `trials/${trialKey}/vendors/vendor-estimate/${currentMonth}`,
      currentVendor
    );
    const vendorName = this.organizationQuery.getVendor(this.selected_vendor.value || '')[0].name;
    if (success && data) {
      const fileName = `${this.mainQuery.getSelectedTrial()
        ?.short_name}-${vendorName}-${currentMonth}-vendor-estimate`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
  }

  async onUploadVendorEstimate() {
    const overlay = this.overlayService.open({
      content: AddVendorEstimateUploadComponent,
      data: {
        selectedVendor: this.selected_vendor.value,
        // using date(1) since period close always uses the third day of the month but AddVendorEstimateUploadComponent uses the first day of the month
        selectedMonth: dayjs(this.selected_month.value).date(1).format('MM-DD-YYYY'),
      },
    });
    overlay.afterClosed$.subscribe(() =>
      /*
        give it three seconds to upload before checking for supporting docs.
        If the NEW_TASK appsync notification doesn't reach the FE, this will ensure that we check for supporting docs
      */
      setTimeout(() => {
        this.vendorEstimateSupportingDocUploaded$.next();
      }, 3000)
    );
  }

  updateMonths() {
    if (this.periodCloseComponent.selectedQuarter.value) {
      const months =
        this.periodCloseComponent.quartersObj[this.periodCloseComponent.selectedQuarter.value];

      const currentMonth = dayjs(this.periodCloseComponent.currentMonth);
      this.months = months.map((m: QuarterDate) => {
        const isClosed = m.parsedDate.isBefore(currentMonth);
        const isOpen = m.parsedDate.date(1).isSame(currentMonth);
        const label = `${m.parsedDate.format('MMMM YYYY')}${isClosed ? ' (Closed)' : ''}${
          isOpen ? ' (Open)' : ''
        }`;
        return { ...m, label };
      });
      return true;
    }
    return false;
  }

  shouldShowEvidenceColumns$ = combineLatest([
    this.sitesQuery.selectAll(),
    this.selected_vendor.valueChanges.pipe(startWith(this.selected_vendor.value)),
    this.selected_category.valueChanges.pipe(startWith(this.selected_category.value)),
  ]).pipe(
    map(([sites, vendor, category]) => {
      const ids = sites.map((site) => site.managed_by_id);
      return (
        (category === ActivityType.ACTIVITY_INVESTIGATOR || category === '') && ids.includes(vendor)
      );
    })
  );

  async updateCategoryFiltering() {
    const gridApi = this.gridApi$.getValue();
    if (gridApi) {
      const filterInstance = gridApi.getFilterInstance('activity_type');
      if (filterInstance) {
        filterInstance.setModel(
          this.selected_category.value
            ? {
                values: [this.selected_category.value],
              }
            : null
        );
      }
      gridApi.onFilterChanged();
    }

    await this.generatePinnedBottomData();
    this.updateBottomData();
  }

  updateBottomData() {
    this.gridApi$.getValue()?.setPinnedBottomRowData([
      merge(
        {
          activity_name: 'Total',
        },
        this.bottomRowData$.getValue()
      ),
    ]);
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridColumnApi$.next(columnApi);
    this.gridApi$.next(api);
    if (this.afterOnSave.getValue()) {
      api.forEachNode((node) => {
        node.expanded = true;
      });
      api.onGroupExpandedOrCollapsed();
      this.gridApi$.next(api);
      this.afterOnSave.next(false);
    }
    this.updateCategoryFiltering();
  }

  async onCancel() {
    this.editMode$.next(false);
    this.saveCheck$.next(false);
    this.editedRows.clear();
    this.editedOldGridRow.clear();
    this.unitChangedRows.clear();
    this.totalChangedRows.clear();
    this.sourceChangedRows.clear();
    this.manualsToBeDeleted.clear();
    this.gridApi$.getValue()?.refreshHeader();
    this.historicalAdjustmentChangedRows.clear();
    this.vendorEstimateChangedRows.clear();
    this.vendorEstimateEffectedRows.clear();
    this.vendorEstimateSelectableCategories.clear();
    this.parseExpenses();
  }

  private getBudgetInfo() {
    const budget = this.budgetQuery.getValue();

    return budget.budget_info.filter((info) => info.vendor_id === this.selected_vendor.value)[0];
  }

  async onSave() {
    const api = this.gridApi$.getValue();
    const activity_ids: string[] = [];

    this.afterOnSave.next(true);

    api?.forEachNode((row) => {
      if (row.data && this.editedRows.has(row.data.activity_id)) {
        activity_ids.push(row.data.activity_id);
      }
    });

    const resp = await firstValueFrom(
      this.overlayService.open<AdjustmentModalResponseType, unknown>({
        content: AdjustmentModalComponent,
        data: {
          header: 'Save In-Month Adjustments',
          useDesignSystemStyling: true,
          displayX: true,
          current_month: this.getBudgetInfo()?.current_month,
          entity_ids: activity_ids,
          vendor_id: this.selected_vendor.value,
        },
      }).afterClosed$
    );

    if (!resp.data?.note || !api) {
      return;
    }

    const adjustmentData: BudgetExpenseInput[] = [];
    const budget_info = this.getBudgetInfo();

    const adjustments: Record<string, listInMonthExpensesQuery['manual_adjustment']> = {};
    const vendorEstimateAdjustments: Record<string, listInMonthExpensesQuery['vendor_estimate']> =
      {};
    const historicalAdjustments: Record<string, listInMonthExpensesQuery['historical_adjustment']> =
      {};
    api.forEachNode((row) => {
      if (
        row.data &&
        this.editedRows.has(row.data.activity_id) &&
        !this.manualsToBeDeleted.has(row.data.activity_id)
      ) {
        const oldData: {
          tma_amount: number;
          tma_unit_cost: number;
          historical_adjustment_amount: number;
        } = this.editedOldGridRow.get(row.data.activity_id) || {
          historical_adjustment_amount: 0,
          tma_amount: 0,
          tma_unit_cost: 0,
        };

        const isUnitChanged = this.unitChangedRows.has(row.data.activity_id);
        const isTotalChanged = this.totalChangedRows.has(row.data.activity_id);
        const vendorEstimateChanged = this.vendorEstimateChangedRows.has(row.data.activity_id);
        const isHistoricalAdjustmentChanged = this.historicalAdjustmentChangedRows.has(
          row.data.activity_id
        );
        let adjustment_type = isUnitChanged ? AdjustmentType.ADJUSTMENT_UNIT : null;
        if (isTotalChanged || vendorEstimateChanged) {
          adjustment_type = adjustment_type ? null : AdjustmentType.ADJUSTMENT_AMOUNT;
        }
        if (isUnitChanged || isTotalChanged) {
          adjustments[row.data.activity_id] = {
            id: row.data?.manual_adjustment?.id,
            amount: +row.data.tma_amount,
            adjustment_type,
            previous_amount: oldData?.tma_amount,
            unit_cost: oldData?.tma_unit_cost,
            __typename: 'BudgetExpenseData',
            updated_by: this.authQuery?.getValue()?.sub || '',
          };

          adjustmentData.push({
            budget_version_id: budget_info?.budget_version_id,
            activity_id: row.data.activity_id,
            expense_type_id: ExpenseType.EXPENSE_ACCRUAL_OVERRIDE,
            expense_detail: JSON.stringify({}),
            period_start: budget_info?.current_month,
            period_end: budget_info?.current_month,
            source: 'BASE',
            amount_type: `AMOUNT_${row.data.activity_type?.slice(9)}` as AmountType,
            amount_curr: `CURRENCY_${this.selectedVendorCurrency}`,
            amount: +row.data.tma_amount,
            adjustment_type,
            expense_source: ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT,
          });
        }

        if (isHistoricalAdjustmentChanged) {
          historicalAdjustments[row.data.activity_id] = {
            id: row.data?.historical_adjustment?.id,
            amount: +row.data.historical_adjustment_amount,
            adjustment_type: null,
            previous_amount: oldData?.historical_adjustment_amount,
            unit_cost: null,
            __typename: 'BudgetExpenseData',
            updated_by: this.authQuery?.getValue()?.sub || '',
          };

          adjustmentData.push({
            budget_version_id: budget_info?.budget_version_id,
            activity_id: row.data.activity_id,
            expense_type_id: ExpenseType.EXPENSE_HISTORICAL_ADJUSTMENT,
            expense_detail: JSON.stringify({}),
            period_start: budget_info?.current_month,
            period_end: budget_info?.current_month,
            source: 'BASE',
            amount_type: `AMOUNT_${row.data.activity_type?.slice(9)}` as AmountType,
            amount_curr: `CURRENCY_${this.selectedVendorCurrency}`,
            amount: +row.data.historical_adjustment_amount,
            adjustment_type,
            expense_source: ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT,
          });
        }

        if (vendorEstimateChanged) {
          vendorEstimateAdjustments[row.data.activity_id] = {
            amount: +row.data.vendor_estimate_amount,
            unit_cost: row.data.direct_cost.unit_cost,
            __typename: 'BudgetExpenseData',
          };

          adjustmentData.push({
            budget_version_id: budget_info?.budget_version_id,
            activity_id: row.data.activity_id,
            expense_type_id: ExpenseType.EXPENSE_VENDOR_ESTIMATE,
            expense_detail: JSON.stringify({}),
            period_start: budget_info?.current_month,
            period_end: budget_info?.current_month,
            source: 'BASE',
            amount_type: `AMOUNT_${row.data.activity_type?.slice(9)}` as AmountType,
            amount_curr: `CURRENCY_${this.selectedVendorCurrency}`,
            amount: +row.data.vendor_estimate_amount,
            adjustment_type,
            expense_source: ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE,
          });
        }
      }
    });

    const sourceChangedRows: { id: string; newValue: ExpenseSourceType }[] = [];
    const manualsToBeDeleted: string[] = [];
    api.forEachNode((row) => {
      if (row.data) {
        if (this.sourceChangedRows.has(row.data.activity_id)) {
          sourceChangedRows.push({
            id: row.data.activity_id,
            newValue: expenseSourceReverseMapping[row.data.tma_source as Expense_Source],
          });
        }
        if (this.manualsToBeDeleted.has(row.data.activity_id) && row.data.manual_adjustment?.id) {
          manualsToBeDeleted.push(row.data.manual_adjustment.id);
        }
      }
    });

    this.loading$.next(true);

    const maxBatchSize = 50;
    let { data, success, errors }: GraphqlResponse<batchCreateBudgetExpensesMutation[]> = {
      data: [],
      success: true,
      errors: [],
    };
    let i = 0;
    while (i < adjustmentData.length) {
      const result = await firstValueFrom(
        this.gqlService.batchCreateBudgetExpenses$(adjustmentData.slice(i, i + maxBatchSize))
      );
      success ||= result.success;
      if (result.success && result.data) {
        data = data.concat(result.data);
      } else {
        errors = errors.concat(result.errors);
      }

      i += maxBatchSize;
    }

    if (success && data) {
      const size = 100;
      const sourceChangedRowsBatches = [];
      for (let j = 0; j < sourceChangedRows.length; j += size) {
        sourceChangedRowsBatches.push(sourceChangedRows.slice(j, j + size));
      }

      const manualsToBeDeletedBatches: string[][] = [];
      for (let j = 0; j < manualsToBeDeleted.length; j += size) {
        manualsToBeDeletedBatches.push(manualsToBeDeleted.slice(j, j + size));
      }

      await Promise.allSettled(
        sourceChangedRowsBatches.map((p) => {
          return firstValueFrom(
            this.gqlService.batchOverrideExpenseSources$({
              organization_id: <string>this.selected_vendor.value,
              period: <string>budget_info.current_month,
              overrides: p.map(({ id, newValue }) => {
                return { activity_id: id, source: newValue };
              }),
            })
          );
        })
      );

      await Promise.allSettled(
        manualsToBeDeletedBatches.map((p) => {
          return firstValueFrom(this.gqlService.batchRemoveBudgetExpenses$(p));
        })
      );

      await firstValueFrom(this.gqlService.invalidateBudgetCache$());

      if (this.vendorEstimateChangedRows.size > 0) {
        await this.updateVendorEstimateAccruals(this.vendorEstimateChangedRows);
      }

      const updatedActivityIds = Array.from(
        new Set([
          ...this.unitChangedRows,
          ...this.totalChangedRows,
          ...this.sourceChangedRows,
          ...this.historicalAdjustmentChangedRows,
          ...this.vendorEstimateChangedRows,
        ])
      );

      const noteMaxBatchSize = 100;
      const createNoteInputs: CreateNoteInput[] = updatedActivityIds.map((entity_id) => {
        const expense_note_types: ExpenseNoteType[] = [];
        if (this.sourceChangedRows.has(entity_id)) {
          expense_note_types.push(ExpenseNoteType.EXPENSE_NOTE_EXPENSE_SOURCE_OVERRIDE);
        }
        if (this.historicalAdjustmentChangedRows.has(entity_id)) {
          expense_note_types.push(ExpenseNoteType.EXPENSE_NOTE_HISTORICAL_ADJUSTMENT);
        }
        if (this.totalChangedRows.has(entity_id) || this.unitChangedRows.has(entity_id)) {
          expense_note_types.push(ExpenseNoteType.EXPENSE_NOTE_MANUAL_ADJUSTMENT);
        }
        if (this.vendorEstimateChangedRows.has(entity_id)) {
          expense_note_types.push(ExpenseNoteType.EXPENSE_NOTE_VENDOR_ESTIMATE);
        }

        return {
          entity_id,
          entity_type: EntityType.ACTIVITY,
          note_type: NoteType.NOTE_TYPE_EXPENSE,
          message: Utils.scrubUserInput(resp.data?.note || ''),
          expense_note_types,
          metadata: JSON.stringify({
            month: dayjs(this.selected_month.value).format('MMM-YYYY').toUpperCase(),
          }),
        };
      });

      let {
        cData,
        cSuccess,
        cErrors,
      }: {
        cSuccess: boolean;
        cData: batchCreateNotesMutation[] | null;
        cErrors: string[];
      } = {
        cData: [],
        cSuccess: true,
        cErrors: [],
      };
      let j = 0;

      do {
        const result = await firstValueFrom(
          this.gqlService.batchCreateNotes$(createNoteInputs.slice(j, j + noteMaxBatchSize))
        );
        cSuccess ||= result.success;
        if (result.success && result.data) {
          cData = cData.concat(result.data);
        } else {
          cErrors = cErrors.concat(result.errors);
        }

        j += noteMaxBatchSize;
      } while (j < createNoteInputs.length);

      if (cSuccess && cData) {
        this.overlayService.success();
        this.saveCheck$.next(false);
        this.periodCloseComponent.refresh$.next(null);
        this.onCancel();
      } else {
        this.overlayService.error(cErrors);
      }
    } else {
      this.overlayService.error(errors);
    }

    this.loading$.next(false);
  }

  onEditMode() {
    this.editMode$.next(true);
    // trigger new cell styles
    const gridApi = this.gridApi$.getValue();
    const columnApi = this.gridColumnApi$.getValue();
    if (!gridApi || !columnApi) {
      return;
    }
    gridApi.redrawRows();
    gridApi.refreshHeader();
    AgEditFirstRow({
      gridApi,
      columnApi,
      filterNodes: (nodes) => {
        // ignore discount rows
        let filteredNodes = nodes.filter(
          (n) => !(n.key === 'Discount' || n.parent?.key === 'Discount')
        );

        // ignore investigator rows unless it's specifically selected, or it's the only category
        const categorySet = new Set<string>();
        filteredNodes.forEach((n) => n.key && categorySet.add(n.key));
        if (categorySet.size > 1) {
          filteredNodes = filteredNodes.filter(
            (n) => !(n.key === 'Investigator' || n.parent?.key === 'Investigator')
          );
        }
        return filteredNodes;
      },
    });
  }

  async onCellValueChanged(e: CellValueChangedEvent) {
    if (Number.isFinite(+e.newValue) || e.column.getColId() === 'tma_source') {
      this.updateDynamicFields(
        e.newValue,
        e.data.activity_id,
        e.colDef.field,
        e.data.tma_unit_cost,
        e.data.historical_adjustment_amount,
        e.data.vendor_estimate_amount,
        e.oldValue,
        e.data.tma_unit,
        e.data.tma_amount,
        e.data.total_amount,
        e.data.tma_source,
        e.data
      );
    }
  }

  costCategoryHasForecasts(activity_type: ActivityType, rows: QuarterCloseAdjustmentGridData[]) {
    return rows
      .filter((row) => row.activity_type === activity_type)
      .map((row) => row.is_forecasted)
      .reduce((acc, currentRow) => acc || currentRow, false);
  }

  costCategoryHasEvidenceBased(
    activity_type: ActivityType,
    rows: QuarterCloseAdjustmentGridData[]
  ) {
    return rows
      .filter((row) => row.activity_type === activity_type)
      .map((row) => row.evidence_based_exist)
      .reduce((acc, currentRow) => acc || currentRow, false);
  }

  shouldVendorEstimateOverride({
    activity_id,
    current_tma_source,
    row,
    isAffected = false,
  }: {
    activity_id: string;
    current_tma_source: Expense_Source;
    row: QuarterCloseAdjustmentGridData;
    isAffected?: boolean;
  }) {
    const setting = this.expenseSettings.find((s) => s.activity_id === activity_id);
    if (!setting) {
      return false;
    }

    const currentSettingIndex = setting.sources.findIndex(
      (x) => x === expenseSourceReverseMapping[current_tma_source]
    );
    const vendorEstimateIndex = setting.sources.findIndex(
      (x) => x === ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE
    );

    const isVendorEstimateHasHigherPriority = vendorEstimateIndex <= currentSettingIndex;

    let shouldUseVendorEstimate = isVendorEstimateHasHigherPriority;
    if (!isVendorEstimateHasHigherPriority && !isAffected) {
      for (const s of setting.sources.slice(0, vendorEstimateIndex).reverse()) {
        switch (s) {
          case ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED:
            shouldUseVendorEstimate = !this.costCategoryHasEvidenceBased(
              row.activity_type,
              this.gridData$.getValue()
            );
            break;
          case ExpenseSourceType.EXPENSE_SOURCE_FORECAST:
            shouldUseVendorEstimate = !this.costCategoryHasForecasts(
              row.activity_type,
              this.gridData$.getValue()
            );
            break;
        }
      }
    }

    if (current_tma_source === Expense_Source['Vendor Estimate']) {
      return true;
    }

    return current_tma_source === Expense_Source.Manual ||
      !setting.default ||
      this.sourceChangedRows.has(activity_id)
      ? false
      : shouldUseVendorEstimate;
  }

  updateDynamicFields(
    rawNewValue: number,
    activityId: string,
    colDefField: string | undefined,
    pTmaUnitCost: number,
    pHistoricalAdjustmentAmount: number,
    vendor_estimate_amount: number,
    oldValue: number,
    tmaUnit: number,
    tmaAmount: number,
    totalAmount: number,
    tma_source: Expense_Source,
    rowData: QuarterCloseAdjustmentGridData
  ) {
    if (!colDefField) {
      return;
    }
    const newValue = Utils.roundToNumber(rawNewValue || 0);
    const historicalAdjustmentAmount = pHistoricalAdjustmentAmount || 0;
    const tmaUnitCost = isNaN(pTmaUnitCost) ? 0 : pTmaUnitCost;

    this.editedRows.add(activityId);

    let newValues: {
      historical_adjustment_amount: number;
      tma_amount: number;
      tma_unit: number;
      vendor_estimate_amount?: number;
    };
    let vendorEstimateAffectedRows: string[] = [];

    if (colDefField !== 'tma_source') {
      this.manualsToBeDeleted.delete(activityId);
    }

    switch (colDefField) {
      case 'vendor_estimate_amount':
        {
          this.vendorEstimateChangedRows.add(activityId);
          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount: historicalAdjustmentAmount,
            vendor_estimate_amount: oldValue,
            tma_amount: tmaAmount,
            tma_unit_cost: tmaUnitCost,
          });

          const override = this.shouldVendorEstimateOverride({
            current_tma_source: tma_source,
            activity_id: activityId,
            row: rowData,
          });

          newValues = {
            historical_adjustment_amount: historicalAdjustmentAmount,
            vendor_estimate_amount: newValue,
            tma_amount: override ? newValue : tmaAmount,
            tma_unit: override ? newValue / tmaUnitCost : tmaUnit,
          };

          let rows: string[] = [];
          this.vendorEstimateSelectableCategories.add(rowData.activity_type);
          if (rowData.activity_type === ActivityType.ACTIVITY_SERVICE) {
            this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_DISCOUNT);
            rows = this.getDiscountActivities();
          }
          if (rowData.activity_type === ActivityType.ACTIVITY_DISCOUNT) {
            this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_SERVICE);
            rows = this.getServiceActivities();
          }
          vendorEstimateAffectedRows = [
            ...this.getAllActivitiesOfTheSameType(new Set([activityId])),
            ...rows,
          ];
        }
        break;
      case 'tma_unit':
        {
          const tma_amount = newValue * tmaUnitCost;
          const historical_adjustment_amount = historicalAdjustmentAmount;

          this.unitChangedRows.add(activityId);
          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount,
            tma_amount: tmaUnitCost * oldValue,
            tma_unit_cost: tmaUnitCost,
            vendor_estimate_amount,
          });
          newValues = {
            historical_adjustment_amount,
            vendor_estimate_amount,
            tma_amount,
            tma_unit: newValue,
          };
        }
        break;
      case 'tma_amount':
        {
          const historical_adjustment_amount = historicalAdjustmentAmount;
          const tma_unit = newValue / tmaUnitCost || 0;

          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount,
            tma_amount: oldValue,
            tma_unit_cost: oldValue / tmaUnit,
            vendor_estimate_amount,
          });
          newValues = {
            historical_adjustment_amount,
            vendor_estimate_amount,
            tma_amount: newValue,
            tma_unit,
          };
          this.totalChangedRows.add(activityId);
        }
        break;
      case 'historical_adjustment_amount':
        {
          this.historicalAdjustmentChangedRows.add(activityId);
          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount: oldValue,
            tma_amount: tmaAmount,
            tma_unit_cost: tmaUnitCost,
            vendor_estimate_amount,
          });
          newValues = {
            historical_adjustment_amount: +historicalAdjustmentAmount,
            vendor_estimate_amount,
            tma_amount: tmaAmount,
            tma_unit: tmaUnit,
          };
        }
        break;
      case 'tma_source':
        {
          this.unitChangedRows.delete(activityId);
          this.totalChangedRows.delete(activityId);

          this.sourceChangedRows.add(activityId);
          if ((oldValue as unknown as string) === Expense_Source.Manual) {
            this.manualsToBeDeleted.add(activityId);
          }
          switch (rawNewValue as unknown as Expense_Source) {
            case Expense_Source.Forecast:
              newValues = {
                historical_adjustment_amount: 0,
                tma_amount: rowData.current_forecast_amount,
                tma_unit: rowData.current_forecast_unit,
              };
              break;
            case Expense_Source['Evidence Based']:
              newValues = {
                historical_adjustment_amount: 0,
                tma_amount: rowData.evidence_based_amount,
                tma_unit: rowData.evidence_based_unit,
              };
              break;
            case Expense_Source['Vendor Estimate']:
              newValues = {
                historical_adjustment_amount: 0,
                tma_amount: rowData.vendor_estimate_amount,
                tma_unit: rowData.vendor_estimate_unit,
              };
              break;
            case Expense_Source.Manual:
              newValues = {
                historical_adjustment_amount: rowData.historical_adjustment_amount,
                tma_amount: rowData.tma_amount,
                tma_unit: rowData.tma_unit,
              };
              break;
          }
        }
        break;
      default:
        break;
    }

    const doesSelectedMonthHasVendorEstimate =
      some(this.gridData$.getValue(), (row) => row.vendor_estimate !== null) ||
      colDefField === 'vendor_estimate_amount';

    this.gridData$.next(
      this.gridData$.getValue().map((row) => {
        if (row.activity_id === activityId) {
          const total_monthly_expense =
            newValues.tma_amount + +newValues.historical_adjustment_amount;

          const setting = this.expenseSettings.find((x) => x.activity_id === row.activity_id);
          if (!setting) {
            return row;
          }
          let amount = 0;
          if (setting.default && !this.sourceChangedRows.has(row.activity_id)) {
            let amountSet = false;
            setting.sources.forEach((s) => {
              if (amountSet) {
                return;
              }
              switch (s) {
                case ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED:
                  if (row.evidence_based_exist) {
                    amount = row.evidence_based_amount;
                    amountSet = true;
                  }
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_FORECAST:
                  if (this.costCategoryHasForecasts(row.activity_type, this.gridData$.getValue())) {
                    amount = row.current_forecast_amount;
                    amountSet = true;
                  }
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE:
                  if (doesSelectedMonthHasVendorEstimate) {
                    amount = row.vendor_estimate_amount;
                    amountSet = true;
                  }
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT:
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_NONE:
                  break;
              }
            });
          } else {
            switch (tma_source) {
              case Expense_Source.Forecast:
                amount = row.current_forecast_amount;
                break;
              case Expense_Source['Evidence Based']:
                amount = row.evidence_based_amount;
                break;
              case Expense_Source['Vendor Estimate']:
                amount = row.vendor_estimate_amount;
                break;
              case Expense_Source.Manual:
                amount = row.manual_adjustment?.amount || 0;
                break;
              case Expense_Source.None:
                break;
            }
          }

          const previous_tma_amount = amount;

          const override = this.shouldVendorEstimateOverride({
            current_tma_source: tma_source,
            activity_id: activityId,
            row,
          });

          return {
            ...row,
            ...newValues,
            tma_unit: row.activity_type === ActivityType.ACTIVITY_DISCOUNT ? 0 : newValues.tma_unit,
            tma_percentage: (newValues.tma_amount / totalAmount) * 100 || 0,
            vendor_estimate_unit:
              colDefField === 'vendor_estimate_amount' &&
              row.activity_type !== ActivityType.ACTIVITY_DISCOUNT &&
              row.tma_source !== Expense_Source.Manual
                ? newValues.tma_unit
                : newValues.vendor_estimate_amount || 0 / row.tma_unit_cost,
            vendor_estimate_percentage:
              colDefField === 'vendor_estimate_amount'
                ? ((newValues.vendor_estimate_amount || 0) / totalAmount) * 100 || 0
                : row.vendor_estimate_percentage,
            vendor_estimate_unit_cost: row.direct_cost.unit_cost,
            total_adjustment: total_monthly_expense - (previous_tma_amount || 0),
            variance_to_forecast: newValues.tma_amount - row.current_forecast_amount,
            total_monthly_expense,
            tma_source: ['tma_amount', 'tma_unit'].includes(colDefField)
              ? Expense_Source.Manual
              : colDefField === 'tma_source'
              ? (rawNewValue as unknown as Expense_Source)
              : colDefField === 'vendor_estimate_amount' && override
              ? Expense_Source['Vendor Estimate']
              : tma_source,
            expense_ltd: total_monthly_expense + row.actuals_to_date,
          };
        } else if (vendorEstimateAffectedRows.includes(row.activity_id)) {
          const override = this.shouldVendorEstimateOverride({
            activity_id: row.activity_id,
            current_tma_source: row.tma_source,
            row,
            isAffected: true,
          });

          if (override && row.tma_source !== Expense_Source['Vendor Estimate']) {
            this.vendorEstimateEffectedRows.add(row.activity_id);
            return {
              ...row,
              tma_amount: 0,
              tma_percentage: 0,
              tma_unit: 0,
              total_monthly_expense: 0,
              expense_ltd: row.actuals_to_date,
              variance_to_forecast: -row.current_forecast_amount,
              tma_source: Expense_Source['Vendor Estimate'],
            };
          }
        }
        return row;
      })
    );

    this.saveCheck$.next(true);

    // recalculate aggregated rows with new values
    this.gridApi$.getValue()?.refreshClientSideRowModel('aggregate');
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  onFilterChanged() {
    const filteredRows: QuarterCloseAdjustmentGridData[] = [];
    this.gridApi$.value?.forEachNodeAfterFilter((node) => {
      if (node.data) {
        filteredRows.push(node.data);
      }
    });
    this.generatePinnedBottomData(null, filteredRows);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  getDiscountActivities() {
    const rows = this.inMonthExpenses$.getValue() || [];

    // Get all activity ids for the types that were found
    const filteredRowsByActivityType = filter(
      rows,
      (row) => row.activity_type === ActivityType.ACTIVITY_DISCOUNT
    );

    // Get a unique list of activity ids
    return uniq(_map(filteredRowsByActivityType, 'activity_id'));
  }

  getServiceActivities() {
    const rows = this.inMonthExpenses$.getValue() || [];

    // Get all activity ids for the types that were found
    const filteredRowsByActivityType = filter(
      rows,
      (row) => row.activity_type === ActivityType.ACTIVITY_SERVICE
    );

    // Get a unique list of activity ids
    return uniq(_map(filteredRowsByActivityType, 'activity_id'));
  }

  getActivitiesTypes(activityIds: Set<string>) {
    const rows = this.inMonthExpenses$.getValue() || [];

    const filteredRows = filter(rows, (row) => includes(Array.from(activityIds), row.activity_id));

    return uniq(_map(filteredRows, 'activity_type'));
  }

  getAllActivitiesOfTheSameType(activityIds: Set<string>): string[] {
    const rows = this.inMonthExpenses$.getValue() || [];

    // Get the inMonthExpense rows that correspond to the activity ids passed in
    const filteredRows = filter(rows, (row) => includes(Array.from(activityIds), row.activity_id));

    // Get the uniq list of activity types
    const uniqueActivityTypes = uniq(_map(filteredRows, 'activity_type'));

    // Get all activity ids for the types that were found
    const filteredRowsByActivityType = filter(rows, (row) =>
      includes(uniqueActivityTypes, row.activity_type)
    );

    // Get a unique list of activity ids
    return uniq(_map(filteredRowsByActivityType, 'activity_id'));
  }

  /*
    Since vendor estimate uploads are all or nothing, we need to update every activity under the cost category for the ids provided.
    For example, if just one Services activity is updated, this will call updateAccruals for every Services activity
  */
  async updateVendorEstimateAccruals(activityIdsToSendForUpdate: Set<string>) {
    const doesSelectedMonthHasVendorEstimate = this.doesSelectedMonthHasVendorEstimate();

    let allAffectedActivityIds = this.getAllActivitiesOfTheSameType(activityIdsToSendForUpdate);

    const types = this.getActivitiesTypes(activityIdsToSendForUpdate);

    const isThereServiceInTheTypes = !!types.find(
      (t) => t === ActivityType.ACTIVITY_SERVICE || t === ActivityType.ACTIVITY_DISCOUNT
    );

    const isThereAnyOtherTypes = !!types.find(
      (t) => t !== ActivityType.ACTIVITY_SERVICE && t !== ActivityType.ACTIVITY_DISCOUNT
    );

    const maxBatchSize = 200;
    let { data, success, errors }: GraphqlResponse<updateAccrualsMutation[]> = {
      data: [],
      success: true,
      errors: [],
    };
    let i = 0;

    let shouldSendARequestWithIds = true;
    if (!doesSelectedMonthHasVendorEstimate && isThereServiceInTheTypes) {
      const result = await firstValueFrom(
        this.gqlService.updateAccruals$({
          activity_ids: [],
          activity_types: [ActivityType.ACTIVITY_SERVICE, ActivityType.ACTIVITY_DISCOUNT],
          organization_id: this.selected_vendor.value || '',
          period: this.selected_month.value || '',
        })
      );
      success ||= result.success;
      if (result.success && result.data) {
        data = data.concat(result.data);
      } else {
        errors = errors.concat(result.errors);
      }

      if (isThereAnyOtherTypes) {
        const ids = [...this.getServiceActivities(), ...this.getDiscountActivities()];
        allAffectedActivityIds = allAffectedActivityIds.filter((id) => !ids.includes(id));
      }

      shouldSendARequestWithIds = isThereAnyOtherTypes;
    }

    if (shouldSendARequestWithIds) {
      do {
        const result = await firstValueFrom(
          this.gqlService.updateAccruals$({
            activity_ids: allAffectedActivityIds.slice(i, i + maxBatchSize),
            activity_types: [],
            organization_id: this.selected_vendor.value || '',
            period: this.selected_month.value || '',
          })
        );
        success ||= result.success;
        if (result.success && result.data) {
          data = data.concat(result.data);
        } else {
          errors = errors.concat(result.errors);
        }

        i += maxBatchSize;
      } while (i < allAffectedActivityIds.length);
    }

    if (success) {
      return data;
    } else {
      this.overlayService.error(errors);
      return [];
    }
  }

  async onExport() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const vendor = this.budgetQuery
      .getValue()
      .budget_info.filter((v) => v.vendor_id === this.selected_vendor.value)[0];
    const category = this.categories.filter((c) => c.value === this.selected_category.value)[0];
    const month = dayjs(this.selected_month.value).format('MMMYY');

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.ORGANIZATION,
        entity_id: vendor.vendor_id || '',
        payload: JSON.stringify({
          export_type: ExportType.IN_MONTH_ADJUSTMENTS,
          filename: `${trialName}_${month}_${vendor.name}_${category.label}_Adjustments.xlsx`,
          export_entity_id: vendor.vendor_id || '',
          json_properties: {
            month: dayjs(this.selected_month.value).format('MMM-YYYY'),
            activity_type: category.value || null,
            materiality_threshold: this.selected_threshold.value,
          },
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
  }
}
