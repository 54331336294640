import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FirstDataRenderedEvent } from '@ag-grid-community/core';
import { compactGridOptions } from './compact-grid-options';
import { checkboxGridOptions } from './checkbox-grid-option';
import { gridOptions } from './grid-options';
import { compactGridData, checkboxGridData, gridData } from './grid-data';
import { rowData } from './trial-insights-data';
import { SortOrder } from '@services/gql.service';
import { TrialInsightsClinicalSiteCostChartService } from '../../trial-insights/components/trial-insights-clinical-site-cost/site-cost-chart.service';
import { TrialInsightsClinicalPatientCostQueryService } from '../../trial-insights/components/trial-insights-clinical-patient-cost/patient-cost-query.service';
import { TrialInsightsClinicalPatientCostTableService } from '../../trial-insights/components/trial-insights-clinical-patient-cost/patient-cost-table.service';
import { TrialInsightsPatientCostKey } from '../../trial-insights/models/trial-insights-table.model';
import { createLegend as trialInsightsClinicalCreateLegend } from '../../trial-insights/components/trial-insights-clinical-timeline/trial-insights-clinical-timeline-chart';

@Component({
  selector: 'aux-tables',
  templateUrl: './tables.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TrialInsightsClinicalSiteCostChartService,
    TrialInsightsClinicalPatientCostQueryService,
    TrialInsightsClinicalPatientCostTableService,
  ],
})
export class TablesComponent {
  trialInsightsClinicalCreateLegend = trialInsightsClinicalCreateLegend;

  constructor(
    public trialInsightsSiteChartService: TrialInsightsClinicalSiteCostChartService,
    private trialInsightsPatientTableService: TrialInsightsClinicalPatientCostTableService
  ) {}

  compactGridOptions = compactGridOptions;

  compactGridData = compactGridData;

  checkboxGridOptions = checkboxGridOptions;

  checkboxGridData = checkboxGridData;

  gridOptions = gridOptions;

  gridData = gridData;

  tableOptions = this.trialInsightsPatientTableService.createTable(
    TrialInsightsPatientCostKey.SITE,
    rowData
  );

  isLoadingRemaining = false;

  selectedKey = TrialInsightsPatientCostKey.SITE;

  sortOrder = SortOrder.DESC;

  changeSelectedKey = () => {};

  toggleSortOrder = () => {};

  onDataRendered(e: FirstDataRenderedEvent) {
    const gridAPI = e.api;
    gridAPI?.setPinnedBottomRowData([e.lastRow]);
  }

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((_, i) => i + 1);
  }
}
