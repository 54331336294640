<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(loading$ | async)" class="mb-4">
  <div class="flex items-center mb-4 relative">
    <aux-input class="w-48" placeholder="Search" icon="Search" [(ngModel)]="nameFilterValue" />
    <div class="flex items-center ml-4 mr-auto">
      <div class="text-sm mr-2">Vendor</div>
      <ng-select
        class="w-64 text-sm mt-0"
        [clearable]="false"
        [formControl]="selectedVendor"
        (change)="onOrganizationSelected($event)"
      >
        <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
          <ng-option *ngIf="vendors.length > 1" [value]="''">All</ng-option>
          <ng-option *ngFor="let vendor of vendors" [value]="vendor.id">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-container>
      </ng-select>
    </div>

    <div class="justify-end">
      <!-- -top-6 absolute right-0 -->
      <button type="button" class="text-sm btn btn--blue h-12" (click)="openChangerOrderUpload()">
        <aux-icon name="CirclePlus" />
        <span class="ml-2">Create New</span>
      </button>
    </div>
  </div>

  <div *ngIf="showCOChart$ | async">
    <div
      *ngIf="coChartLoading$ | async"
      class="border-8 m-auto mt-15 mb-10 spinner h-16 w-16"
    ></div>
    <div *ngIf="(coChartLoading$ | async) === false" class="justify-center">
      <h1 class="text-right text-aux-gray-darkest h-11 flex items-center">
        Budget Changes over Time
      </h1>
    </div>
    <canvas
      class="pb-3 px-2 ml-auto"
      width="5"
      height="1"
      style="height: 400px"
      id="changeOrderLineChart"
      [hidden]="(coChartLoading$ | async) === false"
    ></canvas>
  </div>

  <div class="flex items-center justify-end mb-4">
    <aux-export-excel-button
      [gridAPI]="gridAPI"
      [variant]="exportButtonVariant"
      [excelOptions]="excelOptions"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams"
    />
  </div>

  <div class="mb-8 w-full">
    <ag-grid-angular
      class="ag-theme-aux change-order-table tabular-nums w-full"
      domLayout="autoHeight"
      [rowData]="gridData$ | async"
      [gridOptions]="$any(gridOptions$ | async)"
      [quickFilterText]="nameFilterValue"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered()"
      (viewportChanged)="onWindowScroll()"
      (gridSizeChanged)="gridSizeChanged()"
    />
  </div>
</ng-container>
