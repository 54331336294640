<div class="w-[622px]">
  <div class="mb-2 text-xs">Vendor</div>

  <ng-select
    placeholder="Select"
    id="vendor"
    class="w-[290px]"
    [formControl]="vendorControl"
    [appendTo]="'body'"
    [searchable]="true"
    [clearable]="false"
  >
    <ng-option *ngFor="let vendor of vendors$ | async" [value]="vendor.vendor_id">
      <span [title]="vendor.name">{{ vendor.name }}</span>
    </ng-option>
  </ng-select>

  <hr class="my-4" />

  <h4 class="mb-3 text-sm font-bold text-aux-black">Accrued Liabilities:</h4>

  <aux-entity-form-group-row
    [formGroup]="form.controls.accruedLiabilities"
    [disabled]="!vendorControl.value"
  />

  <hr class="my-4" />

  <h4 class="mb-3 text-sm font-bold text-aux-black">Prepaid Expenses:</h4>

  <aux-entity-form-group-row
    [formGroup]="form.controls.prepaidExpenses"
    [disabled]="!vendorControl.value"
  />

  <hr class="my-4" />

  <h4 class="mb-3 text-sm font-bold text-aux-black">AP Manual (Clearing):</h4>

  <aux-entity-form-group-row
    [formGroup]="form.controls.accountsPayable"
    [disabled]="!vendorControl.value"
  />

  <hr class="my-4" />

  <div class="flex justify-between">
    <button class="focus:outline-none" (click)="ref.close()" type="button">Cancel</button>

    <button class="ml-auto mr-2 btn--primary" (click)="save()">Save</button>
  </div>
</div>
