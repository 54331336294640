import { ChangeDetectionStrategy, Component, HostListener, OnDestroy } from '@angular/core';
import { ExportType, Utils } from '@services/utils';
import { differenceWith, isEqual, isBoolean } from 'lodash-es';
import {
  GridApi,
  GridOptions,
  ICellRendererParams,
  RowGroupOpenedEvent,
  RowNode,
  GridReadyEvent,
  IRowNode,
} from '@ag-grid-community/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MilestoneCategoryService } from '@models/milestone-category/milestone-category.service';
import { MilestoneQuery } from '@models/milestone-category/milestone/milestone.query';
import { MilestoneCategoryQuery } from '@models/milestone-category/milestone-category.query';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { StickyElementService } from '@services/sticky-element.service';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { AuthQuery } from '@models/auth/auth.query';
import { AuthService } from '@models/auth/auth.service';
import {
  CreateTimelineMilestoneInput,
  EntityType,
  EventType,
  GqlService,
  listTimelineMilestonesQuery,
  PermissionType,
  WorkflowStep,
} from '@services/gql.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import {
  AgCellWrapperComponent,
  getWrapperCellOptions,
} from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import {
  TimelineService,
  UpdateDependencyInput,
  UpdateTimelineMilestone,
} from './state/timeline.service';
import { TimelineQuery } from './state/timeline.query';
import { TimelineDialogComponent } from './timeline-dialog/timeline-dialog.component';
import { TimelineDialogFormValue } from './timeline-dialog/timeline-dialog.model';
import { WorkflowQuery } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { TableConstants } from '@constants/table.constants';
import { MessagesConstants } from '@constants/messages.constants';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import {
  RemoveDialogComponent,
  RemoveDialogInput,
} from '@components/remove-dialog/remove-dialog.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

export interface TimelineGridData {
  milestone_category_id: string;
  milestone_category_name: string;
  milestone_name: string;
  milestone_id: string;
  organizations_with_forecasts: { id: string; name: string }[];
  track_from_milestone_name: string;
  track_from_milestone_id: string | null;
  contract_start_date: string;
  contract_end_date: string;
  contract_month_difference: number | null;
  revised_start_date: string | null;
  revised_end_date: string | null;
  actual_start_date: string | null;
  actual_end_date: string | null;
  description: string | null;
  id: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-timeline',
  templateUrl: './timeline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnDestroy {
  readonly messagesConstants = MessagesConstants;

  gridData$ = new BehaviorSubject<TimelineGridData[]>([]);

  previousGridData: TimelineGridData[] = [];

  expandedState: Record<string, boolean> = {};

  userHasModifyPermissions$ = new BehaviorSubject<boolean>(false);

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_TIMELINE;

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isTimeLineFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(this.workflowName);

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  hasChanges$ = new BehaviorSubject(false);

  saving$ = new BehaviorSubject(false);

  valuesBeforeRemove: TimelineGridData[] = [];

  createInputs: Omit<CreateTimelineMilestoneInput, 'timeline_id'>[] = [];

  updateInputs: UpdateTimelineMilestone[] = [];

  updateDependencyInputs: UpdateDependencyInput[] = [];

  deleteInputs: string[] = [];

  disabledStateTooltipText$ = new BehaviorSubject('');

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  gridAPI!: GridApi;

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      sortable: false,
      minWidth: TableConstants.ACTIONS_WIDTH,
      cellRenderer: AgCellWrapperComponent,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    groupDisplayType: TableConstants.AG_SYSTEM.CUSTOM,
    suppressAutoSize: true,
    initialGroupOrderComparator: (params) => {
      const a = this.milestoneCategoryQuery.getEntity(params.nodeA.key)?.grouping_order || 0;
      const b = this.milestoneCategoryQuery.getEntity(params.nodeB.key)?.grouping_order || 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    columnDefs: [
      {
        headerName: '',
        field: 'actions',
        getQuickFilterText: () => '',
        cellRendererSelector: (params: ICellRendererParams) => {
          return params.node.group || !params.node.parent ? '' : { component: AgActionsComponent };
        },
        cellRendererParams: {
          processing$: this.iCloseMonthsProcessing$,
          disabled$: this.isTimeLineFinalized$,
          editClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
            this.onUpdateLine(rowNode);
          },
          hideDeleteButton: !this.authQuery.getValue().is_admin,
          deleteClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
            this.onRemoveLine(rowNode);
          },
          tooltipText$: this.disabledStateTooltipText$,
        },
        editable: false,
        width: TableConstants.ACTIONS_WIDTH,
        cellClass: TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Category',
        field: 'milestone_category_name',
        getQuickFilterText: () => '',
        rowGroup: true,
        hide: true,
      },
      {
        headerName: 'Name',
        field: 'milestone_name',
        minWidth: 250,
        tooltipField: 'milestone_name',
        cellClass: 'text-left',
        showRowGroup: true,
        cellRenderer: TableConstants.AG_SYSTEM.AG_GROUP_CELL_RENDERER,
        ...getWrapperCellOptions(),
        cellRendererParams: {
          suppressCount: true,
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        getQuickFilterText: () => '',
        minWidth: 250,
        tooltipField: 'description',
        cellClass: 'text-left',
      },
      {
        headerName: 'Track From',
        field: 'track_from_milestone_name',
        getQuickFilterText: () => '',
        minWidth: 200,
        tooltipField: 'track_from_milestone_name',
        cellClass: 'text-left',
      },
      {
        headerName: 'Months',
        field: 'contract_month_difference',
        getQuickFilterText: () => '',
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
        minWidth: 100,
      },
      {
        headerName: 'Start Date',
        getQuickFilterText: () => '',
        minWidth: 150,
        field: 'startDate',
        valueFormatter: (val) => {
          if (!val.data) {
            return '';
          }
          const { contract_start_date, revised_start_date, actual_start_date } = val.data;

          if (actual_start_date) return `${actual_start_date}`;
          if (revised_start_date) return `${revised_start_date}`;
          return `${contract_start_date}`;
        },
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      },
      {
        headerName: 'End Date',
        getQuickFilterText: () => '',
        minWidth: 150,
        field: 'endDate',
        valueFormatter: (val) => {
          if (!val.data) {
            return '';
          }
          const { contract_end_date, revised_end_date, actual_end_date } = val.data;

          if (actual_end_date) return `${actual_end_date}`;
          if (revised_end_date) return `${revised_end_date}`;
          return `${contract_end_date}`;
        },
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      },
    ],
    getRowClass: Utils.oddEvenRowClass,
  } as GridOptions;

  nameFilterValue = '';

  isAdminUser = false;

  userHasLockTrialTimelinePermission = false;

  constructor(
    private timelineService: TimelineService,
    public timelineQuery: TimelineQuery,
    private milestoneCategoryService: MilestoneCategoryService,
    private milestoneQuery: MilestoneQuery,
    private milestoneCategoryQuery: MilestoneCategoryQuery,
    private overlayService: OverlayService,
    private authQuery: AuthQuery,
    private workflowQuery: WorkflowQuery,
    private authService: AuthService,
    private mainQuery: MainQuery,
    private stickyElementService: StickyElementService,
    private launchDarklyService: LaunchDarklyService,
    private gqlService: GqlService
  ) {
    this.setUserPermissions();
    this.initTimelineListValue();

    this.timelineQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(({ items }) => {
        this.gridData$.next(this.convertToTimelineGridData(items));
      });

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_TRIAL_TIMELINE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasModifyPermissions$.next(x);
      });

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      if (this.valuesBeforeRemove.length) {
        setTimeout(() => {
          this.gridAPI?.setRowData(this.valuesBeforeRemove);
          this.valuesBeforeRemove = [];
        }, 0);
      }

      if (!this.hasChanges$.getValue()) {
        this.gridAPI?.setRowData(gridData);
      }

      this.checkChanges();
    });

    this.isTimeLineFinalized$.pipe(untilDestroyed(this)).subscribe((val: boolean): void => {
      this.disabledStateTooltipText$.next(
        val ? MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : ''
      );
    });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  addTimelineMilestone = async () => {
    const ref = await firstValueFrom(
      this.overlayService.open<
        Omit<CreateTimelineMilestoneInput, 'timeline_id'>,
        { mode: 'edit' | 'add'; formValue?: TimelineDialogFormValue; forbiddenNames?: string[] }
      >({
        content: TimelineDialogComponent,
        data: {
          mode: 'add',
          forbiddenNames: this.gridData$.value.map((x) => x.milestone_name),
        },
      }).afterClosed$
    );

    if (ref.data) {
      this.createInputs.push(ref.data);
    }
  };

  async onRemoveLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }

    const { id, milestone_name, milestone_id, organizations_with_forecasts } =
      rowNode.data as TimelineGridData;
    const { items } = this.timelineQuery.getValue();
    const tracking_milestones = items.filter((x) => x.track_from_milestone?.id === milestone_id);
    if (organizations_with_forecasts.length > 0) {
      const cannotDeleteMessage = `${milestone_name} cannot be deleted because it is used in Forecast Methodology.<br>Please first remove the dependencies below and then ${milestone_name} can be successfully deleted.<br>`;
      const routeInputs = [
        {
          componentName: 'Forecast Methodology:',
          name: organizations_with_forecasts.map(({ name }) => name),
          link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
        },
      ];

      const removeDialogInput: RemoveDialogInput = {
        header: 'Remove Milestone',
        cannotDeleteMessage,
        routeInputs,
      };

      const resp = this.overlayService.open({
        content: RemoveDialogComponent,
        data: removeDialogInput,
      });

      await firstValueFrom(resp.afterClosed$);
    } else if (tracking_milestones?.length > 0) {
      let milestoneNames = '';
      for (const tracking_milestone of tracking_milestones) {
        milestoneNames += `- ${tracking_milestone.milestone.name}<br>`;
      }
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Milestone',
        message: `Are you sure that you want to remove ${milestone_name}? The following milestones will no longer track this milestone:<br>${milestoneNames}`,
        okBtnText: 'Remove',
      });
      const event = await firstValueFrom(resp.afterClosed$);
      if (event.data?.result) {
        for (const tracking_milestone of tracking_milestones) {
          const milestone = this.milestoneQuery.getEntity(tracking_milestone.milestone.id);
          const input: UpdateTimelineMilestone = {
            id: tracking_milestone.id,
            name: tracking_milestone.milestone.name,
            milestone_category_id: tracking_milestone.milestone.milestone_category_id,
            milestone_id: tracking_milestone.milestone.id,
            description: milestone?.description || null,
            track_from_milestone_id: null,
            actual_end_date: tracking_milestone.actual_end_date,
            actual_start_date: tracking_milestone.actual_start_date,
            contract_end_date: tracking_milestone.contract_end_date,
            contract_start_date: tracking_milestone.contract_start_date,
            revised_end_date: tracking_milestone.revised_end_date,
            revised_start_date: tracking_milestone.revised_start_date,
          };
          // eslint-disable-next-line no-await-in-loop
          await this.timelineService.updateTimelineMilestone(input);
          this.updateInputs.push(input);
        }
        this.gridAPI.applyTransaction({ remove: [rowNode] });
        await this.timelineService.deleteTimelineMilestone(id);
        this.deleteInputs.push(id);
        this.hasChanges$.next(true);
      }
    } else {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Milestone',
        message: `Are you sure that you want to remove ${milestone_name}?`,
        okBtnText: 'Remove',
      });
      const event = await firstValueFrom(resp.afterClosed$);
      if (event.data?.result) {
        this.gridAPI.applyTransaction({ remove: [rowNode] });
        await this.timelineService.deleteTimelineMilestone(id);
        this.deleteInputs.push(id);
        this.hasChanges$.next(true);
      }
    }
  }

  onUpdateLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }

    const {
      actual_end_date,
      actual_start_date,
      contract_end_date,
      contract_start_date,
      milestone_category_id,
      track_from_milestone_id,
      revised_end_date,
      revised_start_date,
      milestone_name,
      description,
      id,
      milestone_id,
    } = rowNode.data as TimelineGridData;

    const ref = this.overlayService.open<
      { update: UpdateTimelineMilestone; updateDependency: UpdateDependencyInput[] },
      {
        mode: 'edit' | 'add';
        forbiddenNames: string[];
        formValue?: TimelineDialogFormValue;
        timeline_milestone_id?: string;
        milestone_id?: string;
      }
    >({
      content: TimelineDialogComponent,
      data: {
        mode: 'edit',
        forbiddenNames: this.gridData$.value
          .filter((x) => x.milestone_name !== milestone_name)
          .map((x) => x.milestone_name),
        formValue: {
          id,
          description,
          name: milestone_name,
          milestone_id,
          milestone_category_id,
          revised_end_date,
          revised_start_date,
          contract_start_date,
          contract_end_date,
          actual_end_date,
          actual_start_date,
          track_from_milestone_id,
        },
        milestone_id,
        timeline_milestone_id: id,
      },
    });

    ref.afterClosed$.subscribe(async (resp) => {
      if (resp.data) {
        this.updateInputs.push(resp.data.update);
        this.updateDependencyInputs.push(...resp.data.updateDependency);
      }
    });
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridOptions.columnApi?.setColumnVisible(
      'actions',
      this.userHasModifyPermissions$.getValue()
    );
    api.sizeColumnsToFit();
  }

  onDataRendered({ api }: { api: GridApi }) {
    api.forEachNode((node) => {
      if (node.key && !isBoolean(this.expandedState[node.key])) {
        node.setExpanded(true);
      }
    });

    api.sizeColumnsToFit();
  }

  onGroupOpened({ expanded, node }: RowGroupOpenedEvent) {
    if (node.key) {
      this.expandedState[node.key] = expanded;
    }
  }

  onRowDataChanged() {
    const gridData = this.gridData$.value;
    const changedMilestoneCategoryId = differenceWith(gridData, this.previousGridData, isEqual)[0]
      ?.milestone_category_id;

    this.gridOptions.api?.forEachNode((rowNode: IRowNode) => {
      if (rowNode.key) {
        const currentExpandedState = isBoolean(this.expandedState[rowNode.key])
          ? this.expandedState[rowNode.key]
          : true;
        const expanded = rowNode.key === changedMilestoneCategoryId ? true : currentExpandedState;

        rowNode.setExpanded(expanded);
      }
    });

    this.sizeColumnsToFit();
    this.checkChanges();
    this.previousGridData = gridData;
  }

  sizeColumnsToFit(): void {
    this.gridOptions.api?.sizeColumnsToFit();
  }

  checkChanges() {
    this.hasChanges$.next(
      this.createInputs.length > 0 ||
        this.updateInputs.length > 0 ||
        this.updateDependencyInputs.length > 0 ||
        this.deleteInputs.length > 0
    );
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  onSaveAll = async () => {
    this.saving$.next(true);

    if (this.deleteInputs.length > 0) {
      this.deleteInputs.forEach((x) => {
        this.timelineService.deleteTimelineMilestone(x);
      });
    }

    const resp = await this.timelineService.saveChanges(
      this.createInputs,
      this.updateInputs,
      this.updateDependencyInputs,
      this.deleteInputs,
      this.gridData$.getValue()
    );

    if (resp.success) {
      this.overlayService.success(`Successfully Saved`);
      this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();
    } else if (resp.errors.length > 0) {
      this.overlayService.error(resp.errors);
      // allow time for the user to see the error message before refreshing
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else {
      this.overlayService.error(`An error has occurred`);
    }

    this.createInputs = [];
    this.updateInputs = [];
    this.updateDependencyInputs = [];
    this.deleteInputs = [];
    this.saving$.next(false);
    this.hasChanges$.next(false);
  };

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }

  reset(): void {
    this.createInputs = [];
    this.updateInputs = [];
    this.updateDependencyInputs = [];
    this.deleteInputs = [];
    this.hasChanges$.next(false);
    this.initTimelineListValue();
  }

  private initTimelineListValue(): void {
    combineLatest([this.timelineService.getTimelineItems(), this.milestoneCategoryService.get()])
      .pipe(untilDestroyed(this))
      .subscribe(([timelineResponse]) => {
        this.gridData$.next(this.convertToTimelineGridData(timelineResponse?.data || []));
      });
  }

  private convertToTimelineGridData(items: listTimelineMilestonesQuery[]): TimelineGridData[] {
    const gridData: TimelineGridData[] = [];

    items.forEach((item) => {
      const track_milestone = this.milestoneQuery.getEntity(item.track_from_milestone?.id);
      const milestone = this.milestoneQuery.getEntity(item.milestone.id);
      const milestone_category_name = this.milestoneCategoryQuery.getEntity(
        item.milestone.milestone_category_id
      );
      const data: TimelineGridData = {
        id: item.id,
        milestone_category_id: item.milestone.milestone_category_id,
        milestone_category_name: milestone_category_name?.name || '',
        milestone_name: milestone?.name || item.milestone.name || '',
        milestone_id: item.milestone.id,
        organizations_with_forecasts: item.milestone.organizations_with_forecasts,
        track_from_milestone_name: track_milestone?.name || '',
        track_from_milestone_id: item.track_from_milestone?.id || null,
        contract_start_date: item.contract_start_date,
        contract_end_date: item.contract_end_date,
        contract_month_difference: item.contract_month_difference || null,
        revised_start_date: item.revised_start_date || null,
        revised_end_date: item.revised_end_date || null,
        actual_start_date: item.actual_start_date || null,
        actual_end_date: item.actual_end_date || null,
        description: milestone?.description || null,
      };
      gridData.push(data);
    });

    return gridData;
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  async onExportTimeline() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.TIMELINE,
          filename: `${trialName}_auxilius-timeline__${dateStr}`,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_TRIAL_TIMELINE],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasLockTrialTimelinePermission]) => {
        this.userHasLockTrialTimelinePermission = userHasLockTrialTimelinePermission;
      });
  }
}
