<ng-container *ngIf="$any(budgetQuery.selectLoading() | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(budgetQuery.selectLoading() | async)">
  <div *ngIf="showAnalyticsSection$ | async" class="mt-8 grid grid-cols-3 gap-7.5">
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-1">
        <div class="font-bold text-aux-black">Actuals</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            % of Total<br />
            Current $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
        <div *ngIf="(wpLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('work_performed') | async as wp">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? wp[selectedVendor.value]
                      ? wp[selectedVendor.value].wp_percentage
                      : zeroHyphen
                    : wp.ALL
                    ? wp.ALL.wp_percentage
                    : zeroHyphen
                }}
                <br />
                {{
                  selectedVendor && selectedVendor.value
                    ? wp[selectedVendor.value]
                      ? wp[selectedVendor.value].wp_total
                      : zeroHyphen
                    : wp.ALL
                    ? wp.ALL.wp_total
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-14">
        <div class="font-bold text-aux-black">Invoiced</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            % of Total<br />
            Current $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4">
        <div *ngIf="(invoicesTotalLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('invoices') | async as invoices">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? invoices[selectedVendor.value]
                      ? invoices[selectedVendor.value].invoice_percentage
                      : zeroHyphen
                    : invoices.ALL
                    ? invoices.ALL.invoice_percentage
                    : zeroHyphen
                }}
                <br />
                {{
                  selectedVendor && selectedVendor.value
                    ? invoices[selectedVendor.value]
                      ? invoices[selectedVendor.value].invoice_total
                      : zeroHyphen
                    : invoices.ALL
                    ? invoices.ALL.invoice_total
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-1">
        <div class="font-bold text-aux-black">Pending Change</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            Change Log + Unsigned<br />
            Change Orders $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4">
        <div *ngIf="(pendingChangesLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('pendingChanges') | async as pendingChanges">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? pendingChanges[selectedVendor.value]
                      ? pendingChanges[selectedVendor.value]
                      : zeroHyphen
                    : pendingChanges.ALL
                    ? pendingChanges.ALL
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div #budgetFilters>
    <div class="flex justify-between items-center space-x-4">
      <div class="flex space-x-4 -mt-3.5 justify-between items-center">
        <div class="flex flex-row justify-between items-center">
          <div class="text-sm mr-1">Vendor</div>
          <ng-select
            class="w-64"
            [clearable]="false"
            [formControl]="selectedVendor"
            (change)="onVendorSelected($event)"
          >
            <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
              <ng-option *ngIf="vendors.length > 1" [value]="''">All</ng-option>
              <ng-option *ngFor="let vendor of vendors" [value]="vendor.id">
                <span [title]="vendor.name">{{ vendor.name }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
        <div
          class="flex"
          [auxTooltip]="
            !isVendorCurrency ? 'Comparing to a snapshot is only supported in Vendor Currency' : ''
          "
        >
          <aux-compare-dropdown
            class="mr-1.5"
            [initialValue]="compareToValue"
            [disabled]="!isVendorCurrency"
            [refreshTable]="refreshTable"
            (valueChange)="compareDropdownChange($event)"
          />
        </div>

        <div class="flex flex-row justify-between items-center">
          <div class="text-sm mr-1">Period</div>
          <ng-select
            class="w-32"
            bindValue="value"
            [formControl]="selectedPeriodType"
            [clearable]="false"
            [multiple]="false"
            [searchable]="false"
            [items]="periodTypes"
          />
        </div>
      </div>
      <div class="flex space-x-4 -mt-3.5 mb-3 justify-between items-center">
        <div>
          <button
            type="button"
            class="text-sm btn-nr btn--white justify-end btn--secondary"
            (click)="onToggleBudgetGraph()"
          >
            <aux-icon name="ChartBar" *ngIf="!showBudgetGraph" />
            <aux-icon name="ChartBarOff" *ngIf="showBudgetGraph" />
            <span class="ml-2"> {{ showBudgetGraph ? 'Hide' : 'Show' }} Graph</span>
          </button>
        </div>

        <div>
          <aux-menu #budget_menu>
            <button
              type="button"
              role="menuitem"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
              (click)="onBudgetUploadClick(); budget_menu.close()"
            >
              <aux-icon name="Upload" />
              <span class="ml-2">Templates</span>
            </button>
          </aux-menu>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="budgetCanvas$ | async as budgetCanvas">
    <ng-container *ngIf="showBudgetGraph">
      <div class="grid my-8" style="height: 250px">
        <canvas
          baseChart
          [labels]="budgetCanvas.data.labels"
          [datasets]="$any(canvasDatasets$ | async)"
          [options]="budgetCanvas.options"
          [type]="budgetCanvas.type"
        >
        </canvas>
        <div *ngIf="isSnapShotSelected$.getValue().selected" class="flex justify-center">
          <div class="flex items-center mr-4" (click)="chartLegendClick(true)">
            <div
              class="w-3 h-3 rounded-full bg-aux-blue-dark mr-1"
              [ngClass]="{
                'opacity-50': !this.isSnapShotSelected$.getValue().currentLegend,
                'opacity-100': this.isSnapShotSelected$.getValue().currentLegend
              }"
            ></div>
            <span
              [ngClass]="{
                'line-through': !this.isSnapShotSelected$.getValue().currentLegend,
                'no-underline': this.isSnapShotSelected$.getValue().currentLegend
              }"
              >Current (LRE) Budget</span
            >
          </div>
          <div class="flex items-center" (click)="chartLegendClick()">
            <div
              class="w-3 h-3 rounded-full bg-aux-green-dark mr-1"
              [ngClass]="{
                'opacity-50': !this.isSnapShotSelected$.getValue().snapShotLegend,
                'opacity-100': this.isSnapShotSelected$.getValue().snapShotLegend
              }"
            ></div>
            <span
              [ngClass]="{
                'line-through': !this.isSnapShotSelected$.getValue().snapShotLegend,
                'no-underline': this.isSnapShotSelected$.getValue().snapShotLegend
              }"
            >
              {{ this.compareToValue }}</span
            >
          </div>
        </div>
      </div>

      <hr class="mb-8" />
    </ng-container>
  </ng-container>

  <div style="width: 100%; overflow: auto">
    <div class="flex mb-8 justify-between items-center" style="margin-top: 1px">
      <div class="flex">
        <div
          class="text-sm flex justify-between items-center px-3 h-11 w-60 border border-aux-gray-dark font-medium rounded-md shadow-sm"
          (click)="openCustomList()"
        >
          <ng-container
            *ngIf="selectedCustom$ | async; else placeholderTemplate"
            class="flex justify-between"
          >
            <span class="text-sm">{{ selectedCustom$ | async }}</span>
          </ng-container>

          <aux-icon name="ChevronDown" class="text-aux-gray-darkest" />
        </div>
        <div>
          <div #customTrigger="cdkOverlayOrigin" class="mt-11" cdkOverlayOrigin></div>
        </div>

        <ng-template
          cdkConnectedOverlay
          class="border-solid border-aux-gray-dark-100"
          [cdkConnectedOverlayOrigin]="customTrigger"
          [cdkConnectedOverlayOpen]="isCustomOpen"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'ttt'"
          [cdkConnectedOverlayPositions]="positions"
          (backdropClick)="closeCustomList()"
          (detach)="closeCustomList()"
        >
          <ul
            tabindex="-1"
            class="bg-white border-solid border-aux-gray-light rounded-md py-1 text-base ring-1 overflow-auto focus:outline-none sm:text-sm mt-1 w-60 max-h-100"
          >
            <ng-container
              *ngFor="let item of customValues$ | async; index as index; let first = first"
              class="grid"
            >
              <li
                class="text-gray-900 cursor-default select-none relative flex flex-col"
                (click)="customChanges(item)"
              >
                <div
                  *ngIf="item.showLine && !first"
                  class="border-b h-1 border-aux-gray-lightest mb-2 mt-1 mr-2 ml-2"
                ></div>

                <div
                  class="text-gray-900 cursor-default select-none relative py-2 pl-2 flex justify-between"
                  [ngClass]="{
                    'bg-aux-gray-light': index === (highlightedCustom | async)
                  }"
                  (mouseenter)="highlightCustom(index)"
                  (mouseleave)="highlightedCustom.next(null)"
                >
                  {{ item.name }}
                  <div class="flex mr-2">
                    <aux-icon
                      name="Pencil"
                      [size]="16"
                      *ngIf="item.is_custom"
                      class="w-3 text-aux-blue-dark mr-1"
                      (click)="editCustom(item)"
                    />
                    <aux-icon
                      name="Trash"
                      [size]="16"
                      (click)="removeCustom(item)"
                      *ngIf="item.is_custom"
                      class="w-3 text-aux-red-hover"
                    />
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-template>
        <button class="btn--primary pl-3 ml-2 mr-2" (click)="onColumnChooser()">
          <aux-icon name="Eye" class="mr-2" />
          Column Chooser
        </button>
        <button
          class="btn--secondary pl-3"
          [disabled]="!areUnsavedChanges"
          (click)="saveCustomUserView()"
        >
          <aux-icon name="DeviceFloppy" class="mr-2" />
          Save View
        </button>
        <div class="flex items-center ml-4">
          <div class="text-sm">
            <span>Years</span>
          </div>
          <div
            class="text-sm flex justify-between items-center px-3 h-10 w-[120px] border border-aux-gray-dark font-medium rounded-md shadow-sm ml-1"
            (click)="openList()"
          >
            <ng-container *ngIf="selectedYear; else placeholderTemplate">
              <span class="text-sm">{{ selectedYear }}</span>
            </ng-container>

            <ng-template #placeholderTemplate>
              <span class="text-gray-500"></span>
            </ng-template>

            <aux-icon name="ChevronDown" class="text-aux-gray-darkest" />
          </div>

          <div>
            <div #trigger="cdkOverlayOrigin" class="mt-11" cdkOverlayOrigin></div>
          </div>
          <ng-template
            cdkConnectedOverlay
            class="border-solid border-aux-gray-dark-100"
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isYearsOpen"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayBackdropClass]="'ttt'"
            [cdkConnectedOverlayPositions]="positions"
            (backdropClick)="closeList()"
            (detach)="closeList()"
          >
            <ul
              tabindex="-1"
              class="bg-white border-solid text-gray-900 border-aux-gray-light rounded-md py-1 text-base ring-1 overflow-auto focus:outline-none sm:text-sm w-28"
            >
              <ng-container *ngFor="let item of years; index as index" class="grid">
                <li class="cursor-default select-none relative flex py-2 pl-2">
                  <aux-checkbox
                    [checked]="item.enabled"
                    (customChange)="yearChanged($event, item.label)"
                  />
                  {{ item.label }}
                </li>
              </ng-container>
            </ul>
          </ng-template>
          <div
            *ngIf="numberOfVendorCurrencies > 1 && (vendorCurrencyEnabled$ | async)"
            class="flex justify-between items-center"
            [auxTooltip]="
              isSnapShotSelected$.getValue().selected
                ? 'Comparing to a snapshot is only supported in Vendor Currency'
                : ''
            "
          >
            <aux-toggle-budget-currency
              class="ml-4"
              [value]="isVendorCurrency"
              [disabled]="isSnapShotSelected$.getValue().selected"
              (nameSelectedCurrency)="selectedBudgetCurrencyType$.next($event)"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showSnapshotSection$ | async" class="flex">
        <div
          [auxTooltip]="!isVendorCurrency ? 'Snapshot can only be taken in Vendor Currencies' : ''"
        >
          <aux-button
            *ngVar="organizationQuery.allVendors$ | async as vendors"
            [label]="'Snap'"
            icon="CameraPlus"
            [variant]="'secondary'"
            [onClick]="openSnapshotModal"
            [disabled]="
              vendors.length === 1
                ? false
                : selectedVendor.value || !isVendorCurrency || !vendors.length
            "
          />
        </div>
        <div class="mx-2.5 border-l border-l-aux-gray-dark border-solid"></div>
        <button
          type="button"
          class="text-sm btn-nr btn--white btn--secondary justify-end focus:outline-none"
          (click)="onBudgetExportClick()"
        >
          <aux-icon name="FileExport" />
          <span class="ml-2">Export</span>
        </button>
      </div>
    </div>
    <ag-grid-angular
      *ngIf="showGrid$ | async"
      domLayout="autoHeight"
      class="ag-theme-aux tabular-nums budget-enhanced-table"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="gridData$ | async"
      [postProcessPopup]="postProcessPopup"
      [groupAllowUnbalanced]="true"
      (firstDataRendered)="onDataRendered($event)"
      (modelUpdated)="modelUpdated()"
      (viewportChanged)="onWindowScroll()"
      (filterChanged)="onFilterChanged($event)"
      (gridReady)="onGridReady($event)"
      (gridSizeChanged)="gridSizeChanged()"
    />
  </div>
</ng-container>
