import { Injectable } from '@angular/core';
import { DataSource, GqlService } from '@services/gql.service';
import { switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { MainQuery } from '../../../../layouts/main-layout/state/main.query';
import { createInitialState, IntegrationsStore } from './integrations.store';

@Injectable({ providedIn: 'root' })
export class IntegrationsService {
  mapLogo = new Map<DataSource, string>([
    [DataSource.DATA_SOURCE_AUXILIUS, 'assets/img/logo.png'],
    [DataSource.DATA_SOURCE_BILL_COM, 'assets/img/billcom.png'],
    [DataSource.DATA_SOURCE_COUPA, 'assets/img/coupa.png'],
    [DataSource.DATA_SOURCE_DYNAMICS365, 'assets/img/microsoft-dynamics.png'],
    [DataSource.DATA_SOURCE_NETSUITE, 'assets/img/netsuite.png'],
    [DataSource.DATA_SOURCE_ORACLE_FUSION, 'assets/img/oracle-fusion.png'],
    [DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE, 'assets/img/quickbooks.png'],
    [DataSource.DATA_SOURCE_SAGE_INTACCT, 'assets/img/sage-intacct.png'],
  ]);

  mapType = new Map<DataSource, string>([
    [DataSource.DATA_SOURCE_AUXILIUS, 'Auxilius'],
    [DataSource.DATA_SOURCE_BILL_COM, 'Invoices'],
    [DataSource.DATA_SOURCE_COUPA, 'Invoices'],
    [DataSource.DATA_SOURCE_DYNAMICS365, 'Invoices'],
    [DataSource.DATA_SOURCE_NETSUITE, 'Invoices'],
    [DataSource.DATA_SOURCE_ORACLE_FUSION, 'Invoices'],
    [DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE, 'Invoices'],
    [DataSource.DATA_SOURCE_SAGE_INTACCT, 'Invoices'],
  ]);

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private integrationsStore: IntegrationsStore
  ) {}

  getIntegrations() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.integrationsStore.setLoading(true);
        this.integrationsStore.update(createInitialState());
        return this.gqlService.listIntegrationConnections$().pipe(
          tap(({ data, success, errors }) => {
            if (success && data) {
              const intArray = data
                .filter((x) => x.enabled)
                .sort((x, y) => Utils.alphaNumSort(x.name, y.name))
                .map((x) => {
                  return {
                    ...x,
                    logo: this.mapLogo.get(x.data_source) || '',
                    type: this.mapType.get(x.data_source) || '',
                  };
                });

              this.integrationsStore.update({
                items: intArray || [],
              });
            } else {
              this.overlayService.error(errors);
            }
            this.integrationsStore.setLoading(false);
          })
        );
      })
    );
  }
}
