<form [formGroup]="form">
  <h3>Slider</h3>
  <aux-slider type="percent" [options]="sliderOptions" [formControlName]="'slider'" />
</form>

<div class="flex items-center mt-2 mb-6">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(6)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-slider</div>
    <div class="m-2">options="any valid options from ngx-slider</div>
    <div class="m-2">minLabel="any string(by default takes from options floor)</div>
    <div class="m-2">maxLabel="any string(by default takes from options ceil)</div>
    <div class="m-2">type="percent | number"</div>
    <div class="m-2">&lt;/aux-slider&gt;</div>
  </div>
</div>

<h3>Search Bar</h3>
<aux-input class="w-48" placeholder="Search" icon="Search" [(ngModel)]="searchBar" />

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(6)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-input</div>
    <div class="m-2">class="w-48"</div>
    <div class="m-2">placeholder="Search"</div>
    <div class="m-2">[(ngModel)]="searchBar"</div>
    <div class="m-2">icon="search.svg"&gt;</div>
    <div class="m-2">&lt;/aux-input&gt;</div>
  </div>
</div>

<h3 class="mt-6">Text Input</h3>
<aux-input class="w-48" [(ngModel)]="textInput" />
<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div class="m-2">1</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-input class="w-48" [(ngModel)]="textInput"&gt;&lt;/aux-input&gt;</div>
  </div>
</div>

<h3 class="mt-6">Text Input w/ Description</h3>
<aux-input class="w-48" placeholder="Description" [(ngModel)]="textInputWithDescription" />

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(5)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-input</div>
    <div class="m-2">class="w-48"</div>
    <div class="m-2">placeholder="Description"</div>
    <div class="m-2">[(ngModel)]="textInputWithDescription"&gt;</div>
    <div class="m-2">&lt;/aux-input&gt;</div>
  </div>
</div>

<h3 class="mt-6">Text field filled out</h3>
<aux-input class="w-48" [(ngModel)]="textFieldFilledOut" />

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(4)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-input</div>
    <div class="m-2">class="w-48"</div>
    <div class="m-2">[(ngModel)]="textFieldFilledOut"&gt;</div>
    <div class="m-2">&lt;/aux-input&gt;</div>
  </div>
</div>

<p class="mt-2">
  Note that "textFieldFilledOut" will need to be assigned an inital value in the .ts file e.g.
</p>

<div class="flex items-center">
  <div class="text-black bg-aux-gray-dark">
    <div class="m-2">1</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">textFieldFilledOut = 'Filled out field';</div>
  </div>
</div>

<h3 class="mt-6">Dropdown</h3>

<ng-select
  class="w-64"
  placeholder="options"
  [multiple]="false"
  [clearable]="false"
  [formControl]="selectedOption"
>
  <ng-option *ngFor="let option of ['option1', 'option2', 'option3']" [value]="option">
    <span [title]="option">{{ option }}</span>
  </ng-option>
</ng-select>

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(10)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;ng-select</div>
    <div class="m-2">class="w-64"</div>
    <div class="m-2">[multiple]="false"</div>
    <div class="m-2">[clearable]="false"</div>
    <div class="m-2">[formControl]="selectedOption"</div>
    <div class="m-2">placeholder="options"&gt;</div>
    <div class="m-2">
      &lt;ng-option [value]="option" *ngFor="let option of ['option1', 'option2', 'option3']"&gt;
    </div>
    <div class="m-2">
      <div class="m-2">&lt;span [title]="option"&gt;{{"{{ option }}"}}&lt;/span&gt;</div>
    </div>
    <div class="m-2">&lt;/ng-option&gt;</div>
    <div class="m-2">&lt;/ng-select&gt;</div>
  </div>
</div>

<div class="mb-8"></div>

<h3 class="mt-6">Editable List Dropdown</h3>

<aux-editable-list-dropdown
  [dropdownFormControl]="editableListDropdownControl"
  [items]="editableListOptions"
  [label]="'Select Item:'"
  [classList]="'w-64'"
  [createBtnLabel]="'Create Item'"
  (edit)="editableListAction()"
  (delete)="editableListAction()"
  (create)="editableListAction()"
/>

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(9)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-editable-list-dropdown</div>
    <div class="m-2">
      <div class="mx-4">[dropdownFormControl]="editableListDropdownControl"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">[items]="editableListOptions"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">[label]="'Select Item:'"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">[classList]="'w-64'"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">[createBtnLabel]="'Create Item'"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">(edit)="editableListAction()"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">(delete)="editableListAction()"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">(create)="editableListAction()"&gt;&lt;/aux-editable-list-dropdown&gt;</div>
    </div>
  </div>
</div>

<div class="mb-8"></div>

<h3 class="mt-6">Date Picker</h3>
<aux-datepicker class="w-64" [formControl]="datePicked" />

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div class="m-2">1</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">
      &lt;aux-datepicker class="w-64" [formControl]="datePicked"&gt;&lt;/aux-datepicker&gt;
    </div>
  </div>
</div>
