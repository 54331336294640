import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import { ChangeLogStore, ChangeLogState } from './change-log.store';

@Injectable({ providedIn: 'root' })
export class ChangeLogQuery extends QueryEntity<ChangeLogState> {
  nonActiveLogs$ = this.selectActiveId().pipe(
    switchMap((id) => this.selectAll({ filterBy: (x) => x.id !== id }))
  );

  nonActiveCount$ = this.selectActiveId().pipe(
    switchMap((id) => this.selectCount((x) => x.id !== id))
  );

  constructor(protected store: ChangeLogStore) {
    super(store);
  }
}
