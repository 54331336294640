import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { Column, IHeaderGroupParams } from '@ag-grid-community/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { Utils } from '@services/utils';
import * as dayjs from 'dayjs';
import { CloseQuarterService } from '../close-quarter-check-list';
import { AsyncPipe, DatePipe, NgClass, NgIf } from '@angular/common';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { DirectivesModule } from '@directives/directives.module';
import { IconComponent } from '@components/icon/icon.component';

type InMonthCloseHeaderParams = IHeaderGroupParams & {
  bottomRowData$: BehaviorSubject<{ [k: string]: Record<string, number> }>;
  monthButtonClick: () => void;
  isMonthCloseAvailable$: BehaviorSubject<boolean>;
  isWorkflowAvailable$: BehaviorSubject<boolean>;
  isQuarterCloseEnabled$: BehaviorSubject<boolean>;
  processing$: BehaviorSubject<boolean>;
  userHasClosingPermission$: BehaviorSubject<boolean>;
  mode: 'closed' | 'open' | 'future';
  headerName?: string;
  hideTotal?: boolean;
  month: {
    parsedDate: dayjs.Dayjs;
    date: string;
    iso: string;
  };
};

@UntilDestroy()
@Component({
  templateUrl: 'ag-in-month-group-header.component.html',
  styleUrls: ['ag-in-month-group-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TooltipDirective, DirectivesModule, NgClass, DatePipe, AsyncPipe, IconComponent],
})
export class AgInMonthGroupHeaderComponent implements IHeaderGroupAngularComp {
  public params!: InMonthCloseHeaderParams;

  total$!: Observable<string>;

  showChild$ = new BehaviorSubject(false);

  constructor(public closeQuarterService: CloseQuarterService) {}

  agInit(params: InMonthCloseHeaderParams): void {
    this.params = params;

    this.total$ = params.bottomRowData$.pipe(
      map((x) => x.net_accruals[params.month?.date]),
      map((n) => Utils.currencyFormatter(n))
    );

    const displayedChildren = this.params.columnGroup.getDisplayedChildren() || [];
    const allChildren = this.params.columnGroup.getChildren() || [];

    this.showChild$.next(displayedChildren.length === allChildren.length);
  }

  onShowClick() {
    const bool = !this.showChild$.getValue();
    if (this.params.mode === 'open') {
      this.setAdjustedWidthForOpenMonth();
    }
    this.params.setExpanded(this.params.mode === 'open' ? !bool : bool);
    this.showChild$.next(bool);
  }

  setAdjustedWidthForOpenMonth(): void {
    const adjustedColumns = this.params.columnGroup
      .getDisplayedChildren()
      ?.filter(
        (children) =>
          ((children as Column).getColId().includes('eom_accrual.') ||
            (children as Column).getColId().includes('eom_prepaid.')) &&
          (children as Column).getColId().includes(this.params.month.date)
      );

    this.params.columnApi.getAllDisplayedColumns().forEach((column) => {
      if (adjustedColumns?.some((adjColumn) => column.getColId() === adjColumn.getUniqueId())) {
        const adjWidth = column.getActualWidth() === 150 ? 170 : 150;
        column.setActualWidth(adjWidth);
      }
    });
  }
}
