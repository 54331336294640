<section class="flex justify-between items-center mb-4">
  <p class="text-xl">
    Total (USD): <span class="font-bold">{{ totalCost$ | async | money }}</span>
  </p>
  <div class="flex items-center space-x-4">
    <p>Updated: {{ lastSourceRefreshDate$ | async | date: 'MMMM d, y, h:mm a' }}</p>
    <div class="h-7 w-px bg-aux-gray-dark"></div>
    <aux-checkbox
      class="text-sm"
      [checked]="(isContractSelected$ | async)!"
      (customChange)="toggleContractCurrency($event)"
    >
      Show Contracted Currency
    </aux-checkbox>
    <aux-exchange-link />
    <div class="h-7 w-px bg-aux-gray-dark"></div>
    <aux-button
      variant="secondary"
      icon="FileExport"
      [onClick]="this.onExportInvestigatorTransactions.bind(this)"
      label="Export"
      [loading]="isBtnLoading('export') | async"
      [spinnerSize]="7"
    />
  </div>
</section>
