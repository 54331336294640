import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { ExportType, Utils } from '@services/utils';
import { TrialUserQuery } from '@models/trial-users/trial-user.query';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  EntityType,
  EventType,
  GqlService,
  listPermissionsQuery,
  Permission,
  PermissionType,
} from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { groupBy } from 'lodash-es';
import { AuthService } from '@models/auth/auth.service';
import { UserModel } from '@models/trial-users/trial-user.store';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';
import * as dayjs from 'dayjs';
import { OverlayService } from '@services/overlay.service';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@UntilDestroy()
@Component({
  selector: 'aux-user-permissions',
  templateUrl: './user-permissions.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPermissionsComponent implements OnInit {
  permissions$ = new BehaviorSubject<{ header: string; children: Permission[] }[]>([]);

  isSysAdmin$ = new BehaviorSubject<boolean>(false);

  loggedInUserSub = new BehaviorSubject<string>('');

  loggedInUserHasAdministration = new BehaviorSubject<boolean>(false);

  matrixUsers$ = this.trialUserQuery.selectAll().pipe(
    map((users) => {
      return users
        .filter((user) => {
          // todo: remove this when we get superAdmin flag
          return user.email ? !user.email.includes('@auxili.us') : true;
        })
        .sort((x, y) => {
          return Utils.alphaNumSort(
            `${x.given_name} ${x.family_name}` as string,
            `${y.given_name} ${y.family_name}` as string
          );
        });
    })
  );

  constructor(
    private trialUserQuery: TrialUserQuery,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private authService: AuthService,
    private trialUserService: TrialUserService,
    private overlayService: OverlayService
  ) {}

  ngOnInit(): void {
    this.getUser();

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => this.gqlService.listPermissions$()),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        const groupedData = groupBy(x.data, 'permission_group');

        const arr: { header: string; children: listPermissionsQuery[] }[] = [];

        for (const [key, val] of Object.entries(groupedData)) {
          arr.push({ header: key, children: val });
        }

        //for showing administration as last element
        const firstElem = arr.shift();
        if (firstElem) {
          arr.push(firstElem);
        }
        this.permissions$.next(arr);
      });
  }

  async getUser() {
    const isSysAdmin = await firstValueFrom(
      this.authService
        .isAuthorized$({
          sysAdminsOnly: true,
        })
        .pipe(take(1))
    );
    const loggedInUser = await this.authService.getLoggedInUser();
    this.loggedInUserHasAdministration.next(
      loggedInUser?.hasPermission(PermissionType.PERMISSION_UPDATE_USER_PERMISSIONS) || false
    );
    this.loggedInUserSub.next(loggedInUser?.getSub() || '');

    this.isSysAdmin$.next(isSysAdmin);
  }

  async onCheckboxChange(val: boolean, perm: Permission, user: UserModel) {
    await this.trialUserService.updatePermission(user, perm.id || '', val ? 'E' : 'U');
  }

  async onCheckboxGroupChange(val: boolean, obj: Permission[], user: UserModel) {
    await this.trialUserService.updateGroupPermission(user, obj, val ? 'E' : 'U');
  }

  isGroupChecked(obj: Permission[], user: UserModel) {
    return obj.every((x: Permission) => user.permissions[x.id] !== 'U');
  }

  isGroupIndeterminate(obj: Permission[], user: UserModel) {
    return (
      obj.some((x: Permission) => user.permissions[x.id] !== 'U') &&
      obj.some((x: Permission) => user.permissions[x.id] === 'U')
    );
  }

  isCheckBoxDisabled(perm: Permission, user: UserModel) {
    return !this.isSysAdmin$.getValue()
      ? !(
          this.loggedInUserHasAdministration.getValue() &&
          this.loggedInUserSub.getValue() !== user.sub &&
          perm.permission_type !== PermissionType.PERMISSION_UPDATE_USER_PERMISSIONS
        )
      : false;
  }

  isGroupCheckBoxDisabled(perms: Permission[], user: UserModel) {
    return perms.some((perm) => this.isCheckBoxDisabled(perm, user));
  }

  async onExport() {
    const trialShortName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const trialId = this.mainQuery.getSelectedTrial()?.id || '';
    const fileTimestamp = dayjs().format('YYYY.MM.DD-HHmmss');
    const currentTz = dayjs.tz.guess();

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: trialId,
        payload: JSON.stringify({
          export_type: ExportType.PERMISSIONS,
          filename: `auxilius_${trialShortName}_User_Permissions_${fileTimestamp}`,
          export_entity_id: trialId || '',
          json_properties: {
            fileTimestamp: `${fileTimestamp} ${currentTz}`,
          },
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
  }
}
