import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { AuthService } from '@models/auth/auth.service';
import { OverlayService } from '@services/overlay.service';
import { AppInitService } from '@services/app-init.service';
import { EntityType, EventType, GqlService } from '@services/gql.service';
import { AuthQuery } from '@models/auth/auth.query';
import { CustomValidators } from '@components/form-inputs/custom-validators';
import { MessagesConstants } from '@constants/messages.constants';
import { firstValueFrom } from 'rxjs';
import { ROUTING_PATH } from '../../../app-routing-path.const';

@Component({
  selector: 'aux-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  @ViewChild(FormGroupDirective) formRef: FormGroupDirective | undefined;

  public year = new Date().getFullYear();

  loginLink = `/${ROUTING_PATH.LOGIN}`;

  errorMessage = '';

  successMessage = '';

  forgotForm = this.fb.group({
    email: ['', [Validators.required, CustomValidators.emailValidator()]],
  });

  newPasswordForm = this.fb.group({
    email: ['', [Validators.required, CustomValidators.emailValidator()]],
    code: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  showSecondForm = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private overlayService: OverlayService,
    public appInitService: AppInitService,
    private changeDetector: ChangeDetectorRef,
    private gqlService: GqlService,
    private authQuery: AuthQuery
  ) {}

  async forgotPassword() {
    if (this.forgotForm.valid) {
      this.successMessage = '';
      this.errorMessage = '';
      const ss = await this.authService.forgotPassword(this.forgotForm.get('email')?.value);

      if (typeof ss === 'string') {
        this.errorMessage = ss;
        this.changeDetector.detectChanges();
      } else if (ss) {
        this.successMessage = MessagesConstants.FORGOT_PASSWORD.EMAIL_HAS_BEEN_SEND;
        this.showSecondForm = true;
        this.newPasswordForm.get('email')?.setValue(this.forgotForm.get('email')?.value);
        this.changeDetector.detectChanges();
      }
    }
  }

  async newPasswordSubmit() {
    if (this.newPasswordForm.valid) {
      this.successMessage = '';
      this.errorMessage = '';
      const { email, code, password } = this.newPasswordForm.value;
      const ss = await this.authService.resetPassword(email, code, password);

      if (typeof ss === 'string') {
        this.errorMessage = ss;
        this.changeDetector.detectChanges();
      } else if (ss) {
        this.overlayService.success(MessagesConstants.FORGOT_PASSWORD.PASSWORD_RESET_SUCCESSFUL);
        await this.authService.signIn(email, password);
        const userSet = await this.authService.setUserAttributes();
        await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.USER_PASSWORD_UPDATED,
            entity_type: EntityType.USER,
            entity_id: '',
            payload: JSON.stringify({
              password_changed_for: `${userSet.email}`,
            }),
          })
        );
        await this.router.navigateByUrl('/');
      }
    }
  }
}
