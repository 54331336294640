import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TimelineStore, TimelineState } from './timeline.store';
import * as dayjs from 'dayjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TimelineQuery extends Query<TimelineState> {
  constructor(protected store: TimelineStore) {
    super(store);
  }

  getTimelineRange(transformPattern = 'YYYY-MM-DD'): string[] {
    const timelineList = this.getValue().items;

    const timelineRange: string[] = [];

    timelineList.forEach(({ contract_start_date, contract_end_date }) => {
      let startDate = dayjs(contract_start_date);
      const endDate = dayjs(contract_end_date);

      while (startDate.isBefore(endDate)) {
        timelineRange.push(startDate.format(transformPattern));
        startDate = startDate.add(1, 'month');
      }
    });

    return timelineRange;
  }

  selectTimelineRange(transformPattern = 'YYYY-MM-DD') {
    return this.select('items').pipe(map(() => this.getTimelineRange(transformPattern)));
  }
}
