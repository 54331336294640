import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { OrganizationQuery } from '@models/organization/organization.query';

type BudgetLibraryActionsParams = ICellRendererParams & {
  deleteClickFN?: (params: { rowNode: IRowNode }) => void;
  downloadClickFN?: (params: { rowNode: IRowNode }) => void;
  baselineClickFN?: (params: { rowNode: IRowNode }) => void;
  hideDownloadButton?: boolean;
  hideDeleteButton?: boolean;
  hideRadioButton?: boolean;
  hideIsCurrent?: boolean;
};

@Component({
  template: `
    <div *ngIf="params?.data" class="flex items-center justify-center space-x-2">
      <button
        *ngIf="!params.hideDownloadButton"
        class="text-aux-blue p-0 w-8 h-8"
        type="button"
        auxTooltip="Download"
        [ngClass]="{ 'opacity-50 pointer-events-none': params.data.bucket_key === null }"
        [disabled]="params.data.bucket_key === null"
        (click)="onDownloadClick()"
      >
        <aux-icon name="Download" />
      </button>

      <button
        *ngIf="
          !params.hideDeleteButton &&
          ((!params?.data?.is_current && !params?.data?.is_baseline) ||
            params?.data?.budget_type === 'BUDGET_SECONDARY')
        "
        class="p-0 w-8 h-8"
        type="button"
        auxTooltip="Delete"
        (click)="onDeleteClick()"
      >
        <aux-icon name="Trash" class="text-aux-error" />
      </button>
      <input
        *ngIf="
          !params.hideRadioButton &&
          !params.hideIsCurrent &&
          params?.data?.budget_type !== 'BUDGET_SECONDARY'
        "
        class="cursor-pointer"
        type="radio"
        [checked]="params.data.is_current"
        [disabled]="true"
      />
      <input
        *ngIf="
          !params.hideRadioButton &&
          params.hideIsCurrent &&
          params?.data?.budget_type !== 'BUDGET_SECONDARY'
        "
        class="cursor-pointer"
        type="radio"
        [ngClass]="{ 'focus:outline-none': params.hideIsCurrent }"
        [checked]="params.data.is_baseline"
        (click)="onBaselineClick()"
      />
    </div>
    <div *ngIf="params?.value && params.hideRadioButton" class="text-sm font-bold">
      {{ vendorName(params.value) }}
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetLibraryActionsComponent implements ICellRendererAngularComp {
  params!: BudgetLibraryActionsParams;

  constructor(public organizationQuery: OrganizationQuery) {}

  refresh(): boolean {
    return false;
  }

  agInit(params: BudgetLibraryActionsParams): void {
    this.params = params;
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }

  onBaselineClick() {
    if (this.params.baselineClickFN) {
      this.params.baselineClickFN({ rowNode: this.params.node });
    }
  }

  vendorName(id: string) {
    return this.organizationQuery.getEntity(id)?.name || '';
  }
}
