<ng-container *ngIf="vendorsQuery.selectLoading() | async">
  <div class="mt-10 text-xl font-semibold">Current Vendors</div>

  <ng-template #loader>
    <div class="w-64 p-4 border border-blue-200 rounded-md shadow">
      <div class="flex space-x-4 animate-pulse">
        <div class="flex-1 py-1 space-y-4">
          <div class="flex justify-between">
            <div class="grow"></div>
            <div class="w-1/6 h-3 bg-blue-200 rounded"></div>
          </div>
          <div class="space-y-2">
            <div class="w-2/6 h-3 bg-blue-200 rounded"></div>
            <div class="w-1/6 h-2 bg-blue-200 rounded"></div>
            <div class="w-3/6 h-2 bg-blue-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="flex mt-8 space-x-4">
    <ng-container [ngTemplateOutlet]="loader" />
    <ng-container [ngTemplateOutlet]="loader" />
  </div>
</ng-container>

<ng-container *ngIf="!$any(vendorsQuery.selectLoading() | async)">
  <div class="flex items-center space-x-4">
    <div class="text-xl font-semibold">Current Vendors</div>
    <aux-button
      *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
      variant="custom"
      classList="text-sm btn btn--blue"
      label="Add New Vendor"
      [onClick]="onNewVendor"
      icon="CirclePlus"
    />
    <aux-button
      *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
      variant="secondary"
      label="Add Multiple Vendors"
      [onClick]="onAddMultipleVendors"
      icon="CirclePlus"
    />
  </div>

  <div class="flex flex-wrap pb-4 mt-4">
    <ng-container *ngFor="let vendor of vendorsQuery.allVendors$ | async">
      <div class="shrink-0 w-64 p-4 border rounded-md mr-4 mt-4 justify-between break-words">
        <div class="text-lg font-bold text-aux-blue">{{ vendor.name }}</div>
        <div class="text-sm tabular-nums">
          <div>
            Total Budget:
            {{
              currencyFormatter(
                vendorsQuery.getPrimaryBudgetVersion(vendor.id)?.total_budget_amount,
                vendor.currency
              )
            }}
          </div>
        </div>

        <div class="flex space-x-2 text-sm font-medium items-center mt-4">
          <button class="btn px-2 h-8" type="button" (click)="onEdit(vendor)">
            <aux-icon name="Pencil" [size]="16" class="text-aux-blue" />
          </button>
          <ng-container *auxAuthorize="{ roles: ['ROLE_ADMIN'] }">
            <button class="btn px-2 h-8" type="button" (click)="removeVendor(vendor)">
              <aux-icon name="Trash" [size]="16" class="text-aux-error" />
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
