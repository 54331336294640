<div class="flex items-start filters" [formGroup]="form">
  <div class="max-w-sm w-full">
    <div class="h-5 mb-1"></div>
    <aux-input placeholder="Search" formControlName="search" icon="Search" class="w-full" />
  </div>
  <div class="pl-4">
    <span class="text-xs mb-1">Vendor:</span>
    <ng-select
      class="w-60 tabular-nums"
      placeholder="All"
      formControlName="vendors"
      bindValue="value"
      [multiple]="true"
      [clearable]="true"
      [searchable]="false"
      [items]="invoiceService.vendorOptions"
      (change)="onFilterChange()"
    >
      <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
        <div *ngIf="items.length === 1" class="ng-value">
          <div *ngFor="let item of items | slice: 0 : 1" class="flex">
            <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
            <span class="ng-value-label overflow-hidden text-ellipsis">{{ $any(item).label }}</span>
          </div>
        </div>
        <div *ngIf="items.length > 1" class="ng-value">
          <span class="ng-value-label">{{ items.length }} Selected</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="pl-4">
    <span class="text-xs mb-1">Invoice Date:</span>
    <aux-input
      placeholder="YYYY-MM-DD"
      formControlName="invoiceDate"
      class="w-36"
      [type]="'date'"
      (change)="onFilterChange()"
    />
  </div>
  <div class="pl-4">
    <span class="text-xs mb-1">Accrual Period:</span>
    <ng-select
      class="w-60 tabular-nums"
      placeholder="All Months"
      formControlName="accrualPeriod"
      bindValue="value"
      [multiple]="true"
      [clearable]="true"
      [searchable]="false"
      [items]="invoiceService.accrualPeriodOptions"
      (change)="onFilterChange()"
    >
      <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
        <div *ngIf="items.length === 1" class="ng-value">
          <div *ngFor="let item of items | slice: 0 : 1" class="flex">
            <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
            <span class="ng-value-label overflow-hidden text-ellipsis">{{ $any(item).label }}</span>
          </div>
        </div>
        <div *ngIf="items.length > 1" class="ng-value">
          <span class="ng-value-label">{{ items.length }} Selected</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <button
    class="pt-8 pl-4 focus:outline-none aux-link underline whitespace-nowrap"
    (click)="resetAllFilters()"
  >
    Clear All
  </button>
</div>
