import { FilterEntity, TableFilter, TableService } from '@services/table.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowNode,
  RowSelectedEvent,
  GridApi,
  ValueFormatterParams,
  IRowNode,
  SortModelItem,
  CellClassParams,
} from '@ag-grid-community/core';
import { Document, SortField } from '@services/gql.service';
import { Utils } from '@services/utils';
import { AuthQuery } from '@models/auth/auth.query';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { ApiService, AwsFile } from '@services/api.service';
import { DatePipe } from '@angular/common';
import { OverlayService } from '@services/overlay.service';
import { TableConstants } from '@constants/table.constants';
import { AgDropdownComponent, AgInputComponent } from './controls';
import { DocumentLibraryFile, DocumentLibraryService } from '../document-library.service';
import { StickyElementService } from '@services/sticky-element.service';
import { isEqual, isNumber, pick } from 'lodash-es';
import { AgLoadingCellComponent } from '@components/ag-loading-cell';
import { AuxExcelStyleKeys, GetExcelStyle } from '@shared/utils';

@Component({
  selector: 'aux-document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLibraryComponent implements OnInit, OnDestroy {
  gridAPI!: GridApi;

  @Input() formGroup!: UntypedFormGroup;

  gridData$ = new BehaviorSubject<Document[]>([]);

  @Input() inputChangeHandler!: () => void;

  @Input() rowSelectedHandler!: (event?: RowSelectedEvent<Document>) => void;

  @Input() isExternalFilterPresent: (() => boolean) | undefined;

  @Input() removedRows$!: BehaviorSubject<string[]>;

  @Input() doesExternalFilterPass: ((node: IRowNode<Document>) => boolean) | undefined;

  @Output() deleteRowEvent = new EventEmitter<string>();

  @Output() filterChange = new EventEmitter<
    Pick<DocumentLibraryFile, 'description' | 'document_type_id' | 'vendor_id' | 'site_id' | 'id'>[]
  >();

  @Output() rollbackDeleteRowEvent = new EventEmitter<RowNode>();

  @Output() rowDataChangedEvent = new EventEmitter();

  private controlCellClassNames = 'flex flex-col justify-center !items-stretch';

  gridOptions$ = new BehaviorSubject<GridOptions>({});

  overlayNoRowsTemplate = TableConstants.NO_ROWS_MESSAGE;

  totalItems$ = new BehaviorSubject(10);

  prevFilters: Record<string, TableFilter> = {};

  loadingCellRenderer = AgLoadingCellComponent;

  constructor(
    private authQuery: AuthQuery,
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private documentLibraryService: DocumentLibraryService,
    private stickyElementService: StickyElementService
  ) {}

  ngOnInit() {
    this.gridOptions$.next({
      ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
      rowHeight: 45,
      pagination: true,
      paginationPageSize: 10,
      suppressPaginationPanel: true,
      rowModelType: 'serverSide',
      isRowSelectable: (node) => !!node?.data?.is_metadata_editable,
      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      onModelUpdated: (event: ModelUpdatedEvent) => {
        if (event.api.getDisplayedRowCount() === 0) {
          event.api.showNoRowsOverlay();
        } else {
          event.api.hideOverlay();
        }

        if (this.gridData$.getValue().length) {
          this.overlayNoRowsTemplate = TableConstants.NO_ROWS_ON_FILTERING_MESSAGE;
        }
      },
      defaultColDef: {
        ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_CENTER,
        resizable: true,
      },
      columnDefs: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          maxWidth: 35,
          width: 35,
          minWidth: 35,
          cellClass: this.getRowClassesDeleteIndicatorRow([]),
        },
        {
          headerName: '',
          field: 'actions',
          suppressMenu: true,
          cellClass: this.getRowClassesDeleteIndicatorRow(['cell-justify-center']),
          cellRendererSelector: (params) => ({ component: AgActionsComponent, params }),
          cellRendererParams: {
            hideDownloadButton: false,
            hideEditButton: true,
            deletedRows: this.removedRows$,
            downloadClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
              const ref = this.overlayService.loading();

              await this.apiService.downloadFile({
                key: rowNode.data.bucket_key.replace('public/', ''),
                fileName: rowNode.data.name,
              } as AwsFile);

              ref.close();
            },
            deleteClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
              if (!this.removedRows$.getValue().includes(rowNode.data.id)) {
                this.deleteRowEvent.emit(rowNode.data.id);
              } else {
                this.rollbackDeleteRowEvent.emit(rowNode);
              }

              this.gridAPI.redrawRows({ rowNodes: [rowNode] });
            },
          },
          maxWidth: 75,
        },
        {
          headerName: 'Document Short Name',
          headerClass: 'ag-header-align-center font-bold',
          field: 'description',
          tooltipField: 'description',
          cellClass: this.getRowClassesDeleteIndicatorRow([this.controlCellClassNames]),
          cellRenderer: AgInputComponent,
          cellRendererParams: {
            formGroup: this.formGroup,
            changeHandler: this.inputChangeHandler,
          },
        },
        {
          headerName: 'File Name',
          headerClass: 'ag-header-align-center font-bold',
          field: 'name',
          tooltipField: 'name',
          valueGetter: (params) => params.data?.name || '',
          cellClass: this.getRowClassesDeleteIndicatorRow(['text-left', 'cell-align-center']),
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'value'),
        },
        {
          headerName: 'Document Type',
          headerClass: 'ag-header-align-center font-bold',
          field: 'document_type_id',
          cellClass: this.getRowClassesDeleteIndicatorRow([this.controlCellClassNames]),
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.documentLibraryService.getDocumentOptions(),
            changeHandler: this.inputChangeHandler,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(
              this.documentLibraryService.getDocumentOptions(),
              params?.data?.document_type_id
            ),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(
              this.documentLibraryService.getDocumentOptions(),
              params?.data?.document_type_id
            ),
        },
        {
          headerName: 'Vendor',
          headerClass: 'ag-header-align-center font-bold',
          field: 'vendor_id',
          cellClass: this.getRowClassesDeleteIndicatorRow([this.controlCellClassNames]),
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.documentLibraryService.vendors,
            changeHandler: this.inputChangeHandler,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.vendors, params?.data?.vendor_id),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.vendors, params?.data?.vendor_id),
        },
        {
          headerName: 'Site',
          headerClass: 'ag-header-align-center font-bold',
          field: 'site_id',
          cellClass: this.getRowClassesDeleteIndicatorRow([this.controlCellClassNames]),
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.documentLibraryService.sites,
            changeHandler: this.inputChangeHandler,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.sites, params?.data?.site_id),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.sites, params?.data?.site_id),
        },
        {
          headerName: 'Date Uploaded',
          headerClass: 'ag-header-align-center font-bold',
          field: 'create_date',
          cellClass: this.getRowClassesDeleteIndicatorRow(['text-right', 'cell-align-center']),
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          maxWidth: 180,
          tooltipValueGetter: (params) =>
            new DatePipe('en-US').transform(params?.data?.create_date, 'MM/dd/YYYY, h:mm a'),
          valueFormatter: (val: ValueFormatterParams) => {
            return new DatePipe('en-US').transform(val.value, 'MM/dd/YYYY, h:mm a') || '';
          },
        },
        {
          headerName: 'Uploaded by',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: this.getRowClassesDeleteIndicatorRow(['text-left', 'cell-align-center']),
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          field: 'created_by',
          tooltipValueGetter: (params) =>
            Utils.agUserFormatter(params?.data?.updatedByName, this.authQuery.isAuxAdmin()),
          valueFormatter: (params) =>
            Utils.agUserFormatter(params?.data?.updatedByName, this.authQuery.isAuxAdmin()),
        },
      ],
      excelStyles: [
        GetExcelStyle(AuxExcelStyleKeys.HEADER),
        GetExcelStyle(AuxExcelStyleKeys.FIRST_ROW),
        {
          id: 'cell',
          alignment: { horizontal: 'Left' },
          font: { fontName: 'Arial', size: 11 },
        },
      ],
    });
  }

  ngOnDestroy() {
    this.stickyElementService.reset();
  }

  getGridContext() {
    return {
      formGroup: this.formGroup,
    };
  }

  refreshFormControls(data: DocumentLibraryFile[], resetFormBeforeReinitialize: boolean) {
    if (this.gridAPI) {
      this.createFormControls(data, resetFormBeforeReinitialize);
      this.gridAPI.refreshCells({ force: true });
    }
  }

  private getRowClassesDeleteIndicatorRow =
    (classes: string[]) =>
    (cellClassParams: CellClassParams): string[] => {
      const markedToDeleteRow = this.removedRows$.getValue().includes(cellClassParams?.data?.id);

      return markedToDeleteRow ? [...classes, 'removed-row'] : classes;
    };

  private createFormControls(data: DocumentLibraryFile[], resetFormBeforeReinitialize: boolean) {
    if (resetFormBeforeReinitialize) {
      const tableFormControl = this.formGroup.controls.table as UntypedFormArray;

      tableFormControl.clear();
    }

    const gridRows = ((this.formGroup.controls?.table as UntypedFormArray)?.controls ||
      []) as AbstractControl[];

    const formArray = new UntypedFormArray(gridRows);

    data.forEach(
      ({ description, document_type_id, vendor_id, site_id, id, is_metadata_editable }) => {
        formArray.push(
          this.fb.group({
            description: new UntypedFormControl({
              value: description || '',
              disabled: false,
            }),
            document_type_id: new UntypedFormControl({
              value: document_type_id,
              disabled: !is_metadata_editable,
            }),
            vendor_id: new UntypedFormControl({
              value: vendor_id,
              disabled: !is_metadata_editable,
            }),
            site_id: new UntypedFormControl({ value: site_id, disabled: !is_metadata_editable }),
            id,
          })
        );
      }
    );

    this.formGroup.setControl('table', formArray);
  }

  getSortingModel(sortModel: SortModelItem[]): SortField[] {
    return sortModel.map(({ colId, sort }) => ({
      field: colId,
      descending: sort === 'desc',
    }));
  }

  paginationChange() {
    this.gridAPI.deselectAll();
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;

    this.gridAPI.setServerSideDatasource({
      getRows: (params) => {
        this.resetTableStateBeforePagination();

        const { search, dateFrom, dateTo, documentTypes, sites, vendors } =
          this.formGroup.getRawValue();

        const filterModel: Record<string, FilterEntity> = {
          document_type_id: {
            type: 'text',
            value: documentTypes,
          },
          vendor_id: {
            type: 'text',
            value: vendors,
          },
          site_id: {
            type: 'text',
            value: sites,
          },
          create_date: {
            type: 'date',
            value: [dateFrom, dateTo],
          },
        };

        const filters = TableService.getServerSideFilters(filterModel);

        this.documentLibraryService
          .getDocumentsWithPagination({
            start_row: params.request.startRow || 0,
            end_row: params.request.endRow || 100,
            search_text: search,
            sort_model: this.getSortingModel(params.request.sortModel),
            filter_model: JSON.stringify(filters),
          })
          .subscribe((gridData) => {
            if (gridData.data) {
              if (isNumber(gridData.totalItems)) {
                this.totalItems$.next(gridData.totalItems);
              }

              const allGridData =
                params.request.startRow === 0
                  ? gridData.data
                  : this.gridData$.getValue().concat(gridData.data);

              this.gridData$.next(allGridData);
              params.success({ rowData: gridData.data, rowCount: this.totalItems$.getValue() });
              this.gridAPI?.hideOverlay();
            } else {
              params.success({ rowData: [], rowCount: 0 });
              this.gridData$.next([]);
              this.gridAPI?.showNoRowsOverlay();
            }

            const noFilterChange = isEqual(this.prevFilters, filters);

            this.refreshFormControls(gridData.data, !noFilterChange);

            if (!noFilterChange) {
              this.prevFilters = { ...filters };

              const list = gridData.data.map((file) => {
                const transformFile = pick(file, [
                  'description',
                  'document_type_id',
                  'vendor_id',
                  'site_id',
                  'id',
                ]);

                transformFile.description = transformFile.description || '';

                return transformFile;
              });
              this.filterChange.emit(list);
            }
          });
      },
    });

    this.gridAPI.sizeColumnsToFit();
  }

  private resetTableStateBeforePagination() {
    this.gridAPI.deselectAll();
    this.rowSelectedHandler();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }
}
