import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@services/utils';
import { InvoiceCard } from '../state/invoice.model';

@Component({
  selector: 'aux-invoice-card',
  templateUrl: './invoice-card.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class InvoiceCardComponent implements OnInit {
  @Input() card: InvoiceCard = {
    id: '',
    header: '',
    status: 'Complete',
    lines: [],
    note: '',
  };

  originalCard = this.card;

  zeroHyphen = Utils.zeroHyphen;

  cardStatus = ['Complete', 'In Process', 'Up Next'];

  @Input() editMode = false;

  @Input() isInvoiceDisabled = false;

  @Input() invoiceLockTooltip = '';

  ngOnInit() {
    this.originalCard = { ...this.card };
  }

  onStatusChange(
    status: 'Complete' | 'In Process' | 'Up Next',
    oldValue: 'Complete' | 'In Process' | 'Up Next'
  ) {
    switch (status) {
      case 'Complete':
      case 'In Process':
        if (oldValue === 'Up Next') {
          this.card.lines = [...this.originalCard.lines];
        }
        break;
      case 'Up Next':
        this.card.lines = [];
        this.card.lines.push({ type: 'textarea', text: '' });
        break;
      default:
        break;
    }
  }

  onAddNewLine() {
    this.card.lines.push({ type: 'label', label: '' });
  }

  removeChild(index: number) {
    this.card.lines.splice(index, 1);
  }
}
