import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

@Component({
  selector: 'aux-how-it-works-modal',
  templateUrl: './how-it-works-modal.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksModalComponent {
  constructor(public ref: CustomOverlayRef) {}

  close(): void {
    this.ref.close();
  }
}
