import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkflowQuery, WorkflowService } from '../store';

@UntilDestroy()
@Component({
  selector: 'aux-static-check-list',
  templateUrl: './static-check-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticCheckListComponent implements OnInit {
  workflowList$ = this.workflowQuery.selectWorkflowList();

  isLoadingList$ = this.workflowQuery.selectLoading();

  constructor(
    private workflowService: WorkflowService,
    private workflowQuery: WorkflowQuery
  ) {}

  ngOnInit(): void {
    this.workflowService.getWorkflowList(false).pipe(untilDestroyed(this)).subscribe();
  }
}
