<div class="flex flex-row-reverse">
  <div class="flex justify-between items-center ml-4">
    <aux-menu #site_driver_menu>
      <button
        type="button"
        role="menuitem"
        class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
        (click)="onSiteDriverUploadClick(); site_driver_menu.close()"
      >
        <aux-icon name="Upload" />
        <span class="ml-2">Upload</span>
      </button>
    </aux-menu>
  </div>
  <aux-editable-list-dropdown
    [dropdownFormControl]="curveControl"
    [items]="siteCurveListOptions"
    [label]="'Select a Curve:'"
    [classList]="'w-64'"
    [createBtnLabel]="'Create Blended Curve'"
    (edit)="editCurve($event)"
    (delete)="deleteCurve($event)"
    (create)="editCurve()"
  />
</div>

<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(showEnrollmentSettings$ | async) === true">
  <ng-container *ngIf="$any(driverSiteQuery.selectLoading() | async)">
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="!$any(driverSiteQuery.selectLoading() | async)">
    <div class="border rounded border-aux-gray-dark bg-aux-gray-light py-3 p-4">
      <div class="flex justify-between items-center mb-4">
        <div class="font-bold text-lg text-aux-black">Site Assumptions</div>
        <aux-menu *ngIf="(editMode$ | async) === false" #cat_menu>
          <button
            class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
            role="menuitem"
            (click)="editMode$.next(true); cat_menu.close()"
          >
            <aux-icon name="Pencil" class="mr-2" [size]="20" />
            <span>Edit settings</span>
          </button>
        </aux-menu>
      </div>
      <div
        class="grid grid-cols-12 gap-5 text-sm whitespace-nowrap mb-4 pb-2 border-b"
        [ngClass]="{ 'mr-16': (editMode$ | async) === true, 'pl-3': (editMode$ | async) === false }"
      >
        <div class="col-span-3">Cohort Name</div>
        <div># of Sites</div>
        <div class="col-span-2">First Site Initiated</div>
        <div class="col-span-2">Sites Discontinued</div>
        <div class="col-span-2">Ramp Time (In Weeks)</div>
        <div class="col-span-2">Target Patient Count</div>
      </div>

      <ng-container *ngIf="(editMode$ | async) === false">
        <div class="grid grid-cols-12 gap-5 mb-3 text-xs ml-3">
          <ng-container *ngFor="let ss of driverSiteQuery.selectAll() | async">
            <div class="col-span-3">{{ ss.cohort_name }}</div>
            <div>{{ ss.number_of_sites }}</div>
            <div class="col-span-2">{{ ss.first_initiated_date | date }}</div>
            <div class="col-span-2">{{ ss.discontinued_date | date }}</div>
            <div class="col-span-2">{{ ss.ramp_time_in_weeks }}</div>
            <div class="col-span-2">{{ ss.target_patient_count }}</div>
          </ng-container>
        </div>

        <div
          *ngIf="avg$ | async as avg"
          class="grid grid-cols-12 gap-10 p-3 border rounded-md my-5 text-xs font-medium"
        >
          <span class="col-span-3">Average of All Sites</span>
          <span class="">{{ avg.total_number_of_sites }}</span>
          <span class="col-span-2">{{ avg.first_initiated_date }}</span>
          <span class="col-span-2">{{ avg.discontinued_date }}</span>
          <span class="col-span-2">{{ avg.ramp_time_in_weeks | number: '1.0-0' }}</span>
          <span class="col-span-2">{{ avg.target_patient_count | number: '1.0-0' }}</span>
        </div>
      </ng-container>

      <form *ngIf="(editMode$ | async) === true" [formGroup]="fg" (ngSubmit)="onSaveAll()">
        <ng-container
          *ngFor="let set of fg.controls.settings.controls; index as i"
          formArrayName="settings"
        >
          <div
            *ngIf="set"
            class="grid grid-cols-12 gap-5 mb-3 text-xs relative mr-16"
            [formGroupName]="i"
          >
            <aux-input class="col-span-3" formControlName="cohort_name" validators="required" />
            <aux-input formControlName="number_of_sites" validators="required" [type]="'number'" />
            <aux-input
              class="col-span-2"
              formControlName="first_initiated_date"
              validators="required"
              [type]="'date'"
            />
            <aux-input
              class="col-span-2"
              formControlName="discontinued_date"
              validators="required"
              [type]="'date'"
            />
            <aux-input
              class="col-span-2"
              formControlName="ramp_time_in_weeks"
              validators="required"
              [type]="'number'"
            />
            <aux-input
              class="col-span-2"
              formControlName="target_patient_count"
              validators="required"
              [type]="'number'"
            />
            <button
              class="btn absolute -right-16 w-12 h-12"
              type="button"
              (click)="onRemoveCohort(i, set)"
            >
              <aux-icon name="Trash" class="text-aux-error" [size]="20" />
            </button>
          </div>
        </ng-container>

        <button class="mt-4 flex items-center" type="button" (click)="onAddCohort()">
          <aux-icon name="Plus" class="text-aux-blue mr-1" [size]="20" />
          <span class="aux-link text-xs">Add Cohort</span>
        </button>

        <div class="flex space-x-4 items-center text-xs mb-5 mt-4">
          <button type="submit" class="btn btn--blue">
            <span *ngIf="loading$ | async" class="spinner w-4 h-4 mr-3"></span>
            <span>Save Edits</span>
          </button>
          <button class="aux-link focus:outline-none" type="button" (click)="editMode$.next(false)">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</ng-container>
<div *ngIf="(loading$ | async) === false">
  <div class="flex justify-between">
    <div class="font-bold text-xl text-aux-black mt-[25px] mb-4">Estimated Site Curve</div>
  </div>

  <div *ngIf="multiChart$ | async as chart">
    <aux-canvas-chart [chartOptions]="chart" />
  </div>

  <div class="mb-5 mt-[25px] flex items-center space-x-4 justify-between">
    <div class="text-lg font-bold text-black">Sites Online Estimates</div>
    <div *ngIf="(this.gridData$ | async)?.length !== 0" class="flex justify-end">
      <aux-button
        *ngIf="
          (editMode$ | async) === false &&
          (isBlended$ | async) === false &&
          userHasModifyPermissions
        "
        label="Edit"
        classList="ml-1 btn btn-secondary"
        variant="secondary"
        icon="Pencil"
        [auxTooltip]="editBtnTitle"
        [disabled]="isSitesFinalized$ | async"
        [onClick]="this.onEditClick.bind(this)"
      />
      <button
        *ngIf="(editMode$ | async) === true && (isBlended$ | async) === false"
        type="button"
        class="btn btn--secondary"
        (click)="cancelEditMode()"
      >
        <aux-icon name="X" />
        <span class="ml-0.5">Cancel</span>
      </button>

      <aux-button
        *ngIf="(editMode$ | async) === true"
        label="Save"
        variant="success"
        classList="ml-1 btn"
        [disabled]="isSaveBtnDisabled || (isNetSiteNegative$ | async) === true"
        [auxTooltip]="saveBtnTitle"
        [onClick]="saveEditMode"
        [loading]="(btnLoading$ | async) === true"
        icon="Check"
      />

      <aux-export-excel-button
        className="ml-1"
        [gridAPI]="gridApi"
        [excelOptions]="excelOptions"
        [variant]="exportButtonVariant"
        [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
      />
    </div>
  </div>
  <ag-grid-angular
    class="ag-theme-aux tabular-nums site-table"
    domLayout="autoHeight"
    id="site-table"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [stopEditingWhenCellsLoseFocus]="true"
    [enableFillHandle]="(editMode$ | async) === true"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged()"
    (firstDataRendered)="onDataRendered($event)"
    (cellEditingStarted)="rowPinnedCheck($event)"
    (viewportChanged)="onWindowScroll()"
  />
</div>
