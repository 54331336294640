import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonToggleItem } from '@components/button-toggle-group/button-toggle-item.model';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

export interface ProtocolForm {
  protocolVersion: string | null;
  patientGroup: string | null;
}

@UntilDestroy()
@Component({
  selector: 'aux-protocol-section',
  templateUrl: './protocol-section.component.html',
  styleUrls: ['./protocol-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolSectionComponent implements OnInit {
  @Input() versionOptions!: ButtonToggleItem[];

  @Input() patientOptions!: ButtonToggleItem[];

  @Input() template?: TemplateRef<unknown>;

  @Input() className = '';

  @Input() alwaysShowSection = false;

  @Input() isAmendmentDisabled = false;

  @Input() isVersionControlEditable = false;

  @Output() editProtocolVersion = new EventEmitter<EditableListDropdownItem>();

  @Output() deleteProtocolVersion = new EventEmitter<EditableListDropdownItem>();

  @Input() hideAmendment = false;

  @Output() valuesChange = new EventEmitter();

  @Output() formReady = new EventEmitter<FormGroup>();

  @Output() amendmentClick = new EventEmitter<boolean>();

  @Input() labelDirection: 'horizontal' | 'vertical' = 'horizontal';

  form = this.fb.group<ProtocolForm>({
    protocolVersion: null,
    patientGroup: null,
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((formValues) => {
      this.valuesChange.emit(formValues);
    });

    this.formReady.emit(this.form);
  }

  onCreateAmendment = () => {
    this.amendmentClick.emit(true);
  };

  getOptionWithVisibility(option: ButtonToggleItem): ButtonToggleItem {
    return !option.show ? { ...option, show: new BehaviorSubject(true) } : option;
  }

  convertOptionToEditableState(): EditableListDropdownItem[] {
    return this.versionOptions.map((item, i, arr) => ({
      name: item.label,
      value: item.value,
      showLine: i === 1 && arr.length !== 1,
      isEditable: true,
      isDeletable: i !== 0,
    }));
  }
}
