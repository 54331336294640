<ng-container *ngIf="$any(budgetLibraryQuery.selectLoading() | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(budgetLibraryQuery.selectLoading() | async)">
  <div class="flex mb-2 items-center">
    <aux-input
      class="w-64 mr-4 mt-1"
      placeholder="Search"
      icon="Search"
      [(ngModel)]="nameFilterValue"
    />
    <ng-select
      class="w-64 select select__big"
      [multiple]="false"
      [clearable]="false"
      [formControl]="selectedVendor"
    >
      <ng-option *ngIf="vendors.length > 1" [value]="''">All Vendors</ng-option>
      <ng-option *ngFor="let vendor of vendors" [value]="vendor.id">
        <span [title]="vendor.name">{{ vendor.name }}</span>
      </ng-option>
    </ng-select>
  </div>
  <div>
    <ag-grid-angular
      style="height: 600px"
      class="ag-theme-alpine budget-library-table tabular-nums w-full"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="gridData$ | async"
      [quickFilterText]="nameFilterValue"
      [getRowHeight]="getRowHeight"
      (firstDataRendered)="onDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (columnResized)="autoSize()"
    />
  </div>
</ng-container>
