<ng-container *ngIf="{ isSysAdmin: isSysAdmin$ | async } as obj">
  <div class="flex justify-end">
    <aux-button
      variant="secondary"
      icon="FileExport"
      [onClick]="this.onExport.bind(this)"
      label="Export"
      [spinnerSize]="7"
      classList="ml-1 btn"
    />
  </div>
  <ng-container *ngVar="matrixUsers$ | async as users">
    <div *ngIf="users.length" class="pl-4 my-12">
      <div class="text-xl font-semibold">User Permissions</div>

      <div
        class="grid mt-8 overflow-auto"
        [ngStyle]="{
          'grid-template-columns': 'repeat(' + (users.length + 1) + ',1fr)'
        }"
      >
        <div></div>
        <div
          *ngFor="let user of matrixUsers$ | async; first as first; last as last"
          class="p-4 text-xs text-center border-t border-b"
          [ngClass]="{
            'border-l rounded-tl': first,
            'border-r rounded-tr': last
          }"
        >
          <div class="font-bold whitespace-nowrap">
            {{ user.given_name }} {{ user.family_name }}
          </div>
          <div class="whitespace-nowrap">{{ user.title }}, {{ user.organization.name }}</div>
        </div>

        <ng-container
          *ngFor="let permission of permissions$ | async; first as pFirst; last as pLast"
        >
          <div
            class="p-4 space-y-2 text-sm border-b border-l border-r"
            [ngClass]="{
              'border-t': pFirst,
              'rounded-tl': pFirst,
              'rounded-bl': pLast
            }"
          >
            <div class="font-bold whitespace-nowrap">{{ permission.header }}</div>
            <div *ngFor="let child of permission.children" class="pl-2 whitespace-nowrap">
              {{ child.permission }}
            </div>
          </div>

          <div
            *ngFor="let user of matrixUsers$ | async; first as first; last as last"
            class="flex flex-col items-center justify-center p-4 space-y-2 text-sm border-b border-r"
            [ngClass]="{
              'rounded-br': pLast && last
            }"
          >
            <div>
              <aux-checkbox
                class="text-sm"
                [checked]="isGroupChecked(permission.children, user)"
                [disabled]="isGroupCheckBoxDisabled(permission.children, user)"
                [indeterminate]="isGroupIndeterminate(permission.children, user)"
                (customChange)="onCheckboxGroupChange($event, permission.children, user)"
              />
            </div>
            <div *ngFor="let child of permission.children" class="h-5">
              <ng-container *ngIf="user.permissions[$any(child.id)] as perm">
                <aux-checkbox
                  class="text-sm"
                  [checked]="perm === 'E'"
                  [disabled]="isCheckBoxDisabled(child, user)"
                  (customChange)="onCheckboxChange($event, child, user)"
                />
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
