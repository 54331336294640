import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Observable } from 'rxjs';

interface AgSiteTableActionsComponentParams extends ICellRendererParams {
  deleteClickFN?: (params: string) => void;
  processing$?: Observable<boolean>;
}

@Component({
  selector: 'aux-ag-site-table-actions',
  template: `
    <div
      class="flex items-center justify-center h-full"
      [attr.auto-qa]="autoTestAttribute"
      *ngVar="{
        processing: params.processing$ | async
      } as obj"
    >
      <button
        class="p-0 w-8 h-8 flex justify-center items-center"
        type="button"
        [disabled]="obj.processing"
        (click)="onDeleteClick()"
        *ngIf="params.deleteClickFN"
      >
        <aux-icon name="Trash" [size]="16" class="text-aux-error" />
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgSiteTableActionsComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp
{
  params!: AgSiteTableActionsComponentParams;

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN(this.params.value);
    }
  }

  agInit(params: AgSiteTableActionsComponentParams) {
    this.params = params;
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }
}
