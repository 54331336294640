import { NgModule } from '@angular/core';
import { CardComponent } from './components';

const sharedComponents = [CardComponent] as const;

@NgModule({
  imports: [...sharedComponents],
  exports: [...sharedComponents],
})
export class SharedModule {}
