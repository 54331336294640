<aux-protocol-section
  *ngIf="(isProtocolVersionLoading$ | async) === false"
  className="my-4"
  [versionOptions]="protocolVersionOptions"
  [patientOptions]="patientOptions"
  [isVersionControlEditable]="true"
  [isAmendmentDisabled]="(editModeGrid$ | async) === true"
  [template]="actionButtons"
  (valuesChange)="onChangePatientGroup($event)"
  (formReady)="onProtocolFormReady($event)"
  (amendmentClick)="createProtocol($event)"
  (editProtocolVersion)="editProtocolVersion($event)"
  (deleteProtocolVersion)="deleteProtocolVersion($event)"
/>

<ng-template #actionButtons>
  <div *ngIf="(isProtocolVersionLoading$ | async) === false" class="flex justify-end space-x-2">
    <ng-container *auxAuthorize="{ sysAdminsOnly: true }">
      <aux-button
        *ngIf="!protocolVersionOptions.length"
        label="Create Protocol"
        variant="secondary"
        icon="CirclePlus"
        [spinnerSize]="7"
        [onClick]="createProtocol"
      />
    </ng-container>

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="primary"
      label="Add Entry"
      classList="h-[40px]"
      [disabled]="$any(loading$ | async)"
      [onClick]="onAddPatientProtocol.bind(this)"
    ></aux-button>

    <aux-button
      *ngIf="(editModeGrid$ | async) === false && protocolVersionOptions.length"
      variant="secondary"
      label="Edit"
      icon="Pencil"
      [onClick]="editGrid"
    />

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="secondary"
      label="Cancel"
      icon="X"
      [onClick]="cancelEditMode"
    />

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="success"
      label="Save"
      class="ml-1"
      icon="Check"
      [disabled]="!hasChanges"
      [onClick]="onSaveAll"
    />
  </div>
</ng-template>

<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="!$any(loading$ | async)">
  <ag-grid-angular
    id="patientBudgetEntryGrid"
    class="ag-theme-aux tabular-nums"
    [domLayout]="domLayout"
    [ngStyle]="{
      height: domLayout === 'normal' ? '500px' : ''
    }"
    [gridOptions]="$any(gridOptions$ | async)"
    [rowData]="$any(gridData$ | async)"
    [enableFillHandle]="(editModeGrid$ | async) === true"
    [rowDragManaged]="true"
    [animateRows]="true"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    (viewportChanged)="onWindowScroll()"
    (gridSizeChanged)="gridSizeChanged()"
  />
</div>
