import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivesModule } from '@directives/directives.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import {
  DividerComponent,
  TypographyComponent,
  TypographyRuleComponent,
  TypographyTypeScaleComponent,
} from './typography';
import { ActionsComponent, TablesComponent } from './tables';
import { ModalDialogComponent, ModalComponent } from './modal';
import { FormsComponent } from './forms/forms.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { ColorsComponent } from './colors/colors.component';
import { DesignSystemComponent } from './design-system.component';
import { ButtonToggleComponentDemoComponent } from './button-toggle-component/button-toggle-component-demo.component';
import { TrialInsightsModule } from '../trial-insights/trial-insights.module';
import { IconComponent } from '@components/icon/icon.component';

@NgModule({
  declarations: [
    DividerComponent,
    TypographyComponent,
    TypographyRuleComponent,
    TypographyTypeScaleComponent,
    ActionsComponent,
    FormsComponent,
    ButtonsComponent,
    ModalDialogComponent,
    ModalComponent,
    TablesComponent,
    ColorsComponent,
    DesignSystemComponent,
    ButtonToggleComponentDemoComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    ComponentsModule,
    DirectivesModule,
    OverlayModule,
    RouterModule,
    AgGridModule,
    TrialInsightsModule,
    IconComponent,
  ],
})
export class DesignSystemModule {}
