import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {
  ColumnApi,
  ExcelExportParams,
  ProcessCellForExportParams,
  GridApi,
} from '@ag-grid-community/core';
import { TableService } from '@services/table.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import * as dayjs from 'dayjs';
import { InvestigatorTransactionsService } from '../investigator-transactions.service';
import { MainQuery } from '../../../../layouts/main-layout/state/main.query';
import { EntityType, EventType, GqlService } from '@services/gql.service';
import { ExportType } from '@services/utils';
import { OverlayService } from '@services/overlay.service';
import { map } from 'rxjs/operators';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.type';
import { AgSetColumnsVisible } from '@shared/utils';

@Component({
  selector: 'aux-investigator-transactions-header',
  templateUrl: './investigator-transactions-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorTransactionsHeaderComponent {
  @Input() gridAPI!: GridApi;

  @Input() columnAPI!: ColumnApi;

  @Input() isContractSelected$!: BehaviorSubject<boolean>;

  totalCost$ = this.investigatorTransactionsService.totalCost$;

  lastSourceRefreshDate$ = this.investigatorTransactionsService.lastSourceRefreshDate$;

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  constructor(
    private investigatorTransactionsService: InvestigatorTransactionsService,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  toggleContractCurrency(selected: boolean) {
    AgSetColumnsVisible({
      columnApi: this.columnAPI,
      keys: ['contractCurrency', 'totalContract', 'exchangeRate'],
      visible: selected,
    });
    this.isContractSelected$.next(selected);
    this.gridAPI.sizeColumnsToFit();
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  async onExportInvestigatorTransactions() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.INVESTIGATOR_TRANSACTIONS,
          filename: `${trialName}_auxilius-investigator-transactions__${dateStr}`,
          json_properties: this.investigatorTransactionsService.currentFilters$.getValue(),
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    const name = this.mainQuery.getSelectedTrial()?.short_name;

    return {
      fileName: `auxilius-investigator-transactions_${dayjs().format('YYYY.MM.DD-HHmmss')}.xlsx`,
      sheetName: 'Investigator Transactions',
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${name}`, type: 'String' },
              mergeAcross: 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      processCellCallback(params: ProcessCellForExportParams): string {
        const colId = params.column.getColId();

        const isContractCell =
          colId === 'totalContract' ? CurrencyToggle.CONTRACTED : CurrencyToggle.PRIMARY;

        return TableService.processCellForExcel(
          new BehaviorSubject<CurrencyToggle>(isContractCell),
          ['totalContract', 'totalUsd']
        )(params);
      },
    };
  };
}
