<div class="bg-white p-4 border shadow-md rounded pr-28">
  <div class="font-bold mb-4">Closing Checklist</div>
  <div *ngIf="(isLoadingList$ | async) === false" class="space-y-2.5 text-sm">
    <div *ngFor="let workflow of workflowList$ | async; let i = index" class="flex items-center">
      <div
        class="w-6 h-6 border bg-aux-gray-light mr-2.5 rounded-full flex items-center justify-center"
      >
        {{ i + 1 }}
      </div>
      <div>
        <a class="aux-link" [routerLink]="'/' + workflow.properties.route">{{ workflow.name }}</a>
      </div>
    </div>
  </div>
  <div *ngIf="isLoadingList$ | async" class="border-8 m-auto spinner h-16 w-16"></div>
</div>
