import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { AgSetColumnsVisible } from '@shared/utils';

export interface AgBudgetAttributeComponentParams extends IHeaderParams {
  columnsToCollapse: string[];
  localStorageKey: string;
  expandLevel: () => number;
  afterAttrToggle?: () => void;
  alignHeaderOneRowHeight?: boolean;
}

@Component({
  template: `
    <div
      class="flex items-center justify-center space-x-[10px]"
      [ngClass]="{ '-mt-[48px] absolute top-[80%]': !params.alignHeaderOneRowHeight }"
    >
      <div class="flex items-center justify-center space-x-[5px]">
        <button (click)="toggleRowExpand(false)" class="flex">
          <aux-icon name="ChevronsDown" [size]="20" [auxTooltip]="'Expand All Rows'" />
        </button>

        <button (click)="toggleRowExpand(true)" class="flex">
          <aux-icon name="ChevronsUp" [size]="20" [auxTooltip]="'Collapse All Rows'" />
        </button>
      </div>

      <div class="flex items-center justify-center space-x-1">
        <div class="text-aux-black text-base" [innerHTML]="params.template"></div>

        <aux-icon
          *ngIf="params.columnsToCollapse.length"
          class="cursor-pointer ml-1"
          (click)="toggleExpand()"
          [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
          [size]="20"
        />
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgBudgetEnhancedGroupHeaderComponent implements IHeaderAngularComp {
  params!: AgBudgetAttributeComponentParams;

  visible = true;

  agInit(params: AgBudgetAttributeComponentParams): void {
    this.params = params;

    this.visible = params.columnsToCollapse?.some((c) => {
      // sometimes func doesn't return value
      // So collapse doesn't show correctly
      return params.columnApi.getColumn(c)?.isVisible();
    });
  }

  toggleExpand() {
    this.visible = !this.visible;
    AgSetColumnsVisible({
      columnApi: this.params.columnApi,
      keys: this.params.columnsToCollapse,
      visible: this.visible,
    });
    if (this.params.localStorageKey)
      localStorage.setItem(this.params.localStorageKey, this.visible.toString());

    if (this.params.afterAttrToggle) {
      this.params.afterAttrToggle();
    }
  }

  toggleRowExpand(isExpanded: boolean) {
    this.params.api.forEachNode((node) => {
      if (isExpanded) {
        node.expanded = false;
      } else {
        const lvl = this.params.expandLevel();
        node.expanded = lvl !== -1 ? node.level < lvl : true;
      }
    });
    this.params.api.onGroupExpandedOrCollapsed();
  }

  refresh(): boolean {
    return false;
  }
}
