import {
  Workflow,
  WorkflowStore,
} from './../../quarter-close/close-quarter-check-list/store/workflow.store';
import { Injectable } from '@angular/core';
import { EventType, GqlService } from '@services/gql.service';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { WorkflowQuery, WorkflowService } from '../../quarter-close/close-quarter-check-list/store';
import { QuarterCloseChecklistWorkflowService } from './quarter-close-checklist-workflow.service';
import { ChecklistSectionGatherContractsComponent } from '../components/checklist-section-gather-contracts/checklist-section-gather-contracts.component';
import { ChecklistSectionGatherEstimatesComponent } from '../components/checklist-section-gather-estimates/checklist-section-gather-estimates.component';
import {
  ChecklistComponent,
  QuarterCloseChecklistRow,
  QuarterCloseChecklistRowComponents,
  QuarterCloseChecklistRowDisabled,
  QuarterCloseChecklistRowTitles,
  QuarterCloseChecklistSection,
  QuarterCloseChecklistSectionTitles,
  QuarterCloseChecklistVendorEstimateSummary,
} from '../models/quarter-close-checklist.model';
import { QuarterCloseChecklistPeriodCloseService } from './quarter-close-checklist-period-close.service';
import { ChecklistSectionDiscountComponent } from '../components/checklist-section-discount/checklist-section-discount.component';

@Injectable()
export class QuarterCloseChecklistService {
  Sections = QuarterCloseChecklistSection;

  SectionTitles = QuarterCloseChecklistSectionTitles;

  Rows = QuarterCloseChecklistRow;

  RowTitles = QuarterCloseChecklistRowTitles;

  RowComponents: QuarterCloseChecklistRowComponents = {
    GatherContracts: new ChecklistComponent(
      ChecklistSectionGatherContractsComponent,
      {},
      'GatherContracts'
    ),
    GatherEstimates: new ChecklistComponent(
      ChecklistSectionGatherEstimatesComponent,
      {},
      'GatherEstimates'
    ),
    CloseDiscounts: new ChecklistComponent(ChecklistSectionDiscountComponent, {}, 'CloseDiscounts'),
  };

  RowDisabled: QuarterCloseChecklistRowDisabled = {
    GatherContracts: false,
    GatherInvoices: true,
    GatherPatients: true,
    GatherEstimates: false,
    ForecastTimeline: true,
    ForecastCurves: true,
    ForecastMethodology: true,
    CloseExpenses: true,
    CloseDiscounts: false,
  };

  // cache invalidate refreshes checklist and so discount component is gonna destroyed
  // this is just to store the latest selected vendor. This way discount component can
  // re-select the vendor after refresh.
  discountSelectedVendor = '';

  constructor(
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private workflowQuery: WorkflowQuery,
    private workflowStore: WorkflowStore,
    private workflowService: QuarterCloseChecklistWorkflowService,
    private originalWorkflowService: WorkflowService,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService
  ) {}

  isAdminUser(): Observable<boolean> {
    return this.periodCloseService.isAdminUser;
  }

  isCurrentQuarterSelected(): Observable<boolean> {
    return this.periodCloseService.isCurrentQuarterSelected;
  }

  isClosedMonthsProcessing(): Observable<boolean> {
    return this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);
  }

  isQuarterCloseEnabled(): Observable<boolean> {
    return this.workflowQuery.isQuarterCloseEnabled$;
  }

  resetWorkflowState() {
    this.workflowStore.remove(() => true);
    this.workflowStore.update({ loading: false });
  }

  workflowAdjustmentAvailable(): Observable<boolean> {
    return this.workflowQuery.getInMonthAdjustmentAvailability();
  }

  workflowLoading(): Observable<boolean> {
    return this.workflowQuery.selectLoading();
  }

  workflowList(): Observable<Workflow[]> {
    return this.workflowQuery.selectWorkflowList();
  }

  vendorEstimateSummaries(
    period: string
  ): Observable<QuarterCloseChecklistVendorEstimateSummary[]> {
    return this.gqlService
      .listVendorEstimateSummaries$(period)
      .pipe(map(this.workflowService.filterVendorEstimates.bind(this)));
  }

  onQuarterMonthChange(selectedQuarterMonth: string, isAdminUser: boolean): void {
    if (selectedQuarterMonth) {
      const workflowDate = dayjs(selectedQuarterMonth).format('MMM-YYYY').toUpperCase();

      this.originalWorkflowService
        .getWorkflowList(isAdminUser, workflowDate)
        .pipe(first())
        .subscribe();
    }
  }
}
