import { Injectable } from '@angular/core';
import {
  EntitySettingTypes,
  EntityType,
  GqlService,
  listEntitySettingsQuery,
  updateEntitySettingsMutation,
} from '@services/gql.service';
import { FormGroup } from '@angular/forms';
import {
  EntitySettingsFormGroup,
  EntitySettingsFormGroupRow,
  EntitySettingsResponseRow,
} from '@models/organization/entity-settings/entity-settings.model';
import { Observable } from 'rxjs';

@Injectable()
export class ManageBalanceSheetAccountsModalService {
  constructor(private gqlService: GqlService) {}

  getListEntitySettings(vendorId: string): Observable<GraphqlResponse<listEntitySettingsQuery[]>> {
    return this.gqlService.listEntitySettings$({
      entity_id: vendorId,
      entity_type: EntityType.ORGANIZATION,
      setting_type: EntitySettingTypes.VENDOR_ACCOUNTS,
    });
  }

  save(
    vendorId: string,
    form: FormGroup<EntitySettingsFormGroup>
  ): Observable<GraphqlResponse<updateEntitySettingsMutation[]>> {
    const settings = {
      ACCRUED_LIABILITIES: this.combineSettingsParameters(form.controls.accruedLiabilities),
      PREPAID_EXPENSES: this.combineSettingsParameters(form.controls.prepaidExpenses),
      ACCOUNTS_PAYABLE: this.combineSettingsParameters(form.controls.accountsPayable),
    };

    return this.gqlService.updateEntitySettings$({
      entity_id: vendorId,
      entity_type: EntityType.ORGANIZATION,
      setting_type: EntitySettingTypes.VENDOR_ACCOUNTS,
      settings: JSON.stringify(settings),
    });
  }

  private combineSettingsParameters(
    formGroup: FormGroup<EntitySettingsFormGroupRow>
  ): EntitySettingsResponseRow {
    return {
      ACCOUNT: formGroup.controls.account.value || '',
      DEPARTMENT: formGroup.controls.department.value || '',
      PROGRAM: formGroup.controls.program.value || '',
      PROJECT: formGroup.controls.project.value || '',
    };
  }
}
