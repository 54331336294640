<ng-container *ngIf="$any(timelineQuery.selectLoading() | async) || (saving$ | async)!">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="!$any(timelineQuery.selectLoading() | async) && !(saving$ | async)!">
  <aux-workflow-panel
    *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
    [workflowName]="workflowName"
    [isAdminUser]="isAdminUser"
    [processing]="(iCloseMonthsProcessing$ | async)!"
    [hasUserPermissions]="userHasLockTrialTimelinePermission"
  />
  <div class="form-group flex justify-between my-4">
    <aux-input class="w-72" placeholder="Search" icon="Search" [(ngModel)]="nameFilterValue" />
    <div class="flex">
      <aux-button
        variant="secondary"
        label="Export"
        icon="FileExport"
        [onClick]="this.onExportTimeline.bind(this)"
        [loading]="isBtnLoading('export') | async"
        [spinnerSize]="7"
      />
      <aux-button
        *ngIf="userHasModifyPermissions$ | async"
        variant="primary"
        label="Add Milestone"
        classList="h-11 ml-3"
        [disabled]="(isTimeLineFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
        [onClick]="this.addTimelineMilestone"
        [auxTooltip]="
          (isTimeLineFinalized$ | async) || (iCloseMonthsProcessing$ | async)
            ? messagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE
            : ''
        "
      />
    </div>
  </div>

  <ag-grid-angular
    class="ag-theme-aux tabular-nums"
    domLayout="autoHeight"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [quickFilterText]="nameFilterValue"
    (firstDataRendered)="onDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (rowGroupOpened)="onGroupOpened($event)"
    (rowDataUpdated)="onRowDataChanged()"
    (viewportChanged)="onWindowScroll()"
    (gridSizeChanged)="gridSizeChanged()"
  />
</ng-container>

<aux-save-changes
  *ngIf="(hasChanges$ | async)!"
  [showDiscardChangesBtn]="true"
  [onSaveChanges]="onSaveAll"
  (cancel)="reset()"
/>
