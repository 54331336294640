import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VarDirective } from './var.directive';
import { FormErrorDirective } from './form-error.directive';
import { AuthorizeDirective } from './authorize.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { GridDynamicHeightDirective } from './grid-dynamic-height.directive';
// import { GroupByDirective } from './group-by.directive';

@NgModule({
  declarations: [
    FormErrorDirective,
    VarDirective,
    AuthorizeDirective,
    ClickOutsideDirective,
    GridDynamicHeightDirective,
  ],
  imports: [CommonModule],
  exports: [
    FormErrorDirective,
    VarDirective,
    AuthorizeDirective,
    ClickOutsideDirective,
    GridDynamicHeightDirective,
  ],
})
export class DirectivesModule {}
