/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from '@angular/core';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { Observable } from 'zen-observable-ts';
import gql from 'fake-tag';
import { gql$ } from '@shared/utils';

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  notification: notificationSubscription;
};

export type GetTemplatePathInput = {
  payload?: string | null;
  template_type: TemplateType;
  vendor_id?: string | null;
};

export enum TemplateType {
  BUDGET_TEMPLATE = 'BUDGET_TEMPLATE',
  BULK_INVOICE_TEMPLATE = 'BULK_INVOICE_TEMPLATE',
  BULK_SITE_TEMPLATE = 'BULK_SITE_TEMPLATE',
  INVOICE_TEMPLATE = 'INVOICE_TEMPLATE',
  PATIENT_BUDGET_TEMPLATE = 'PATIENT_BUDGET_TEMPLATE',
  PATIENT_DRIVER_TEMPLATE = 'PATIENT_DRIVER_TEMPLATE',
  SITE_DRIVER_TEMPLATE = 'SITE_DRIVER_TEMPLATE',
  SITE_PATIENT_TRACKER_TEMPLATE = 'SITE_PATIENT_TRACKER_TEMPLATE',
  SITE_PAYMENT_SCHEDULE_TEMPLATE = 'SITE_PAYMENT_SCHEDULE_TEMPLATE',
  SPECIFICATION_TEMPLATE = 'SPECIFICATION_TEMPLATE',
  VENDOR_ESTIMATE_TEMPLATE = 'VENDOR_ESTIMATE_TEMPLATE',
}

export type GenericResponse = {
  __typename: 'GenericResponse';
  id: string;
};

export type ApprovalInput = {
  activity_details?: string | null;
  approval_type: ApprovalType;
  approved: boolean;
  comments?: string | null;
  entity_id: string;
  entity_type: EntityType;
  permission: string;
};

export enum ApprovalType {
  APPROVAL_CHANGE_ORDER = 'APPROVAL_CHANGE_ORDER',
  APPROVAL_INVOICE = 'APPROVAL_INVOICE',
  APPROVAL_IN_MONTH = 'APPROVAL_IN_MONTH',
}

export enum EntityType {
  ACTIVITY = 'ACTIVITY',
  AGGREGATION = 'AGGREGATION',
  AMOUNT = 'AMOUNT',
  BLENDED_PATIENT_GROUP = 'BLENDED_PATIENT_GROUP',
  BLENDED_SITE_GROUP = 'BLENDED_SITE_GROUP',
  BUDGET = 'BUDGET',
  BUDGET_EXPENSE = 'BUDGET_EXPENSE',
  BUDGET_V2 = 'BUDGET_V2',
  BUDGET_VERSION = 'BUDGET_VERSION',
  BUDGET_VIEW = 'BUDGET_VIEW',
  CATEGORY = 'CATEGORY',
  CHANGE_ORDER = 'CHANGE_ORDER',
  CL = 'CL',
  CLA = 'CLA',
  CLI = 'CLI',
  CONTACT = 'CONTACT',
  COST = 'COST',
  COUNTRY = 'COUNTRY',
  CRON_JOB = 'CRON_JOB',
  CURRENCY = 'CURRENCY',
  DATA_SOURCE = 'DATA_SOURCE',
  DISTRIBUTION = 'DISTRIBUTION',
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_STORAGE = 'DOCUMENT_STORAGE',
  DRIVER = 'DRIVER',
  DRIVER_PATIENT_DELTA = 'DRIVER_PATIENT_DELTA',
  DRIVER_SETTING = 'DRIVER_SETTING',
  DURATION = 'DURATION',
  EVIDENCE = 'EVIDENCE',
  EXPENSE = 'EXPENSE',
  FORECAST = 'FORECAST',
  INPUT = 'INPUT',
  INVESTIGATOR_FORECAST_SETTING = 'INVESTIGATOR_FORECAST_SETTING',
  INVOICE = 'INVOICE',
  LEDGER_ACCOUNT = 'LEDGER_ACCOUNT',
  MILESTONE = 'MILESTONE',
  ORGANIZATION = 'ORGANIZATION',
  PATIENT_GROUP = 'PATIENT_GROUP',
  PATIENT_PROTOCOL = 'PATIENT_PROTOCOL',
  PATIENT_TRACKER = 'PATIENT_TRACKER',
  PATIENT_TRACKER_VERSION = 'PATIENT_TRACKER_VERSION',
  PERMISSION = 'PERMISSION',
  PROFILE = 'PROFILE',
  PROTOCOL_ENTRY = 'PROTOCOL_ENTRY',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  ROLE = 'ROLE',
  SITE = 'SITE',
  SITE_CONTRACT = 'SITE_CONTRACT',
  SITE_GROUP = 'SITE_GROUP',
  SITE_PAYMENT_SCHEDULE = 'SITE_PAYMENT_SCHEDULE',
  SPECIFICATION = 'SPECIFICATION',
  STATUS = 'STATUS',
  SUBSIDIARY = 'SUBSIDIARY',
  TIMELINE = 'TIMELINE',
  TRIAL = 'TRIAL',
  TRIALEVENT = 'TRIALEVENT',
  UOM = 'UOM',
  USER = 'USER',
  USER_AUDIT_LOG_ACTION = 'USER_AUDIT_LOG_ACTION',
  USER_AUDIT_LOG_CATEGORY = 'USER_AUDIT_LOG_CATEGORY',
  VENDOR_BUDGET = 'VENDOR_BUDGET',
  VIEW_LOCATION = 'VIEW_LOCATION',
  WF = 'WF',
  WF_STEP = 'WF_STEP',
}

export type CreateNoteInput = {
  entity_id: string;
  entity_type: EntityType;
  expense_note_types?: Array<ExpenseNoteType> | null;
  message: string;
  metadata?: string | null;
  note_type: NoteType;
};

export enum ExpenseNoteType {
  EXPENSE_NOTE_EXPENSE_SOURCE_OVERRIDE = 'EXPENSE_NOTE_EXPENSE_SOURCE_OVERRIDE',
  EXPENSE_NOTE_HISTORICAL_ADJUSTMENT = 'EXPENSE_NOTE_HISTORICAL_ADJUSTMENT',
  EXPENSE_NOTE_MANUAL_ADJUSTMENT = 'EXPENSE_NOTE_MANUAL_ADJUSTMENT',
  EXPENSE_NOTE_VENDOR_ESTIMATE = 'EXPENSE_NOTE_VENDOR_ESTIMATE',
}

export enum NoteType {
  NOTE_TYPE_ADMIN_REVIEW_REASON = 'NOTE_TYPE_ADMIN_REVIEW_REASON',
  NOTE_TYPE_APPROVED_REASON = 'NOTE_TYPE_APPROVED_REASON',
  NOTE_TYPE_DECLINE_REASON = 'NOTE_TYPE_DECLINE_REASON',
  NOTE_TYPE_DELETE_REASON = 'NOTE_TYPE_DELETE_REASON',
  NOTE_TYPE_EXPENSE = 'NOTE_TYPE_EXPENSE',
  NOTE_TYPE_GENERAL = 'NOTE_TYPE_GENERAL',
}

export type Note = {
  __typename: 'Note';
  create_date: string;
  created_by: string;
  entity_id: string;
  entity_type: EntityType;
  expense_note_types: Array<ExpenseNoteType>;
  id: string;
  message: string;
  metadata?: string | null;
  note_type: NoteType;
};

export type GetS3ArchiveInput = {
  key: string;
  vendor_id?: string | null;
};

export type FetchUserAuditLogsInput = {
  end_row: number;
  filter_model?: string | null;
  search_text?: string | null;
  sort_model?: Array<SortField> | null;
  start_row: number;
};

export type SortField = {
  descending?: boolean | null;
  field: string;
};

export type FetchUserAuditLogsResponse = {
  __typename: 'FetchUserAuditLogsResponse';
  items: Array<UserAuditLog>;
  last_row: number;
  meta_data?: UserAuditLogsMetaData | null;
};

export type UserAuditLog = {
  __typename: 'UserAuditLog';
  action?: UserAuditLogAction | null;
  action_text?: string | null;
  category?: UserAuditLogCategory | null;
  create_date: string;
  created_by: string;
  details_json?: string | null;
  entity_id?: string | null;
  entity_type?: EntityType | null;
  id: string;
  is_custom?: boolean | null;
  properties_changed_json?: string | null;
  vendor_id?: string | null;
};

export enum UserAuditLogAction {
  USER_AUDIT_LOG_ACTION_ACTUAL_PATIENT_VISITS_APPLIED = 'USER_AUDIT_LOG_ACTION_ACTUAL_PATIENT_VISITS_APPLIED',
  USER_AUDIT_LOG_ACTION_ACTUAL_PATIENT_VISITS_UPDATED = 'USER_AUDIT_LOG_ACTION_ACTUAL_PATIENT_VISITS_UPDATED',
  USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_CREATED = 'USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_CREATED',
  USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_NAME_UPDATED = 'USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_NAME_UPDATED',
  USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_PATIENT_GROUPS_UPDATED = 'USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_PATIENT_GROUPS_UPDATED',
  USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_REMOVED = 'USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_REMOVED',
  USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_UPDATED = 'USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_UPDATED',
  USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_CREATED = 'USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_CREATED',
  USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_NAME_UPDATED = 'USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_NAME_UPDATED',
  USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_REMOVED = 'USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_REMOVED',
  USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_SITE_GROUPS_UPDATED = 'USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_SITE_GROUPS_UPDATED',
  USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_UPDATED = 'USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_UPDATED',
  USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_HISTORICAL_ADJUSTMENT_CREATED = 'USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_HISTORICAL_ADJUSTMENT_CREATED',
  USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_HISTORICAL_ADJUSTMENT_UPDATED = 'USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_HISTORICAL_ADJUSTMENT_UPDATED',
  USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_CREATED = 'USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_CREATED',
  USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_DOCUMENTS_CREATED = 'USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_DOCUMENTS_CREATED',
  USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_DOCUMENTS_REMOVED = 'USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_DOCUMENTS_REMOVED',
  USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_NOTES_CREATED = 'USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_NOTES_CREATED',
  USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_NOTES_REMOVED = 'USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_NOTES_REMOVED',
  USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_UPDATED = 'USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_UPDATED',
  USER_AUDIT_LOG_ACTION_BUDGET_REMOVED = 'USER_AUDIT_LOG_ACTION_BUDGET_REMOVED',
  USER_AUDIT_LOG_ACTION_BUDGET_UPLOADED = 'USER_AUDIT_LOG_ACTION_BUDGET_UPLOADED',
  USER_AUDIT_LOG_ACTION_CHANGE_ORDERS_LOCKED = 'USER_AUDIT_LOG_ACTION_CHANGE_ORDERS_LOCKED',
  USER_AUDIT_LOG_ACTION_CHANGE_ORDERS_UNLOCKED = 'USER_AUDIT_LOG_ACTION_CHANGE_ORDERS_UNLOCKED',
  USER_AUDIT_LOG_ACTION_CHANGE_ORDER_APPROVED = 'USER_AUDIT_LOG_ACTION_CHANGE_ORDER_APPROVED',
  USER_AUDIT_LOG_ACTION_CHANGE_ORDER_CREATED = 'USER_AUDIT_LOG_ACTION_CHANGE_ORDER_CREATED',
  USER_AUDIT_LOG_ACTION_CHANGE_ORDER_DECLINED = 'USER_AUDIT_LOG_ACTION_CHANGE_ORDER_DECLINED',
  USER_AUDIT_LOG_ACTION_CHANGE_ORDER_PENDING_APPROVAL = 'USER_AUDIT_LOG_ACTION_CHANGE_ORDER_PENDING_APPROVAL',
  USER_AUDIT_LOG_ACTION_CHANGE_ORDER_REMOVED = 'USER_AUDIT_LOG_ACTION_CHANGE_ORDER_REMOVED',
  USER_AUDIT_LOG_ACTION_DISCOUNTS_LOCKED = 'USER_AUDIT_LOG_ACTION_DISCOUNTS_LOCKED',
  USER_AUDIT_LOG_ACTION_DISCOUNTS_UNLOCKED = 'USER_AUDIT_LOG_ACTION_DISCOUNTS_UNLOCKED',
  USER_AUDIT_LOG_ACTION_DRIVER_LOCKED = 'USER_AUDIT_LOG_ACTION_DRIVER_LOCKED',
  USER_AUDIT_LOG_ACTION_DRIVER_UNLOCKED = 'USER_AUDIT_LOG_ACTION_DRIVER_UNLOCKED',
  USER_AUDIT_LOG_ACTION_EXPENSE_DEFAULTS_UPDATED = 'USER_AUDIT_LOG_ACTION_EXPENSE_DEFAULTS_UPDATED',
  USER_AUDIT_LOG_ACTION_EXPENSE_SOURCE_UPDATED = 'USER_AUDIT_LOG_ACTION_EXPENSE_SOURCE_UPDATED',
  USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_LOCKED = 'USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_LOCKED',
  USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_UNLOCKED = 'USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_UNLOCKED',
  USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_UPDATED = 'USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_UPDATED',
  USER_AUDIT_LOG_ACTION_INVOICES_LOCKED = 'USER_AUDIT_LOG_ACTION_INVOICES_LOCKED',
  USER_AUDIT_LOG_ACTION_INVOICES_UNLOCKED = 'USER_AUDIT_LOG_ACTION_INVOICES_UNLOCKED',
  USER_AUDIT_LOG_ACTION_INVOICE_APPROVED = 'USER_AUDIT_LOG_ACTION_INVOICE_APPROVED',
  USER_AUDIT_LOG_ACTION_INVOICE_CREATED = 'USER_AUDIT_LOG_ACTION_INVOICE_CREATED',
  USER_AUDIT_LOG_ACTION_INVOICE_DECLINED = 'USER_AUDIT_LOG_ACTION_INVOICE_DECLINED',
  USER_AUDIT_LOG_ACTION_INVOICE_PENDING_APPROVAL = 'USER_AUDIT_LOG_ACTION_INVOICE_PENDING_APPROVAL',
  USER_AUDIT_LOG_ACTION_INVOICE_PENDING_REVIEW = 'USER_AUDIT_LOG_ACTION_INVOICE_PENDING_REVIEW',
  USER_AUDIT_LOG_ACTION_INVOICE_REMOVED = 'USER_AUDIT_LOG_ACTION_INVOICE_REMOVED',
  USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_CREATED = 'USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_CREATED',
  USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_REMOVED = 'USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_REMOVED',
  USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_UPDATED = 'USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_UPDATED',
  USER_AUDIT_LOG_ACTION_PATIENT_GROUP_ADDED = 'USER_AUDIT_LOG_ACTION_PATIENT_GROUP_ADDED',
  USER_AUDIT_LOG_ACTION_PATIENT_GROUP_REMOVED = 'USER_AUDIT_LOG_ACTION_PATIENT_GROUP_REMOVED',
  USER_AUDIT_LOG_ACTION_PATIENT_GROUP_UPDATED = 'USER_AUDIT_LOG_ACTION_PATIENT_GROUP_UPDATED',
  USER_AUDIT_LOG_ACTION_PATIENT_PROTOCOL_UPDATED = 'USER_AUDIT_LOG_ACTION_PATIENT_PROTOCOL_UPDATED',
  USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_LOCKED = 'USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_LOCKED',
  USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_UNLOCKED = 'USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_UNLOCKED',
  USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_UPDATED = 'USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_UPDATED',
  USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_ADDED = 'USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_ADDED',
  USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_REMOVED = 'USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_REMOVED',
  USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_UPDATED = 'USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_UPDATED',
  USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_ADDED = 'USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_ADDED',
  USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_DELETED = 'USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_DELETED',
  USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_UPDATED = 'USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_UPDATED',
  USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_ADDED = 'USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_ADDED',
  USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_REMOVED = 'USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_REMOVED',
  USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_UPDATED = 'USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_UPDATED',
  USER_AUDIT_LOG_ACTION_SCENARIO_BUDGET_REMOVED = 'USER_AUDIT_LOG_ACTION_SCENARIO_BUDGET_REMOVED',
  USER_AUDIT_LOG_ACTION_SCENARIO_BUDGET_UPLOAD = 'USER_AUDIT_LOG_ACTION_SCENARIO_BUDGET_UPLOAD',
  USER_AUDIT_LOG_ACTION_SITE_ADDED = 'USER_AUDIT_LOG_ACTION_SITE_ADDED',
  USER_AUDIT_LOG_ACTION_SITE_BUDGET_UPDATED = 'USER_AUDIT_LOG_ACTION_SITE_BUDGET_UPDATED',
  USER_AUDIT_LOG_ACTION_SITE_CONTRACT_SOURCE_APPLIED = 'USER_AUDIT_LOG_ACTION_SITE_CONTRACT_SOURCE_APPLIED',
  USER_AUDIT_LOG_ACTION_SITE_CONTRACT_SOURCE_UPDATED = 'USER_AUDIT_LOG_ACTION_SITE_CONTRACT_SOURCE_UPDATED',
  USER_AUDIT_LOG_ACTION_SITE_DRIVER_CREATED = 'USER_AUDIT_LOG_ACTION_SITE_DRIVER_CREATED',
  USER_AUDIT_LOG_ACTION_SITE_DRIVER_REMOVED = 'USER_AUDIT_LOG_ACTION_SITE_DRIVER_REMOVED',
  USER_AUDIT_LOG_ACTION_SITE_DRIVER_UPDATED = 'USER_AUDIT_LOG_ACTION_SITE_DRIVER_UPDATED',
  USER_AUDIT_LOG_ACTION_SITE_GROUP_ADDED = 'USER_AUDIT_LOG_ACTION_SITE_GROUP_ADDED',
  USER_AUDIT_LOG_ACTION_SITE_GROUP_REMOVED = 'USER_AUDIT_LOG_ACTION_SITE_GROUP_REMOVED',
  USER_AUDIT_LOG_ACTION_SITE_GROUP_UPDATED = 'USER_AUDIT_LOG_ACTION_SITE_GROUP_UPDATED',
  USER_AUDIT_LOG_ACTION_SITE_REMOVED = 'USER_AUDIT_LOG_ACTION_SITE_REMOVED',
  USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSED = 'USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSED',
  USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_LOCKED = 'USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_LOCKED',
  USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_UNLOCKED = 'USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_UNLOCKED',
  USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_QUARTER_CLOSED = 'USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_QUARTER_CLOSED',
  USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_LOCKED = 'USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_LOCKED',
  USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_ADDED = 'USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_ADDED',
  USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_REMOVED = 'USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_REMOVED',
  USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_UPDATED = 'USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_UPDATED',
  USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_UNLOCKED = 'USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_UNLOCKED',
  USER_AUDIT_LOG_ACTION_USER_ADDED = 'USER_AUDIT_LOG_ACTION_USER_ADDED',
  USER_AUDIT_LOG_ACTION_USER_PERMISSION_ADDED = 'USER_AUDIT_LOG_ACTION_USER_PERMISSION_ADDED',
  USER_AUDIT_LOG_ACTION_USER_PERMISSION_REMOVED = 'USER_AUDIT_LOG_ACTION_USER_PERMISSION_REMOVED',
  USER_AUDIT_LOG_ACTION_USER_REMOVED = 'USER_AUDIT_LOG_ACTION_USER_REMOVED',
  USER_AUDIT_LOG_ACTION_VENDOR_ADDED = 'USER_AUDIT_LOG_ACTION_VENDOR_ADDED',
  USER_AUDIT_LOG_ACTION_VENDOR_CONTRACT_SOURCE_UPDATED = 'USER_AUDIT_LOG_ACTION_VENDOR_CONTRACT_SOURCE_UPDATED',
  USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATES_LOCKED = 'USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATES_LOCKED',
  USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATES_UNLOCKED = 'USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATES_UNLOCKED',
  USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_ADDED = 'USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_ADDED',
  USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_REMOVED = 'USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_REMOVED',
  USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_UPDATED = 'USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_UPDATED',
  USER_AUDIT_LOG_ACTION_VENDOR_REMOVED = 'USER_AUDIT_LOG_ACTION_VENDOR_REMOVED',
}

export enum UserAuditLogCategory {
  USER_AUDIT_LOG_CATEGORY_BUDGET = 'USER_AUDIT_LOG_CATEGORY_BUDGET',
  USER_AUDIT_LOG_CATEGORY_CHANGE_ORDER = 'USER_AUDIT_LOG_CATEGORY_CHANGE_ORDER',
  USER_AUDIT_LOG_CATEGORY_DRIVER = 'USER_AUDIT_LOG_CATEGORY_DRIVER',
  USER_AUDIT_LOG_CATEGORY_EXPENSE_DEFAULTS = 'USER_AUDIT_LOG_CATEGORY_EXPENSE_DEFAULTS',
  USER_AUDIT_LOG_CATEGORY_EXPENSE_SOURCE = 'USER_AUDIT_LOG_CATEGORY_EXPENSE_SOURCE',
  USER_AUDIT_LOG_CATEGORY_FORECAST = 'USER_AUDIT_LOG_CATEGORY_FORECAST',
  USER_AUDIT_LOG_CATEGORY_HISTORICAL_ADJUSTMENT = 'USER_AUDIT_LOG_CATEGORY_HISTORICAL_ADJUSTMENT',
  USER_AUDIT_LOG_CATEGORY_INVESTIGATOR_FORECAST = 'USER_AUDIT_LOG_CATEGORY_INVESTIGATOR_FORECAST',
  USER_AUDIT_LOG_CATEGORY_INVOICE = 'USER_AUDIT_LOG_CATEGORY_INVOICE',
  USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE = 'USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE',
  USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE_DOCUMENTS = 'USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE_DOCUMENTS',
  USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE_NOTES = 'USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE_NOTES',
  USER_AUDIT_LOG_CATEGORY_PATIENT_DRIVER = 'USER_AUDIT_LOG_CATEGORY_PATIENT_DRIVER',
  USER_AUDIT_LOG_CATEGORY_PATIENT_GROUP = 'USER_AUDIT_LOG_CATEGORY_PATIENT_GROUP',
  USER_AUDIT_LOG_CATEGORY_PATIENT_TRACKER = 'USER_AUDIT_LOG_CATEGORY_PATIENT_TRACKER',
  USER_AUDIT_LOG_CATEGORY_PAYMENT_MILESTONE = 'USER_AUDIT_LOG_CATEGORY_PAYMENT_MILESTONE',
  USER_AUDIT_LOG_CATEGORY_PROTOCOL_ENTRY = 'USER_AUDIT_LOG_CATEGORY_PROTOCOL_ENTRY',
  USER_AUDIT_LOG_CATEGORY_PURCHASE_ORDER = 'USER_AUDIT_LOG_CATEGORY_PURCHASE_ORDER',
  USER_AUDIT_LOG_CATEGORY_SITE = 'USER_AUDIT_LOG_CATEGORY_SITE',
  USER_AUDIT_LOG_CATEGORY_SITE_BUDGET = 'USER_AUDIT_LOG_CATEGORY_SITE_BUDGET',
  USER_AUDIT_LOG_CATEGORY_SITE_DRIVER = 'USER_AUDIT_LOG_CATEGORY_SITE_DRIVER',
  USER_AUDIT_LOG_CATEGORY_SITE_GROUP = 'USER_AUDIT_LOG_CATEGORY_SITE_GROUP',
  USER_AUDIT_LOG_CATEGORY_TIMELINE_EVENT = 'USER_AUDIT_LOG_CATEGORY_TIMELINE_EVENT',
  USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE = 'USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE',
  USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE_MILESTONE = 'USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE_MILESTONE',
  USER_AUDIT_LOG_CATEGORY_USER = 'USER_AUDIT_LOG_CATEGORY_USER',
  USER_AUDIT_LOG_CATEGORY_USER_PERMISSIONS = 'USER_AUDIT_LOG_CATEGORY_USER_PERMISSIONS',
  USER_AUDIT_LOG_CATEGORY_VENDOR = 'USER_AUDIT_LOG_CATEGORY_VENDOR',
  USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE = 'USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE',
}

export type UserAuditLogsMetaData = {
  __typename: 'UserAuditLogsMetaData';
  total_count?: number | null;
};

export type IntegrationConnection = {
  __typename: 'IntegrationConnection';
  data_source: DataSource;
  enabled: boolean;
  is_connected: boolean;
  is_oauth: boolean;
  last_connection_date?: string | null;
  name: string;
};

export enum DataSource {
  DATA_SOURCE_AUXILIUS = 'DATA_SOURCE_AUXILIUS',
  DATA_SOURCE_BILL_COM = 'DATA_SOURCE_BILL_COM',
  DATA_SOURCE_COUPA = 'DATA_SOURCE_COUPA',
  DATA_SOURCE_DYNAMICS365 = 'DATA_SOURCE_DYNAMICS365',
  DATA_SOURCE_NETSUITE = 'DATA_SOURCE_NETSUITE',
  DATA_SOURCE_ORACLE_FUSION = 'DATA_SOURCE_ORACLE_FUSION',
  DATA_SOURCE_QUICKBOOKS_ONLINE = 'DATA_SOURCE_QUICKBOOKS_ONLINE',
  DATA_SOURCE_SAGE_INTACCT = 'DATA_SOURCE_SAGE_INTACCT',
}

export type GetOAuthLoginUrlResponse = {
  __typename: 'GetOAuthLoginUrlResponse';
  callback_origin: string;
  login_url: string;
};

export type DefaultExpenseSource = {
  __typename: 'DefaultExpenseSource';
  cost_category: CategoryType;
  organization_id: string;
  sources: Array<ExpenseSourceType>;
};

export enum CategoryType {
  CATEGORY_DISCOUNT = 'CATEGORY_DISCOUNT',
  CATEGORY_INVESTIGATOR = 'CATEGORY_INVESTIGATOR',
  CATEGORY_PASSTHROUGH = 'CATEGORY_PASSTHROUGH',
  CATEGORY_ROOT = 'CATEGORY_ROOT',
  CATEGORY_SERVICE = 'CATEGORY_SERVICE',
  CATEGORY_UNDETERMINED = 'CATEGORY_UNDETERMINED',
}

export enum ExpenseSourceType {
  EXPENSE_SOURCE_EVIDENCE_BASED = 'EXPENSE_SOURCE_EVIDENCE_BASED',
  EXPENSE_SOURCE_FORECAST = 'EXPENSE_SOURCE_FORECAST',
  EXPENSE_SOURCE_MANUAL_ADJUSTMENT = 'EXPENSE_SOURCE_MANUAL_ADJUSTMENT',
  EXPENSE_SOURCE_NONE = 'EXPENSE_SOURCE_NONE',
  EXPENSE_SOURCE_VENDOR_ESTIMATE = 'EXPENSE_SOURCE_VENDOR_ESTIMATE',
}

export type UpdateDefaultExpenseSourceInput = {
  cost_category: CategoryType;
  organization_id: string;
  sources: Array<ExpenseSourceType>;
};

export type BudgetViewInput = {
  budget_type?: BudgetType | null;
  budget_version_id?: string | null;
  cache_enabled?: boolean | null;
  in_month: boolean;
  period?: string | null;
  quarter_start_month?: string | null;
  vendor_id?: string | null;
};

export enum BudgetType {
  BUDGET_CHANGE_ORDER = 'BUDGET_CHANGE_ORDER',
  BUDGET_MODEL = 'BUDGET_MODEL',
  BUDGET_PRIMARY = 'BUDGET_PRIMARY',
  BUDGET_SECONDARY = 'BUDGET_SECONDARY',
  BUDGET_SNAPSHOT = 'BUDGET_SNAPSHOT',
  BUDGET_VENDOR_ESTIMATE = 'BUDGET_VENDOR_ESTIMATE',
}

export type BudgetView = {
  __typename: 'BudgetView';
  budget_data?: Array<BudgetData> | null;
  budget_info?: Array<BudgetInfo> | null;
  cache_info?: CacheInfo | null;
  header_data?: Array<BudgetHeader> | null;
};

export type BudgetData = {
  __typename: 'BudgetData';
  activity_id?: string | null;
  activity_name?: string | null;
  activity_name_label?: string | null;
  activity_no?: string | null;
  attributes?: Array<BudgetActivityAttributes> | null;
  contract_direct_cost?: number | null;
  contract_direct_cost_currency?: string | null;
  contract_unit_cost?: number | null;
  contract_unit_cost_currency?: string | null;
  cost_category?: string | null;
  cost_category_ordering?: number | null;
  direct_cost?: number | null;
  direct_cost_currency?: string | null;
  display_group?: string | null;
  display_label?: string | null;
  expense_note?: string | null;
  expenses?: Array<BudgetExpenseData> | null;
  group0?: string | null;
  group1?: string | null;
  group2?: string | null;
  group3?: string | null;
  group4?: string | null;
  group_index?: number | null;
  item_group?: string | null;
  item_label?: string | null;
  section_path?: string | null;
  unit_cost?: number | null;
  unit_cost_currency?: string | null;
  unit_num?: number | null;
  uom?: string | null;
  vendor_id?: string | null;
};

export type BudgetActivityAttributes = {
  __typename: 'BudgetActivityAttributes';
  attribute?: string | null;
  attribute_index?: number | null;
  attribute_name?: string | null;
  attribute_value?: string | null;
};

export type BudgetExpenseData = {
  __typename: 'BudgetExpenseData';
  activity_type?: ActivityType | null;
  adjustment_type?: AdjustmentType | null;
  amount?: number | null;
  amount_curr?: string | null;
  amount_type?: AmountType | null;
  contract_amount?: number | null;
  contract_curr?: string | null;
  expense_detail?: string | null;
  expense_source?: ExpenseSourceType | null;
  expense_type?: ExpenseType | null;
  id?: string | null;
  period?: string | null;
  previous_amount?: number | null;
  source?: string | null;
  unit_cost?: number | null;
  unit_num?: number | null;
  uom?: string | null;
  update_date?: string | null;
  updated_by?: string | null;
};

export enum ActivityType {
  ACTIVITY_DISCOUNT = 'ACTIVITY_DISCOUNT',
  ACTIVITY_INVESTIGATOR = 'ACTIVITY_INVESTIGATOR',
  ACTIVITY_INVESTIGATOR_OTHER = 'ACTIVITY_INVESTIGATOR_OTHER',
  ACTIVITY_INVESTIGATOR_OVERHEAD = 'ACTIVITY_INVESTIGATOR_OVERHEAD',
  ACTIVITY_INVESTIGATOR_PATIENT = 'ACTIVITY_INVESTIGATOR_PATIENT',
  ACTIVITY_PASSTHROUGH = 'ACTIVITY_PASSTHROUGH',
  ACTIVITY_SERVICE = 'ACTIVITY_SERVICE',
}

export enum AdjustmentType {
  ADJUSTMENT_AMOUNT = 'ADJUSTMENT_AMOUNT',
  ADJUSTMENT_UNIT = 'ADJUSTMENT_UNIT',
}

export enum AmountType {
  AMOUNT_DEFAULT = 'AMOUNT_DEFAULT',
  AMOUNT_DISCOUNT = 'AMOUNT_DISCOUNT',
  AMOUNT_INVESTIGATOR = 'AMOUNT_INVESTIGATOR',
  AMOUNT_MATCHED_PAYMENT = 'AMOUNT_MATCHED_PAYMENT',
  AMOUNT_OVERHEAD = 'AMOUNT_OVERHEAD',
  AMOUNT_OVER_PAYMENT = 'AMOUNT_OVER_PAYMENT',
  AMOUNT_PASSTHROUGH = 'AMOUNT_PASSTHROUGH',
  AMOUNT_PAYMENT = 'AMOUNT_PAYMENT',
  AMOUNT_SERVICE = 'AMOUNT_SERVICE',
  AMOUNT_TOTAL = 'AMOUNT_TOTAL',
}

export enum ExpenseType {
  EXPENSE_ACCRUAL = 'EXPENSE_ACCRUAL',
  EXPENSE_ACCRUAL_ADJUSTED = 'EXPENSE_ACCRUAL_ADJUSTED',
  EXPENSE_ACCRUAL_OVERRIDE = 'EXPENSE_ACCRUAL_OVERRIDE',
  EXPENSE_BASELINE_QUOTE = 'EXPENSE_BASELINE_QUOTE',
  EXPENSE_FORECAST = 'EXPENSE_FORECAST',
  EXPENSE_FORECAST_AT_CLOSE = 'EXPENSE_FORECAST_AT_CLOSE',
  EXPENSE_HISTORICAL_ADJUSTMENT = 'EXPENSE_HISTORICAL_ADJUSTMENT',
  EXPENSE_OVERRIDE = 'EXPENSE_OVERRIDE',
  EXPENSE_QUOTE = 'EXPENSE_QUOTE',
  EXPENSE_VENDOR_ESTIMATE = 'EXPENSE_VENDOR_ESTIMATE',
  EXPENSE_WP = 'EXPENSE_WP',
}

export type BudgetInfo = {
  __typename: 'BudgetInfo';
  base_budget_version?: string | null;
  budget_type?: BudgetType | null;
  budget_version?: string | null;
  budget_version_id?: string | null;
  current_month?: string | null;
  description?: string | null;
  discount_type?: DiscountType | null;
  eom_month?: string | null;
  expense_amounts: Array<ExpenseAmount>;
  major_version?: string | null;
  manual_forecast?: boolean | null;
  minor_version?: string | null;
  name?: string | null;
  source?: string | null;
  vendor_currency?: string | null;
  vendor_id?: string | null;
  vendor_name_budget?: string | null;
};

export enum DiscountType {
  DISCOUNT_MANUAL = 'DISCOUNT_MANUAL',
  DISCOUNT_PERCENTAGE = 'DISCOUNT_PERCENTAGE',
  DISCOUNT_TOTAL = 'DISCOUNT_TOTAL',
}

export type ExpenseAmount = {
  __typename: 'ExpenseAmount';
  amount?: number | null;
  amount_curr: string;
  amount_perct?: number | null;
  amount_type: string;
  contract_amount?: number | null;
  contract_curr?: string | null;
  details?: string | null;
  entity_id: string;
  entity_type_id: string;
  exchange_rate?: MonthlyExchangeRate | null;
  expense_description?: string | null;
  fnc_amount?: number | null;
  fnc_amount_curr?: string | null;
  id?: string | null;
  tax_amount?: number | null;
  tax_rate?: number | null;
  units?: number | null;
};

export type MonthlyExchangeRate = {
  __typename: 'MonthlyExchangeRate';
  base_currency: Currency;
  month: string;
  rate: number;
  target_currency: Currency;
};

export enum Currency {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTC = 'BTC',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLF = 'CLF',
  CLP = 'CLP',
  CNH = 'CNH',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STD = 'STD',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export type CacheInfo = {
  __typename: 'CacheInfo';
  cache_date: string;
  cache_exists: boolean;
  cache_file: string;
  content_type: string;
  entity_type: string;
  id: string;
  record_count?: number | null;
  signed_url?: string | null;
  ttl?: number | null;
};

export type BudgetHeader = {
  __typename: 'BudgetHeader';
  date_headers: Array<string>;
  expense_type?: string | null;
  group_name?: string | null;
};

export type Approval = {
  __typename: 'Approval';
  activity_details?: string | null;
  approval_time?: string | null;
  approver_name?: string | null;
  aux_user_id?: string | null;
  category?: string | null;
  permission?: string | null;
  vendor_id?: string | null;
};

export type BudgetVersion = {
  __typename: 'BudgetVersion';
  bucket_key?: string | null;
  budget_id: string;
  budget_name: string;
  budget_snapshot_id?: string | null;
  budget_type: string;
  budget_version_id: string;
  create_date: string;
  created_by?: string | null;
  current_month?: string | null;
  discount_type?: DiscountType | null;
  entity_id: string;
  entity_status?: ChangeOrderStatus | null;
  entity_type: EntityType;
  expense_amounts: Array<ExpenseAmount>;
  has_docs?: boolean | null;
  is_baseline?: boolean | null;
  is_change_order?: boolean | null;
  is_current?: boolean | null;
  manual_forecast: boolean;
  snapshot_type?: BudgetSnapshotType | null;
  target_month?: string | null;
  total_budget_amount?: number | null;
  vendor_id?: string | null;
  vendor_name?: string | null;
  version: string;
};

export enum ChangeOrderStatus {
  STATUS_APPROVED = 'STATUS_APPROVED',
  STATUS_DECLINED = 'STATUS_DECLINED',
  STATUS_PENDING_APPROVAL = 'STATUS_PENDING_APPROVAL',
  STATUS_PENDING_REVIEW = 'STATUS_PENDING_REVIEW',
}

export enum BudgetSnapshotType {
  BUDGET_SNAPSHOT_CHANGE_ORDER_MERGED = 'BUDGET_SNAPSHOT_CHANGE_ORDER_MERGED',
  BUDGET_SNAPSHOT_MONTH_CLOSE = 'BUDGET_SNAPSHOT_MONTH_CLOSE',
  BUDGET_SNAPSHOT_USER_CREATED = 'BUDGET_SNAPSHOT_USER_CREATED',
}

export type TrialInformation = {
  __typename: 'TrialInformation';
  budget_version_id: string;
  trial_end_date: string;
  trial_month_close: string;
  trial_start_date: string;
  vendor_id: string;
  vendor_month_close: string;
};

export type UpdateBudgetVersionInput = {
  discount_amount?: number | null;
  discount_amount_perct?: number | null;
  discount_type?: DiscountType | null;
  id: string;
};

export type EventInput = {
  bucket_key?: string | null;
  entity_id: string;
  entity_type: EntityType;
  payload?: string | null;
  suppress_transaction_id?: boolean | null;
  tracking_id?: string | null;
  transaction_id?: string | null;
  triggered_by?: EventInput | null;
  type: EventType;
};

export enum EventType {
  ANALYTICS = 'ANALYTICS',
  ANALYTICS_CARD_UPDATED = 'ANALYTICS_CARD_UPDATED',
  APPSYNC = 'APPSYNC',
  BALANCE_IN_ESCROW_UPDATED = 'BALANCE_IN_ESCROW_UPDATED',
  BLENDED_PATIENT_CURVE_UPDATED = 'BLENDED_PATIENT_CURVE_UPDATED',
  BLENDED_SITE_CURVE_UPDATED = 'BLENDED_SITE_CURVE_UPDATED',
  BUDGET_CACHE_INVALIDATED = 'BUDGET_CACHE_INVALIDATED',
  BUDGET_FORECAST_SETTINGS_UPDATED = 'BUDGET_FORECAST_SETTINGS_UPDATED',
  BUDGET_MERGED = 'BUDGET_MERGED',
  BUDGET_MONTH_CLOSED = 'BUDGET_MONTH_CLOSED',
  BUDGET_SNAPSHOT_NOTIFICATION = 'BUDGET_SNAPSHOT_NOTIFICATION',
  BUDGET_TEMPLATE_UPLOADED = 'BUDGET_TEMPLATE_UPLOADED',
  BULK_INVOICE_TEMPLATE_UPLOADED = 'BULK_INVOICE_TEMPLATE_UPLOADED',
  BULK_SITE_TEMPLATE_UPLOADED = 'BULK_SITE_TEMPLATE_UPLOADED',
  CASH_REQUIREMENTS_UPDATED = 'CASH_REQUIREMENTS_UPDATED',
  CATEGORIZE_INVOICES_REMINDER = 'CATEGORIZE_INVOICES_REMINDER',
  CHANGE_LOG_UPDATED = 'CHANGE_LOG_UPDATED',
  CHANGE_ORDER_APPROVED = 'CHANGE_ORDER_APPROVED',
  CHANGE_ORDER_BACK_TO_PENDING_REVIEW = 'CHANGE_ORDER_BACK_TO_PENDING_REVIEW',
  CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED = 'CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED',
  CHANGE_ORDER_DECLINED = 'CHANGE_ORDER_DECLINED',
  CHANGE_ORDER_DELETED = 'CHANGE_ORDER_DELETED',
  CHANGE_ORDER_PENDING_APPROVAL = 'CHANGE_ORDER_PENDING_APPROVAL',
  CHANGE_ORDER_PENDING_REVIEW = 'CHANGE_ORDER_PENDING_REVIEW',
  CLOSE_TRIAL_MONTH = 'CLOSE_TRIAL_MONTH',
  CREATE_BUDGET_SNAPSHOT = 'CREATE_BUDGET_SNAPSHOT',
  CREATE_CLOSE_TRIAL_MONTH_REPORTS = 'CREATE_CLOSE_TRIAL_MONTH_REPORTS',
  CREATE_MONTH_CLOSE_LOCK = 'CREATE_MONTH_CLOSE_LOCK',
  CREATE_TRIAL_BUDGET_SNAPSHOT = 'CREATE_TRIAL_BUDGET_SNAPSHOT',
  DOCUMENT_LIBRARY_MIGRATION = 'DOCUMENT_LIBRARY_MIGRATION',
  DOCUMENT_UPLOADED_NOTIFICATION = 'DOCUMENT_UPLOADED_NOTIFICATION',
  DOWNLOAD_EXCHANGE_RATES = 'DOWNLOAD_EXCHANGE_RATES',
  ENVIRONMENT_BUILT = 'ENVIRONMENT_BUILT',
  ERROR = 'ERROR',
  GENERATE_EXPORT = 'GENERATE_EXPORT',
  INVOICE_CREATED = 'INVOICE_CREATED',
  INVOICE_DOCUMENT_UPLOADED = 'INVOICE_DOCUMENT_UPLOADED',
  INVOICE_PENDING_APPROVAL = 'INVOICE_PENDING_APPROVAL',
  INVOICE_PENDING_REVIEW = 'INVOICE_PENDING_REVIEW',
  INVOICE_TEMPLATE_UPLOADED = 'INVOICE_TEMPLATE_UPLOADED',
  INVOICE_UPDATED = 'INVOICE_UPDATED',
  INVOICE_UPLOADED = 'INVOICE_UPLOADED',
  MONTH_AVAILABLE_TO_CLOSE = 'MONTH_AVAILABLE_TO_CLOSE',
  NETSUITE_CLOSE_MONTH_JOURNAL_ENTRY = 'NETSUITE_CLOSE_MONTH_JOURNAL_ENTRY',
  NEW_TASK = 'NEW_TASK',
  NUMBER_OF_PENDING_APPROVAL_COS_UPDATED = 'NUMBER_OF_PENDING_APPROVAL_COS_UPDATED',
  PATIENT_BUDGET_TEMPLATE_UPLOADED = 'PATIENT_BUDGET_TEMPLATE_UPLOADED',
  PATIENT_DRIVER_DISTRIBUTION_UPDATED = 'PATIENT_DRIVER_DISTRIBUTION_UPDATED',
  PATIENT_DRIVER_TEMPLATE_UPLOADED = 'PATIENT_DRIVER_TEMPLATE_UPLOADED',
  PAYMENT_MILESTONE_UPDATED = 'PAYMENT_MILESTONE_UPDATED',
  PO_REPORT_UPLOADED = 'PO_REPORT_UPLOADED',
  QUARTER_CLOSED = 'QUARTER_CLOSED',
  REFRESH_BILL_COM = 'REFRESH_BILL_COM',
  REFRESH_BUDGET = 'REFRESH_BUDGET',
  REFRESH_COUPA = 'REFRESH_COUPA',
  REFRESH_DYNAMICS365 = 'REFRESH_DYNAMICS365',
  REFRESH_NETSUITE = 'REFRESH_NETSUITE',
  REFRESH_ORACLE_FUSION = 'REFRESH_ORACLE_FUSION',
  REFRESH_PATIENT_BUDGET = 'REFRESH_PATIENT_BUDGET',
  REFRESH_PORTFOLIO_DASHBOARD = 'REFRESH_PORTFOLIO_DASHBOARD',
  REFRESH_QUICKBOOKS_ONLINE = 'REFRESH_QUICKBOOKS_ONLINE',
  REFRESH_SAGE_INTACCT = 'REFRESH_SAGE_INTACCT',
  REFRESH_SITES = 'REFRESH_SITES',
  REFRESH_USER_AUDIT_LOG = 'REFRESH_USER_AUDIT_LOG',
  REQUEST_JOURNAL_ENTRY_REPORT_NOTIFICATION = 'REQUEST_JOURNAL_ENTRY_REPORT_NOTIFICATION',
  SITE_CONTRACT_UPLOADED = 'SITE_CONTRACT_UPLOADED',
  SITE_DRIVER_DISTRIBUTION_UPDATED = 'SITE_DRIVER_DISTRIBUTION_UPDATED',
  SITE_DRIVER_TEMPLATE_UPLOADED = 'SITE_DRIVER_TEMPLATE_UPLOADED',
  SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED = 'SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED',
  SITE_PAYMENT_SCHEDULE_TEMPLATE_UPLOADED = 'SITE_PAYMENT_SCHEDULE_TEMPLATE_UPLOADED',
  SPECIFICATIONS_IMAGE_UPLOADED = 'SPECIFICATIONS_IMAGE_UPLOADED',
  SPECIFICATIONS_TEMPLATE_UPLOADED = 'SPECIFICATIONS_TEMPLATE_UPLOADED',
  SPECIFICATIONS_UPDATED = 'SPECIFICATIONS_UPDATED',
  STORED_DOCUMENT_UPLOADED = 'STORED_DOCUMENT_UPLOADED',
  SYNC_USERS_TO_HUBSPOT = 'SYNC_USERS_TO_HUBSPOT',
  TIMELINE_UPDATED = 'TIMELINE_UPDATED',
  TRIAL_CHANGED = 'TRIAL_CHANGED',
  TRIAL_MONTH_CLOSED = 'TRIAL_MONTH_CLOSED',
  UPDATE_INVESTIGATOR_SCHEDULES = 'UPDATE_INVESTIGATOR_SCHEDULES',
  UPDATE_MONTH_ACCRUALS = 'UPDATE_MONTH_ACCRUALS',
  UPDATE_QUARTER_ACCRUALS = 'UPDATE_QUARTER_ACCRUALS',
  UPLOAD_INVOICE_REMINDER = 'UPLOAD_INVOICE_REMINDER',
  UPLOAD_VENDOR_ESTIMATE_REMINDER = 'UPLOAD_VENDOR_ESTIMATE_REMINDER',
  USER_ADDED_TO_TRIAL = 'USER_ADDED_TO_TRIAL',
  USER_AUDIT_LOG = 'USER_AUDIT_LOG',
  USER_AUDIT_LOG_UPDATED = 'USER_AUDIT_LOG_UPDATED',
  USER_PASSWORD_UPDATED = 'USER_PASSWORD_UPDATED',
  VENDOR_DOCUMENT_UPLOADED_NOTIFICATION = 'VENDOR_DOCUMENT_UPLOADED_NOTIFICATION',
  VENDOR_ESTIMATE_SUPPORTING_DOCUMENT_UPLOADED = 'VENDOR_ESTIMATE_SUPPORTING_DOCUMENT_UPLOADED',
  VENDOR_ESTIMATE_TEMPLATE_UPLOADED = 'VENDOR_ESTIMATE_TEMPLATE_UPLOADED',
}

export type BudgetExpenseInput = {
  activity_id?: string | null;
  adjustment_type?: AdjustmentType | null;
  amount?: number | null;
  amount_curr?: string | null;
  amount_type?: AmountType | null;
  budget_version_id?: string | null;
  details?: string | null;
  expense_detail?: string | null;
  expense_source?: ExpenseSourceType | null;
  expense_type_id?: string | null;
  id?: string | null;
  period_end?: string | null;
  period_start?: string | null;
  source?: string | null;
};

export type BudgetExpense = {
  __typename: 'BudgetExpense';
  activity_id?: string | null;
  category_id?: string | null;
  details?: string | null;
  expense_amounts: Array<ExpenseAmount>;
  expense_detail?: string | null;
  expense_source?: ExpenseSourceType | null;
  expense_type_id?: string | null;
  id: string;
  notes: Array<Note>;
  period_end?: string | null;
  period_start?: string | null;
  source?: string | null;
};

export type RemoveVendorEstimateInput = {
  target_month: string;
  vendor_id: string;
};

export type UpdateAccrualsInput = {
  activity_ids: Array<string>;
  activity_types: Array<ActivityType>;
  organization_id: string;
  period: string;
};

export type ListActivityVariancesInput = {
  budget_snapshot_id: string;
  organization_id?: string | null;
  period_end?: string | null;
  period_start?: string | null;
};

export type ActivityVariance = {
  __typename: 'ActivityVariance';
  activity_id?: string | null;
  activity_name?: string | null;
  activity_type?: ActivityType | null;
  variance?: number | null;
  variance_percentage?: number | null;
  variance_type: VarianceType;
  variance_unit_of_measure?: string | null;
};

export enum VarianceType {
  VARIANCE_ACTIVITY_AMOUNT_PERCENTAGE = 'VARIANCE_ACTIVITY_AMOUNT_PERCENTAGE',
  VARIANCE_ACTIVITY_AMOUNT_TOTAL = 'VARIANCE_ACTIVITY_AMOUNT_TOTAL',
  VARIANCE_ACTIVITY_UNITS = 'VARIANCE_ACTIVITY_UNITS',
  VARIANCE_ACTIVITY_UNIT_COST = 'VARIANCE_ACTIVITY_UNIT_COST',
  VARIANCE_FORECAST = 'VARIANCE_FORECAST',
}

export type GetRemainingSpendInput = {
  entity_type: EntityType;
  period_type: PeriodType;
  start_date: string;
};

export enum PeriodType {
  HALF = 'HALF',
  MONTH = 'MONTH',
  PERIOD_MONTH = 'PERIOD_MONTH',
  PERIOD_QUARTER = 'PERIOD_QUARTER',
  PERIOD_YEAR = 'PERIOD_YEAR',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export type GroupedRemainingSpend = {
  __typename: 'GroupedRemainingSpend';
  entity_id?: string | null;
  entity_name: string;
  entity_type: EntityType;
  remaining_spend: RemainingSpend;
  remaining_spend_time_series: Array<RemainingSpend>;
};

export type RemainingSpend = {
  __typename: 'RemainingSpend';
  amount: number;
  percentage?: number | null;
  period: string;
};

export type ChangeLog = {
  __typename: 'ChangeLog';
  change_log_status?: string | null;
  change_order_id?: string | null;
  id: string;
  log_no: number;
  trial_id: string;
  vendor_id: string;
};

export type ChangeLogItem = {
  __typename: 'ChangeLogItem';
  activity_id?: string | null;
  cause?: string | null;
  change_log_id: string;
  change_log_item_status: ChangeLogItemStatus;
  change_order_reference?: string | null;
  cnf_no: number;
  cost_driver_description?: string | null;
  description?: string | null;
  expense_amounts: Array<ExpenseAmount>;
  id: string;
  is_planned?: boolean | null;
  organization: Organization;
  request_date?: string | null;
  requester?: RequesterType | null;
  start_date?: string | null;
};

export enum ChangeLogItemStatus {
  STATUS_APPROVED = 'STATUS_APPROVED',
  STATUS_DECLINED = 'STATUS_DECLINED',
  STATUS_NO_STATUS = 'STATUS_NO_STATUS',
  STATUS_ON_HOLD = 'STATUS_ON_HOLD',
  STATUS_PENDING_APPROVAL = 'STATUS_PENDING_APPROVAL',
  STATUS_PENDING_REVIEW = 'STATUS_PENDING_REVIEW',
}

export type Organization = {
  __typename: 'Organization';
  baseline_budget_version?: BudgetVersion | null;
  contacts: Array<Contact>;
  costs_included_in_parent_wo?: boolean | null;
  currency: Currency;
  current_budget_versions: Array<BudgetVersion>;
  has_closed_months?: boolean | null;
  id: string;
  name: string;
  organization_type: OrganizationType;
  parent_organization?: Organization | null;
};

export type Contact = {
  __typename: 'Contact';
  contact_type: ContactType;
  email?: string | null;
  entity_type: EntityType;
  family_name: string;
  given_name: string;
  id: string;
  phone_number?: string | null;
  title?: string | null;
};

export enum ContactType {
  CONTACT_INVESTIGATOR = 'CONTACT_INVESTIGATOR',
  CONTACT_SPONSOR = 'CONTACT_SPONSOR',
  CONTACT_VENDOR = 'CONTACT_VENDOR',
}

export enum OrganizationType {
  ORGANIZATION_SPONSOR = 'ORGANIZATION_SPONSOR',
  ORGANIZATION_VENDOR = 'ORGANIZATION_VENDOR',
}

export enum RequesterType {
  REQUESTER_JOINT = 'REQUESTER_JOINT',
  REQUESTER_SPONSOR = 'REQUESTER_SPONSOR',
  REQUESTER_UNKNOWN = 'REQUESTER_UNKNOWN',
  REQUESTER_VENDOR = 'REQUESTER_VENDOR',
}

export type ChangeLogItemInput = {
  activity_id?: string | null;
  cause?: string | null;
  change_log_id: string;
  change_log_item_id?: string | null;
  change_log_item_status?: ChangeLogItemStatus | null;
  change_order_reference?: string | null;
  cnf_no?: number | null;
  cost_driver?: string | null;
  cost_driver_description?: string | null;
  description?: string | null;
  investigator_fee?: number | null;
  is_planned?: boolean | null;
  organization_id: string;
  passthrough_fee?: number | null;
  request_date?: string | null;
  requester?: RequesterType | null;
  service_fee?: number | null;
  start_date?: string | null;
  verification_status?: string | null;
};

export type ChangeOrder = {
  __typename: 'ChangeOrder';
  admin_review_reason?: string | null;
  approvals?: Array<Approval | null> | null;
  approved_budget_version?: BudgetVersion | null;
  budget_total?: GenericVariance | null;
  change_order_no: string;
  change_order_status: ChangeOrderStatus;
  create_date: string;
  created_by: string;
  date_received: string;
  decline_reason?: string | null;
  delete_reason?: string | null;
  effective_date: string;
  id: string;
  merged_budget_version?: BudgetVersion | null;
  notes?: string | null;
  organization: Organization;
  reasons: Array<Note>;
  workflow_details: Array<WorkflowDetail>;
};

export type GenericVariance = {
  __typename: 'GenericVariance';
  prev_value?: number | null;
  value?: number | null;
  variance?: number | null;
  variance_percentage?: number | null;
};

export type WorkflowDetail = {
  __typename: 'WorkflowDetail';
  id: string;
  name: string;
  order: number;
  properties: string;
  step_type: WorkflowStep;
  type: WorkflowDetailType;
  update_date: string;
  updated_by: string;
};

export enum WorkflowStep {
  WF_STEP_CHANGE_ORDER_UPLOAD_VERIFICATION_STATS = 'WF_STEP_CHANGE_ORDER_UPLOAD_VERIFICATION_STATS',
  WF_STEP_INV_UPLOAD_CHECKING_INPUTS = 'WF_STEP_INV_UPLOAD_CHECKING_INPUTS',
  WF_STEP_INV_UPLOAD_CONFIRMING_EVENTS = 'WF_STEP_INV_UPLOAD_CONFIRMING_EVENTS',
  WF_STEP_INV_UPLOAD_FINANCIAL_HEALTH_CHECKS = 'WF_STEP_INV_UPLOAD_FINANCIAL_HEALTH_CHECKS',
  WF_STEP_INV_UPLOAD_VALIDATION_CHECKS = 'WF_STEP_INV_UPLOAD_VALIDATION_CHECKS',
  WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS = 'WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS',
  WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS = 'WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS',
  WF_STEP_MONTH_CLOSE_LOCK_CURVES = 'WF_STEP_MONTH_CLOSE_LOCK_CURVES',
  WF_STEP_MONTH_CLOSE_LOCK_DISCOUNTS = 'WF_STEP_MONTH_CLOSE_LOCK_DISCOUNTS',
  WF_STEP_MONTH_CLOSE_LOCK_FORECAST_METHODOLOGY = 'WF_STEP_MONTH_CLOSE_LOCK_FORECAST_METHODOLOGY',
  WF_STEP_MONTH_CLOSE_LOCK_INVOICES = 'WF_STEP_MONTH_CLOSE_LOCK_INVOICES',
  WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER = 'WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER',
  WF_STEP_MONTH_CLOSE_LOCK_TIMELINE = 'WF_STEP_MONTH_CLOSE_LOCK_TIMELINE',
  WF_STEP_MONTH_CLOSE_LOCK_VENDOR_ESTIMATES = 'WF_STEP_MONTH_CLOSE_LOCK_VENDOR_ESTIMATES',
}

export enum WorkflowDetailType {
  WF_CHANGE_ORDER_UPLOAD = 'WF_CHANGE_ORDER_UPLOAD',
  WF_INV_UPLOAD = 'WF_INV_UPLOAD',
  WF_MONTH_CLOSE_LOCK = 'WF_MONTH_CLOSE_LOCK',
}

export type CreateChangeOrderInput = {
  approved_budget_version?: string | null;
  change_order_no: string;
  date_received: string;
  effective_date: string;
  notes?: string | null;
  organization_id: string;
};

export type UpdateChangeOrderInput = {
  admin_review_reason?: string | null;
  approved_budget_version?: string | null;
  budget_id?: string | null;
  change_order_no?: string | null;
  change_order_status?: ChangeOrderStatus | null;
  date_received?: string | null;
  decline_reason?: string | null;
  effective_date?: string | null;
  id: string;
  notes?: string | null;
  organization_id: string;
};

export type RemoveChangeOrderInput = {
  id: string;
};

export type ApproveChangeOrderInput = {
  id: string;
  organization_id: string;
};

export type ListMonthlyExchangeRatesResponse = {
  __typename: 'ListMonthlyExchangeRatesResponse';
  currencies: Array<Currency>;
  exchange_rates: Array<MonthlyExchangeRate>;
  months: Array<string>;
  trial_currency: Currency;
  update_date?: string | null;
};

export type CreateDataStreamResponse = {
  __typename: 'CreateDataStreamResponse';
  bucket_key?: string | null;
  stream_id: string;
  total_rows: number;
};

export type FetchDataStreamInput = {
  id: string;
  limit?: number | null;
  offset?: number | null;
};

export type FetchDocumentsDataStreamResponse = {
  __typename: 'FetchDocumentsDataStreamResponse';
  aggregation: Document;
  items: Array<Document>;
  offset: number;
  total_rows: number;
};

export type Document = {
  __typename: 'Document';
  bucket_key: string;
  create_date: string;
  created_by: string;
  description?: string | null;
  document_type_id?: DocumentType | null;
  entity_id?: string | null;
  entity_type_id?: string | null;
  id: string;
  is_metadata_editable?: boolean | null;
  name: string;
  site_id?: string | null;
  target_date?: string | null;
  trial_id: string;
  vendor_id?: string | null;
};

export enum DocumentType {
  DOCUMENT_ACCRUAL_SUMMARY = 'DOCUMENT_ACCRUAL_SUMMARY',
  DOCUMENT_ANNOTATED_CRF = 'DOCUMENT_ANNOTATED_CRF',
  DOCUMENT_AUDIT_EVIDENCE = 'DOCUMENT_AUDIT_EVIDENCE',
  DOCUMENT_CHANGE_ORDER = 'DOCUMENT_CHANGE_ORDER',
  DOCUMENT_CHANGE_ORDER_SUPPORT = 'DOCUMENT_CHANGE_ORDER_SUPPORT',
  DOCUMENT_CNF_LOG_ITEM = 'DOCUMENT_CNF_LOG_ITEM',
  DOCUMENT_CUSTOM_REPORT = 'DOCUMENT_CUSTOM_REPORT',
  DOCUMENT_INVOICE = 'DOCUMENT_INVOICE',
  DOCUMENT_INVOICE_BULK = 'DOCUMENT_INVOICE_BULK',
  DOCUMENT_INVOICE_SUPPORT = 'DOCUMENT_INVOICE_SUPPORT',
  DOCUMENT_JOURNAL_ENTRY_REPORT = 'DOCUMENT_JOURNAL_ENTRY_REPORT',
  DOCUMENT_MANUAL_ADJUSTMENT = 'DOCUMENT_MANUAL_ADJUSTMENT',
  DOCUMENT_MONTH_CLOSE_REPORT = 'DOCUMENT_MONTH_CLOSE_REPORT',
  DOCUMENT_MSA = 'DOCUMENT_MSA',
  DOCUMENT_OTHER = 'DOCUMENT_OTHER',
  DOCUMENT_PATIENT_DATA = 'DOCUMENT_PATIENT_DATA',
  DOCUMENT_PATIENT_DISTRIBUTION = 'DOCUMENT_PATIENT_DISTRIBUTION',
  DOCUMENT_PERIOD_CLOSE = 'DOCUMENT_PERIOD_CLOSE',
  DOCUMENT_PO_REPORT = 'DOCUMENT_PO_REPORT',
  DOCUMENT_PROPOSAL = 'DOCUMENT_PROPOSAL',
  DOCUMENT_PURCHASE_ORDER = 'DOCUMENT_PURCHASE_ORDER',
  DOCUMENT_RFP = 'DOCUMENT_RFP',
  DOCUMENT_SITE_AGREEMENTS = 'DOCUMENT_SITE_AGREEMENTS',
  DOCUMENT_SITE_BUDGET = 'DOCUMENT_SITE_BUDGET',
  DOCUMENT_SITE_BULK = 'DOCUMENT_SITE_BULK',
  DOCUMENT_SITE_DISTRIBUTION = 'DOCUMENT_SITE_DISTRIBUTION',
  DOCUMENT_SPECIFICATION = 'DOCUMENT_SPECIFICATION',
  DOCUMENT_TRIAL_PROTOCOL = 'DOCUMENT_TRIAL_PROTOCOL',
  DOCUMENT_VENDOR_BUDGET = 'DOCUMENT_VENDOR_BUDGET',
  DOCUMENT_VENDOR_CONTRACT = 'DOCUMENT_VENDOR_CONTRACT',
  DOCUMENT_VENDOR_ESTIMATE = 'DOCUMENT_VENDOR_ESTIMATE',
  DOCUMENT_VENDOR_ESTIMATE_SUPPORT = 'DOCUMENT_VENDOR_ESTIMATE_SUPPORT',
  DOCUMENT_WORK_ORDER = 'DOCUMENT_WORK_ORDER',
}

export type FetchUserAuditLogsDataStreamResponse = {
  __typename: 'FetchUserAuditLogsDataStreamResponse';
  aggregation: UserAuditLog;
  items: Array<UserAuditLog>;
  offset: number;
  total_rows: number;
};

export type FetchDocumentsInput = {
  end_row: number;
  filter_model?: string | null;
  search_text?: string | null;
  sort_model?: Array<SortField> | null;
  start_row: number;
};

export type FetchDocumentsResponse = {
  __typename: 'FetchDocumentsResponse';
  items: Array<Document>;
  last_row: number;
  meta_data?: DocumentsMetaData | null;
};

export type DocumentsMetaData = {
  __typename: 'DocumentsMetaData';
  total_count?: number | null;
};

export type CreateDocumentInput = {
  bucket_key: string;
  description?: string | null;
  document_type_id?: DocumentType | null;
  entity_id?: string | null;
  entity_type_id?: string | null;
  is_metadata_editable?: boolean | null;
  name: string;
  site_id?: string | null;
  target_date?: string | null;
  vendor_id?: string | null;
};

export type RemoveDocumentInput = {
  id: string;
};

export type UpdateDocumentInput = {
  description?: string | null;
  document_type_id?: DocumentType | null;
  id: string;
  site_id?: string | null;
  vendor_id?: string | null;
};

export type StoredDocumentInput = {
  bucket?: string | null;
  bucket_key?: string | null;
  doclib_key?: string | null;
  document_type_id?: DocumentType | null;
  entity_id?: string | null;
  entity_type_id?: string | null;
  id?: string | null;
  process_status?: string | null;
  processed_documents?: string | null;
  site_id?: string | null;
  trial_id?: string | null;
  vendor_id?: string | null;
};

export type StoredDocument = {
  __typename: 'StoredDocument';
  bucket?: string | null;
  bucket_key: string;
  create_date: string;
  created_by: string;
  description?: string | null;
  document_type_id?: DocumentType | null;
  entity_id?: string | null;
  entity_type_id?: string | null;
  file_name?: string | null;
  id: string;
  name?: string | null;
  process_date?: string | null;
  process_status: string;
  processed_documents?: string | null;
  site_id?: string | null;
  trial_id: string;
  update_date: string;
  vendor_id?: string | null;
};

export type DriverPatientDistribution = {
  __typename: 'DriverPatientDistribution';
  actual?: PatientEnrollmentData | null;
  distribution_month: string;
  forecast: PatientEnrollmentData;
};

export type PatientEnrollmentData = {
  __typename: 'PatientEnrollmentData';
  cumulative_enrollment_percentage?: number | null;
  distribution_mode: DistributionMode;
  id?: string | null;
  net_patients_enrolled?: number | null;
  patients_complete?: number | null;
  patients_discontinued?: number | null;
  patients_enrolled?: number | null;
  total_patients_enrolled?: number | null;
};

export enum DistributionMode {
  DISTRIBUTION_MODE_ACTUAL = 'DISTRIBUTION_MODE_ACTUAL',
  DISTRIBUTION_MODE_FORECAST = 'DISTRIBUTION_MODE_FORECAST',
}

export type DriverPatientGroup = {
  __typename: 'DriverPatientGroup';
  constituent_patient_groups: Array<PatientGroup>;
  curve_type: CurveType;
  driver_setting_id: string;
  is_blended: boolean;
  name: string;
  organizations_with_forecasts: Array<Organization>;
  patient_group_id?: string | null;
};

export type PatientGroup = {
  __typename: 'PatientGroup';
  description?: string | null;
  id: string;
  name: string;
  rank_order?: number | null;
  type: PatientGroupType;
};

export enum PatientGroupType {
  PATIENT_GROUP_BLENDED = 'PATIENT_GROUP_BLENDED',
  PATIENT_GROUP_FORECAST_ONLY = 'PATIENT_GROUP_FORECAST_ONLY',
  PATIENT_GROUP_STANDARD = 'PATIENT_GROUP_STANDARD',
}

export enum CurveType {
  ACTIVATION = 'ACTIVATION',
  CLOSE_OUT = 'CLOSE_OUT',
  NET = 'NET',
}

export type UpdateDriverBlendedPatientDistributionInput = {
  blended_group_id: string;
  name: string;
  patient_group_ids: Array<string>;
};

export type CreateDriverBlendedPatientDistributionInput = {
  name: string;
  patient_group_ids: Array<string>;
};

export type RemainingInvestigatorInfo = {
  __typename: 'RemainingInvestigatorInfo';
  amount_remaining?: number | null;
  expected_patients_enrolled: number;
  patients_enrolled: number;
};

export type CreateDriverSiteSettingInput = {
  cohort_name: string;
  discontinued_date: string;
  first_initiated_date: string;
  number_of_sites: number;
  ramp_time_in_weeks: number;
  target_patient_count: number;
};

export type DriverSiteSetting = {
  __typename: 'DriverSiteSetting';
  cohort_name: string;
  discontinued_date: string;
  driver_setting: DriverSetting;
  driver_setting_id: string;
  first_initiated_date: string;
  id: string;
  number_of_sites: number;
  ramp_time_in_weeks: number;
  target_patient_count: number;
};

export type DriverSetting = {
  __typename: 'DriverSetting';
  driver_type: DriverType;
  id: string;
  patient_group_id?: string | null;
  site_group_id?: string | null;
};

export enum DriverType {
  DRIVER_DISCOUNT_BLENDED = 'DRIVER_DISCOUNT_BLENDED',
  DRIVER_PASSTHROUGH_BLENDED = 'DRIVER_PASSTHROUGH_BLENDED',
  DRIVER_PATIENT = 'DRIVER_PATIENT',
  DRIVER_SERVICES_BLENDED = 'DRIVER_SERVICES_BLENDED',
  DRIVER_SITE = 'DRIVER_SITE',
  DRIVER_TIME = 'DRIVER_TIME',
}

export type UpdateDriverSiteSettingInput = {
  cohort_name?: string | null;
  discontinued_date?: string | null;
  first_initiated_date?: string | null;
  id: string;
  number_of_sites?: number | null;
  ramp_time_in_weeks?: number | null;
  target_patient_count?: number | null;
};

export type DriverSiteDistribution = {
  __typename: 'DriverSiteDistribution';
  distribution_mode: DistributionMode;
  distribution_month: string;
  id: string;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
  sites_activated?: number | null;
  sites_activated_percentage?: number | null;
  sites_closed?: number | null;
  sites_closed_percentage?: number | null;
};

export type DriverSiteGroup = {
  __typename: 'DriverSiteGroup';
  constituent_site_groups: Array<SiteGroup>;
  curve_type: CurveType;
  driver_setting_id: string;
  is_blended: boolean;
  name: string;
  organizations_with_forecasts: Array<Organization>;
  site_group_id?: string | null;
};

export type SiteGroup = {
  __typename: 'SiteGroup';
  description?: string | null;
  id: string;
  name: string;
  rank_order?: number | null;
  site_group_id: string;
  type: SiteGroupType;
};

export enum SiteGroupType {
  SITE_GROUP_BLENDED = 'SITE_GROUP_BLENDED',
  SITE_GROUP_STANDARD = 'SITE_GROUP_STANDARD',
}

export type CreateDriverBlendedSiteDistributionInput = {
  name: string;
  site_group_ids: Array<string>;
};

export type UpdateDriverBlendedSiteDistributionInput = {
  blended_group_id: string;
  name: string;
  site_group_ids: Array<string>;
};

export type ListCategorySettingsInput = {
  budget_type: BudgetType;
  category_id?: string | null;
  month?: string | null;
  vendor_id: string;
};

export type Category = {
  __typename: 'Category';
  activities: Array<Activity>;
  activity_driver_settings?: Array<ActivityDriverSetting> | null;
  budget_forecast_settings?: BudgetForecastSetting | null;
  categories: Array<Category>;
  category_type: CategoryType;
  display_label?: string | null;
  expense_notes?: Array<Note> | null;
  expenses?: Array<BudgetExpenseData> | null;
  id: string;
  name: string;
  parent_category_id?: string | null;
  vendor_id: string;
};

export type Activity = {
  __typename: 'Activity';
  activity_no?: string | null;
  activity_sub_type?: ActivityType | null;
  activity_type: ActivityType;
  budget_forecast_settings?: BudgetForecastSetting | null;
  budget_override_note?: string | null;
  budget_override_settings?: BudgetExpense | null;
  category_id: string;
  display_label?: string | null;
  expense_notes?: Array<Note> | null;
  expenses?: Array<BudgetExpenseData> | null;
  id: string;
  name: string;
  unit_cost?: number | null;
  unit_num?: number | null;
  uom?: string | null;
};

export type BudgetForecastSetting = {
  __typename: 'BudgetForecastSetting';
  activity_id?: string | null;
  amount_type: AmountType;
  category_id?: string | null;
  discount: boolean;
  driver?: DriverType | null;
  driver_setting_id?: string | null;
  evidence_source?: EvidenceSourceType | null;
  forecast_method?: ForecastMethodType | null;
  id: string;
  milestone_category?: string | null;
  override: boolean;
  period_end_date?: string | null;
  period_end_milestone_id?: string | null;
  period_start_date?: string | null;
  period_start_milestone_id?: string | null;
};

export enum EvidenceSourceType {
  EVIDENCE_EDC = 'EVIDENCE_EDC',
  EVIDENCE_INVOICE = 'EVIDENCE_INVOICE',
  EVIDENCE_TIME = 'EVIDENCE_TIME',
}

export enum ForecastMethodType {
  FORECAST_BACKLOADED = 'FORECAST_BACKLOADED',
  FORECAST_CUSTOM = 'FORECAST_CUSTOM',
  FORECAST_FRONTLOADED = 'FORECAST_FRONTLOADED',
  FORECAST_STRAIGHTLINE = 'FORECAST_STRAIGHTLINE',
}

export type ActivityDriverSetting = {
  __typename: 'ActivityDriverSetting';
  activity_override: boolean;
  category_override: boolean;
  driver?: string | null;
  setting_count: number;
};

export type UnforecastedEntityIds = {
  __typename: 'UnforecastedEntityIds';
  activity_ids: Array<string>;
  category_ids: Array<string>;
  vendor_id: string;
};

export type AnalyticsCardInput = {
  analytics_card_type: AnalyticsCardType;
  budget_version_id?: string | null;
  change_log_id?: string | null;
  vendor_id?: string | null;
};

export enum AnalyticsCardType {
  BUDGET_INVOICES = 'BUDGET_INVOICES',
  BUDGET_PENDING_CHANGES = 'BUDGET_PENDING_CHANGES',
  BUDGET_WORK_PERFORMED = 'BUDGET_WORK_PERFORMED',
  FORECAST_ACTIVITIES_BY_DRIVER = 'FORECAST_ACTIVITIES_BY_DRIVER',
  INVESTIGATOR_DETAILS_IN_MON_ACC_TO_DATE = 'INVESTIGATOR_DETAILS_IN_MON_ACC_TO_DATE',
  INVESTIGATOR_DETAILS_TOTAL_ACC_BY_DATE = 'INVESTIGATOR_DETAILS_TOTAL_ACC_BY_DATE',
  INVESTIGATOR_DETAILS_TOTAL_AVG_COST_BY_SITE = 'INVESTIGATOR_DETAILS_TOTAL_AVG_COST_BY_SITE',
  PATIENT_TRACKER_AVG_COST_THROUGH_EOT = 'PATIENT_TRACKER_AVG_COST_THROUGH_EOT',
  PATIENT_TRACKER_AVG_ENROLLEES_TO_DATE = 'PATIENT_TRACKER_AVG_ENROLLEES_TO_DATE',
  PATIENT_TRACKER_FORECASTED_AVG_COST_ENROLLEES = 'PATIENT_TRACKER_FORECASTED_AVG_COST_ENROLLEES',
  UNFORECASTED_ACTIVITIES_COST = 'UNFORECASTED_ACTIVITIES_COST',
}

export type AnalyticsCard = {
  __typename: 'AnalyticsCard';
  data: string;
  primary_key: string;
  sort_key: string;
};

export type Budget = {
  __typename: 'Budget';
  budget_type: BudgetType;
  id: string;
  name: string;
  vendor_id: string;
};

export type BudgetForecastSettingInput = {
  activity_id?: string | null;
  amount_type: AmountType;
  category_id?: string | null;
  discount: boolean;
  driver?: DriverType | null;
  driver_setting_id?: string | null;
  evidence_source?: EvidenceSourceType | null;
  forecast_method?: ForecastMethodType | null;
  milestone_category?: string | null;
  override: boolean;
  period_end_date?: string | null;
  period_end_milestone_id?: string | null;
  period_start_date?: string | null;
  period_start_milestone_id?: string | null;
};

export type UpdateActivityDiscountsInput = {
  activity_id: string;
  amount_type?: AmountType | null;
  discount: boolean;
};

export type InvestigatorForecastContractedAmount = {
  __typename: 'InvestigatorForecastContractedAmount';
  activity_name: string;
  amount: number;
  amount_curr: string;
  amount_type: AmountType;
  contract_amount?: number | null;
  contract_curr?: string | null;
  expense_type: ExpenseType;
  source_last_updated?: string | null;
};

export type SiteContractSetting = {
  __typename: 'SiteContractSetting';
  average_patient_cost: number;
  average_patient_cost_curr: string;
  current_average_patient_cost: number;
  current_average_patient_cost_curr: string;
  from_patient_curve: number;
  invoiceables_percent?: number | null;
  last_updated: string;
  other_percent?: number | null;
  overhead_percent?: number | null;
  site_count: number;
  source_last_updated?: string | null;
  total_forecasted_patients: number;
};

export type CreateSiteContractSettingInput = {
  average_patient_cost: number;
  invoiceables_percent: number;
  organization_id: string;
  other_percent: number;
  overhead_percent: number;
  total_forecasted_patients: number;
};

export type UpdateInvestigatorForecastInput = {
  organization_id: string;
  other_amount: number;
  overhead_amount: number;
  patient_visits_amount: number;
  source: InvestigatorForecastSource;
};

export enum InvestigatorForecastSource {
  ACTUAL_PATIENT_VISITS = 'ACTUAL_PATIENT_VISITS',
  CONTRACTED_AMOUNT = 'CONTRACTED_AMOUNT',
  SITE_CONTRACT_AVERAGES = 'SITE_CONTRACT_AVERAGES',
}

export type ActualPatientVisitSetting = {
  __typename: 'ActualPatientVisitSetting';
  average_completed_patient_cost: number;
  current_discontinued_amount: number;
  current_discontinued_percent: number;
  current_enrolled_amount: number;
  current_invoiceables_percent: number;
  discontinued_percent?: number | null;
  forecasted_remaining_visit_costs: number;
  invoicables_to_date: number;
  invoiceables_percent?: number | null;
  last_updated: string;
  other_percent?: number | null;
  overhead_percent?: number | null;
  remaining_patients_to_discontinue_cost?: number | null;
  remaining_patients_to_discontinue_percent?: number | null;
  source_last_updated?: string | null;
  visit_costs_to_date: number;
};

export type CreateActualPatientVisitSettingInput = {
  discontinued_percent: number;
  invoiceables_percent: number;
  organization_id: string;
  other_percent: number;
  overhead_percent: number;
  remaining_patients_to_discontinue_cost: number;
  remaining_patients_to_discontinue_percent: number;
};

export type Invoice = {
  __typename: 'Invoice';
  accrual_period?: string | null;
  admin_review_reason?: string | null;
  approvals?: Array<Approval | null> | null;
  create_date: string;
  created_by: string;
  data_source_id: DataSource;
  decline_reason?: string | null;
  delete_reason?: string | null;
  due_date?: string | null;
  expense_amounts: Array<ExpenseAmount>;
  has_docs?: boolean | null;
  id: string;
  invoice_date?: string | null;
  invoice_details?: string | null;
  invoice_no?: string | null;
  invoice_status: InvoiceStatus;
  line_items?: string | null;
  ocr_line_items?: string | null;
  organization: Organization;
  payment_date?: string | null;
  payment_status?: PaymentStatus | null;
  purchase_order_id?: string | null;
  reasons: Array<Note>;
  trial_id: string;
  workflow_details: Array<WorkflowDetail>;
};

export enum InvoiceStatus {
  STATUS_APPROVED = 'STATUS_APPROVED',
  STATUS_DECLINED = 'STATUS_DECLINED',
  STATUS_IN_QUEUE = 'STATUS_IN_QUEUE',
  STATUS_PENDING_APPROVAL = 'STATUS_PENDING_APPROVAL',
  STATUS_PENDING_REVIEW = 'STATUS_PENDING_REVIEW',
}

export enum PaymentStatus {
  PAYMENT_STATUS_DECLINED = 'PAYMENT_STATUS_DECLINED',
  PAYMENT_STATUS_PAID_IN_FULL = 'PAYMENT_STATUS_PAID_IN_FULL',
  PAYMENT_STATUS_PARTIAL_PAYMENT = 'PAYMENT_STATUS_PARTIAL_PAYMENT',
  PAYMENT_STATUS_SCHEDULED = 'PAYMENT_STATUS_SCHEDULED',
  PAYMENT_STATUS_UNPAID = 'PAYMENT_STATUS_UNPAID',
}

export type ListInvoicesInput = {
  from_date?: string | null;
  invoice_statuses: Array<InvoiceStatus>;
  to_date?: string | null;
};

export type CreateInvoiceInput = {
  bucket_keys?: Array<string> | null;
  id?: string | null;
  organization_id: string;
  po_reference?: string | null;
};

export type UpdateWorkflowInput = {
  id: string;
  properties: string;
  soft_update?: boolean | null;
};

export type UpdateInvoiceInput = {
  accrual_period?: string | null;
  admin_review_reason?: string | null;
  decline_reason?: string | null;
  due_date?: string | null;
  id: string;
  invoice_date?: string | null;
  invoice_no?: string | null;
  invoice_status?: InvoiceStatus | null;
  organization_id?: string | null;
  payment_date?: string | null;
  payment_status?: PaymentStatus | null;
  po_reference?: string | null;
};

export type ExpenseAmountInput = {
  amount_curr: string;
  amount_perct?: number | null;
  amount_type: string;
  amount_value: number;
  details?: string | null;
  entity_id: string;
  entity_type_id: string;
  fnc_amount?: number | null;
  fnc_amount_curr?: string | null;
  tax_amount?: number | null;
  tax_rate?: number | null;
  units?: number | null;
};

export type RemoveInvoiceInput = {
  id: string;
};

export type CreateOrganizationInput = {
  costs_included_in_parent_wo?: boolean | null;
  currency?: Currency | null;
  name: string;
  organization_type: OrganizationType;
  parent_organization_id?: string | null;
};

export type UpdateOrganizationInput = {
  costs_included_in_parent_wo?: boolean | null;
  currency?: Currency | null;
  id: string;
  name: string;
  parent_organization_id?: string | null;
};

export type ContactInput = {
  contact_type: ContactType;
  email?: string | null;
  entity_id: string;
  entity_type: EntityType;
  family_name: string;
  given_name: string;
  phone_number?: string | null;
  title?: string | null;
};

export type UpdateContactInput = {
  email?: string | null;
  family_name: string;
  given_name: string;
  id: string;
  phone_number?: string | null;
  title?: string | null;
};

export type VendorEstimateSummary = {
  __typename: 'VendorEstimateSummary';
  activities_exceeding_amount_remaining: Array<string>;
  activities_exceeding_forecast: Array<string>;
  organization_id: string;
  organization_name: string;
  vendor_estimate_exists: boolean;
};

export type EntitySettingsInput = {
  entity_id: string;
  entity_type: EntityType;
  setting_type: EntitySettingTypes;
  settings?: string | null;
};

export enum EntitySettingTypes {
  META_DATA = 'META_DATA',
  VENDOR_ACCOUNTS = 'VENDOR_ACCOUNTS',
}

export type EntitySettings = {
  __typename: 'EntitySettings';
  entity_id: string;
  settings?: string | null;
};

export type JournalEntry = {
  __typename: 'JournalEntry';
  attributes?: string | null;
  budget_version_id: string;
  credit_amount?: number | null;
  credit_contracted_amount?: number | null;
  debit_amount?: number | null;
  debit_contracted_amount?: number | null;
  entry_name: string;
  entry_type: string;
  line_memo?: string | null;
  reversal_date?: string | null;
  sponsor_name: string;
  trial_currency?: string | null;
  vendor_currency?: string | null;
  vendor_id: string;
  vendor_name: string;
};

export type CreatePaymentMilestoneInput = {
  amount: number;
  name: string;
  organization_id: string;
  target_date: string;
};

export type PaymentMilestone = {
  __typename: 'PaymentMilestone';
  amount: number;
  id: string;
  name: string;
  organization: Organization;
  target_date: string;
};

export type UpdatePaymentMilestoneInput = {
  amount?: number | null;
  id: string;
  name?: string | null;
  organization_id?: string | null;
  target_date?: string | null;
};

export type WorkPerformed = {
  __typename: 'WorkPerformed';
  amount: number;
  contract_amount: number;
  organization?: Organization | null;
  period_date: string;
};

export type CashRequirement = {
  __typename: 'CashRequirement';
  expense_amount: ExpenseAmount;
  id: string;
  organization: Organization;
  requirement_date: string;
};

export type PaymentMilestoneSummary = {
  __typename: 'PaymentMilestoneSummary';
  milestone_name: string;
  time_period: string;
  total_amount: number;
  vendor_name: string;
};

export type ListInMonthExpensesInput = {
  amount_types?: Array<AmountType> | null;
  organization_id: string;
  period: string;
};

export type InMonthExpenseView = {
  __typename: 'InMonthExpenseView';
  accrual?: BudgetExpenseData | null;
  activity_id: string;
  activity_name: string;
  activity_no?: string | null;
  activity_type: ActivityType;
  direct_cost: BudgetExpenseData;
  display_label: string;
  documents: Array<Document>;
  evidence_based?: BudgetExpenseData | null;
  forecast?: BudgetExpenseData | null;
  group0?: string | null;
  group1?: string | null;
  group2?: string | null;
  group3?: string | null;
  group4?: string | null;
  historical_adjustment?: BudgetExpenseData | null;
  is_forecasted: boolean;
  manual_adjustment?: BudgetExpenseData | null;
  monthly_expense?: BudgetExpenseData | null;
  notes: Array<Note>;
  prev_month_work_performed?: BudgetExpenseData | null;
  prev_months_accruals?: BudgetExpenseData | null;
  total_monthly_expense?: BudgetExpenseData | null;
  vendor_estimate?: BudgetExpenseData | null;
  work_performed?: BudgetExpenseData | null;
  work_performed_to_date?: BudgetExpenseData | null;
};

export type ListExpenseSourceSettingsInput = {
  organization_id: string;
  period: string;
};

export type ExpenseSourceSetting = {
  __typename: 'ExpenseSourceSetting';
  activity_id: string;
  default: boolean;
  period?: string | null;
  sources: Array<ExpenseSourceType>;
};

export type BatchOverrideExpenseSourceInput = {
  organization_id: string;
  overrides: Array<OverrideExpenseSourceInput>;
  period: string;
};

export type OverrideExpenseSourceInput = {
  activity_id: string;
  source: ExpenseSourceType;
};

export type BatchRemoveExpenseSourceOverridesInput = {
  activity_ids: Array<string>;
  organization_id: string;
  period: string;
};

export type PurchaseOrder = {
  __typename: 'PurchaseOrder';
  description?: string | null;
  id: string;
  invoice_summaries: Array<InvoiceSummary>;
  organization: Organization;
  po_amount: number;
  po_number: string;
};

export type InvoiceSummary = {
  __typename: 'InvoiceSummary';
  invoice_id: string;
  invoice_status: InvoiceStatus;
  total_amount?: number | null;
};

export type CreatePurchaseOrderInput = {
  description?: string | null;
  organization_id: string;
  po_amount: number;
  po_number: string;
};

export type UpdatePurchaseOrderInput = {
  description?: string | null;
  id: string;
  organization_id: string;
  po_amount?: number | null;
  po_number?: string | null;
};

export type ScenarioModel = {
  __typename: 'ScenarioModel';
  label: Array<string>;
  model?: Array<ScenarioModelData | null> | null;
};

export type ScenarioModelData = {
  __typename: 'ScenarioModelData';
  data: Array<number>;
  source: string;
};

export type Site = {
  __typename: 'Site';
  address_line_1?: string | null;
  address_line_2?: string | null;
  address_line_3?: string | null;
  city?: string | null;
  closeout_date?: string | null;
  contacts?: Array<Contact> | null;
  country?: Country | null;
  currency?: Currency | null;
  id: string;
  managed_by: Organization;
  name: string;
  site_activation?: string | null;
  site_no: string;
  state?: string | null;
  target_patients?: number | null;
  zip?: string | null;
};

export enum Country {
  COUNTRY_AD = 'COUNTRY_AD',
  COUNTRY_AE = 'COUNTRY_AE',
  COUNTRY_AF = 'COUNTRY_AF',
  COUNTRY_AG = 'COUNTRY_AG',
  COUNTRY_AI = 'COUNTRY_AI',
  COUNTRY_AL = 'COUNTRY_AL',
  COUNTRY_AM = 'COUNTRY_AM',
  COUNTRY_AO = 'COUNTRY_AO',
  COUNTRY_AQ = 'COUNTRY_AQ',
  COUNTRY_AR = 'COUNTRY_AR',
  COUNTRY_AS = 'COUNTRY_AS',
  COUNTRY_AT = 'COUNTRY_AT',
  COUNTRY_AU = 'COUNTRY_AU',
  COUNTRY_AW = 'COUNTRY_AW',
  COUNTRY_AX = 'COUNTRY_AX',
  COUNTRY_AZ = 'COUNTRY_AZ',
  COUNTRY_BA = 'COUNTRY_BA',
  COUNTRY_BB = 'COUNTRY_BB',
  COUNTRY_BD = 'COUNTRY_BD',
  COUNTRY_BE = 'COUNTRY_BE',
  COUNTRY_BF = 'COUNTRY_BF',
  COUNTRY_BG = 'COUNTRY_BG',
  COUNTRY_BH = 'COUNTRY_BH',
  COUNTRY_BI = 'COUNTRY_BI',
  COUNTRY_BJ = 'COUNTRY_BJ',
  COUNTRY_BL = 'COUNTRY_BL',
  COUNTRY_BM = 'COUNTRY_BM',
  COUNTRY_BN = 'COUNTRY_BN',
  COUNTRY_BO = 'COUNTRY_BO',
  COUNTRY_BQ = 'COUNTRY_BQ',
  COUNTRY_BR = 'COUNTRY_BR',
  COUNTRY_BS = 'COUNTRY_BS',
  COUNTRY_BT = 'COUNTRY_BT',
  COUNTRY_BV = 'COUNTRY_BV',
  COUNTRY_BW = 'COUNTRY_BW',
  COUNTRY_BY = 'COUNTRY_BY',
  COUNTRY_BZ = 'COUNTRY_BZ',
  COUNTRY_CA = 'COUNTRY_CA',
  COUNTRY_CC = 'COUNTRY_CC',
  COUNTRY_CD = 'COUNTRY_CD',
  COUNTRY_CF = 'COUNTRY_CF',
  COUNTRY_CG = 'COUNTRY_CG',
  COUNTRY_CH = 'COUNTRY_CH',
  COUNTRY_CI = 'COUNTRY_CI',
  COUNTRY_CK = 'COUNTRY_CK',
  COUNTRY_CL = 'COUNTRY_CL',
  COUNTRY_CM = 'COUNTRY_CM',
  COUNTRY_CN = 'COUNTRY_CN',
  COUNTRY_CO = 'COUNTRY_CO',
  COUNTRY_CR = 'COUNTRY_CR',
  COUNTRY_CU = 'COUNTRY_CU',
  COUNTRY_CV = 'COUNTRY_CV',
  COUNTRY_CW = 'COUNTRY_CW',
  COUNTRY_CX = 'COUNTRY_CX',
  COUNTRY_CY = 'COUNTRY_CY',
  COUNTRY_CZ = 'COUNTRY_CZ',
  COUNTRY_DE = 'COUNTRY_DE',
  COUNTRY_DJ = 'COUNTRY_DJ',
  COUNTRY_DK = 'COUNTRY_DK',
  COUNTRY_DM = 'COUNTRY_DM',
  COUNTRY_DO = 'COUNTRY_DO',
  COUNTRY_DZ = 'COUNTRY_DZ',
  COUNTRY_EC = 'COUNTRY_EC',
  COUNTRY_EE = 'COUNTRY_EE',
  COUNTRY_EG = 'COUNTRY_EG',
  COUNTRY_EH = 'COUNTRY_EH',
  COUNTRY_ER = 'COUNTRY_ER',
  COUNTRY_ES = 'COUNTRY_ES',
  COUNTRY_ET = 'COUNTRY_ET',
  COUNTRY_FI = 'COUNTRY_FI',
  COUNTRY_FJ = 'COUNTRY_FJ',
  COUNTRY_FK = 'COUNTRY_FK',
  COUNTRY_FM = 'COUNTRY_FM',
  COUNTRY_FO = 'COUNTRY_FO',
  COUNTRY_FR = 'COUNTRY_FR',
  COUNTRY_GA = 'COUNTRY_GA',
  COUNTRY_GB = 'COUNTRY_GB',
  COUNTRY_GD = 'COUNTRY_GD',
  COUNTRY_GE = 'COUNTRY_GE',
  COUNTRY_GF = 'COUNTRY_GF',
  COUNTRY_GG = 'COUNTRY_GG',
  COUNTRY_GH = 'COUNTRY_GH',
  COUNTRY_GI = 'COUNTRY_GI',
  COUNTRY_GL = 'COUNTRY_GL',
  COUNTRY_GM = 'COUNTRY_GM',
  COUNTRY_GN = 'COUNTRY_GN',
  COUNTRY_GP = 'COUNTRY_GP',
  COUNTRY_GQ = 'COUNTRY_GQ',
  COUNTRY_GR = 'COUNTRY_GR',
  COUNTRY_GS = 'COUNTRY_GS',
  COUNTRY_GT = 'COUNTRY_GT',
  COUNTRY_GU = 'COUNTRY_GU',
  COUNTRY_GW = 'COUNTRY_GW',
  COUNTRY_GY = 'COUNTRY_GY',
  COUNTRY_HK = 'COUNTRY_HK',
  COUNTRY_HM = 'COUNTRY_HM',
  COUNTRY_HN = 'COUNTRY_HN',
  COUNTRY_HR = 'COUNTRY_HR',
  COUNTRY_HT = 'COUNTRY_HT',
  COUNTRY_HU = 'COUNTRY_HU',
  COUNTRY_ID = 'COUNTRY_ID',
  COUNTRY_IE = 'COUNTRY_IE',
  COUNTRY_IL = 'COUNTRY_IL',
  COUNTRY_IM = 'COUNTRY_IM',
  COUNTRY_IN = 'COUNTRY_IN',
  COUNTRY_IO = 'COUNTRY_IO',
  COUNTRY_IQ = 'COUNTRY_IQ',
  COUNTRY_IR = 'COUNTRY_IR',
  COUNTRY_IS = 'COUNTRY_IS',
  COUNTRY_IT = 'COUNTRY_IT',
  COUNTRY_JE = 'COUNTRY_JE',
  COUNTRY_JM = 'COUNTRY_JM',
  COUNTRY_JO = 'COUNTRY_JO',
  COUNTRY_JP = 'COUNTRY_JP',
  COUNTRY_KE = 'COUNTRY_KE',
  COUNTRY_KG = 'COUNTRY_KG',
  COUNTRY_KH = 'COUNTRY_KH',
  COUNTRY_KI = 'COUNTRY_KI',
  COUNTRY_KM = 'COUNTRY_KM',
  COUNTRY_KN = 'COUNTRY_KN',
  COUNTRY_KP = 'COUNTRY_KP',
  COUNTRY_KR = 'COUNTRY_KR',
  COUNTRY_KW = 'COUNTRY_KW',
  COUNTRY_KY = 'COUNTRY_KY',
  COUNTRY_KZ = 'COUNTRY_KZ',
  COUNTRY_LA = 'COUNTRY_LA',
  COUNTRY_LB = 'COUNTRY_LB',
  COUNTRY_LC = 'COUNTRY_LC',
  COUNTRY_LI = 'COUNTRY_LI',
  COUNTRY_LK = 'COUNTRY_LK',
  COUNTRY_LR = 'COUNTRY_LR',
  COUNTRY_LS = 'COUNTRY_LS',
  COUNTRY_LT = 'COUNTRY_LT',
  COUNTRY_LU = 'COUNTRY_LU',
  COUNTRY_LV = 'COUNTRY_LV',
  COUNTRY_LY = 'COUNTRY_LY',
  COUNTRY_MA = 'COUNTRY_MA',
  COUNTRY_MC = 'COUNTRY_MC',
  COUNTRY_MD = 'COUNTRY_MD',
  COUNTRY_ME = 'COUNTRY_ME',
  COUNTRY_MF = 'COUNTRY_MF',
  COUNTRY_MG = 'COUNTRY_MG',
  COUNTRY_MH = 'COUNTRY_MH',
  COUNTRY_MK = 'COUNTRY_MK',
  COUNTRY_ML = 'COUNTRY_ML',
  COUNTRY_MM = 'COUNTRY_MM',
  COUNTRY_MN = 'COUNTRY_MN',
  COUNTRY_MO = 'COUNTRY_MO',
  COUNTRY_MP = 'COUNTRY_MP',
  COUNTRY_MQ = 'COUNTRY_MQ',
  COUNTRY_MR = 'COUNTRY_MR',
  COUNTRY_MS = 'COUNTRY_MS',
  COUNTRY_MT = 'COUNTRY_MT',
  COUNTRY_MU = 'COUNTRY_MU',
  COUNTRY_MV = 'COUNTRY_MV',
  COUNTRY_MW = 'COUNTRY_MW',
  COUNTRY_MX = 'COUNTRY_MX',
  COUNTRY_MY = 'COUNTRY_MY',
  COUNTRY_MZ = 'COUNTRY_MZ',
  COUNTRY_NA = 'COUNTRY_NA',
  COUNTRY_NC = 'COUNTRY_NC',
  COUNTRY_NE = 'COUNTRY_NE',
  COUNTRY_NF = 'COUNTRY_NF',
  COUNTRY_NG = 'COUNTRY_NG',
  COUNTRY_NI = 'COUNTRY_NI',
  COUNTRY_NL = 'COUNTRY_NL',
  COUNTRY_NO = 'COUNTRY_NO',
  COUNTRY_NP = 'COUNTRY_NP',
  COUNTRY_NR = 'COUNTRY_NR',
  COUNTRY_NU = 'COUNTRY_NU',
  COUNTRY_NZ = 'COUNTRY_NZ',
  COUNTRY_OM = 'COUNTRY_OM',
  COUNTRY_PA = 'COUNTRY_PA',
  COUNTRY_PE = 'COUNTRY_PE',
  COUNTRY_PF = 'COUNTRY_PF',
  COUNTRY_PG = 'COUNTRY_PG',
  COUNTRY_PH = 'COUNTRY_PH',
  COUNTRY_PK = 'COUNTRY_PK',
  COUNTRY_PL = 'COUNTRY_PL',
  COUNTRY_PM = 'COUNTRY_PM',
  COUNTRY_PN = 'COUNTRY_PN',
  COUNTRY_PR = 'COUNTRY_PR',
  COUNTRY_PS = 'COUNTRY_PS',
  COUNTRY_PT = 'COUNTRY_PT',
  COUNTRY_PW = 'COUNTRY_PW',
  COUNTRY_PY = 'COUNTRY_PY',
  COUNTRY_QA = 'COUNTRY_QA',
  COUNTRY_RE = 'COUNTRY_RE',
  COUNTRY_RO = 'COUNTRY_RO',
  COUNTRY_RS = 'COUNTRY_RS',
  COUNTRY_RU = 'COUNTRY_RU',
  COUNTRY_RW = 'COUNTRY_RW',
  COUNTRY_SA = 'COUNTRY_SA',
  COUNTRY_SB = 'COUNTRY_SB',
  COUNTRY_SC = 'COUNTRY_SC',
  COUNTRY_SD = 'COUNTRY_SD',
  COUNTRY_SE = 'COUNTRY_SE',
  COUNTRY_SG = 'COUNTRY_SG',
  COUNTRY_SH = 'COUNTRY_SH',
  COUNTRY_SI = 'COUNTRY_SI',
  COUNTRY_SJ = 'COUNTRY_SJ',
  COUNTRY_SK = 'COUNTRY_SK',
  COUNTRY_SL = 'COUNTRY_SL',
  COUNTRY_SM = 'COUNTRY_SM',
  COUNTRY_SN = 'COUNTRY_SN',
  COUNTRY_SO = 'COUNTRY_SO',
  COUNTRY_SR = 'COUNTRY_SR',
  COUNTRY_SS = 'COUNTRY_SS',
  COUNTRY_ST = 'COUNTRY_ST',
  COUNTRY_SV = 'COUNTRY_SV',
  COUNTRY_SX = 'COUNTRY_SX',
  COUNTRY_SY = 'COUNTRY_SY',
  COUNTRY_SZ = 'COUNTRY_SZ',
  COUNTRY_TC = 'COUNTRY_TC',
  COUNTRY_TD = 'COUNTRY_TD',
  COUNTRY_TF = 'COUNTRY_TF',
  COUNTRY_TG = 'COUNTRY_TG',
  COUNTRY_TH = 'COUNTRY_TH',
  COUNTRY_TJ = 'COUNTRY_TJ',
  COUNTRY_TK = 'COUNTRY_TK',
  COUNTRY_TL = 'COUNTRY_TL',
  COUNTRY_TM = 'COUNTRY_TM',
  COUNTRY_TN = 'COUNTRY_TN',
  COUNTRY_TO = 'COUNTRY_TO',
  COUNTRY_TR = 'COUNTRY_TR',
  COUNTRY_TT = 'COUNTRY_TT',
  COUNTRY_TV = 'COUNTRY_TV',
  COUNTRY_TW = 'COUNTRY_TW',
  COUNTRY_TZ = 'COUNTRY_TZ',
  COUNTRY_UA = 'COUNTRY_UA',
  COUNTRY_UG = 'COUNTRY_UG',
  COUNTRY_UM = 'COUNTRY_UM',
  COUNTRY_US = 'COUNTRY_US',
  COUNTRY_UY = 'COUNTRY_UY',
  COUNTRY_UZ = 'COUNTRY_UZ',
  COUNTRY_VA = 'COUNTRY_VA',
  COUNTRY_VC = 'COUNTRY_VC',
  COUNTRY_VE = 'COUNTRY_VE',
  COUNTRY_VG = 'COUNTRY_VG',
  COUNTRY_VI = 'COUNTRY_VI',
  COUNTRY_VN = 'COUNTRY_VN',
  COUNTRY_VU = 'COUNTRY_VU',
  COUNTRY_WF = 'COUNTRY_WF',
  COUNTRY_WS = 'COUNTRY_WS',
  COUNTRY_XK = 'COUNTRY_XK',
  COUNTRY_YE = 'COUNTRY_YE',
  COUNTRY_YT = 'COUNTRY_YT',
  COUNTRY_ZA = 'COUNTRY_ZA',
  COUNTRY_ZM = 'COUNTRY_ZM',
  COUNTRY_ZW = 'COUNTRY_ZW',
}

export type CreatePatientGroupInput = {
  description?: string | null;
  name: string;
  rank_order?: number | null;
  type?: PatientGroupType | null;
};

export type UpdatePatientGroupInput = {
  description?: string | null;
  id: string;
  name?: string | null;
  rank_order?: number | null;
  type?: PatientGroupType | null;
};

export type ClonePatientProtocolInput = {
  source_patient_group_id?: string | null;
  target_patient_group_id: string;
};

export type PatientProtocol = {
  __typename: 'PatientProtocol';
  id: string;
  name: string;
  order_by: number;
  patient_group_id?: string | null;
  patient_protocol_frequency?: PatientProtocolFrequency | null;
  patient_protocol_sub_type?: PatientProtocolSubType | null;
  patient_protocol_type: PatientProtocolType;
  patient_protocol_version?: PatientProtocolVersion | null;
  target_date_days_out?: number | null;
  target_tolerance_days_out: number;
};

export enum PatientProtocolFrequency {
  PATIENT_PROTOCOL_FREQUENCY_ACTIVATION = 'PATIENT_PROTOCOL_FREQUENCY_ACTIVATION',
  PATIENT_PROTOCOL_FREQUENCY_ANNUALLY = 'PATIENT_PROTOCOL_FREQUENCY_ANNUALLY',
  PATIENT_PROTOCOL_FREQUENCY_CLOSEOUT = 'PATIENT_PROTOCOL_FREQUENCY_CLOSEOUT',
  PATIENT_PROTOCOL_FREQUENCY_MONTHLY = 'PATIENT_PROTOCOL_FREQUENCY_MONTHLY',
  PATIENT_PROTOCOL_FREQUENCY_QUARTERLY = 'PATIENT_PROTOCOL_FREQUENCY_QUARTERLY',
}

export type PatientProtocolSubType = {
  __typename: 'PatientProtocolSubType';
  id: string;
  name: string;
  patient_protocol_type: PatientProtocolType;
};

export enum PatientProtocolType {
  PATIENT_PROTOCOL_DISCONTINUED = 'PATIENT_PROTOCOL_DISCONTINUED',
  PATIENT_PROTOCOL_OTHER = 'PATIENT_PROTOCOL_OTHER',
  PATIENT_PROTOCOL_OVERHEAD = 'PATIENT_PROTOCOL_OVERHEAD',
  PATIENT_PROTOCOL_PATIENT_VISIT = 'PATIENT_PROTOCOL_PATIENT_VISIT',
  PATIENT_PROTOCOL_SCREEN_FAIL = 'PATIENT_PROTOCOL_SCREEN_FAIL',
}

export type PatientProtocolVersion = {
  __typename: 'PatientProtocolVersion';
  id: string;
  is_current: boolean;
  name: string;
};

export type CreateSiteGroupInput = {
  description?: string | null;
  name: string;
  rank_order?: number | null;
};

export type UpdateSiteGroupInput = {
  description?: string | null;
  id: string;
  name?: string | null;
  rank_order?: number | null;
};

export type SitePaymentSchedule = {
  __typename: 'SitePaymentSchedule';
  country?: Country | null;
  expense_amount: ExpenseAmount;
  id: string;
  note?: string | null;
  patient_protocol: PatientProtocol;
  site_id: string;
  unscheduled: number;
};

export type FetchTrialSitePaymentSchedulesInput = {
  page: PageInput;
  patient_group_id?: string | null;
  patient_protocol_types: Array<PatientProtocolType>;
  patient_protocol_version_id?: string | null;
  site_ids: Array<string>;
};

export type PageInput = {
  limit?: number | null;
  offset: number;
};

export type FetchSitePaymentScheduleResponse = {
  __typename: 'FetchSitePaymentScheduleResponse';
  items: Array<SitePaymentSchedule>;
  next_offset: number;
};

export type SitePatientTrackerMonthlyAmount = {
  __typename: 'SitePatientTrackerMonthlyAmount';
  completion_month: string;
  other_amount: number;
  overhead_amount: number;
  patient_amount: number;
  total_amount: number;
  vendor_id: string;
};

export type FetchSitePatientTrackersFlatInput = {
  end_date?: string | null;
  page: PageInput;
  patient_group_ids?: Array<string | null> | null;
  patient_protocol_types: Array<PatientProtocolType>;
  patient_tracker_version_id?: string | null;
  site_ids: Array<string>;
  start_date?: string | null;
  tpsa_patient_protocol_types: Array<PatientProtocolType>;
};

export type FetchSitePatientTrackersFlatResponse = {
  __typename: 'FetchSitePatientTrackersFlatResponse';
  items: Array<SitePatientTrackerFlat>;
  next_offset: number;
};

export type SitePatientTrackerFlat = {
  __typename: 'SitePatientTrackerFlat';
  completion_date: string;
  create_date?: string | null;
  external_patient_id?: string | null;
  id: string;
  patient_id?: string | null;
  patient_tracker_version_id?: string | null;
  sps_contract_expense_amount?: number | null;
  sps_contract_expense_currency?: string | null;
  sps_expense_amount?: number | null;
  sps_expense_currency?: string | null;
  sps_id: string;
  sps_note?: string | null;
  sps_pp_id: string;
  sps_pp_name: string;
  sps_pp_order_by: number;
  sps_pp_patient_protocol_type: PatientProtocolType;
  sps_pp_target_date_days_out?: number | null;
  sps_pp_target_tolerance_days_out: number;
  sps_site_id: string;
  sps_site_managed_by_id: string;
  sps_site_target_patients?: number | null;
  sps_site_total_payment_schedule_amount?: number | null;
  sps_total_contract_expense_amount?: number | null;
  sps_total_expense_amount?: number | null;
};

export type CreatePatientProtocolInput = {
  name: string;
  order_by: number;
  patient_group_id?: string | null;
  patient_protocol_frequency?: PatientProtocolFrequency | null;
  patient_protocol_sub_type_id: string;
  patient_protocol_version_id: string;
  target_date_days_out?: number | null;
  target_tolerance_days_out?: number | null;
};

export type FetchPatientVisitSchedulesInput = {
  page: PageInput;
  patient_group_ids?: Array<string | null> | null;
  site_ids?: Array<string> | null;
  sort_by?: Array<PatientVisitSchedulesSortInput> | null;
};

export type PatientVisitSchedulesSortInput = {
  field: PatientVisitScheduleSortableFields;
  order?: SortOrder | null;
};

export enum PatientVisitScheduleSortableFields {
  PATIENT_ID = 'PATIENT_ID',
  SITE_NO = 'SITE_NO',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FetchPatientVisitSchedulesResponse = {
  __typename: 'FetchPatientVisitSchedulesResponse';
  items: Array<PatientVisitSchedule>;
  meta_data?: PatientVisitsMetaData | null;
  next_offset: number;
};

export type PatientVisitSchedule = {
  __typename: 'PatientVisitSchedule';
  external_patient_id: string;
  patient_group_id?: string | null;
  patient_id: string;
  site_id: string;
  site_no: string;
  visit_schedule: Array<PatientVisit>;
};

export type PatientVisit = {
  __typename: 'PatientVisit';
  completed: boolean;
  cost: ExpenseAmount;
  patient_protocol_id: string;
  patient_protocol_name: string;
  patient_protocol_type: PatientProtocolType;
  patient_protocol_version_id: string;
  scheduled_date: string;
  visit_index?: number | null;
};

export type PatientVisitsMetaData = {
  __typename: 'PatientVisitsMetaData';
  current_month_total?: number | null;
  total?: number | null;
};

export type CreatePatientProtocolVersionInput = {
  copy_from_id?: string | null;
  name: string;
};

export type UpdatePatientProtocolInput = {
  id: string;
  name?: string | null;
  order_by?: number | null;
  patient_protocol_frequency?: PatientProtocolFrequency | null;
  patient_protocol_sub_type_id: string;
  patient_protocol_version_id: string;
  target_date_days_out?: number | null;
  target_tolerance_days_out?: number | null;
};

export type CreateSitePaymentScheduleInput = {
  amount: number;
  note?: string | null;
  patient_protocol_id: string;
  site_id: string;
};

export type UpdateSitePaymentScheduleInput = {
  amount?: number | null;
  id: string;
  note?: string | null;
  patient_protocol_id?: string | null;
};

export type CreateSiteInput = {
  address_line_1?: string | null;
  address_line_2?: string | null;
  address_line_3?: string | null;
  city?: string | null;
  closeout_date?: string | null;
  country: Country;
  currency?: Currency | null;
  managed_by_id: string;
  name: string;
  site_activation?: string | null;
  site_no: string;
  state?: string | null;
  target_patients?: number | null;
  zip?: string | null;
};

export type CreateDriverSiteDistributionInput = {
  distribution_mode: DistributionMode;
  distribution_month: string;
  driver_setting_id: string;
  driver_site_distribution_id?: string | null;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
  sites_activated?: number | null;
  sites_activated_percentage?: number | null;
  sites_closed?: number | null;
  sites_closed_percentage?: number | null;
};

export type UpdateDriverSiteDistributionInput = {
  distribution_mode?: DistributionMode | null;
  distribution_month?: string | null;
  id: string;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
  sites_activated?: number | null;
  sites_activated_percentage?: number | null;
  sites_closed?: number | null;
  sites_closed_percentage?: number | null;
};

export type UpdateSiteInput = {
  address_line_1?: string | null;
  address_line_2?: string | null;
  address_line_3?: string | null;
  city?: string | null;
  closeout_date?: string | null;
  country?: Country | null;
  currency?: Currency | null;
  id: string;
  managed_by_id?: string | null;
  name?: string | null;
  site_activation?: string | null;
  site_no?: string | null;
  state?: string | null;
  target_patients?: number | null;
  zip?: string | null;
};

export type CreateDriverPatientDistributionInput = {
  cumulative_enrollment_percentage?: number | null;
  distribution_mode: DistributionMode;
  distribution_month: string;
  driver_patient_distribution_id?: string | null;
  driver_setting_id: string;
  net_patients_enrolled?: number | null;
  patients_complete?: number | null;
  patients_discontinued?: number | null;
  patients_enrolled?: number | null;
  total_patients_enrolled?: number | null;
};

export type UpdateDriverPatientDistributionInput = {
  cumulative_enrollment_percentage?: number | null;
  distribution_mode?: DistributionMode | null;
  distribution_month?: string | null;
  id: string;
  net_patients_enrolled?: number | null;
  patients_complete?: number | null;
  patients_discontinued?: number | null;
  patients_enrolled?: number | null;
  total_patients_enrolled?: number | null;
};

export type SitePatientTrackerVersion = {
  __typename: 'SitePatientTrackerVersion';
  create_date: string;
  id: string;
  is_current: boolean;
};

export type FetchPatientTransactionsInput = {
  countries?: Array<Country> | null;
  end_activity_date?: string | null;
  page: PageInput;
  patient_group_ids?: Array<string | null> | null;
  patient_ids?: Array<string | null> | null;
  patient_protocol_categories?: Array<string> | null;
  patient_protocol_names?: Array<string> | null;
  patient_protocol_sub_types?: Array<string> | null;
  patient_protocol_version_ids?: Array<string> | null;
  site_ids?: Array<string> | null;
  sort_by?: Array<PatientTransactionsSortInput> | null;
  start_activity_date?: string | null;
};

export type PatientTransactionsSortInput = {
  field: PatientTransactionSortableFields;
  order?: SortOrder | null;
};

export enum PatientTransactionSortableFields {
  ACTIVITY_DATE = 'ACTIVITY_DATE',
  CONTRACT_CURRENCY = 'CONTRACT_CURRENCY',
  CONTRACT_TOTAL = 'CONTRACT_TOTAL',
  COUNTRY = 'COUNTRY',
  EXCHANGE_RATE = 'EXCHANGE_RATE',
  PATIENT_GROUP_NAME = 'PATIENT_GROUP_NAME',
  PATIENT_NO = 'PATIENT_NO',
  PATIENT_PROTOCOL_CATEGORY = 'PATIENT_PROTOCOL_CATEGORY',
  PATIENT_PROTOCOL_NAME = 'PATIENT_PROTOCOL_NAME',
  PATIENT_PROTOCOL_SUB_TYPE = 'PATIENT_PROTOCOL_SUB_TYPE',
  PATIENT_PROTOCOL_VERSION = 'PATIENT_PROTOCOL_VERSION',
  PRINCIPAL_INVESTIGATOR = 'PRINCIPAL_INVESTIGATOR',
  SITE_NAME = 'SITE_NAME',
  SITE_NO = 'SITE_NO',
  SOURCE_CREATED_DATE = 'SOURCE_CREATED_DATE',
  TRIAL_CURRENCY_TOTAL = 'TRIAL_CURRENCY_TOTAL',
}

export type FetchPatientTransactionsResponse = {
  __typename: 'FetchPatientTransactionsResponse';
  items: Array<PatientTransaction>;
  latest_source_date?: string | null;
  meta_data?: PatientTransactionsMetaData | null;
  next_offset: number;
};

export type PatientTransaction = {
  __typename: 'PatientTransaction';
  activity_date: string;
  create_date: string;
  exchange_rate?: MonthlyExchangeRate | null;
  external_patient_id?: string | null;
  id: string;
  patient_group_id?: string | null;
  patient_id?: string | null;
  patient_protocol_category: PatientProtocolCategory;
  site_payment_schedule: SitePaymentSchedule;
  source_created_date?: string | null;
};

export enum PatientProtocolCategory {
  PATIENT_PROTOCOL_CATEGORY_INVOICEABLE = 'PATIENT_PROTOCOL_CATEGORY_INVOICEABLE',
  PATIENT_PROTOCOL_CATEGORY_PATIENT_VISIT = 'PATIENT_PROTOCOL_CATEGORY_PATIENT_VISIT',
}

export type PatientTransactionsMetaData = {
  __typename: 'PatientTransactionsMetaData';
  total_cost?: number | null;
  total_count?: number | null;
};

export type Patient = {
  __typename: 'Patient';
  external_patient_id: string;
  id: string;
};

export type UpdatePatientProtocolVersionInput = {
  id: string;
  is_current?: boolean | null;
  name?: string | null;
};

export type FetchInvestigatorTransactionsInput = {
  page: PageInput;
  patient_protocol_version_id?: string | null;
  patient_tracker_version_id?: string | null;
};

export type FetchInvestigatorSummariesResponse = {
  __typename: 'FetchInvestigatorSummariesResponse';
  items: Array<InvestigatorSummary>;
  next_offset: number;
};

export type InvestigatorSummary = {
  __typename: 'InvestigatorSummary';
  external_patient_id?: string | null;
  investigator_costs: Array<InvestigatorCost>;
  patient_id?: string | null;
  remaining_visit_costs?: number | null;
  site_id: string;
};

export type InvestigatorCost = {
  __typename: 'InvestigatorCost';
  cost: number;
  cost_type: PatientProtocolCategory;
  patient_group_id?: string | null;
  patient_group_name?: string | null;
  patient_group_rank?: number | null;
};

export type FetchInvestigatorDetailInput = {
  end_date?: string | null;
  page: PageInput;
  patient_protocol_types: Array<PatientProtocolType>;
  patient_tracker_version_id?: string | null;
  site_ids: Array<string>;
  start_date?: string | null;
  tpsa_patient_protocol_types: Array<PatientProtocolType>;
};

export type FetchInvestigatorDetailResponse = {
  __typename: 'FetchInvestigatorDetailResponse';
  items: Array<InvestigatorDetail>;
  next_offset: number;
};

export type InvestigatorDetail = {
  __typename: 'InvestigatorDetail';
  completion_date?: string | null;
  create_date?: string | null;
  external_patient_id?: string | null;
  id?: string | null;
  patient_id?: string | null;
  patient_tracker_version_id?: string | null;
  sps_contract_expense_amount?: number | null;
  sps_contract_expense_currency?: string | null;
  sps_expense_amount?: number | null;
  sps_expense_currency?: string | null;
  sps_id?: string | null;
  sps_note?: string | null;
  sps_pp_id?: string | null;
  sps_pp_name?: string | null;
  sps_pp_order_by?: number | null;
  sps_pp_patient_protocol_type?: PatientProtocolType | null;
  sps_pp_target_date_days_out?: number | null;
  sps_pp_target_tolerance_days_out?: number | null;
  sps_site_id: string;
  sps_site_managed_by_id: string;
  sps_site_target_patients?: number | null;
  sps_site_total_payment_schedule_amount?: number | null;
  sps_total_contract_expense_amount?: number | null;
  sps_total_expense_amount?: number | null;
};

export type GetPatientCostSummaryInput = {
  patient_group_cost_order?: SortOrder | null;
  patient_group_page?: PageInput | null;
  site_cost_order?: SortOrder | null;
  site_page?: PageInput | null;
};

export type PatientCostSummary = {
  __typename: 'PatientCostSummary';
  expected_patients_enrolled: number;
  patient_group_costs: Array<StratifiedPatientCost>;
  site_costs: Array<StratifiedPatientCost>;
  total_cost?: StratifiedPatientCost | null;
};

export type StratifiedPatientCost = {
  __typename: 'StratifiedPatientCost';
  average_patient_cost: number;
  patients_enrolled: number;
  source: PatientCostSourceType;
  source_entity_data: string;
  source_entity_id?: string | null;
};

export enum PatientCostSourceType {
  PATIENT_COST_SOURCE_PATIENT_GROUP = 'PATIENT_COST_SOURCE_PATIENT_GROUP',
  PATIENT_COST_SOURCE_SITE = 'PATIENT_COST_SOURCE_SITE',
  PATIENT_COST_SOURCE_TOTAL = 'PATIENT_COST_SOURCE_TOTAL',
}

export type TotalInvestigatorCost = {
  __typename: 'TotalInvestigatorCost';
  cost: number;
  patient_protocol_type: PatientProtocolType;
};

export type GetScreenFailSummaryInput = {
  site_screen_fail_rates_order?: SortOrder | null;
  site_screen_fail_rates_page?: PageInput | null;
  site_screen_fail_totals_order?: SortOrder | null;
  site_screen_fail_totals_page?: PageInput | null;
};

export type ScreenFailSummary = {
  __typename: 'ScreenFailSummary';
  dropout_rate: number;
  screen_fail_rate: number;
  screen_fail_total_cost: number;
  screen_fails: number;
  site_screen_fail_rates: Array<SiteScreenFailRate>;
  site_screen_fail_totals: Array<SiteScreenFailTotal>;
};

export type SiteScreenFailRate = {
  __typename: 'SiteScreenFailRate';
  primary_investigator?: Contact | null;
  screen_fail_rate: number;
  site_name: string;
};

export type SiteScreenFailTotal = {
  __typename: 'SiteScreenFailTotal';
  primary_investigator?: Contact | null;
  screen_fails: number;
  site_name: string;
};

export type GetSiteCostSummaryInput = {
  site_cost_order?: SortOrder | null;
  site_cost_page?: PageInput | null;
  sites_lag_order?: SortOrder | null;
  sites_lag_page?: PageInput | null;
};

export type SiteCostSummary = {
  __typename: 'SiteCostSummary';
  expected_sites_activated: number;
  expected_sites_closed: number;
  site_costs: Array<SiteCost>;
  sites_activated: number;
  sites_lag: Array<SiteLag>;
};

export type SiteCost = {
  __typename: 'SiteCost';
  patients_enrolled: number;
  primary_investigator?: Contact | null;
  site_name: string;
  total_cost: number;
};

export type SiteLag = {
  __typename: 'SiteLag';
  latest_transaction?: string | null;
  primary_investigator?: Contact | null;
  site_name: string;
};

export type CheckPatientProtocolIsUniqueInput = {
  patient_group_id?: string | null;
  patient_protocol_id?: string | null;
  patient_protocol_name: string;
  patient_protocol_type: PatientProtocolType;
  patient_protocol_version_id: string;
};

export type CheckPatientProtocolIsUniqueResponse = {
  __typename: 'CheckPatientProtocolIsUniqueResponse';
  is_unique: boolean;
};

export type BudgetSnapshot = {
  __typename: 'BudgetSnapshot';
  budget_snapshot_type_id: BudgetSnapshotType;
  budget_version_ids: Array<string>;
  id: string;
  name: string;
};

export type UpdateBudgetSnapshotInput = {
  budget_name: string;
  id: string;
};

export type SpecificationCategory = {
  __typename: 'SpecificationCategory';
  id: string;
  name: string;
  order: number;
  specifications: Array<Specification>;
};

export type Specification = {
  __typename: 'Specification';
  id: string;
  input_type: SpecificationInputType;
  name: string;
  settings: Array<SpecificationSetting>;
  trial_id: string;
};

export enum SpecificationInputType {
  INPUT_MULTIVALUE = 'INPUT_MULTIVALUE',
  INPUT_SINGLEVALUE = 'INPUT_SINGLEVALUE',
}

export type SpecificationSetting = {
  __typename: 'SpecificationSetting';
  id: string;
  setting_key?: string | null;
  setting_value: string;
  specification_id: string;
  specification_type: SpecificationSettingType;
};

export enum SpecificationSettingType {
  SPECIFICATION_COMBINITVISITS = 'SPECIFICATION_COMBINITVISITS',
  SPECIFICATION_ENROLLPERIODMON = 'SPECIFICATION_ENROLLPERIODMON',
  SPECIFICATION_GENERAL = 'SPECIFICATION_GENERAL',
  SPECIFICATION_INVESTGCONTRACTTEMPLATE = 'SPECIFICATION_INVESTGCONTRACTTEMPLATE',
  SPECIFICATION_MAXNUMOFSITESIDENT = 'SPECIFICATION_MAXNUMOFSITESIDENT',
  SPECIFICATION_NUMOFCENTIRBANNRENEW = 'SPECIFICATION_NUMOFCENTIRBANNRENEW',
  SPECIFICATION_NUMOFCENTIRBS = 'SPECIFICATION_NUMOFCENTIRBS',
  SPECIFICATION_NUMOFCLETHICSCOMMSUBMSS = 'SPECIFICATION_NUMOFCLETHICSCOMMSUBMSS',
  SPECIFICATION_NUMOFCLINICALTRIALAPPSPREPSUB = 'SPECIFICATION_NUMOFCLINICALTRIALAPPSPREPSUB',
  SPECIFICATION_NUMOFCOUNTRIES = 'SPECIFICATION_NUMOFCOUNTRIES',
  SPECIFICATION_NUMOFCOUNTRIESBACKTRANSLATION = 'SPECIFICATION_NUMOFCOUNTRIESBACKTRANSLATION',
  SPECIFICATION_NUMOFCOUNTRIESPRODUCTLABEL = 'SPECIFICATION_NUMOFCOUNTRIESPRODUCTLABEL',
  SPECIFICATION_NUMOFCTAPPSNOTFS = 'SPECIFICATION_NUMOFCTAPPSNOTFS',
  SPECIFICATION_NUMOFEUAPPFORMS = 'SPECIFICATION_NUMOFEUAPPFORMS',
  SPECIFICATION_NUMOFINFORMCONSENT = 'SPECIFICATION_NUMOFINFORMCONSENT',
  SPECIFICATION_NUMOFIRBSUBMSS = 'SPECIFICATION_NUMOFIRBSUBMSS',
  SPECIFICATION_NUMOFLOCALETHSCOMMSUBMSS = 'SPECIFICATION_NUMOFLOCALETHSCOMMSUBMSS',
  SPECIFICATION_NUMOFLOCALINFORMCONSENT = 'SPECIFICATION_NUMOFLOCALINFORMCONSENT',
  SPECIFICATION_NUMOFLOCALINSCERTREVS = 'SPECIFICATION_NUMOFLOCALINSCERTREVS',
  SPECIFICATION_NUMOFOFCOUNTRIESINFORMCONSENT = 'SPECIFICATION_NUMOFOFCOUNTRIESINFORMCONSENT',
  SPECIFICATION_NUMOFPATIENTSCOMPLETE = 'SPECIFICATION_NUMOFPATIENTSCOMPLETE',
  SPECIFICATION_NUMOFPATIENTSENROLL = 'SPECIFICATION_NUMOFPATIENTSENROLL',
  SPECIFICATION_NUMOFPATIENTSSCREEN = 'SPECIFICATION_NUMOFPATIENTSSCREEN',
  SPECIFICATION_NUMOFSITEINITVISITS = 'SPECIFICATION_NUMOFSITEINITVISITS',
  SPECIFICATION_NUMOFSITEQUALCALLS = 'SPECIFICATION_NUMOFSITEQUALCALLS',
  SPECIFICATION_NUMOFSITEQUALVISITS = 'SPECIFICATION_NUMOFSITEQUALVISITS',
  SPECIFICATION_NUMOFSITES = 'SPECIFICATION_NUMOFSITES',
  SPECIFICATION_NUMOFSITESFORANALY = 'SPECIFICATION_NUMOFSITESFORANALY',
  SPECIFICATION_NUMOFSITESIDENT = 'SPECIFICATION_NUMOFSITESIDENT',
  SPECIFICATION_NUMOFSTARTSTUDYNOTF = 'SPECIFICATION_NUMOFSTARTSTUDYNOTF',
  SPECIFICATION_SITEDISTROBYCOUNTRY = 'SPECIFICATION_SITEDISTROBYCOUNTRY',
}

export type CreateSpecificationInput = {
  category_id: string;
  input_type: SpecificationInputType;
  name: string;
};

export type CreateSpecificationSettingInput = {
  setting_key?: string | null;
  setting_value: string;
  specification_id: string;
  specification_type?: SpecificationSettingType | null;
};

export type UpdateSpecificationInput = {
  category_id?: string | null;
  delete?: boolean | null;
  id: string;
  input_type?: SpecificationInputType | null;
  name?: string | null;
};

export type UpdateSpecificationSettingInput = {
  delete?: boolean | null;
  id: string;
  setting_key?: string | null;
  setting_value?: string | null;
  specification_type?: SpecificationSettingType | null;
};

export type Timeline = {
  __typename: 'Timeline';
  id: string;
  name: string;
  timeline_type: TimelineType;
};

export enum TimelineType {
  TIMELINE_TRIAL = 'TIMELINE_TRIAL',
}

export type MilestoneCategory = {
  __typename: 'MilestoneCategory';
  description?: string | null;
  grouping_order: number;
  id: string;
  milestones: Array<Milestone>;
  name: string;
};

export type Milestone = {
  __typename: 'Milestone';
  description?: string | null;
  id: string;
  milestone_category: MilestoneCategory;
  milestone_category_id: string;
  name: string;
  organizations_with_forecasts: Array<Organization>;
};

export type TimelineMilestone = {
  __typename: 'TimelineMilestone';
  actual_end_date?: string | null;
  actual_month_difference?: number | null;
  actual_start_date?: string | null;
  contract_end_date: string;
  contract_month_difference?: number | null;
  contract_start_date: string;
  error_message?: string | null;
  id: string;
  milestone: Milestone;
  milestone_id: string;
  revised_end_date?: string | null;
  revised_month_difference?: number | null;
  revised_start_date?: string | null;
  track_from_milestone?: Milestone | null;
  track_from_milestone_id?: string | null;
};

export type CreateTimelineMilestoneInput = {
  actual_end_date?: string | null;
  actual_start_date?: string | null;
  contract_end_date: string;
  contract_start_date: string;
  description?: string | null;
  id?: string | null;
  milestone_category_id: string;
  milestone_id?: string | null;
  name: string;
  revised_end_date?: string | null;
  revised_start_date?: string | null;
  timeline_id: string;
  track_from_milestone_id?: string | null;
};

export type UpdateTimelineMilestoneInput = {
  actual_end_date?: string | null;
  actual_start_date?: string | null;
  contract_end_date?: string | null;
  contract_start_date?: string | null;
  description?: string | null;
  id: string;
  milestone_category_id?: string | null;
  name?: string | null;
  revised_end_date?: string | null;
  revised_start_date?: string | null;
  track_from_milestone_id?: string | null;
};

export type CreateMilestoneInput = {
  description?: string | null;
  milestone_category_id: string;
  name: string;
};

export type UpdateMilestoneInput = {
  description?: string | null;
  id: string;
  milestone_category_id?: string | null;
  name?: string | null;
};

export type Trial = {
  __typename: 'Trial';
  auxilius_start_date?: string | null;
  currency: Currency;
  id: string;
  name: string;
  nct_id?: string | null;
  onboarding_complete: boolean;
  program?: string | null;
  project?: string | null;
  short_name: string;
  sponsor_organization: Organization;
  therapy_area: string;
  title: string;
};

export type CreateTrialInput = {
  auxilius_start_date?: string | null;
  currency?: Currency | null;
  nct_id: string;
  onboarding_complete: boolean;
  program?: string | null;
  project?: string | null;
};

export type UpdateTrialInput = {
  auxilius_start_date?: string | null;
  currency?: Currency | null;
  onboarding_complete?: boolean | null;
  program?: string | null;
  project?: string | null;
  short_name?: string | null;
  therapy_area?: string | null;
};

export type DynamoDbResponse = {
  __typename: 'DynamoDbResponse';
  primary_key: string;
  sort_key: string;
  trial_id: string;
};

export type Notification = {
  __typename: 'Notification';
  data: string;
  page: NotificationPage;
  status: NotificationStatus;
  sub: string;
  type: EventType;
};

export enum NotificationPage {
  AUDIT_LOG = 'AUDIT_LOG',
  BUDGET = 'BUDGET',
  CHANGE_ORDER = 'CHANGE_ORDER',
  FORECAST = 'FORECAST',
  GLOBAL = 'GLOBAL',
  INVOICE = 'INVOICE',
  IN_MONTH = 'IN_MONTH',
  IN_MONTH_ADJUSTMENTS = 'IN_MONTH_ADJUSTMENTS',
  PATIENT_TRACKER = 'PATIENT_TRACKER',
  PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE',
  PORTFOLIO_DASHBOARD = 'PORTFOLIO_DASHBOARD',
  QUARTER_CLOSE_CHECKLIST = 'QUARTER_CLOSE_CHECKLIST',
  SITES = 'SITES',
  SITE_CURVE = 'SITE_CURVE',
  TRIAL_SETTINGS = 'TRIAL_SETTINGS',
}

export enum NotificationStatus {
  ERROR = 'ERROR',
  EXPORT_READY = 'EXPORT_READY',
  SUCCESS = 'SUCCESS',
}

export type CreateManualTrialInput = {
  auxilius_start_date?: string | null;
  currency?: Currency | null;
  name: string;
  onboarding_complete: boolean;
  program?: string | null;
  project?: string | null;
  short_name: string;
  sponsor_organization_name: string;
  therapy_area: string;
  title: string;
};

export type PortfolioSummary = {
  __typename: 'PortfolioSummary';
  baseline_budget_estimate?: number | null;
  current_budget_expenses: Array<BudgetExpenseData>;
  current_month?: string | null;
  current_month_work_performed?: number | null;
  invoice_total?: number | null;
  trial_id: string;
  trial_short_name: string;
};

export type User = {
  __typename: 'User';
  department?: Array<DepartmentType | null> | null;
  email: string;
  family_name: string;
  given_name: string;
  last_login_date?: string | null;
  organization: Organization;
  permissions: Array<UserPermission>;
  role: RoleType;
  status?: boolean | null;
  sub: string;
  title: string;
  trial_id: string;
};

export enum DepartmentType {
  DEPARTMENT_TYPE_ACCOUNTING = 'DEPARTMENT_TYPE_ACCOUNTING',
  DEPARTMENT_TYPE_AUDITOR = 'DEPARTMENT_TYPE_AUDITOR',
  DEPARTMENT_TYPE_CLINICAL_OPERATIONS = 'DEPARTMENT_TYPE_CLINICAL_OPERATIONS',
  DEPARTMENT_TYPE_EXECUTIVE = 'DEPARTMENT_TYPE_EXECUTIVE',
  DEPARTMENT_TYPE_FINANCE = 'DEPARTMENT_TYPE_FINANCE',
}

export type UserPermission = {
  __typename: 'UserPermission';
  id: string;
  permission: string;
  permission_group: string;
  permission_id: string;
  permission_roles: string;
  permission_type: PermissionType;
  permission_user_profile: string;
};

export enum PermissionType {
  PERMISSION_APPROVE_CHANGE_ORDER = 'PERMISSION_APPROVE_CHANGE_ORDER',
  PERMISSION_APPROVE_INVOICE = 'PERMISSION_APPROVE_INVOICE',
  PERMISSION_APPROVE_IN_MONTH = 'PERMISSION_APPROVE_IN_MONTH',
  PERMISSION_CHECKLIST_ADMIN = 'PERMISSION_CHECKLIST_ADMIN',
  PERMISSION_CHECKLIST_CONTRACTS = 'PERMISSION_CHECKLIST_CONTRACTS',
  PERMISSION_CHECKLIST_DISCOUNTS = 'PERMISSION_CHECKLIST_DISCOUNTS',
  PERMISSION_CHECKLIST_FORECAST_METHODOLOGY = 'PERMISSION_CHECKLIST_FORECAST_METHODOLOGY',
  PERMISSION_CHECKLIST_INVOICES = 'PERMISSION_CHECKLIST_INVOICES',
  PERMISSION_CHECKLIST_PATIENT_DATA = 'PERMISSION_CHECKLIST_PATIENT_DATA',
  PERMISSION_CHECKLIST_PATIENT_SITE_CURVES = 'PERMISSION_CHECKLIST_PATIENT_SITE_CURVES',
  PERMISSION_CHECKLIST_TRIAL_TIMELINE = 'PERMISSION_CHECKLIST_TRIAL_TIMELINE',
  PERMISSION_CHECKLIST_VENDOR_ESTIMATES = 'PERMISSION_CHECKLIST_VENDOR_ESTIMATES',
  PERMISSION_CHECKLIST_VENDOR_EXPENSES = 'PERMISSION_CHECKLIST_VENDOR_EXPENSES',
  PERMISSION_DELETE_INVOICE = 'PERMISSION_DELETE_INVOICE',
  PERMISSION_EDIT_INVOICE = 'PERMISSION_EDIT_INVOICE',
  PERMISSION_MODIFY_FORECAST_METHODOLOGY = 'PERMISSION_MODIFY_FORECAST_METHODOLOGY',
  PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS = 'PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS',
  PERMISSION_MODIFY_PATIENT_CURVE = 'PERMISSION_MODIFY_PATIENT_CURVE',
  PERMISSION_MODIFY_SITE_CURVE = 'PERMISSION_MODIFY_SITE_CURVE',
  PERMISSION_MODIFY_TRIAL_TIMELINE = 'PERMISSION_MODIFY_TRIAL_TIMELINE',
  PERMISSION_TEMPLATE_UPLOADS = 'PERMISSION_TEMPLATE_UPLOADS',
  PERMISSION_UPDATE_USERS = 'PERMISSION_UPDATE_USERS',
  PERMISSION_UPDATE_USER_PERMISSIONS = 'PERMISSION_UPDATE_USER_PERMISSIONS',
}

export enum RoleType {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
}

export type UserInput = {
  email: string;
  family_name: string;
  given_name: string;
  title: string;
};

export type AuxUser = {
  __typename: 'AuxUser';
  email: string;
  family_name: string;
  given_name: string;
  sub: string;
  title: string;
};

export enum ViewLocation {
  VIEW_LOCATION_BUDGET_GRID = 'VIEW_LOCATION_BUDGET_GRID',
}

export type UserCustomView = {
  __typename: 'UserCustomView';
  id: string;
  is_custom: boolean;
  metadata: string;
  name: string;
  user_id?: string | null;
  view_location: ViewLocation;
};

export type UserTrialInput = {
  department?: Array<DepartmentType | null> | null;
  family_name: string;
  given_name: string;
  organization_id: string;
  role: RoleType;
  title: string;
  trial_id?: string | null;
  user_email?: string | null;
  user_id: string;
};

export type UserPermissionInput = {
  permission_id: string;
  permission_roles: string;
  trial_id?: string | null;
  user_id: string;
};

export type Permission = {
  __typename: 'Permission';
  id: string;
  permission: string;
  permission_group: string;
  permission_type: PermissionType;
  permission_user_profile: string;
};

export type NotificationPreference = {
  __typename: 'NotificationPreference';
  enabled: boolean;
  notification: NotificationType;
};

export type NotificationType = {
  __typename: 'NotificationType';
  event_type: string;
  event_type_group: string;
  id: string;
  is_admin: boolean;
};

export type UpdateNotificationPreferenceInput = {
  enabled: boolean;
  event_type: EventType;
};

export type LoggedInUser = {
  __typename: 'LoggedInUser';
  all_departments?: Array<DepartmentType | null> | null;
  department?: Array<DepartmentType | null> | null;
  family_name?: string | null;
  given_name?: string | null;
  is_sys_admin: boolean;
  organization_id?: string | null;
  permissions: Array<PermissionType>;
  roles: Array<string>;
  sub: string;
  title?: string | null;
  trial_id?: string | null;
};

export enum TrialPreferenceType {
  BUDGET_GRID_YEARS = 'BUDGET_GRID_YEARS',
}

export type DynamoDbTrialPreferenceResponse = {
  __typename: 'DynamoDbTrialPreferenceResponse';
  primary_key: string;
  sort_key: string;
  value: string;
};

export type SetTrialPreferenceInput = {
  preference_type: TrialPreferenceType;
  value: string;
};

export type UserTask = {
  __typename: 'UserTask';
  create_date: string;
  due_date?: string | null;
  organization_id?: string | null;
  organization_name?: string | null;
  task_header: string;
  task_id: string;
  trial_id: string;
  trial_name: string;
  user_task: UserTaskType;
};

export enum UserTaskType {
  CHANGE_ORDER = 'CHANGE_ORDER',
  FORECAST_VENDOR_COSTS = 'FORECAST_VENDOR_COSTS',
  INVOICE = 'INVOICE',
  PERIOD_CLOSE = 'PERIOD_CLOSE',
  VENDOR_ESTIMATE = 'VENDOR_ESTIMATE',
}

export type CreateUserCustomViewInput = {
  metadata: string;
  name: string;
  user_id: string;
  view_location: ViewLocation;
};

export type UpdateUserCustomViewInput = {
  id: string;
  metadata?: string | null;
  name?: string | null;
};

export type ListWorkflowDetailsInput = {
  entity_id?: string | null;
  properties?: string | null;
  type?: string | null;
};

export type ClosingChecklistUpdate = {
  __typename: 'ClosingChecklistUpdate';
  step: WorkflowStep;
  update_date?: string | null;
  updated_by?: string | null;
};

export type getTemplatePathQuery = {
  __typename: 'GenericResponse';
  id: string;
};

export type approveRuleMutation = {
  __typename: 'GenericResponse';
  id: string;
};

export type createNoteMutation = {
  __typename: 'Note';
  id: string;
};

export type batchCreateNotesMutation = {
  __typename: 'Note';
  id: string;
  entity_id: string;
  expense_note_types: Array<ExpenseNoteType>;
};

export type getS3ArchiveQuery = {
  __typename: 'GenericResponse';
  id: string;
};

export type fetchUserAuditLogsQuery = {
  __typename: 'FetchUserAuditLogsResponse';
  items: Array<{
    __typename: 'UserAuditLog';
    id: string;
    entity_id?: string | null;
    entity_type?: EntityType | null;
    category?: UserAuditLogCategory | null;
    action?: UserAuditLogAction | null;
    action_text?: string | null;
    properties_changed_json?: string | null;
    details_json?: string | null;
    is_custom?: boolean | null;
    created_by: string;
    create_date: string;
    vendor_id?: string | null;
  }>;
  last_row: number;
  meta_data?: {
    __typename: 'UserAuditLogsMetaData';
    total_count?: number | null;
  } | null;
};

export type listIntegrationConnectionsQuery = {
  __typename: 'IntegrationConnection';
  data_source: DataSource;
  name: string;
  enabled: boolean;
  is_oauth: boolean;
  is_connected: boolean;
  last_connection_date?: string | null;
};

export type getOAuthLoginUrlQuery = {
  __typename: 'GetOAuthLoginUrlResponse';
  login_url: string;
  callback_origin: string;
};

export type listDefaultExpenseSourcesQuery = {
  __typename: 'DefaultExpenseSource';
  cost_category: CategoryType;
  organization_id: string;
  sources: Array<ExpenseSourceType>;
};

export type exchangeOAuthCodeMutation = {
  __typename: 'IntegrationConnection';
  data_source: DataSource;
  name: string;
  enabled: boolean;
  is_oauth: boolean;
  is_connected: boolean;
  last_connection_date?: string | null;
};

export type revokeOAuthTokenMutation = {
  __typename: 'IntegrationConnection';
  data_source: DataSource;
  name: string;
  enabled: boolean;
  is_oauth: boolean;
  is_connected: boolean;
  last_connection_date?: string | null;
};

export type batchUpdateDefaultExpenseSourcesMutation = {
  __typename: 'DefaultExpenseSource';
  cost_category: CategoryType;
  organization_id: string;
  sources: Array<ExpenseSourceType>;
};

export type listBudgetGridQuery = {
  __typename: 'BudgetView';
  cache_info?: {
    __typename: 'CacheInfo';
    cache_file: string;
  } | null;
  budget_data?: Array<{
    __typename: 'BudgetData';
    activity_no?: string | null;
    activity_id?: string | null;
    activity_name?: string | null;
    activity_name_label?: string | null;
    cost_category?: string | null;
    cost_category_ordering?: number | null;
    contract_unit_cost_currency?: string | null;
    direct_cost?: number | null;
    contract_direct_cost?: number | null;
    contract_unit_cost?: number | null;
    expense_note?: string | null;
    expenses?: Array<{
      __typename: 'BudgetExpenseData';
      amount?: number | null;
      amount_type?: AmountType | null;
      expense_type?: ExpenseType | null;
      period?: string | null;
      source?: string | null;
      contract_curr?: string | null;
      contract_amount?: number | null;
    }> | null;
    attributes?: Array<{
      __typename: 'BudgetActivityAttributes';
      attribute?: string | null;
      attribute_name?: string | null;
      attribute_value?: string | null;
    }> | null;
    group0?: string | null;
    group1?: string | null;
    group2?: string | null;
    group3?: string | null;
    group4?: string | null;
    group_index?: number | null;
    section_path?: string | null;
    unit_cost?: number | null;
    unit_num?: number | null;
    uom?: string | null;
    vendor_id?: string | null;
  }> | null;
  budget_info?: Array<{
    __typename: 'BudgetInfo';
    expense_amounts: Array<{
      __typename: 'ExpenseAmount';
      amount?: number | null;
      amount_type: string;
      amount_perct?: number | null;
      id?: string | null;
      amount_curr: string;
      entity_id: string;
      entity_type_id: string;
    }>;
    discount_type?: DiscountType | null;
    base_budget_version?: string | null;
    budget_type?: BudgetType | null;
    budget_version?: string | null;
    budget_version_id?: string | null;
    manual_forecast?: boolean | null;
    vendor_id?: string | null;
    current_month?: string | null;
    description?: string | null;
    eom_month?: string | null;
    major_version?: string | null;
    minor_version?: string | null;
    name?: string | null;
    source?: string | null;
    vendor_name_budget?: string | null;
  }> | null;
  header_data?: Array<{
    __typename: 'BudgetHeader';
    date_headers: Array<string>;
    expense_type?: string | null;
    group_name?: string | null;
  }> | null;
};

export type listBudgetGridV2Query = {
  __typename: 'BudgetView';
  cache_info?: {
    __typename: 'CacheInfo';
    cache_file: string;
  } | null;
  budget_data?: Array<{
    __typename: 'BudgetData';
    activity_no?: string | null;
    activity_id?: string | null;
    activity_name?: string | null;
    cost_category?: string | null;
    cost_category_ordering?: number | null;
    contract_unit_cost_currency?: string | null;
    direct_cost?: number | null;
    contract_direct_cost?: number | null;
    contract_unit_cost?: number | null;
    expense_note?: string | null;
    expenses?: Array<{
      __typename: 'BudgetExpenseData';
      amount?: number | null;
      amount_type?: AmountType | null;
      expense_type?: ExpenseType | null;
      period?: string | null;
      source?: string | null;
      contract_curr?: string | null;
      contract_amount?: number | null;
    }> | null;
    attributes?: Array<{
      __typename: 'BudgetActivityAttributes';
      attribute?: string | null;
      attribute_name?: string | null;
      attribute_value?: string | null;
    }> | null;
    group0?: string | null;
    group1?: string | null;
    group2?: string | null;
    group3?: string | null;
    group4?: string | null;
    group_index?: number | null;
    section_path?: string | null;
    unit_cost?: number | null;
    unit_num?: number | null;
    uom?: string | null;
    vendor_id?: string | null;
  }> | null;
  budget_info?: Array<{
    __typename: 'BudgetInfo';
    expense_amounts: Array<{
      __typename: 'ExpenseAmount';
      amount?: number | null;
      amount_type: string;
      amount_perct?: number | null;
      id?: string | null;
      amount_curr: string;
      entity_id: string;
      entity_type_id: string;
    }>;
    discount_type?: DiscountType | null;
    base_budget_version?: string | null;
    budget_type?: BudgetType | null;
    budget_version?: string | null;
    budget_version_id?: string | null;
    manual_forecast?: boolean | null;
    vendor_id?: string | null;
    current_month?: string | null;
    description?: string | null;
    eom_month?: string | null;
    major_version?: string | null;
    minor_version?: string | null;
    name?: string | null;
    source?: string | null;
    vendor_name_budget?: string | null;
  }> | null;
  header_data?: Array<{
    __typename: 'BudgetHeader';
    date_headers: Array<string>;
    expense_type?: string | null;
    group_name?: string | null;
  }> | null;
};

export type listMonthCloseApprovalsQuery = {
  __typename: 'Approval';
  aux_user_id?: string | null;
  permission?: string | null;
  activity_details?: string | null;
  category?: string | null;
  vendor_id?: string | null;
};

export type listBudgetVersionsQuery = {
  __typename: 'BudgetVersion';
  budget_version_id: string;
  manual_forecast: boolean;
  target_month?: string | null;
  budget_name: string;
  bucket_key?: string | null;
  budget_type: string;
  discount_type?: DiscountType | null;
  snapshot_type?: BudgetSnapshotType | null;
  budget_snapshot_id?: string | null;
  is_change_order?: boolean | null;
  version: string;
  vendor_id?: string | null;
  create_date: string;
};

export type currentOpenBudgetMonthQuery = {
  __typename: 'TrialInformation';
  trial_month_close: string;
};

export type listBudgetLibraryQuery = {
  __typename: 'BudgetVersion';
  vendor_name?: string | null;
  budget_version_id: string;
  manual_forecast: boolean;
  budget_name: string;
  bucket_key?: string | null;
  version: string;
  vendor_id?: string | null;
  create_date: string;
  created_by?: string | null;
  is_current?: boolean | null;
  is_change_order?: boolean | null;
  is_baseline?: boolean | null;
  budget_type: string;
  total_budget_amount?: number | null;
  entity_status?: ChangeOrderStatus | null;
};

export type listBudgetVersionsExpensesQuery = {
  __typename: 'BudgetVersion';
  budget_version_id: string;
  discount_type?: DiscountType | null;
  is_current?: boolean | null;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_perct?: number | null;
    amount_type: string;
  }>;
};

export type updateBudgetVersionMutation = {
  __typename: 'BudgetVersion';
  budget_id: string;
};

export type processEventMutation = {
  __typename: 'GenericResponse';
  id: string;
};

export type createBudgetExpenseMutation = {
  __typename: 'BudgetExpense';
  id: string;
};

export type batchCreateBudgetExpensesMutation = {
  __typename: 'BudgetExpense';
  id: string;
};

export type removeVendorEstimateMutation = {
  __typename: 'GenericResponse';
  id: string;
};

export type setBudgetVersionAsBaselineMutation = {
  __typename: 'BudgetVersion';
  budget_id: string;
  budget_version_id: string;
  vendor_id?: string | null;
  vendor_name?: string | null;
  is_current?: boolean | null;
  is_baseline?: boolean | null;
  is_change_order?: boolean | null;
  bucket_key?: string | null;
  budget_name: string;
  version: string;
  budget_type: string;
  discount_type?: DiscountType | null;
  manual_forecast: boolean;
  create_date: string;
  created_by?: string | null;
  entity_id: string;
  entity_type: EntityType;
};

export type removeBudgetVersionMutation = {
  __typename: 'BudgetVersion';
  budget_id: string;
  budget_version_id: string;
  bucket_key?: string | null;
  budget_name: string;
};

export type invalidateBudgetCacheMutation = {
  __typename: 'GenericResponse';
  id: string;
};

export type updateAccrualsMutation = {
  __typename: 'BudgetExpense';
  id: string;
  activity_id?: string | null;
  category_id?: string | null;
  expense_type_id?: string | null;
  period_start?: string | null;
  period_end?: string | null;
  source?: string | null;
  expense_detail?: string | null;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    id?: string | null;
    amount_type: string;
    amount_curr: string;
    entity_id: string;
    entity_type_id: string;
    amount?: number | null;
  }>;
  expense_source?: ExpenseSourceType | null;
  details?: string | null;
};

export type listActivityVariancesQuery = {
  __typename: 'ActivityVariance';
  activity_id?: string | null;
  activity_name?: string | null;
  activity_type?: ActivityType | null;
  variance?: number | null;
  variance_percentage?: number | null;
  variance_type: VarianceType;
  variance_unit_of_measure?: string | null;
};

export type getRemainingSpendQuery = {
  __typename: 'GroupedRemainingSpend';
  entity_id?: string | null;
  entity_name: string;
  entity_type: EntityType;
  remaining_spend: {
    __typename: 'RemainingSpend';
    amount: number;
    period: string;
    percentage?: number | null;
  };
  remaining_spend_time_series: Array<{
    __typename: 'RemainingSpend';
    amount: number;
    period: string;
  }>;
};

export type listChangeLogsQuery = {
  __typename: 'ChangeLog';
  id: string;
  vendor_id: string;
  change_order_id?: string | null;
  change_log_status?: string | null;
  log_no: number;
};

export type listChangeLogItemsQuery = {
  __typename: 'ChangeLogItem';
  id: string;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
  cnf_no: number;
  change_log_item_status: ChangeLogItemStatus;
  change_order_reference?: string | null;
  change_log_id: string;
  activity_id?: string | null;
  request_date?: string | null;
  start_date?: string | null;
  description?: string | null;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount_type: string;
    amount?: number | null;
  }>;
  cost_driver_description?: string | null;
  cause?: string | null;
  requester?: RequesterType | null;
  is_planned?: boolean | null;
};

export type createChangeLogItemMutation = {
  __typename: 'ChangeLogItem';
  id: string;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
  cnf_no: number;
  change_log_item_status: ChangeLogItemStatus;
  change_order_reference?: string | null;
  change_log_id: string;
  activity_id?: string | null;
  request_date?: string | null;
  start_date?: string | null;
  description?: string | null;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount_type: string;
    amount?: number | null;
  }>;
  cause?: string | null;
  requester?: RequesterType | null;
  is_planned?: boolean | null;
  cost_driver_description?: string | null;
};

export type removeChangeLogItemMutation = {
  __typename: 'ChangeLogItem';
  id: string;
};

export type updateChangeLogItemMutation = {
  __typename: 'ChangeLogItem';
  id: string;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
  cnf_no: number;
  change_log_item_status: ChangeLogItemStatus;
  change_order_reference?: string | null;
  change_log_id: string;
  activity_id?: string | null;
  request_date?: string | null;
  start_date?: string | null;
  description?: string | null;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount_type: string;
    amount?: number | null;
  }>;
  cost_driver_description?: string | null;
  cause?: string | null;
  requester?: RequesterType | null;
  is_planned?: boolean | null;
};

export type listChangeOrdersStatusesQuery = {
  __typename: 'ChangeOrder';
  id: string;
  change_order_no: string;
  change_order_status: ChangeOrderStatus;
};

export type listChangeOrdersQuery = {
  __typename: 'ChangeOrder';
  id: string;
  change_order_no: string;
  change_order_status: ChangeOrderStatus;
  date_received: string;
  approvals?: Array<{
    __typename: 'Approval';
    aux_user_id?: string | null;
    approval_time?: string | null;
  } | null> | null;
  workflow_details: Array<{
    __typename: 'WorkflowDetail';
    id: string;
    properties: string;
  }>;
  reasons: Array<{
    __typename: 'Note';
    note_type: NoteType;
    message: string;
  }>;
  effective_date: string;
  notes?: string | null;
  create_date: string;
  merged_budget_version?: {
    __typename: 'BudgetVersion';
    budget_version_id: string;
    budget_name: string;
    budget_type: string;
  } | null;
  budget_total?: {
    __typename: 'GenericVariance';
    prev_value?: number | null;
    value?: number | null;
    variance?: number | null;
    variance_percentage?: number | null;
  } | null;
  created_by: string;
  decline_reason?: string | null;
  approved_budget_version?: {
    __typename: 'BudgetVersion';
    budget_version_id: string;
  } | null;
  organization: {
    __typename: 'Organization';
    id: string;
  };
};

export type listChangeOrderNosQuery = {
  __typename: 'ChangeOrder';
  id: string;
  change_order_no: string;
};

export type getChangeOrderQuery = {
  __typename: 'ChangeOrder';
  id: string;
  change_order_no: string;
  change_order_status: ChangeOrderStatus;
  date_received: string;
  workflow_details: Array<{
    __typename: 'WorkflowDetail';
    id: string;
    name: string;
    properties: string;
  }>;
  approvals?: Array<{
    __typename: 'Approval';
    aux_user_id?: string | null;
    permission?: string | null;
    approval_time?: string | null;
  } | null> | null;
  effective_date: string;
  notes?: string | null;
  create_date: string;
  created_by: string;
  decline_reason?: string | null;
  reasons: Array<{
    __typename: 'Note';
    id: string;
    message: string;
    note_type: NoteType;
    create_date: string;
    created_by: string;
    entity_id: string;
    expense_note_types: Array<ExpenseNoteType>;
    entity_type: EntityType;
  }>;
  approved_budget_version?: {
    __typename: 'BudgetVersion';
    budget_id: string;
    budget_version_id: string;
    bucket_key?: string | null;
    budget_name: string;
    version: string;
    budget_type: string;
    manual_forecast: boolean;
    create_date: string;
    entity_id: string;
    entity_type: EntityType;
  } | null;
  organization: {
    __typename: 'Organization';
    id: string;
  };
};

export type createChangeOrderMutation = {
  __typename: 'ChangeOrder';
  id: string;
  change_order_no: string;
  change_order_status: ChangeOrderStatus;
  date_received: string;
  workflow_details: Array<{
    __typename: 'WorkflowDetail';
    id: string;
    name: string;
    properties: string;
  }>;
  approvals?: Array<{
    __typename: 'Approval';
    aux_user_id?: string | null;
    permission?: string | null;
    approval_time?: string | null;
  } | null> | null;
  effective_date: string;
  notes?: string | null;
  create_date: string;
  created_by: string;
  decline_reason?: string | null;
  approved_budget_version?: {
    __typename: 'BudgetVersion';
    budget_id: string;
    budget_version_id: string;
    bucket_key?: string | null;
    budget_name: string;
    version: string;
    budget_type: string;
    manual_forecast: boolean;
    create_date: string;
    entity_id: string;
    entity_type: EntityType;
  } | null;
  organization: {
    __typename: 'Organization';
    id: string;
  };
};

export type updateChangeOrderMutation = {
  __typename: 'ChangeOrder';
  id: string;
  change_order_no: string;
  change_order_status: ChangeOrderStatus;
  date_received: string;
  workflow_details: Array<{
    __typename: 'WorkflowDetail';
    id: string;
    name: string;
    properties: string;
  }>;
  approvals?: Array<{
    __typename: 'Approval';
    aux_user_id?: string | null;
    permission?: string | null;
    approval_time?: string | null;
  } | null> | null;
  effective_date: string;
  notes?: string | null;
  create_date: string;
  created_by: string;
  decline_reason?: string | null;
  approved_budget_version?: {
    __typename: 'BudgetVersion';
    budget_id: string;
    budget_version_id: string;
    bucket_key?: string | null;
    budget_name: string;
    version: string;
    budget_type: string;
    manual_forecast: boolean;
    create_date: string;
    entity_id: string;
    entity_type: EntityType;
  } | null;
  organization: {
    __typename: 'Organization';
    id: string;
  };
};

export type removeChangeOrderMutation = {
  __typename: 'ChangeOrder';
  id: string;
};

export type approveChangeOrderMutation = {
  __typename: 'ChangeOrder';
  id: string;
  change_order_status: ChangeOrderStatus;
  approved_budget_version?: {
    __typename: 'BudgetVersion';
    budget_id: string;
    budget_version_id: string;
    bucket_key?: string | null;
    budget_name: string;
    version: string;
    budget_type: string;
    manual_forecast: boolean;
    create_date: string;
    entity_id: string;
    entity_type: EntityType;
  } | null;
  approvals?: Array<{
    __typename: 'Approval';
    aux_user_id?: string | null;
    permission?: string | null;
    approval_time?: string | null;
  } | null> | null;
};

export type listMonthlyExchangeRatesQuery = {
  __typename: 'ListMonthlyExchangeRatesResponse';
  currencies: Array<Currency>;
  exchange_rates: Array<{
    __typename: 'MonthlyExchangeRate';
    base_currency: Currency;
    month: string;
    rate: number;
    target_currency: Currency;
  }>;
  months: Array<string>;
  trial_currency: Currency;
  update_date?: string | null;
};

export type createDataStreamMutation = {
  __typename: 'CreateDataStreamResponse';
  stream_id: string;
  total_rows: number;
  bucket_key?: string | null;
};

export type removeDataStreamMutation = {
  __typename: 'GenericResponse';
  id: string;
};

export type fetchDocumentsDataStreamQuery = {
  __typename: 'FetchDocumentsDataStreamResponse';
  total_rows: number;
  aggregation: {
    __typename: 'Document';
    id: string;
    trial_id: string;
    vendor_id?: string | null;
    site_id?: string | null;
    document_type_id?: DocumentType | null;
    name: string;
    description?: string | null;
    bucket_key: string;
    target_date?: string | null;
    is_metadata_editable?: boolean | null;
    create_date: string;
    created_by: string;
    entity_type_id?: string | null;
    entity_id?: string | null;
  };
  offset: number;
  items: Array<{
    __typename: 'Document';
    id: string;
    trial_id: string;
    vendor_id?: string | null;
    site_id?: string | null;
    document_type_id?: DocumentType | null;
    name: string;
    description?: string | null;
    bucket_key: string;
    target_date?: string | null;
    is_metadata_editable?: boolean | null;
    create_date: string;
    created_by: string;
    entity_type_id?: string | null;
    entity_id?: string | null;
  }>;
};

export type fetchUserAuditLogsDataStreamQuery = {
  __typename: 'FetchUserAuditLogsDataStreamResponse';
  total_rows: number;
  aggregation: {
    __typename: 'UserAuditLog';
    id: string;
    entity_id?: string | null;
    entity_type?: EntityType | null;
    category?: UserAuditLogCategory | null;
    action?: UserAuditLogAction | null;
    action_text?: string | null;
    properties_changed_json?: string | null;
    details_json?: string | null;
    is_custom?: boolean | null;
    created_by: string;
    create_date: string;
    vendor_id?: string | null;
  };
  offset: number;
  items: Array<{
    __typename: 'UserAuditLog';
    id: string;
    entity_id?: string | null;
    entity_type?: EntityType | null;
    category?: UserAuditLogCategory | null;
    action?: UserAuditLogAction | null;
    action_text?: string | null;
    properties_changed_json?: string | null;
    details_json?: string | null;
    is_custom?: boolean | null;
    created_by: string;
    create_date: string;
    vendor_id?: string | null;
  }>;
};

export type listDocumentsQuery = {
  __typename: 'FetchDocumentsResponse';
  items: Array<{
    __typename: 'Document';
    bucket_key: string;
    create_date: string;
    created_by: string;
    description?: string | null;
    document_type_id?: DocumentType | null;
    entity_id?: string | null;
    entity_type_id?: string | null;
    id: string;
    is_metadata_editable?: boolean | null;
    name: string;
    site_id?: string | null;
    target_date?: string | null;
    trial_id: string;
    vendor_id?: string | null;
  }>;
  last_row: number;
  meta_data?: {
    __typename: 'DocumentsMetaData';
    total_count?: number | null;
  } | null;
};

export type batchCreateDocumentsMutation = {
  __typename: 'Document';
  id: string;
};

export type createDocumentMutation = {
  __typename: 'Document';
  id: string;
};

export type removeDocumentMutation = {
  __typename: 'Document';
  id: string;
};

export type updateDocumentMutation = {
  __typename: 'Document';
  bucket_key: string;
  create_date: string;
  created_by: string;
  description?: string | null;
  document_type_id?: DocumentType | null;
  entity_id?: string | null;
  entity_type_id?: string | null;
  id: string;
  is_metadata_editable?: boolean | null;
  name: string;
  site_id?: string | null;
  target_date?: string | null;
  trial_id: string;
  vendor_id?: string | null;
};

export type createStoredDocumentMutation = {
  __typename: 'StoredDocument';
  id: string;
};

export type listDriverPatientDistributionsQuery = {
  __typename: 'DriverPatientDistribution';
  distribution_month: string;
  forecast: {
    __typename: 'PatientEnrollmentData';
    id?: string | null;
    distribution_mode: DistributionMode;
    patients_enrolled?: number | null;
    patients_discontinued?: number | null;
    patients_complete?: number | null;
    net_patients_enrolled?: number | null;
    cumulative_enrollment_percentage?: number | null;
    total_patients_enrolled?: number | null;
  };
  actual?: {
    __typename: 'PatientEnrollmentData';
    distribution_mode: DistributionMode;
    patients_enrolled?: number | null;
    patients_discontinued?: number | null;
    patients_complete?: number | null;
    net_patients_enrolled?: number | null;
    cumulative_enrollment_percentage?: number | null;
    total_patients_enrolled?: number | null;
  } | null;
};

export type listDriverPatientGroupsQuery = {
  __typename: 'DriverPatientGroup';
  constituent_patient_groups: Array<{
    __typename: 'PatientGroup';
    id: string;
    name: string;
    description?: string | null;
    rank_order?: number | null;
  }>;
  driver_setting_id: string;
  is_blended: boolean;
  organizations_with_forecasts: Array<{
    __typename: 'Organization';
    id: string;
    name: string;
  }>;
  name: string;
  patient_group_id?: string | null;
};

export type updateDriverBlendedPatientDistributionMutation = {
  __typename: 'DriverPatientGroup';
  driver_setting_id: string;
  patient_group_id?: string | null;
  name: string;
  is_blended: boolean;
};

export type removeDriverBlendedPatientDistributionMutation = {
  __typename: 'DriverPatientGroup';
  driver_setting_id: string;
  patient_group_id?: string | null;
  name: string;
  is_blended: boolean;
};

export type removePatientGroupDriverMutation = {
  __typename: 'DriverPatientGroup';
  driver_setting_id: string;
  patient_group_id?: string | null;
  name: string;
  is_blended: boolean;
};

export type createDriverBlendedPatientDistributionMutation = {
  __typename: 'DriverPatientGroup';
  driver_setting_id: string;
  patient_group_id?: string | null;
  name: string;
  is_blended: boolean;
  constituent_patient_groups: Array<{
    __typename: 'PatientGroup';
    id: string;
  }>;
};

export type getRemainingInvestigatorInfoQuery = {
  __typename: 'RemainingInvestigatorInfo';
  amount_remaining?: number | null;
  expected_patients_enrolled: number;
  patients_enrolled: number;
};

export type createDriverSiteSettingMutation = {
  __typename: 'DriverSiteSetting';
  id: string;
  cohort_name: string;
  number_of_sites: number;
  first_initiated_date: string;
  discontinued_date: string;
  ramp_time_in_weeks: number;
  target_patient_count: number;
};

export type updateDriverSiteSettingMutation = {
  __typename: 'DriverSiteSetting';
  id: string;
  cohort_name: string;
  number_of_sites: number;
  first_initiated_date: string;
  discontinued_date: string;
  ramp_time_in_weeks: number;
  target_patient_count: number;
};

export type listDriverSiteSettingQuery = {
  __typename: 'DriverSiteSetting';
  id: string;
  cohort_name: string;
  number_of_sites: number;
  first_initiated_date: string;
  discontinued_date: string;
  ramp_time_in_weeks: number;
  target_patient_count: number;
};

export type removeDriverSiteSettingMutation = {
  __typename: 'DriverSiteSetting';
  id: string;
};

export type listDriverSiteDistributionsQuery = {
  __typename: 'DriverSiteDistribution';
  id: string;
  distribution_mode: DistributionMode;
  distribution_month: string;
  sites_activated?: number | null;
  sites_closed?: number | null;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
  sites_activated_percentage?: number | null;
  sites_closed_percentage?: number | null;
};

export type listDriverSiteGroupsQuery = {
  __typename: 'DriverSiteGroup';
  driver_setting_id: string;
  site_group_id?: string | null;
  name: string;
  is_blended: boolean;
  curve_type: CurveType;
  organizations_with_forecasts: Array<{
    __typename: 'Organization';
    id: string;
    name: string;
  }>;
  constituent_site_groups: Array<{
    __typename: 'SiteGroup';
    id: string;
    name: string;
    description?: string | null;
    rank_order?: number | null;
  }>;
};

export type removeSiteGroupDriverMutation = {
  __typename: 'DriverSiteGroup';
  driver_setting_id: string;
  is_blended: boolean;
  name: string;
  site_group_id?: string | null;
};

export type createDriverBlendedSiteDistributionMutation = {
  __typename: 'DriverSiteGroup';
  driver_setting_id: string;
  is_blended: boolean;
  name: string;
  site_group_id?: string | null;
};

export type updateDriverBlendedSiteDistributionMutation = {
  __typename: 'DriverSiteGroup';
  driver_setting_id: string;
  is_blended: boolean;
  name: string;
  site_group_id?: string | null;
};

export type removeDriverBlendedSiteDistributionMutation = {
  __typename: 'DriverSiteGroup';
  driver_setting_id: string;
  is_blended: boolean;
  name: string;
  site_group_id?: string | null;
};

export type listInMonthCategoriesQuery = {
  __typename: 'Category';
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    category_type: CategoryType;
    expenses?: Array<{
      __typename: 'BudgetExpenseData';
      amount?: number | null;
      amount_type?: AmountType | null;
      expense_type?: ExpenseType | null;
      period?: string | null;
    }> | null;
  }>;
};

export type getUnforecastedEntityIdsQuery = {
  __typename: 'UnforecastedEntityIds';
  activity_ids: Array<string>;
  category_ids: Array<string>;
};

export type getInMonthListCategoryAndItsActivitiesQuery = {
  __typename: 'Category';
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    category_type: CategoryType;
    expenses?: Array<{
      __typename: 'BudgetExpenseData';
      amount?: number | null;
      amount_type?: AmountType | null;
      expense_type?: ExpenseType | null;
      period?: string | null;
    }> | null;
  }>;
  activities: Array<{
    __typename: 'Activity';
    id: string;
    name: string;
    unit_cost?: number | null;
    activity_type: ActivityType;
    budget_override_settings?: {
      __typename: 'BudgetExpense';
      id: string;
      notes: Array<{
        __typename: 'Note';
        id: string;
        entity_id: string;
        message: string;
        create_date: string;
        created_by: string;
      }>;
    } | null;
    expenses?: Array<{
      __typename: 'BudgetExpenseData';
      amount?: number | null;
      amount_type?: AmountType | null;
      expense_type?: ExpenseType | null;
      period?: string | null;
    }> | null;
  }>;
};

export type getCategoryAndItsActivitiesQuery = {
  __typename: 'Category';
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    display_label?: string | null;
    expenses?: Array<{
      __typename: 'BudgetExpenseData';
      amount?: number | null;
      amount_type?: AmountType | null;
      expense_type?: ExpenseType | null;
      period?: string | null;
    }> | null;
    category_type: CategoryType;
    budget_forecast_settings?: {
      __typename: 'BudgetForecastSetting';
      id: string;
      forecast_method?: ForecastMethodType | null;
      driver_setting_id?: string | null;
      category_id?: string | null;
      activity_id?: string | null;
      driver?: DriverType | null;
      period_start_milestone_id?: string | null;
      period_end_milestone_id?: string | null;
      period_start_date?: string | null;
      period_end_date?: string | null;
      evidence_source?: EvidenceSourceType | null;
      amount_type: AmountType;
      override: boolean;
      milestone_category?: string | null;
    } | null;
    activity_driver_settings?: Array<{
      __typename: 'ActivityDriverSetting';
      activity_override: boolean;
      category_override: boolean;
      driver?: string | null;
      setting_count: number;
    }> | null;
  }>;
  activities: Array<{
    __typename: 'Activity';
    id: string;
    uom?: string | null;
    unit_num?: number | null;
    expenses?: Array<{
      __typename: 'BudgetExpenseData';
      amount?: number | null;
      amount_type?: AmountType | null;
      expense_type?: ExpenseType | null;
      period?: string | null;
    }> | null;
    activity_type: ActivityType;
    budget_override_note?: string | null;
    budget_forecast_settings?: {
      __typename: 'BudgetForecastSetting';
      id: string;
      forecast_method?: ForecastMethodType | null;
      category_id?: string | null;
      activity_id?: string | null;
      driver_setting_id?: string | null;
      driver?: DriverType | null;
      period_start_milestone_id?: string | null;
      period_end_milestone_id?: string | null;
      period_start_date?: string | null;
      period_end_date?: string | null;
      evidence_source?: EvidenceSourceType | null;
      amount_type: AmountType;
      override: boolean;
      milestone_category?: string | null;
    } | null;
    budget_override_settings?: {
      __typename: 'BudgetExpense';
      id: string;
      category_id?: string | null;
      activity_id?: string | null;
      expense_amounts: Array<{
        __typename: 'ExpenseAmount';
        units?: number | null;
      }>;
      expense_detail?: string | null;
    } | null;
    name: string;
    display_label?: string | null;
  }>;
};

export type getAnalyticsCardQuery = {
  __typename: 'AnalyticsCard';
  primary_key: string;
  sort_key: string;
  data: string;
};

export type listBudgetsQuery = {
  __typename: 'Budget';
  id: string;
  budget_type: BudgetType;
  name: string;
};

export type listBudgetVersionExpensesQuery = {
  __typename: 'BudgetExpenseData';
  expense_type?: ExpenseType | null;
  period?: string | null;
  amount?: number | null;
  amount_type?: AmountType | null;
  uom?: string | null;
  unit_cost?: number | null;
  unit_num?: number | null;
  activity_type?: ActivityType | null;
  source?: string | null;
};

export type updateBudgetForecastSettingMutation = {
  __typename: 'BudgetForecastSetting';
  id: string;
};

export type updateActivityDiscountsMutation = {
  __typename: 'BudgetForecastSetting';
  id: string;
};

export type createOverrideSettingMutation = {
  __typename: 'BudgetExpense';
  id: string;
  category_id?: string | null;
  activity_id?: string | null;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    units?: number | null;
  }>;
  expense_detail?: string | null;
};

export type updateOverrideSettingMutation = {
  __typename: 'BudgetExpense';
  id: string;
  category_id?: string | null;
  activity_id?: string | null;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    units?: number | null;
  }>;
  expense_detail?: string | null;
};

export type listInvestigatorForecastContractedAmountsQuery = {
  __typename: 'InvestigatorForecastContractedAmount';
  activity_name: string;
  expense_type: ExpenseType;
  amount_type: AmountType;
  amount_curr: string;
  amount: number;
  contract_curr?: string | null;
  contract_amount?: number | null;
  source_last_updated?: string | null;
};

export type listSiteContractSettingsQuery = {
  __typename: 'SiteContractSetting';
  source_last_updated?: string | null;
  last_updated: string;
  site_count: number;
  from_patient_curve: number;
  total_forecasted_patients: number;
  average_patient_cost: number;
  average_patient_cost_curr: string;
  current_average_patient_cost: number;
  current_average_patient_cost_curr: string;
  invoiceables_percent?: number | null;
  other_percent?: number | null;
  overhead_percent?: number | null;
};

export type createSiteContractSettingMutation = {
  __typename: 'SiteContractSetting';
  last_updated: string;
  site_count: number;
  from_patient_curve: number;
  total_forecasted_patients: number;
  average_patient_cost: number;
  average_patient_cost_curr: string;
  current_average_patient_cost: number;
  current_average_patient_cost_curr: string;
  invoiceables_percent?: number | null;
  other_percent?: number | null;
  overhead_percent?: number | null;
};

export type updateInvestigatorForecastMutation = {
  __typename: 'GenericResponse';
  id: string;
};

export type listActualPatientVisitSettingsQuery = {
  __typename: 'ActualPatientVisitSetting';
  source_last_updated?: string | null;
  last_updated: string;
  forecasted_remaining_visit_costs: number;
  invoiceables_percent?: number | null;
  other_percent?: number | null;
  overhead_percent?: number | null;
  discontinued_percent?: number | null;
  current_discontinued_percent: number;
  current_discontinued_amount: number;
  current_enrolled_amount: number;
  average_completed_patient_cost: number;
  remaining_patients_to_discontinue_percent?: number | null;
  remaining_patients_to_discontinue_cost?: number | null;
  visit_costs_to_date: number;
  current_invoiceables_percent: number;
  invoicables_to_date: number;
};

export type createActualPatientVisitSettingMutation = {
  __typename: 'ActualPatientVisitSetting';
  other_percent?: number | null;
};

export type listInvoicesQuery = {
  __typename: 'Invoice';
  id: string;
  accrual_period?: string | null;
  created_by: string;
  create_date: string;
  purchase_order_id?: string | null;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
  invoice_no?: string | null;
  invoice_date?: string | null;
  payment_status?: PaymentStatus | null;
  payment_date?: string | null;
  invoice_status: InvoiceStatus;
  line_items?: string | null;
  ocr_line_items?: string | null;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_curr: string;
    amount_type: string;
    contract_curr?: string | null;
    contract_amount?: number | null;
    exchange_rate?: {
      __typename: 'MonthlyExchangeRate';
      base_currency: Currency;
      month: string;
      rate: number;
      target_currency: Currency;
    } | null;
  }>;
  due_date?: string | null;
  data_source_id: DataSource;
};

export type listInvoicesForReconciliationQuery = {
  __typename: 'Invoice';
  id: string;
  accrual_period?: string | null;
  invoice_status: InvoiceStatus;
  payment_date?: string | null;
  organization: {
    __typename: 'Organization';
    id: string;
  };
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_curr: string;
    amount_type: string;
    contract_amount?: number | null;
    contract_curr?: string | null;
    exchange_rate?: {
      __typename: 'MonthlyExchangeRate';
      base_currency: Currency;
      month: string;
      rate: number;
      target_currency: Currency;
    } | null;
  }>;
};

export type getInvoiceQuery = {
  __typename: 'Invoice';
  id: string;
  created_by: string;
  create_date: string;
  approvals?: Array<{
    __typename: 'Approval';
    approver_name?: string | null;
    approval_time?: string | null;
    activity_details?: string | null;
    aux_user_id?: string | null;
    permission?: string | null;
  } | null> | null;
  accrual_period?: string | null;
  purchase_order_id?: string | null;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
  invoice_no?: string | null;
  invoice_date?: string | null;
  invoice_status: InvoiceStatus;
  workflow_details: Array<{
    __typename: 'WorkflowDetail';
    id: string;
    name: string;
    properties: string;
  }>;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_curr: string;
    amount_type: string;
    contract_curr?: string | null;
    contract_amount?: number | null;
    exchange_rate?: {
      __typename: 'MonthlyExchangeRate';
      base_currency: Currency;
      month: string;
      rate: number;
      target_currency: Currency;
    } | null;
  }>;
  due_date?: string | null;
  reasons: Array<{
    __typename: 'Note';
    message: string;
    note_type: NoteType;
  }>;
  line_items?: string | null;
  ocr_line_items?: string | null;
  data_source_id: DataSource;
};

export type batchCreateInvoicesMutation = {
  __typename: 'Invoice';
  id: string;
  accrual_period?: string | null;
  approvals?: Array<{
    __typename: 'Approval';
    approver_name?: string | null;
    approval_time?: string | null;
    activity_details?: string | null;
    aux_user_id?: string | null;
    permission?: string | null;
  } | null> | null;
  purchase_order_id?: string | null;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
  invoice_no?: string | null;
  invoice_date?: string | null;
  invoice_status: InvoiceStatus;
  workflow_details: Array<{
    __typename: 'WorkflowDetail';
    id: string;
    name: string;
    properties: string;
  }>;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_type: string;
  }>;
  due_date?: string | null;
};

export type createInvoiceMutation = {
  __typename: 'Invoice';
  id: string;
  accrual_period?: string | null;
  approvals?: Array<{
    __typename: 'Approval';
    approver_name?: string | null;
    approval_time?: string | null;
    activity_details?: string | null;
    aux_user_id?: string | null;
    permission?: string | null;
  } | null> | null;
  purchase_order_id?: string | null;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
  invoice_no?: string | null;
  invoice_date?: string | null;
  invoice_status: InvoiceStatus;
  workflow_details: Array<{
    __typename: 'WorkflowDetail';
    id: string;
    name: string;
    properties: string;
  }>;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_type: string;
  }>;
  due_date?: string | null;
};

export type batchUpdateWorkflowDetailsMutation = {
  __typename: 'WorkflowDetail';
  id: string;
  update_date: string;
  updated_by: string;
};

export type updateWorkflowDetailMutation = {
  __typename: 'WorkflowDetail';
  id: string;
  update_date: string;
  updated_by: string;
};

export type batchUpdateInvoicesMutation = {
  __typename: 'Invoice';
  id: string;
  due_date?: string | null;
  payment_date?: string | null;
  invoice_no?: string | null;
  invoice_date?: string | null;
  invoice_status: InvoiceStatus;
  accrual_period?: string | null;
  approvals?: Array<{
    __typename: 'Approval';
    approver_name?: string | null;
    approval_time?: string | null;
    activity_details?: string | null;
    aux_user_id?: string | null;
    permission?: string | null;
  } | null> | null;
  purchase_order_id?: string | null;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
  reasons: Array<{
    __typename: 'Note';
    message: string;
    note_type: NoteType;
  }>;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_type: string;
  }>;
};

export type updateInvoiceMutation = {
  __typename: 'Invoice';
  id: string;
  due_date?: string | null;
  payment_date?: string | null;
  invoice_no?: string | null;
  invoice_date?: string | null;
  invoice_status: InvoiceStatus;
  accrual_period?: string | null;
  approvals?: Array<{
    __typename: 'Approval';
    approver_name?: string | null;
    approval_time?: string | null;
    activity_details?: string | null;
    aux_user_id?: string | null;
    permission?: string | null;
  } | null> | null;
  purchase_order_id?: string | null;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
  reasons: Array<{
    __typename: 'Note';
    message: string;
    note_type: NoteType;
  }>;
  expense_amounts: Array<{
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_type: string;
  }>;
};

export type batchUpdateExpenseAmountsMutation = {
  __typename: 'ExpenseAmount';
  id?: string | null;
};

export type updateExpenseAmountMutation = {
  __typename: 'ExpenseAmount';
  id?: string | null;
};

export type listInvoiceNumbersQuery = {
  __typename: 'Invoice';
  id: string;
  invoice_no?: string | null;
};

export type batchRemoveInvoicesMutation = {
  __typename: 'Invoice';
  id: string;
};

export type removeInvoiceMutation = {
  __typename: 'Invoice';
  id: string;
};

export type listOrganizationsQuery = {
  __typename: 'Organization';
  id: string;
  name: string;
  currency: Currency;
  parent_organization?: {
    __typename: 'Organization';
    id: string;
    name: string;
  } | null;
  current_budget_versions: Array<{
    __typename: 'BudgetVersion';
    budget_type: string;
    budget_name: string;
    budget_version_id: string;
    bucket_key?: string | null;
    manual_forecast: boolean;
    entity_id: string;
    entity_type: EntityType;
  }>;
  has_closed_months?: boolean | null;
  costs_included_in_parent_wo?: boolean | null;
  organization_type: OrganizationType;
};

export type listIdOrganizationsQuery = {
  __typename: 'Organization';
  id: string;
  name: string;
  organization_type: OrganizationType;
  currency: Currency;
};

export type listOrganizationsWithInvestigatorCostCategoriesQuery = {
  __typename: 'Organization';
  id: string;
  name: string;
};

export type listOrganizationsWithTotalBudgetAmountQuery = {
  __typename: 'Organization';
  id: string;
  name: string;
  currency: Currency;
  organization_type: OrganizationType;
  current_budget_versions: Array<{
    __typename: 'BudgetVersion';
    budget_type: string;
    budget_name: string;
    budget_version_id: string;
    bucket_key?: string | null;
    manual_forecast: boolean;
    entity_id: string;
    entity_type: EntityType;
    total_budget_amount?: number | null;
  }>;
  baseline_budget_version?: {
    __typename: 'BudgetVersion';
    budget_type: string;
    budget_name: string;
    budget_version_id: string;
    bucket_key?: string | null;
    manual_forecast: boolean;
    entity_id: string;
    entity_type: EntityType;
  } | null;
  has_closed_months?: boolean | null;
};

export type listContactsQuery = {
  __typename: 'Contact';
  id: string;
  given_name: string;
  family_name: string;
  email?: string | null;
  title?: string | null;
  phone_number?: string | null;
};

export type createOrganizationMutation = {
  __typename: 'Organization';
  id: string;
  name: string;
  currency: Currency;
  parent_organization?: {
    __typename: 'Organization';
    id: string;
    name: string;
  } | null;
  costs_included_in_parent_wo?: boolean | null;
  organization_type: OrganizationType;
};

export type removeOrganizationMutation = {
  __typename: 'Organization';
  id: string;
};

export type updateOrganizationMutation = {
  __typename: 'Organization';
  id: string;
  name: string;
  currency: Currency;
  parent_organization?: {
    __typename: 'Organization';
    id: string;
    name: string;
  } | null;
  costs_included_in_parent_wo?: boolean | null;
  organization_type: OrganizationType;
};

export type createContactMutation = {
  __typename: 'Contact';
  id: string;
  given_name: string;
  family_name: string;
  email?: string | null;
  title?: string | null;
  phone_number?: string | null;
};

export type updateContactMutation = {
  __typename: 'Contact';
  id: string;
};

export type listOrganizationNamesQuery = {
  __typename: 'Organization';
  id: string;
  name: string;
};

export type listVendorEstimateSummariesQuery = {
  __typename: 'VendorEstimateSummary';
  organization_id: string;
  organization_name: string;
  vendor_estimate_exists: boolean;
  activities_exceeding_forecast: Array<string>;
  activities_exceeding_amount_remaining: Array<string>;
};

export type listEntitySettingsQuery = {
  __typename: 'EntitySettings';
  entity_id: string;
  settings?: string | null;
};

export type updateEntitySettingsMutation = {
  __typename: 'EntitySettings';
  entity_id: string;
  settings?: string | null;
};

export type listJournalEntriesQuery = {
  __typename: 'JournalEntry';
  budget_version_id: string;
  vendor_id: string;
  vendor_name: string;
  entry_name: string;
  entry_type: string;
  vendor_currency?: string | null;
  line_memo?: string | null;
  sponsor_name: string;
  attributes?: string | null;
  reversal_date?: string | null;
  debit_amount?: number | null;
  credit_amount?: number | null;
  debit_contracted_amount?: number | null;
  credit_contracted_amount?: number | null;
};

export type listBudgetAttributesQuery = {
  __typename: 'BudgetActivityAttributes';
  attribute?: string | null;
  attribute_index?: number | null;
  attribute_name?: string | null;
  attribute_value?: string | null;
};

export type createPaymentMilestoneMutation = {
  __typename: 'PaymentMilestone';
  id: string;
  organization: {
    __typename: 'Organization';
    id: string;
  };
  name: string;
  amount: number;
  target_date: string;
};

export type updatePaymentMilestoneMutation = {
  __typename: 'PaymentMilestone';
  id: string;
  organization: {
    __typename: 'Organization';
    id: string;
  };
  name: string;
  amount: number;
  target_date: string;
};

export type listPaymentMilestonesQuery = {
  __typename: 'PaymentMilestone';
  id: string;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
  name: string;
  amount: number;
  target_date: string;
};

export type removePaymentMilestoneMutation = {
  __typename: 'PaymentMilestone';
  id: string;
};

export type listOrganizationWorkPerformedQuery = {
  __typename: 'WorkPerformed';
  organization?: {
    __typename: 'Organization';
    id: string;
  } | null;
  amount: number;
  period_date: string;
};

export type listCashRequirementsQuery = {
  __typename: 'CashRequirement';
  id: string;
  organization: {
    __typename: 'Organization';
    id: string;
  };
  expense_amount: {
    __typename: 'ExpenseAmount';
    amount_type: string;
    amount_curr: string;
    amount?: number | null;
  };
  requirement_date: string;
};

export type listPaymentMilestoneSummaryQuery = {
  __typename: 'PaymentMilestoneSummary';
  milestone_name: string;
  time_period: string;
  total_amount: number;
  vendor_name: string;
};

export type listInMonthExpensesQuery = {
  __typename: 'InMonthExpenseView';
  activity_id: string;
  activity_no?: string | null;
  activity_name: string;
  activity_type: ActivityType;
  display_label: string;
  group0?: string | null;
  group1?: string | null;
  group2?: string | null;
  group3?: string | null;
  group4?: string | null;
  is_forecasted: boolean;
  direct_cost: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
    uom?: string | null;
    unit_num?: number | null;
  };
  forecast?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
  } | null;
  manual_adjustment?: {
    __typename: 'BudgetExpenseData';
    id?: string | null;
    period?: string | null;
    amount?: number | null;
    unit_cost?: number | null;
    previous_amount?: number | null;
    updated_by?: string | null;
    update_date?: string | null;
    adjustment_type?: AdjustmentType | null;
  } | null;
  historical_adjustment?: {
    __typename: 'BudgetExpenseData';
    id?: string | null;
    period?: string | null;
    amount?: number | null;
    unit_cost?: number | null;
    previous_amount?: number | null;
    updated_by?: string | null;
    update_date?: string | null;
    adjustment_type?: AdjustmentType | null;
  } | null;
  vendor_estimate?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
  } | null;
  work_performed?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
  } | null;
  work_performed_to_date?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
  } | null;
  prev_months_accruals?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
  } | null;
  prev_month_work_performed?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
  } | null;
  monthly_expense?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
    expense_source?: ExpenseSourceType | null;
  } | null;
  total_monthly_expense?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
    expense_source?: ExpenseSourceType | null;
  } | null;
  accrual?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    unit_cost?: number | null;
    expense_source?: ExpenseSourceType | null;
  } | null;
  notes: Array<{
    __typename: 'Note';
    id: string;
    entity_id: string;
    expense_note_types: Array<ExpenseNoteType>;
    entity_type: EntityType;
    note_type: NoteType;
    message: string;
    created_by: string;
    create_date: string;
  }>;
  documents: Array<{
    __typename: 'Document';
    id: string;
  }>;
};

export type listDiscountExpensesQuery = {
  __typename: 'InMonthExpenseView';
  activity_id: string;
  activity_no?: string | null;
  manual_adjustment?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    expense_detail?: string | null;
  } | null;
  vendor_estimate?: {
    __typename: 'BudgetExpenseData';
    amount?: number | null;
  } | null;
};

export type listExpenseSourceSettingsQuery = {
  __typename: 'ExpenseSourceSetting';
  activity_id: string;
  default: boolean;
  period?: string | null;
  sources: Array<ExpenseSourceType>;
};

export type batchOverrideExpenseSourcesMutation = {
  __typename: 'ExpenseSourceSetting';
  activity_id: string;
};

export type batchRemoveExpenseSourceOverridesMutation = {
  __typename: 'ExpenseSourceSetting';
  activity_id: string;
};

export type batchRemoveBudgetExpensesMutation = {
  __typename: 'BudgetExpense';
  activity_id?: string | null;
};

export type listPOQuery = {
  __typename: 'PurchaseOrder';
  id: string;
  invoice_summaries: Array<{
    __typename: 'InvoiceSummary';
    invoice_id: string;
    invoice_status: InvoiceStatus;
    total_amount?: number | null;
  }>;
  po_amount: number;
  po_number: string;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
};

export type createPOMutation = {
  __typename: 'PurchaseOrder';
  id: string;
  po_amount: number;
  po_number: string;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
};

export type updatePOMutation = {
  __typename: 'PurchaseOrder';
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    currency: Currency;
  };
  id: string;
  po_amount: number;
  po_number: string;
};

export type listPONumbersQuery = {
  __typename: 'PurchaseOrder';
  id: string;
  po_number: string;
};

export type removePOMutation = {
  __typename: 'PurchaseOrder';
  id: string;
};

export type listScenarioModelQuery = {
  __typename: 'ScenarioModel';
  label: Array<string>;
  model?: Array<{
    __typename: 'ScenarioModelData';
    source: string;
    data: Array<number>;
  } | null> | null;
};

export type listSitesQuery = {
  __typename: 'Site';
  id: string;
  site_no: string;
  name: string;
  address_line_1?: string | null;
  address_line_2?: string | null;
  address_line_3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: Country | null;
  contacts?: Array<{
    __typename: 'Contact';
    id: string;
    given_name: string;
    family_name: string;
  }> | null;
  target_patients?: number | null;
  managed_by: {
    __typename: 'Organization';
    id: string;
  };
  site_activation?: string | null;
  closeout_date?: string | null;
  currency?: Currency | null;
};

export type listPatientGroupsQuery = {
  __typename: 'PatientGroup';
  id: string;
  name: string;
  description?: string | null;
  type: PatientGroupType;
  rank_order?: number | null;
};

export type patientGroupsOptionQuery = {
  __typename: 'PatientGroup';
  id: string;
  name: string;
  rank_order?: number | null;
};

export type createPatientGroupMutation = {
  __typename: 'PatientGroup';
  id: string;
  name: string;
  description?: string | null;
  type: PatientGroupType;
  rank_order?: number | null;
};

export type updatePatientGroupMutation = {
  __typename: 'PatientGroup';
  id: string;
  name: string;
  description?: string | null;
  type: PatientGroupType;
  rank_order?: number | null;
};

export type removePatientGroupMutation = {
  __typename: 'PatientGroup';
  id: string;
  name: string;
  description?: string | null;
  rank_order?: number | null;
};

export type clonePatientProtocolMutation = {
  __typename: 'PatientProtocol';
  id: string;
  name: string;
  patient_protocol_type: PatientProtocolType;
  order_by: number;
  target_date_days_out?: number | null;
  target_tolerance_days_out: number;
};

export type listSiteGroupsQuery = {
  __typename: 'SiteGroup';
  id: string;
  name: string;
  description?: string | null;
  rank_order?: number | null;
};

export type createSiteGroupMutation = {
  __typename: 'SiteGroup';
  id: string;
  name: string;
  description?: string | null;
  rank_order?: number | null;
};

export type updateSiteGroupMutation = {
  __typename: 'SiteGroup';
  id: string;
  name: string;
  description?: string | null;
  rank_order?: number | null;
};

export type removeSiteGroupMutation = {
  __typename: 'SiteGroup';
  id: string;
  name: string;
  description?: string | null;
  rank_order?: number | null;
};

export type listSiteContactsQuery = {
  __typename: 'Contact';
  id: string;
  given_name: string;
  family_name: string;
};

export type listPatientProtocolsQuery = {
  __typename: 'PatientProtocol';
  id: string;
  name: string;
  order_by: number;
  patient_group_id?: string | null;
  patient_protocol_type: PatientProtocolType;
  patient_protocol_sub_type?: {
    __typename: 'PatientProtocolSubType';
    id: string;
    name: string;
    patient_protocol_type: PatientProtocolType;
  } | null;
  patient_protocol_frequency?: PatientProtocolFrequency | null;
  target_date_days_out?: number | null;
  target_tolerance_days_out: number;
  patient_protocol_version?: {
    __typename: 'PatientProtocolVersion';
    id: string;
    name: string;
    is_current: boolean;
  } | null;
};

export type listPatientProtocolSubTypesQuery = {
  __typename: 'PatientProtocolSubType';
  id: string;
  name: string;
  patient_protocol_type: PatientProtocolType;
};

export type listSitePaymentSchedulesQuery = {
  __typename: 'SitePaymentSchedule';
  id: string;
  patient_protocol: {
    __typename: 'PatientProtocol';
    id: string;
    patient_protocol_type: PatientProtocolType;
  };
  expense_amount: {
    __typename: 'ExpenseAmount';
    amount?: number | null;
  };
  note?: string | null;
};

export type fetchTrialSitePaymentSchedulesQuery = {
  __typename: 'FetchSitePaymentScheduleResponse';
  items: Array<{
    __typename: 'SitePaymentSchedule';
    id: string;
    site_id: string;
    patient_protocol: {
      __typename: 'PatientProtocol';
      id: string;
      patient_protocol_type: PatientProtocolType;
    };
    expense_amount: {
      __typename: 'ExpenseAmount';
      amount?: number | null;
      amount_curr: string;
      contract_amount?: number | null;
      contract_curr?: string | null;
    };
    note?: string | null;
  }>;
  next_offset: number;
};

export type listSitePatientTrackerMonthlyAmountsQuery = {
  __typename: 'SitePatientTrackerMonthlyAmount';
  vendor_id: string;
  completion_month: string;
  patient_amount: number;
  other_amount: number;
  overhead_amount: number;
  total_amount: number;
};

export type fetchSitePatientTrackersFlatQuery = {
  __typename: 'FetchSitePatientTrackersFlatResponse';
  items: Array<{
    __typename: 'SitePatientTrackerFlat';
    id: string;
    completion_date: string;
    patient_id?: string | null;
    external_patient_id?: string | null;
    create_date?: string | null;
    sps_id: string;
    sps_note?: string | null;
    sps_expense_amount?: number | null;
    sps_expense_currency?: string | null;
    sps_contract_expense_amount?: number | null;
    sps_contract_expense_currency?: string | null;
    sps_site_id: string;
    sps_site_target_patients?: number | null;
    sps_site_managed_by_id: string;
    sps_site_total_payment_schedule_amount?: number | null;
    sps_pp_id: string;
    sps_pp_name: string;
    sps_pp_patient_protocol_type: PatientProtocolType;
    sps_pp_target_date_days_out?: number | null;
    sps_pp_target_tolerance_days_out: number;
    sps_pp_order_by: number;
    sps_total_contract_expense_amount?: number | null;
    sps_total_expense_amount?: number | null;
  }>;
  next_offset: number;
};

export type createPatientProtocolMutation = {
  __typename: 'PatientProtocol';
  id: string;
  name: string;
  order_by: number;
  patient_group_id?: string | null;
  patient_protocol_type: PatientProtocolType;
  patient_protocol_sub_type?: {
    __typename: 'PatientProtocolSubType';
    id: string;
    name: string;
    patient_protocol_type: PatientProtocolType;
  } | null;
  patient_protocol_frequency?: PatientProtocolFrequency | null;
  target_date_days_out?: number | null;
  target_tolerance_days_out: number;
};

export type fetchPatientVisitSchedulesQuery = {
  __typename: 'FetchPatientVisitSchedulesResponse';
  items: Array<{
    __typename: 'PatientVisitSchedule';
    external_patient_id: string;
    site_id: string;
    site_no: string;
    visit_schedule: Array<{
      __typename: 'PatientVisit';
      patient_protocol_id: string;
      patient_protocol_type: PatientProtocolType;
      patient_protocol_name: string;
      patient_protocol_version_id: string;
      scheduled_date: string;
      completed: boolean;
      visit_index?: number | null;
      cost: {
        __typename: 'ExpenseAmount';
        amount?: number | null;
        amount_curr: string;
        contract_amount?: number | null;
        contract_curr?: string | null;
      };
    }>;
  }>;
  meta_data?: {
    __typename: 'PatientVisitsMetaData';
    total?: number | null;
    current_month_total?: number | null;
  } | null;
  next_offset: number;
};

export type createPatientProtocolVersionMutation = {
  __typename: 'PatientProtocolVersion';
  id: string;
  name: string;
  is_current: boolean;
};

export type updatePatientProtocolMutation = {
  __typename: 'PatientProtocol';
  id: string;
  name: string;
  order_by: number;
  patient_group_id?: string | null;
  patient_protocol_type: PatientProtocolType;
  patient_protocol_sub_type?: {
    __typename: 'PatientProtocolSubType';
    id: string;
    name: string;
    patient_protocol_type: PatientProtocolType;
  } | null;
  patient_protocol_frequency?: PatientProtocolFrequency | null;
  target_date_days_out?: number | null;
  target_tolerance_days_out: number;
};

export type removePatientProtocolMutation = {
  __typename: 'PatientProtocol';
  id: string;
};

export type createSitePaymentScheduleMutation = {
  __typename: 'SitePaymentSchedule';
  id: string;
  site_id: string;
  patient_protocol: {
    __typename: 'PatientProtocol';
    id: string;
    patient_protocol_type: PatientProtocolType;
  };
  expense_amount: {
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_curr: string;
    amount_type: string;
  };
  note?: string | null;
  unscheduled: number;
};

export type updateSitePaymentScheduleMutation = {
  __typename: 'SitePaymentSchedule';
  id: string;
  site_id: string;
  patient_protocol: {
    __typename: 'PatientProtocol';
    id: string;
    patient_protocol_type: PatientProtocolType;
  };
  expense_amount: {
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_curr: string;
    amount_type: string;
  };
  note?: string | null;
  unscheduled: number;
};

export type removeSitePaymentScheduleMutation = {
  __typename: 'SitePaymentSchedule';
  id: string;
  site_id: string;
  patient_protocol: {
    __typename: 'PatientProtocol';
    id: string;
    patient_protocol_type: PatientProtocolType;
  };
  expense_amount: {
    __typename: 'ExpenseAmount';
    amount?: number | null;
    amount_curr: string;
    amount_type: string;
  };
  note?: string | null;
  unscheduled: number;
};

export type createSiteMutation = {
  __typename: 'Site';
  id: string;
  name: string;
  site_no: string;
  address_line_1?: string | null;
  address_line_2?: string | null;
  address_line_3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: Country | null;
  target_patients?: number | null;
  managed_by: {
    __typename: 'Organization';
    id: string;
  };
  site_activation?: string | null;
  closeout_date?: string | null;
  currency?: Currency | null;
};

export type createDriverSiteDistributionMutation = {
  __typename: 'DriverSiteDistribution';
  id: string;
  distribution_mode: DistributionMode;
  distribution_month: string;
  sites_activated?: number | null;
  sites_closed?: number | null;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
  sites_activated_percentage?: number | null;
  sites_closed_percentage?: number | null;
};

export type updateDriverSiteDistributionMutation = {
  __typename: 'DriverSiteDistribution';
  id: string;
  distribution_mode: DistributionMode;
  distribution_month: string;
  sites_activated?: number | null;
  sites_closed?: number | null;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
  sites_activated_percentage?: number | null;
  sites_closed_percentage?: number | null;
};

export type removeDriverSiteDistributionMutation = {
  __typename: 'DriverSiteDistribution';
  id: string;
  distribution_mode: DistributionMode;
  distribution_month: string;
  sites_activated?: number | null;
  sites_closed?: number | null;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
};

export type removeSiteMutation = {
  __typename: 'Site';
  id: string;
};

export type updateSiteMutation = {
  __typename: 'Site';
  id: string;
};

export type createSiteContactMutation = {
  __typename: 'Contact';
  id: string;
};

export type listSiteNamesQuery = {
  __typename: 'Site';
  id: string;
  name: string;
};

export type createDriverPatientDistributionMutation = {
  __typename: 'DriverPatientDistribution';
  distribution_month: string;
  forecast: {
    __typename: 'PatientEnrollmentData';
    id?: string | null;
    distribution_mode: DistributionMode;
    patients_enrolled?: number | null;
    patients_discontinued?: number | null;
    patients_complete?: number | null;
    net_patients_enrolled?: number | null;
    cumulative_enrollment_percentage?: number | null;
    total_patients_enrolled?: number | null;
  };
};

export type updateDriverPatientDistributionMutation = {
  __typename: 'DriverPatientDistribution';
  distribution_month: string;
  forecast: {
    __typename: 'PatientEnrollmentData';
    id?: string | null;
    distribution_mode: DistributionMode;
    patients_enrolled?: number | null;
    patients_discontinued?: number | null;
    patients_complete?: number | null;
    net_patients_enrolled?: number | null;
    cumulative_enrollment_percentage?: number | null;
    total_patients_enrolled?: number | null;
  };
};

export type removeDriverPatientDistributionMutation = {
  __typename: 'DriverPatientDistribution';
  distribution_month: string;
  forecast: {
    __typename: 'PatientEnrollmentData';
    id?: string | null;
    distribution_mode: DistributionMode;
    patients_enrolled?: number | null;
    patients_discontinued?: number | null;
    patients_complete?: number | null;
    net_patients_enrolled?: number | null;
    cumulative_enrollment_percentage?: number | null;
    total_patients_enrolled?: number | null;
  };
};

export type listSitePatientTrackerVersionsQuery = {
  __typename: 'SitePatientTrackerVersion';
  id: string;
  is_current: boolean;
  create_date: string;
};

export type fetchInvestigatorTransactionsQuery = {
  __typename: 'FetchPatientTransactionsResponse';
  items: Array<{
    __typename: 'PatientTransaction';
    activity_date: string;
    source_created_date?: string | null;
    create_date: string;
    exchange_rate?: {
      __typename: 'MonthlyExchangeRate';
      rate: number;
    } | null;
    external_patient_id?: string | null;
    id: string;
    patient_group_id?: string | null;
    patient_protocol_category: PatientProtocolCategory;
    site_payment_schedule: {
      __typename: 'SitePaymentSchedule';
      expense_amount: {
        __typename: 'ExpenseAmount';
        amount?: number | null;
        amount_curr: string;
        contract_amount?: number | null;
        contract_curr?: string | null;
      };
      country?: Country | null;
      id: string;
      patient_protocol: {
        __typename: 'PatientProtocol';
        id: string;
        name: string;
        patient_protocol_version?: {
          __typename: 'PatientProtocolVersion';
          id: string;
          name: string;
          is_current: boolean;
        } | null;
        patient_protocol_sub_type?: {
          __typename: 'PatientProtocolSubType';
          name: string;
        } | null;
      };
      site_id: string;
    };
  }>;
  next_offset: number;
  meta_data?: {
    __typename: 'PatientTransactionsMetaData';
    total_cost?: number | null;
    total_count?: number | null;
  } | null;
  latest_source_date?: string | null;
};

export type siteDictionaryQuery = {
  __typename: 'Site';
  id: string;
  site_no: string;
  country?: Country | null;
};

export type listPatientsQuery = {
  __typename: 'Patient';
  external_patient_id: string;
  id: string;
};

export type listPatientProtocolListQuery = {
  __typename: 'PatientProtocol';
  id: string;
  name: string;
};

export type listPatientProtocolVersionsQuery = {
  __typename: 'PatientProtocolVersion';
  id: string;
  name: string;
  is_current: boolean;
};

export type updatePatientProtocolVersionMutation = {
  __typename: 'PatientProtocolVersion';
  id: string;
};

export type removePatientProtocolVersionMutation = {
  __typename: 'PatientProtocolVersion';
  id: string;
};

export type fetchInvestigatorSummariesQuery = {
  __typename: 'FetchInvestigatorSummariesResponse';
  items: Array<{
    __typename: 'InvestigatorSummary';
    external_patient_id?: string | null;
    investigator_costs: Array<{
      __typename: 'InvestigatorCost';
      cost: number;
      cost_type: PatientProtocolCategory;
      patient_group_id?: string | null;
      patient_group_name?: string | null;
      patient_group_rank?: number | null;
    }>;
    patient_id?: string | null;
    remaining_visit_costs?: number | null;
    site_id: string;
  }>;
  next_offset: number;
};

export type fetchInvestigatorDetailQuery = {
  __typename: 'FetchInvestigatorDetailResponse';
  items: Array<{
    __typename: 'InvestigatorDetail';
    completion_date?: string | null;
    create_date?: string | null;
    external_patient_id?: string | null;
    id?: string | null;
    patient_id?: string | null;
    patient_tracker_version_id?: string | null;
    sps_contract_expense_amount?: number | null;
    sps_contract_expense_currency?: string | null;
    sps_expense_amount?: number | null;
    sps_expense_currency?: string | null;
    sps_id?: string | null;
    sps_note?: string | null;
    sps_pp_id?: string | null;
    sps_pp_name?: string | null;
    sps_pp_order_by?: number | null;
    sps_pp_patient_protocol_type?: PatientProtocolType | null;
    sps_pp_target_date_days_out?: number | null;
    sps_pp_target_tolerance_days_out?: number | null;
    sps_site_id: string;
    sps_site_managed_by_id: string;
    sps_site_target_patients?: number | null;
    sps_site_total_payment_schedule_amount?: number | null;
    sps_total_contract_expense_amount?: number | null;
    sps_total_expense_amount?: number | null;
  }>;
  next_offset: number;
};

export type getPatientCostSummaryQuery = {
  __typename: 'PatientCostSummary';
  expected_patients_enrolled: number;
  total_cost?: {
    __typename: 'StratifiedPatientCost';
    source_entity_id?: string | null;
    source: PatientCostSourceType;
    source_entity_data: string;
    average_patient_cost: number;
    patients_enrolled: number;
  } | null;
  site_costs: Array<{
    __typename: 'StratifiedPatientCost';
    source_entity_id?: string | null;
    source: PatientCostSourceType;
    source_entity_data: string;
    average_patient_cost: number;
    patients_enrolled: number;
  }>;
  patient_group_costs: Array<{
    __typename: 'StratifiedPatientCost';
    source_entity_id?: string | null;
    source: PatientCostSourceType;
    source_entity_data: string;
    average_patient_cost: number;
    patients_enrolled: number;
  }>;
};

export type getTotalInvestigatorCostsQuery = {
  __typename: 'TotalInvestigatorCost';
  cost: number;
  patient_protocol_type: PatientProtocolType;
};

export type getScreenFailSummaryQuery = {
  __typename: 'ScreenFailSummary';
  screen_fail_total_cost: number;
  screen_fails: number;
  screen_fail_rate: number;
  dropout_rate: number;
  site_screen_fail_rates: Array<{
    __typename: 'SiteScreenFailRate';
    site_name: string;
    primary_investigator?: {
      __typename: 'Contact';
      given_name: string;
      family_name: string;
    } | null;
    screen_fail_rate: number;
  }>;
  site_screen_fail_totals: Array<{
    __typename: 'SiteScreenFailTotal';
    site_name: string;
    primary_investigator?: {
      __typename: 'Contact';
      given_name: string;
      family_name: string;
    } | null;
    screen_fails: number;
  }>;
};

export type getSiteCostSummaryQuery = {
  __typename: 'SiteCostSummary';
  sites_activated: number;
  expected_sites_activated: number;
  expected_sites_closed: number;
  site_costs: Array<{
    __typename: 'SiteCost';
    site_name: string;
    primary_investigator?: {
      __typename: 'Contact';
      given_name: string;
      family_name: string;
    } | null;
    total_cost: number;
    patients_enrolled: number;
  }>;
  sites_lag: Array<{
    __typename: 'SiteLag';
    site_name: string;
    primary_investigator?: {
      __typename: 'Contact';
      given_name: string;
      family_name: string;
    } | null;
    latest_transaction?: string | null;
  }>;
};

export type checkPatientProtocolIsUniqueQuery = {
  __typename: 'CheckPatientProtocolIsUniqueResponse';
  is_unique: boolean;
};

export type removeBudgetSnapshotMutation = {
  __typename: 'BudgetSnapshot';
  id: string;
  name: string;
  budget_snapshot_type_id: BudgetSnapshotType;
  budget_version_ids: Array<string>;
};

export type updateBudgetSnapshotMutation = {
  __typename: 'BudgetSnapshot';
  id: string;
  name: string;
  budget_snapshot_type_id: BudgetSnapshotType;
  budget_version_ids: Array<string>;
};

export type listSpecificationSettingsQuery = {
  __typename: 'SpecificationCategory';
  id: string;
  name: string;
  order: number;
  specifications: Array<{
    __typename: 'Specification';
    id: string;
    input_type: SpecificationInputType;
    name: string;
    settings: Array<{
      __typename: 'SpecificationSetting';
      id: string;
      setting_key?: string | null;
      setting_value: string;
      specification_type: SpecificationSettingType;
    }>;
  }>;
};

export type createSpecificationMutation = {
  __typename: 'Specification';
  id: string;
  name: string;
  input_type: SpecificationInputType;
};

export type createSpecificationSettingMutation = {
  __typename: 'SpecificationSetting';
  id: string;
  specification_id: string;
  setting_key?: string | null;
  setting_value: string;
  specification_type: SpecificationSettingType;
};

export type updateSpecificationMutation = {
  __typename: 'Specification';
  id: string;
  name: string;
  input_type: SpecificationInputType;
};

export type updateSpecificationSettingMutation = {
  __typename: 'SpecificationSetting';
  id: string;
  specification_id: string;
  setting_key?: string | null;
  setting_value: string;
  specification_type: SpecificationSettingType;
};

export type getTimelineQuery = {
  __typename: 'Timeline';
  id: string;
};

export type getMilestonesAndCategoriesQuery = {
  __typename: 'MilestoneCategory';
  id: string;
  name: string;
  description?: string | null;
  grouping_order: number;
  milestones: Array<{
    __typename: 'Milestone';
    id: string;
    description?: string | null;
    name: string;
  }>;
};

export type listTimelineMilestoneIdsQuery = {
  __typename: 'TimelineMilestone';
  id: string;
};

export type listTimelineMilestonesQuery = {
  __typename: 'TimelineMilestone';
  actual_end_date?: string | null;
  actual_start_date?: string | null;
  contract_end_date: string;
  contract_start_date: string;
  contract_month_difference?: number | null;
  id: string;
  revised_end_date?: string | null;
  revised_start_date?: string | null;
  track_from_milestone?: {
    __typename: 'Milestone';
    id: string;
  } | null;
  milestone: {
    __typename: 'Milestone';
    id: string;
    name: string;
    milestone_category_id: string;
    milestone_category: {
      __typename: 'MilestoneCategory';
      name: string;
    };
    organizations_with_forecasts: Array<{
      __typename: 'Organization';
      id: string;
      name: string;
    }>;
  };
};

export type createTimelineMilestoneMutation = {
  __typename: 'TimelineMilestone';
  actual_end_date?: string | null;
  actual_start_date?: string | null;
  contract_end_date: string;
  contract_start_date: string;
  id: string;
  revised_end_date?: string | null;
  revised_start_date?: string | null;
  track_from_milestone?: {
    __typename: 'Milestone';
    id: string;
  } | null;
  milestone: {
    __typename: 'Milestone';
    id: string;
    name: string;
    milestone_category_id: string;
    milestone_category: {
      __typename: 'MilestoneCategory';
      name: string;
    };
    organizations_with_forecasts: Array<{
      __typename: 'Organization';
      id: string;
      name: string;
    }>;
  };
};

export type removeTimelineItemMutation = {
  __typename: 'TimelineMilestone';
  id: string;
};

export type updateTimelineMilestoneMutation = {
  __typename: 'TimelineMilestone';
  contract_end_date: string;
  contract_start_date: string;
  id: string;
  track_from_milestone?: {
    __typename: 'Milestone';
    id: string;
  } | null;
  milestone: {
    __typename: 'Milestone';
    id: string;
    name: string;
    milestone_category_id: string;
    milestone_category: {
      __typename: 'MilestoneCategory';
      name: string;
    };
    organizations_with_forecasts: Array<{
      __typename: 'Organization';
      id: string;
      name: string;
    }>;
  };
};

export type createMilestoneMutation = {
  __typename: 'Milestone';
  id: string;
};

export type updateMilestoneMutation = {
  __typename: 'Milestone';
  id: string;
};

export type listTrialsQuery = {
  __typename: 'Trial';
  id: string;
  nct_id?: string | null;
  name: string;
  short_name: string;
  onboarding_complete: boolean;
  therapy_area: string;
  auxilius_start_date?: string | null;
  title: string;
  sponsor_organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
  program?: string | null;
  project?: string | null;
};

export type createTrialMutation = {
  __typename: 'Trial';
  id: string;
  nct_id?: string | null;
  name: string;
  short_name: string;
  onboarding_complete: boolean;
  title: string;
  sponsor_organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
};

export type updateTrialMutation = {
  __typename: 'Trial';
  id: string;
};

export type setTrialMutation = {
  __typename: 'DynamoDbResponse';
  primary_key: string;
  sort_key: string;
};

export type notificationSubscription = {
  __typename: 'Notification';
  data: string;
  sub: string;
  type: EventType;
  status: NotificationStatus;
  page: NotificationPage;
};

export type createManualTrialMutation = {
  __typename: 'Trial';
  id: string;
  nct_id?: string | null;
  name: string;
  short_name: string;
  onboarding_complete: boolean;
  title: string;
  auxilius_start_date?: string | null;
  sponsor_organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
};

export type listTrialNamesQuery = {
  __typename: 'Trial';
  id: string;
  name: string;
  short_name: string;
};

export type listPortfolioSummariesQuery = {
  __typename: 'PortfolioSummary';
  baseline_budget_estimate?: number | null;
  current_budget_expenses: Array<{
    __typename: 'BudgetExpenseData';
    amount?: number | null;
    expense_type?: ExpenseType | null;
  }>;
  current_month_work_performed?: number | null;
  invoice_total?: number | null;
  current_month?: string | null;
  trial_id: string;
  trial_short_name: string;
};

export type listPortfolioExpensesQuery = {
  __typename: 'BudgetExpenseData';
  amount?: number | null;
  expense_type?: ExpenseType | null;
  period?: string | null;
  source?: string | null;
};

export type getTrialInformationQuery = {
  __typename: 'TrialInformation';
  budget_version_id: string;
  trial_end_date: string;
  trial_month_close: string;
  trial_start_date: string;
  vendor_id: string;
  vendor_month_close: string;
};

export type listUsersQuery = {
  __typename: 'User';
  given_name: string;
  family_name: string;
  email: string;
  department?: Array<DepartmentType | null> | null;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
  sub: string;
  title: string;
  role: RoleType;
  permissions: Array<{
    __typename: 'UserPermission';
    permission_id: string;
    permission_roles: string;
    permission_type: PermissionType;
  }>;
  last_login_date?: string | null;
};

export type createUserMutation = {
  __typename: 'AuxUser';
  sub: string;
};

export type listUserCustomViewsQuery = {
  __typename: 'UserCustomView';
  id: string;
  user_id?: string | null;
  view_location: ViewLocation;
  name: string;
  metadata: string;
  is_custom: boolean;
};

export type addUserToTrialMutation = {
  __typename: 'User';
  role: RoleType;
  department?: Array<DepartmentType | null> | null;
  permissions: Array<{
    __typename: 'UserPermission';
    permission_id: string;
    permission_roles: string;
  }>;
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
  };
};

export type removeUserFromTrialMutation = {
  __typename: 'GenericResponse';
  id: string;
};

export type updateUserPermissionMutation = {
  __typename: 'UserPermission';
  permission_id: string;
};

export type listPermissionsQuery = {
  __typename: 'Permission';
  id: string;
  permission_group: string;
  permission: string;
  permission_user_profile: string;
  permission_type: PermissionType;
};

export type listNotificationPreferencesQuery = {
  __typename: 'NotificationPreference';
  notification: {
    __typename: 'NotificationType';
    id: string;
    event_type: string;
    event_type_group: string;
  };
  enabled: boolean;
};

export type updateNotificationPreferenceMutation = {
  __typename: 'NotificationPreference';
  notification: {
    __typename: 'NotificationType';
    id: string;
  };
  enabled: boolean;
};

export type listNotificationsQuery = {
  __typename: 'NotificationType';
  id: string;
  event_type: string;
  event_type_group: string;
  is_admin: boolean;
};

export type loggedInUserQuery = {
  __typename: 'LoggedInUser';
  sub: string;
  is_sys_admin: boolean;
  trial_id?: string | null;
  roles: Array<string>;
  permissions: Array<PermissionType>;
  organization_id?: string | null;
  title?: string | null;
  given_name?: string | null;
  family_name?: string | null;
  department?: Array<DepartmentType | null> | null;
  all_departments?: Array<DepartmentType | null> | null;
};

export type listUserNamesWithEmailQuery = {
  __typename: 'User';
  sub: string;
  given_name: string;
  family_name: string;
  email: string;
  status?: boolean | null;
};

export type getTrialPreferenceQuery = {
  __typename: 'DynamoDbTrialPreferenceResponse';
  primary_key: string;
  sort_key: string;
  value: string;
};

export type setTrialPreferenceMutation = {
  __typename: 'DynamoDbTrialPreferenceResponse';
  primary_key: string;
  sort_key: string;
  value: string;
};

export type listUserTasksQuery = {
  __typename: 'UserTask';
  create_date: string;
  due_date?: string | null;
  organization_id?: string | null;
  organization_name?: string | null;
  task_header: string;
  task_id: string;
  trial_id: string;
  trial_name: string;
  user_task: UserTaskType;
};

export type createUserCustomViewMutation = {
  __typename: 'UserCustomView';
  id: string;
  user_id?: string | null;
  view_location: ViewLocation;
  name: string;
  metadata: string;
  is_custom: boolean;
};

export type updateUserCustomViewMutation = {
  __typename: 'UserCustomView';
  id: string;
  user_id?: string | null;
  view_location: ViewLocation;
  name: string;
  metadata: string;
  is_custom: boolean;
};

export type removeUserCustomViewMutation = {
  __typename: 'UserCustomView';
  id: string;
  user_id?: string | null;
  view_location: ViewLocation;
  name: string;
  metadata: string;
  is_custom: boolean;
};

export type listWorkflowDetailsQuery = {
  __typename: 'WorkflowDetail';
  id: string;
  name: string;
  order: number;
  properties: string;
  type: WorkflowDetailType;
  step_type: WorkflowStep;
  updated_by: string;
  update_date: string;
};

export type listClosingChecklistUpdatesQuery = {
  __typename: 'ClosingChecklistUpdate';
  step: WorkflowStep;
  update_date?: string | null;
  updated_by?: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class GqlService {
  getTemplatePath$(input: GetTemplatePathInput) {
    const fn = async () => {
      const statement = gql`
        query getTemplatePath($input: GetTemplatePathInput!) {
          getTemplatePath(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getTemplatePathQuery>response.data.getTemplatePath;
    };
    return gql$(fn());
  }
  approveRule$(input: ApprovalInput) {
    const fn = async () => {
      const statement = gql`
        mutation approveRule($input: ApprovalInput!) {
          approveRule(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <approveRuleMutation>response.data.approveRule;
    };
    return gql$(fn());
  }
  createNote$(input: CreateNoteInput) {
    const fn = async () => {
      const statement = gql`
        mutation createNote($input: CreateNoteInput!) {
          createNote(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createNoteMutation>response.data.createNote;
    };
    return gql$(fn());
  }
  batchCreateNotes$(input: Array<CreateNoteInput>) {
    const fn = async () => {
      const statement = gql`
        mutation batchCreateNotes($input: [CreateNoteInput!]!) {
          batchCreateNotes(input: $input) {
            __typename
            id
            entity_id
            expense_note_types
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchCreateNotesMutation>>response.data.batchCreateNotes;
    };
    return gql$(fn());
  }
  getS3Archive$(input: GetS3ArchiveInput) {
    const fn = async () => {
      const statement = gql`
        query getS3Archive($input: GetS3ArchiveInput!) {
          getS3Archive(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getS3ArchiveQuery>response.data.getS3Archive;
    };
    return gql$(fn());
  }
  fetchUserAuditLogs$(input: FetchUserAuditLogsInput) {
    const fn = async () => {
      const statement = gql`
        query fetchUserAuditLogs($input: FetchUserAuditLogsInput!) {
          fetchUserAuditLogs(input: $input) {
            __typename
            items {
              __typename
              id
              entity_id
              entity_type
              category
              action
              action_text
              properties_changed_json
              details_json
              is_custom
              created_by
              create_date
              vendor_id
            }
            last_row
            meta_data {
              __typename
              total_count
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchUserAuditLogsQuery>response.data.fetchUserAuditLogs;
    };
    return gql$(fn());
  }
  listIntegrationConnections$() {
    const fn = async () => {
      const statement = gql`
        query listIntegrationConnections {
          listIntegrationConnections {
            __typename
            data_source
            name
            enabled
            is_oauth
            is_connected
            last_connection_date
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listIntegrationConnectionsQuery>>response.data.listIntegrationConnections;
    };
    return gql$(fn());
  }
  getOAuthLoginUrl$(data_source: DataSource) {
    const fn = async () => {
      const statement = gql`
        query getOAuthLoginUrl($data_source: DataSource!) {
          getOAuthLoginUrl(data_source: $data_source) {
            __typename
            login_url
            callback_origin
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        data_source,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getOAuthLoginUrlQuery>response.data.getOAuthLoginUrl;
    };
    return gql$(fn());
  }
  listDefaultExpenseSources$() {
    const fn = async () => {
      const statement = gql`
        query listDefaultExpenseSources {
          listDefaultExpenseSources {
            __typename
            cost_category
            organization_id
            sources
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listDefaultExpenseSourcesQuery>>response.data.listDefaultExpenseSources;
    };
    return gql$(fn());
  }
  exchangeOAuthCode$(params: string) {
    const fn = async () => {
      const statement = gql`
        mutation exchangeOAuthCode($params: String!) {
          exchangeOAuthCode(params: $params) {
            __typename
            data_source
            name
            enabled
            is_oauth
            is_connected
            last_connection_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        params,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <exchangeOAuthCodeMutation>response.data.exchangeOAuthCode;
    };
    return gql$(fn());
  }
  revokeOAuthToken$(data_source: DataSource) {
    const fn = async () => {
      const statement = gql`
        mutation revokeOAuthToken($data_source: DataSource!) {
          revokeOAuthToken(data_source: $data_source) {
            __typename
            data_source
            name
            enabled
            is_oauth
            is_connected
            last_connection_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        data_source,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <revokeOAuthTokenMutation>response.data.revokeOAuthToken;
    };
    return gql$(fn());
  }
  batchUpdateDefaultExpenseSources$(input: Array<UpdateDefaultExpenseSourceInput>) {
    const fn = async () => {
      const statement = gql`
        mutation batchUpdateDefaultExpenseSources($input: [UpdateDefaultExpenseSourceInput!]!) {
          batchUpdateDefaultExpenseSources(input: $input) {
            __typename
            cost_category
            organization_id
            sources
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchUpdateDefaultExpenseSourcesMutation>>(
        response.data.batchUpdateDefaultExpenseSources
      );
    };
    return gql$(fn());
  }
  listBudgetGrid$(input?: BudgetViewInput) {
    const fn = async () => {
      const statement = gql`
        query listBudgetGrid($input: BudgetViewInput) {
          listBudgetGrid(input: $input) {
            __typename
            cache_info {
              __typename
              cache_file
            }
            budget_data {
              __typename
              activity_no
              activity_id
              activity_name
              activity_name_label
              cost_category
              cost_category_ordering
              contract_unit_cost_currency
              direct_cost
              contract_direct_cost
              contract_unit_cost
              expense_note
              expenses {
                __typename
                amount
                amount_type
                expense_type
                period
                source
                contract_curr
                contract_amount
              }
              attributes {
                __typename
                attribute
                attribute_name
                attribute_value
              }
              group0
              group1
              group2
              group3
              group4
              group_index
              section_path
              unit_cost
              unit_num
              uom
              vendor_id
            }
            budget_info {
              __typename
              expense_amounts {
                __typename
                amount
                amount_type
                amount_perct
              }
              discount_type
              base_budget_version
              budget_type
              budget_version
              budget_version_id
              manual_forecast
              vendor_id
              current_month
              description
              eom_month
              major_version
              minor_version
              name
              source
              vendor_name_budget
              expense_amounts {
                __typename
                id
                amount_type
                amount_curr
                entity_id
                entity_type_id
              }
              discount_type
            }
            header_data {
              __typename
              date_headers
              expense_type
              group_name
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (input) {
        gqlAPIServiceArguments.input = input;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <listBudgetGridQuery>response.data.listBudgetGrid;
    };
    return gql$(fn());
  }
  listBudgetGridV2$(input?: BudgetViewInput) {
    const fn = async () => {
      const statement = gql`
        query listBudgetGridV2($input: BudgetViewInput) {
          listBudgetGridV2(input: $input) {
            __typename
            cache_info {
              __typename
              cache_file
            }
            budget_data {
              __typename
              activity_no
              activity_id
              activity_name
              cost_category
              cost_category_ordering
              contract_unit_cost_currency
              direct_cost
              contract_direct_cost
              contract_unit_cost
              expense_note
              expenses {
                __typename
                amount
                amount_type
                expense_type
                period
                source
                contract_curr
                contract_amount
              }
              attributes {
                __typename
                attribute
                attribute_name
                attribute_value
              }
              group0
              group1
              group2
              group3
              group4
              group_index
              section_path
              unit_cost
              unit_num
              uom
              vendor_id
            }
            budget_info {
              __typename
              expense_amounts {
                __typename
                amount
                amount_type
                amount_perct
              }
              discount_type
              base_budget_version
              budget_type
              budget_version
              budget_version_id
              manual_forecast
              vendor_id
              current_month
              description
              eom_month
              major_version
              minor_version
              name
              source
              vendor_name_budget
              expense_amounts {
                __typename
                id
                amount_type
                amount_curr
                entity_id
                entity_type_id
              }
              discount_type
            }
            header_data {
              __typename
              date_headers
              expense_type
              group_name
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (input) {
        gqlAPIServiceArguments.input = input;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <listBudgetGridV2Query>response.data.listBudgetGridV2;
    };
    return gql$(fn());
  }
  listMonthCloseApprovals$() {
    const fn = async () => {
      const statement = gql`
        query listMonthCloseApprovals {
          listMonthCloseApprovals {
            __typename
            aux_user_id
            permission
            activity_details
            category
            vendor_id
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listMonthCloseApprovalsQuery>>response.data.listMonthCloseApprovals;
    };
    return gql$(fn());
  }
  listBudgetVersions$(budget_types: Array<BudgetType>, vendor_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listBudgetVersions($budget_types: [BudgetType!]!, $vendor_id: String) {
          listBudgetVersions(budget_types: $budget_types, vendor_id: $vendor_id) {
            __typename
            budget_version_id
            manual_forecast
            target_month
            budget_name
            bucket_key
            budget_type
            discount_type
            snapshot_type
            budget_snapshot_id
            is_change_order
            version
            vendor_id
            create_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        budget_types,
      };
      if (vendor_id) {
        gqlAPIServiceArguments.vendor_id = vendor_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listBudgetVersionsQuery>>response.data.listBudgetVersions;
    };
    return gql$(fn());
  }
  currentOpenBudgetMonth$() {
    const fn = async () => {
      const statement = gql`
        query currentOpenBudgetMonth {
          getTrialInformation {
            __typename
            trial_month_close
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<currentOpenBudgetMonthQuery>>response.data.getTrialInformation;
    };
    return gql$(fn());
  }
  listBudgetLibrary$(budget_types: Array<BudgetType>, vendor_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listBudgetLibrary($budget_types: [BudgetType!]!, $vendor_id: String) {
          listBudgetVersions(budget_types: $budget_types, vendor_id: $vendor_id) {
            __typename
            vendor_name
            budget_version_id
            manual_forecast
            budget_name
            bucket_key
            version
            vendor_id
            create_date
            created_by
            is_current
            is_change_order
            is_baseline
            budget_type
            total_budget_amount
            entity_status
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        budget_types,
      };
      if (vendor_id) {
        gqlAPIServiceArguments.vendor_id = vendor_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listBudgetLibraryQuery>>response.data.listBudgetVersions;
    };
    return gql$(fn());
  }
  listBudgetVersionsExpenses$(budget_types: Array<BudgetType>, vendor_id: string) {
    const fn = async () => {
      const statement = gql`
        query listBudgetVersionsExpenses($budget_types: [BudgetType!]!, $vendor_id: String!) {
          listBudgetVersions(budget_types: $budget_types, vendor_id: $vendor_id) {
            __typename
            budget_version_id
            discount_type
            is_current
            expense_amounts {
              __typename
              amount
              amount_perct
              amount_type
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        budget_types,
        vendor_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listBudgetVersionsExpensesQuery>>response.data.listBudgetVersions;
    };
    return gql$(fn());
  }
  updateBudgetVersion$(input: UpdateBudgetVersionInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateBudgetVersion($input: UpdateBudgetVersionInput!) {
          updateBudgetVersion(input: $input) {
            __typename
            budget_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateBudgetVersionMutation>response.data.updateBudgetVersion;
    };
    return gql$(fn());
  }
  processEvent$(input: EventInput) {
    const fn = async () => {
      const statement = gql`
        mutation processEvent($input: EventInput!) {
          processEvent(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <processEventMutation>response.data.processEvent;
    };
    return gql$(fn());
  }
  createBudgetExpense$(input: BudgetExpenseInput) {
    const fn = async () => {
      const statement = gql`
        mutation createBudgetExpense($input: BudgetExpenseInput!) {
          createBudgetExpense(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createBudgetExpenseMutation>response.data.createBudgetExpense;
    };
    return gql$(fn());
  }
  batchCreateBudgetExpenses$(input: Array<BudgetExpenseInput>) {
    const fn = async () => {
      const statement = gql`
        mutation batchCreateBudgetExpenses($input: [BudgetExpenseInput!]!) {
          batchCreateBudgetExpenses(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchCreateBudgetExpensesMutation>>response.data.batchCreateBudgetExpenses;
    };
    return gql$(fn());
  }
  removeVendorEstimate$(input: RemoveVendorEstimateInput) {
    const fn = async () => {
      const statement = gql`
        mutation removeVendorEstimate($input: RemoveVendorEstimateInput!) {
          removeVendorEstimate(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeVendorEstimateMutation>response.data.removeVendorEstimate;
    };
    return gql$(fn());
  }
  setBudgetVersionAsBaseline$(id: string, organization_id: string) {
    const fn = async () => {
      const statement = gql`
        mutation setBudgetVersionAsBaseline($id: String!, $organization_id: String!) {
          setBudgetVersionAsBaseline(id: $id, organization_id: $organization_id) {
            __typename
            budget_id
            budget_version_id
            vendor_id
            vendor_name
            is_current
            is_baseline
            is_change_order
            bucket_key
            budget_name
            version
            budget_type
            discount_type
            manual_forecast
            create_date
            created_by
            entity_id
            entity_type
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
        organization_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<setBudgetVersionAsBaselineMutation>>response.data.setBudgetVersionAsBaseline;
    };
    return gql$(fn());
  }
  removeBudgetVersion$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeBudgetVersion($id: String!) {
          removeBudgetVersion(id: $id) {
            __typename
            budget_id
            budget_version_id
            bucket_key
            budget_name
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeBudgetVersionMutation>response.data.removeBudgetVersion;
    };
    return gql$(fn());
  }
  invalidateBudgetCache$(vendor_id?: string) {
    const fn = async () => {
      const statement = gql`
        mutation invalidateBudgetCache($vendor_id: String) {
          invalidateBudgetCache(vendor_id: $vendor_id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (vendor_id) {
        gqlAPIServiceArguments.vendor_id = vendor_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <invalidateBudgetCacheMutation>response.data.invalidateBudgetCache;
    };
    return gql$(fn());
  }
  updateAccruals$(input: UpdateAccrualsInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateAccruals($input: UpdateAccrualsInput!) {
          updateAccruals(input: $input) {
            __typename
            id
            activity_id
            category_id
            expense_type_id
            period_start
            period_end
            source
            expense_detail
            expense_amounts {
              __typename
              id
              amount_type
              amount_curr
              entity_id
              entity_type_id
              amount
            }
            expense_source
            details
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<updateAccrualsMutation>>response.data.updateAccruals;
    };
    return gql$(fn());
  }
  listActivityVariances$(input: ListActivityVariancesInput) {
    const fn = async () => {
      const statement = gql`
        query listActivityVariances($input: ListActivityVariancesInput!) {
          listActivityVariances(input: $input) {
            __typename
            activity_id
            activity_name
            activity_type
            variance
            variance_percentage
            variance_type
            variance_unit_of_measure
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listActivityVariancesQuery>>response.data.listActivityVariances;
    };
    return gql$(fn());
  }
  getRemainingSpend$(input: GetRemainingSpendInput) {
    const fn = async () => {
      const statement = gql`
        query getRemainingSpend($input: GetRemainingSpendInput!) {
          getRemainingSpend(input: $input) {
            __typename
            entity_id
            entity_name
            entity_type
            remaining_spend {
              __typename
              amount
              period
              percentage
            }
            remaining_spend_time_series {
              __typename
              amount
              period
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<getRemainingSpendQuery>>response.data.getRemainingSpend;
    };
    return gql$(fn());
  }
  listChangeLogs$(vendor_id: string) {
    const fn = async () => {
      const statement = gql`
        query listChangeLogs($vendor_id: String!) {
          listChangeLogs(vendor_id: $vendor_id) {
            __typename
            id
            vendor_id
            change_order_id
            change_log_status
            log_no
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        vendor_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listChangeLogsQuery>>response.data.listChangeLogs;
    };
    return gql$(fn());
  }
  listChangeLogItems$(change_log_id: string) {
    const fn = async () => {
      const statement = gql`
        query listChangeLogItems($change_log_id: String!) {
          listChangeLogItems(change_log_id: $change_log_id) {
            __typename
            id
            organization {
              __typename
              id
              name
            }
            cnf_no
            change_log_item_status
            change_order_reference
            change_log_id
            activity_id
            request_date
            start_date
            description
            expense_amounts {
              __typename
              amount_type
              amount
            }
            cost_driver_description
            cause
            requester
            is_planned
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        change_log_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listChangeLogItemsQuery>>response.data.listChangeLogItems;
    };
    return gql$(fn());
  }
  createChangeLogItem$(input: ChangeLogItemInput) {
    const fn = async () => {
      const statement = gql`
        mutation createChangeLogItem($input: ChangeLogItemInput!) {
          createChangeLogItem(input: $input) {
            __typename
            id
            organization {
              __typename
              id
              name
            }
            cnf_no
            change_log_item_status
            change_order_reference
            change_log_id
            activity_id
            request_date
            start_date
            description
            expense_amounts {
              __typename
              amount_type
              amount
            }
            cause
            requester
            is_planned
            cost_driver_description
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createChangeLogItemMutation>response.data.createChangeLogItem;
    };
    return gql$(fn());
  }
  removeChangeLogItem$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeChangeLogItem($id: String!) {
          removeChangeLogItem(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeChangeLogItemMutation>response.data.removeChangeLogItem;
    };
    return gql$(fn());
  }
  updateChangeLogItem$(input: ChangeLogItemInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateChangeLogItem($input: ChangeLogItemInput!) {
          updateChangeLogItem(input: $input) {
            __typename
            id
            organization {
              __typename
              id
              name
            }
            cnf_no
            change_log_item_status
            change_order_reference
            change_log_id
            activity_id
            request_date
            start_date
            description
            expense_amounts {
              __typename
              amount_type
              amount
            }
            cost_driver_description
            cause
            requester
            is_planned
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateChangeLogItemMutation>response.data.updateChangeLogItem;
    };
    return gql$(fn());
  }
  listChangeOrdersStatuses$() {
    const fn = async () => {
      const statement = gql`
        query listChangeOrdersStatuses {
          listChangeOrders {
            __typename
            id
            change_order_no
            change_order_status
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listChangeOrdersStatusesQuery>>response.data.listChangeOrders;
    };
    return gql$(fn());
  }
  listChangeOrders$() {
    const fn = async () => {
      const statement = gql`
        query listChangeOrders {
          listChangeOrders {
            __typename
            id
            change_order_no
            change_order_status
            date_received
            approvals {
              __typename
              aux_user_id
              approval_time
            }
            workflow_details {
              __typename
              id
              properties
            }
            reasons {
              __typename
              note_type
              message
            }
            effective_date
            notes
            create_date
            merged_budget_version {
              __typename
              budget_version_id
              budget_name
              budget_type
            }
            budget_total {
              __typename
              prev_value
              value
              variance
              variance_percentage
            }
            created_by
            decline_reason
            approved_budget_version {
              __typename
              budget_version_id
            }
            organization {
              __typename
              id
            }
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listChangeOrdersQuery>>response.data.listChangeOrders;
    };
    return gql$(fn());
  }
  listChangeOrderNos$() {
    const fn = async () => {
      const statement = gql`
        query listChangeOrderNos {
          listChangeOrders {
            __typename
            id
            change_order_no
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listChangeOrderNosQuery>>response.data.listChangeOrders;
    };
    return gql$(fn());
  }
  getChangeOrder$(id: string) {
    const fn = async () => {
      const statement = gql`
        query getChangeOrder($id: String!) {
          getChangeOrder(id: $id) {
            __typename
            id
            change_order_no
            change_order_status
            date_received
            workflow_details {
              __typename
              id
              name
              properties
            }
            approvals {
              __typename
              aux_user_id
              permission
              approval_time
            }
            effective_date
            notes
            create_date
            created_by
            decline_reason
            reasons {
              __typename
              id
              message
              note_type
              create_date
              created_by
              entity_id
              expense_note_types
              entity_type
            }
            approved_budget_version {
              __typename
              budget_id
              budget_version_id
              bucket_key
              budget_name
              version
              budget_type
              manual_forecast
              create_date
              entity_id
              entity_type
            }
            organization {
              __typename
              id
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getChangeOrderQuery>response.data.getChangeOrder;
    };
    return gql$(fn());
  }
  createChangeOrder$(input: CreateChangeOrderInput) {
    const fn = async () => {
      const statement = gql`
        mutation createChangeOrder($input: CreateChangeOrderInput!) {
          createChangeOrder(input: $input) {
            __typename
            id
            change_order_no
            change_order_status
            date_received
            workflow_details {
              __typename
              id
              name
              properties
            }
            approvals {
              __typename
              aux_user_id
              permission
              approval_time
            }
            effective_date
            notes
            create_date
            created_by
            decline_reason
            approved_budget_version {
              __typename
              budget_id
              budget_version_id
              bucket_key
              budget_name
              version
              budget_type
              manual_forecast
              create_date
              entity_id
              entity_type
            }
            organization {
              __typename
              id
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createChangeOrderMutation>response.data.createChangeOrder;
    };
    return gql$(fn());
  }
  updateChangeOrder$(input: UpdateChangeOrderInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateChangeOrder($input: UpdateChangeOrderInput!) {
          updateChangeOrder(input: $input) {
            __typename
            id
            change_order_no
            change_order_status
            date_received
            workflow_details {
              __typename
              id
              name
              properties
            }
            approvals {
              __typename
              aux_user_id
              permission
              approval_time
            }
            effective_date
            notes
            create_date
            created_by
            decline_reason
            approved_budget_version {
              __typename
              budget_id
              budget_version_id
              bucket_key
              budget_name
              version
              budget_type
              manual_forecast
              create_date
              entity_id
              entity_type
            }
            organization {
              __typename
              id
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateChangeOrderMutation>response.data.updateChangeOrder;
    };
    return gql$(fn());
  }
  removeChangeOrder$(input: RemoveChangeOrderInput) {
    const fn = async () => {
      const statement = gql`
        mutation removeChangeOrder($input: RemoveChangeOrderInput!) {
          removeChangeOrder(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeChangeOrderMutation>response.data.removeChangeOrder;
    };
    return gql$(fn());
  }
  approveChangeOrder$(input: ApproveChangeOrderInput) {
    const fn = async () => {
      const statement = gql`
        mutation approveChangeOrder($input: ApproveChangeOrderInput!) {
          approveChangeOrder(input: $input) {
            __typename
            id
            change_order_status
            approved_budget_version {
              __typename
              budget_id
              budget_version_id
              bucket_key
              budget_name
              version
              budget_type
              manual_forecast
              create_date
              entity_id
              entity_type
            }
            approvals {
              __typename
              aux_user_id
              permission
              approval_time
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <approveChangeOrderMutation>response.data.approveChangeOrder;
    };
    return gql$(fn());
  }
  listMonthlyExchangeRates$(currencies: Array<Currency>) {
    const fn = async () => {
      const statement = gql`
        query listMonthlyExchangeRates($currencies: [Currency!]!) {
          listMonthlyExchangeRates(currencies: $currencies) {
            __typename
            currencies
            exchange_rates {
              __typename
              base_currency
              month
              rate
              target_currency
            }
            months
            trial_currency
            update_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        currencies,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <listMonthlyExchangeRatesQuery>response.data.listMonthlyExchangeRates;
    };
    return gql$(fn());
  }
  createDataStream$(query_name: string, zip: boolean, input: string) {
    const fn = async () => {
      const statement = gql`
        mutation createDataStream($query_name: String!, $zip: Boolean!, $input: AWSJSON!) {
          createDataStream(query_name: $query_name, zip: $zip, input: $input) {
            __typename
            stream_id
            total_rows
            bucket_key
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        query_name,
        zip,
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createDataStreamMutation>response.data.createDataStream;
    };
    return gql$(fn());
  }
  removeDataStream$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeDataStream($id: String!) {
          removeDataStream(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeDataStreamMutation>response.data.removeDataStream;
    };
    return gql$(fn());
  }
  fetchDocumentsDataStream$(input: FetchDataStreamInput) {
    const fn = async () => {
      const statement = gql`
        query fetchDocumentsDataStream($input: FetchDataStreamInput!) {
          fetchDocumentsDataStream(input: $input) {
            __typename
            total_rows
            aggregation {
              __typename
              id
              trial_id
              vendor_id
              site_id
              document_type_id
              name
              description
              bucket_key
              target_date
              is_metadata_editable
              create_date
              created_by
              entity_type_id
              entity_id
            }
            offset
            items {
              __typename
              id
              trial_id
              vendor_id
              site_id
              document_type_id
              name
              description
              bucket_key
              target_date
              is_metadata_editable
              create_date
              created_by
              entity_type_id
              entity_id
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchDocumentsDataStreamQuery>response.data.fetchDocumentsDataStream;
    };
    return gql$(fn());
  }
  fetchUserAuditLogsDataStream$(input: FetchDataStreamInput) {
    const fn = async () => {
      const statement = gql`
        query fetchUserAuditLogsDataStream($input: FetchDataStreamInput!) {
          fetchUserAuditLogsDataStream(input: $input) {
            __typename
            total_rows
            aggregation {
              __typename
              id
              entity_id
              entity_type
              category
              action
              action_text
              properties_changed_json
              details_json
              is_custom
              created_by
              create_date
              vendor_id
            }
            offset
            items {
              __typename
              id
              entity_id
              entity_type
              category
              action
              action_text
              properties_changed_json
              details_json
              is_custom
              created_by
              create_date
              vendor_id
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchUserAuditLogsDataStreamQuery>response.data.fetchUserAuditLogsDataStream;
    };
    return gql$(fn());
  }
  listDocuments$(input: FetchDocumentsInput) {
    const fn = async () => {
      const statement = gql`
        query listDocuments($input: FetchDocumentsInput!) {
          fetchDocuments(input: $input) {
            __typename
            items {
              __typename
              bucket_key
              create_date
              created_by
              description
              document_type_id
              entity_id
              entity_type_id
              id
              is_metadata_editable
              name
              site_id
              target_date
              trial_id
              vendor_id
            }
            last_row
            meta_data {
              __typename
              total_count
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <listDocumentsQuery>response.data.fetchDocuments;
    };
    return gql$(fn());
  }
  batchCreateDocuments$(input: Array<CreateDocumentInput>) {
    const fn = async () => {
      const statement = gql`
        mutation batchCreateDocuments($input: [CreateDocumentInput!]!) {
          batchCreateDocuments(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchCreateDocumentsMutation>>response.data.batchCreateDocuments;
    };
    return gql$(fn());
  }
  createDocument$(input: CreateDocumentInput) {
    const fn = async () => {
      const statement = gql`
        mutation createDocument($input: CreateDocumentInput!) {
          createDocument(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createDocumentMutation>response.data.createDocument;
    };
    return gql$(fn());
  }
  removeDocument$(input: RemoveDocumentInput) {
    const fn = async () => {
      const statement = gql`
        mutation removeDocument($input: RemoveDocumentInput!) {
          removeDocument(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeDocumentMutation>response.data.removeDocument;
    };
    return gql$(fn());
  }
  updateDocument$(input: UpdateDocumentInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateDocument($input: UpdateDocumentInput!) {
          updateDocument(input: $input) {
            __typename
            bucket_key
            create_date
            created_by
            description
            document_type_id
            entity_id
            entity_type_id
            id
            is_metadata_editable
            name
            site_id
            target_date
            trial_id
            vendor_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateDocumentMutation>response.data.updateDocument;
    };
    return gql$(fn());
  }
  createStoredDocument$(input: StoredDocumentInput) {
    const fn = async () => {
      const statement = gql`
        mutation createStoredDocument($input: StoredDocumentInput!) {
          createStoredDocument(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createStoredDocumentMutation>response.data.createStoredDocument;
    };
    return gql$(fn());
  }
  listDriverPatientDistributions$(patient_group_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listDriverPatientDistributions($patient_group_id: String) {
          listDriverPatientDistributions(patient_group_id: $patient_group_id) {
            __typename
            distribution_month
            forecast {
              __typename
              id
              distribution_mode
              patients_enrolled
              patients_discontinued
              patients_complete
              net_patients_enrolled
              cumulative_enrollment_percentage
              total_patients_enrolled
            }
            actual {
              __typename
              distribution_mode
              patients_enrolled
              patients_discontinued
              patients_complete
              net_patients_enrolled
              cumulative_enrollment_percentage
              total_patients_enrolled
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (patient_group_id) {
        gqlAPIServiceArguments.patient_group_id = patient_group_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listDriverPatientDistributionsQuery>>(
        response.data.listDriverPatientDistributions
      );
    };
    return gql$(fn());
  }
  listDriverPatientGroups$() {
    const fn = async () => {
      const statement = gql`
        query listDriverPatientGroups {
          listDriverPatientGroups {
            __typename
            constituent_patient_groups {
              __typename
              id
              name
              description
              rank_order
            }
            driver_setting_id
            is_blended
            organizations_with_forecasts {
              __typename
              id
              name
            }
            name
            organizations_with_forecasts {
              __typename
              id
              name
            }
            patient_group_id
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listDriverPatientGroupsQuery>>response.data.listDriverPatientGroups;
    };
    return gql$(fn());
  }
  updateDriverBlendedPatientDistribution$(input: UpdateDriverBlendedPatientDistributionInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateDriverBlendedPatientDistribution(
          $input: UpdateDriverBlendedPatientDistributionInput!
        ) {
          updateDriverBlendedPatientDistribution(input: $input) {
            __typename
            driver_setting_id
            patient_group_id
            name
            is_blended
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateDriverBlendedPatientDistributionMutation>(
        response.data.updateDriverBlendedPatientDistribution
      );
    };
    return gql$(fn());
  }
  removeDriverBlendedPatientDistribution$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeDriverBlendedPatientDistribution($id: String!) {
          removeDriverBlendedPatientDistribution(id: $id) {
            __typename
            driver_setting_id
            patient_group_id
            name
            is_blended
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeDriverBlendedPatientDistributionMutation>(
        response.data.removeDriverBlendedPatientDistribution
      );
    };
    return gql$(fn());
  }
  removePatientGroupDriver$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removePatientGroupDriver($id: String!) {
          removePatientGroupDriver(id: $id) {
            __typename
            driver_setting_id
            patient_group_id
            name
            is_blended
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removePatientGroupDriverMutation>response.data.removePatientGroupDriver;
    };
    return gql$(fn());
  }
  createDriverBlendedPatientDistribution$(input: CreateDriverBlendedPatientDistributionInput) {
    const fn = async () => {
      const statement = gql`
        mutation createDriverBlendedPatientDistribution(
          $input: CreateDriverBlendedPatientDistributionInput!
        ) {
          createDriverBlendedPatientDistribution(input: $input) {
            __typename
            driver_setting_id
            patient_group_id
            name
            is_blended
            constituent_patient_groups {
              __typename
              id
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createDriverBlendedPatientDistributionMutation>(
        response.data.createDriverBlendedPatientDistribution
      );
    };
    return gql$(fn());
  }
  getRemainingInvestigatorInfo$() {
    const fn = async () => {
      const statement = gql`
        query getRemainingInvestigatorInfo {
          getRemainingInvestigatorInfo {
            __typename
            amount_remaining
            expected_patients_enrolled
            patients_enrolled
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <getRemainingInvestigatorInfoQuery>response.data.getRemainingInvestigatorInfo;
    };
    return gql$(fn());
  }
  createDriverSiteSetting$(input: CreateDriverSiteSettingInput) {
    const fn = async () => {
      const statement = gql`
        mutation createDriverSiteSetting($input: CreateDriverSiteSettingInput!) {
          createDriverSiteSetting(input: $input) {
            __typename
            id
            cohort_name
            number_of_sites
            first_initiated_date
            discontinued_date
            ramp_time_in_weeks
            target_patient_count
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createDriverSiteSettingMutation>response.data.createDriverSiteSetting;
    };
    return gql$(fn());
  }
  updateDriverSiteSetting$(input: UpdateDriverSiteSettingInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateDriverSiteSetting($input: UpdateDriverSiteSettingInput!) {
          updateDriverSiteSetting(input: $input) {
            __typename
            id
            cohort_name
            number_of_sites
            first_initiated_date
            discontinued_date
            ramp_time_in_weeks
            target_patient_count
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateDriverSiteSettingMutation>response.data.updateDriverSiteSetting;
    };
    return gql$(fn());
  }
  listDriverSiteSetting$() {
    const fn = async () => {
      const statement = gql`
        query listDriverSiteSetting {
          listDriverSiteSetting {
            __typename
            id
            cohort_name
            number_of_sites
            first_initiated_date
            discontinued_date
            ramp_time_in_weeks
            target_patient_count
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listDriverSiteSettingQuery>>response.data.listDriverSiteSetting;
    };
    return gql$(fn());
  }
  removeDriverSiteSetting$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeDriverSiteSetting($id: String!) {
          removeDriverSiteSetting(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeDriverSiteSettingMutation>response.data.removeDriverSiteSetting;
    };
    return gql$(fn());
  }
  listDriverSiteDistributions$(
    distribution_modes: Array<DistributionMode>,
    site_group_id?: string
  ) {
    const fn = async () => {
      const statement = gql`
        query listDriverSiteDistributions(
          $distribution_modes: [DistributionMode!]!
          $site_group_id: String
        ) {
          listDriverSiteDistributions(
            distribution_modes: $distribution_modes
            site_group_id: $site_group_id
          ) {
            __typename
            id
            distribution_mode
            distribution_month
            sites_activated
            sites_closed
            net_sites_per_month
            net_sites_per_month_percentage
            sites_activated_percentage
            sites_closed_percentage
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        distribution_modes,
      };
      if (site_group_id) {
        gqlAPIServiceArguments.site_group_id = site_group_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listDriverSiteDistributionsQuery>>response.data.listDriverSiteDistributions;
    };
    return gql$(fn());
  }
  listDriverSiteGroups$() {
    const fn = async () => {
      const statement = gql`
        query listDriverSiteGroups {
          listDriverSiteGroups {
            __typename
            driver_setting_id
            site_group_id
            name
            is_blended
            curve_type
            organizations_with_forecasts {
              __typename
              id
              name
            }
            constituent_site_groups {
              __typename
              id
              name
              description
              rank_order
            }
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listDriverSiteGroupsQuery>>response.data.listDriverSiteGroups;
    };
    return gql$(fn());
  }
  removeSiteGroupDriver$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeSiteGroupDriver($id: String!) {
          removeSiteGroupDriver(id: $id) {
            __typename
            driver_setting_id
            is_blended
            name
            site_group_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeSiteGroupDriverMutation>response.data.removeSiteGroupDriver;
    };
    return gql$(fn());
  }
  createDriverBlendedSiteDistribution$(input?: CreateDriverBlendedSiteDistributionInput) {
    const fn = async () => {
      const statement = gql`
        mutation createDriverBlendedSiteDistribution(
          $input: CreateDriverBlendedSiteDistributionInput
        ) {
          createDriverBlendedSiteDistribution(input: $input) {
            __typename
            driver_setting_id
            is_blended
            name
            site_group_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (input) {
        gqlAPIServiceArguments.input = input;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createDriverBlendedSiteDistributionMutation>(
        response.data.createDriverBlendedSiteDistribution
      );
    };
    return gql$(fn());
  }
  updateDriverBlendedSiteDistribution$(input?: UpdateDriverBlendedSiteDistributionInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateDriverBlendedSiteDistribution(
          $input: UpdateDriverBlendedSiteDistributionInput
        ) {
          updateDriverBlendedSiteDistribution(input: $input) {
            __typename
            driver_setting_id
            is_blended
            name
            site_group_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (input) {
        gqlAPIServiceArguments.input = input;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateDriverBlendedSiteDistributionMutation>(
        response.data.updateDriverBlendedSiteDistribution
      );
    };
    return gql$(fn());
  }
  removeDriverBlendedSiteDistribution$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeDriverBlendedSiteDistribution($id: String!) {
          removeDriverBlendedSiteDistribution(id: $id) {
            __typename
            driver_setting_id
            is_blended
            name
            site_group_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeDriverBlendedSiteDistributionMutation>(
        response.data.removeDriverBlendedSiteDistribution
      );
    };
    return gql$(fn());
  }
  listInMonthCategories$(input: ListCategorySettingsInput) {
    const fn = async () => {
      const statement = gql`
        query listInMonthCategories($input: ListCategorySettingsInput!) {
          listCategorySettings(input: $input) {
            __typename
            categories {
              __typename
              id
              name
              category_type
              expenses {
                __typename
                amount
                amount_type
                expense_type
                period
              }
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <listInMonthCategoriesQuery>response.data.listCategorySettings;
    };
    return gql$(fn());
  }
  getUnforecastedEntityIds$(vendor_id: string) {
    const fn = async () => {
      const statement = gql`
        query getUnforecastedEntityIds($vendor_id: String!) {
          getUnforecastedEntityIds(vendor_id: $vendor_id) {
            __typename
            activity_ids
            category_ids
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        vendor_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getUnforecastedEntityIdsQuery>response.data.getUnforecastedEntityIds;
    };
    return gql$(fn());
  }
  getInMonthListCategoryAndItsActivities$(input: ListCategorySettingsInput) {
    const fn = async () => {
      const statement = gql`
        query getInMonthListCategoryAndItsActivities($input: ListCategorySettingsInput!) {
          listCategorySettings(input: $input) {
            __typename
            categories {
              __typename
              id
              name
              category_type
              expenses {
                __typename
                amount
                amount_type
                expense_type
                period
              }
            }
            activities {
              __typename
              id
              name
              unit_cost
              activity_type
              budget_override_settings {
                __typename
                id
                notes {
                  __typename
                  id
                  entity_id
                  message
                  create_date
                  created_by
                }
              }
              expenses {
                __typename
                amount
                amount_type
                expense_type
                period
              }
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getInMonthListCategoryAndItsActivitiesQuery>response.data.listCategorySettings;
    };
    return gql$(fn());
  }
  getCategoryAndItsActivities$(input: ListCategorySettingsInput) {
    const fn = async () => {
      const statement = gql`
        query getCategoryAndItsActivities($input: ListCategorySettingsInput!) {
          listCategorySettings(input: $input) {
            __typename
            categories {
              __typename
              id
              name
              display_label
              expenses {
                __typename
                amount
                amount_type
                expense_type
                period
              }
              category_type
              budget_forecast_settings {
                __typename
                id
                forecast_method
                driver_setting_id
                category_id
                activity_id
                driver
                period_start_milestone_id
                period_end_milestone_id
                period_start_date
                period_end_date
                evidence_source
                amount_type
                override
                milestone_category
              }
              activity_driver_settings {
                __typename
                activity_override
                category_override
                driver
                setting_count
              }
            }
            activities {
              __typename
              id
              uom
              unit_num
              expenses {
                __typename
                amount
                amount_type
                expense_type
                period
              }
              activity_type
              budget_override_note
              budget_forecast_settings {
                __typename
                id
                forecast_method
                category_id
                activity_id
                driver_setting_id
                driver
                period_start_milestone_id
                period_end_milestone_id
                period_start_date
                period_end_date
                evidence_source
                amount_type
                override
                milestone_category
              }
              budget_override_settings {
                __typename
                id
                category_id
                activity_id
                expense_amounts {
                  __typename
                  units
                }
                expense_detail
              }
              id
              name
              display_label
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getCategoryAndItsActivitiesQuery>response.data.listCategorySettings;
    };
    return gql$(fn());
  }
  getAnalyticsCard$(input: AnalyticsCardInput) {
    const fn = async () => {
      const statement = gql`
        query getAnalyticsCard($input: AnalyticsCardInput!) {
          getAnalyticsCard(input: $input) {
            __typename
            primary_key
            sort_key
            data
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getAnalyticsCardQuery>response.data.getAnalyticsCard;
    };
    return gql$(fn());
  }
  listBudgets$(budget_type: BudgetType, vendor_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listBudgets($budget_type: BudgetType!, $vendor_id: String) {
          listBudgets(budget_type: $budget_type, vendor_id: $vendor_id) {
            __typename
            id
            budget_type
            name
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        budget_type,
      };
      if (vendor_id) {
        gqlAPIServiceArguments.vendor_id = vendor_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <listBudgetsQuery>response.data.listBudgets;
    };
    return gql$(fn());
  }
  listBudgetVersionExpenses$(budget_version_id: string) {
    const fn = async () => {
      const statement = gql`
        query listBudgetVersionExpenses($budget_version_id: String!) {
          listBudgetVersionExpenses(budget_version_id: $budget_version_id) {
            __typename
            expense_type
            period
            amount
            amount_type
            uom
            unit_cost
            unit_num
            activity_type
            source
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        budget_version_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listBudgetVersionExpensesQuery>>response.data.listBudgetVersionExpenses;
    };
    return gql$(fn());
  }
  updateBudgetForecastSetting$(input: BudgetForecastSettingInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateBudgetForecastSetting($input: BudgetForecastSettingInput!) {
          updateBudgetForecastSetting(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateBudgetForecastSettingMutation>response.data.updateBudgetForecastSetting;
    };
    return gql$(fn());
  }
  updateActivityDiscounts$(input: Array<UpdateActivityDiscountsInput>) {
    const fn = async () => {
      const statement = gql`
        mutation updateActivityDiscounts($input: [UpdateActivityDiscountsInput!]!) {
          updateActivityDiscounts(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<updateActivityDiscountsMutation>>response.data.updateActivityDiscounts;
    };
    return gql$(fn());
  }
  createOverrideSetting$(input?: BudgetExpenseInput) {
    const fn = async () => {
      const statement = gql`
        mutation createOverrideSetting($input: BudgetExpenseInput) {
          createBudgetExpense(input: $input) {
            __typename
            id
            category_id
            activity_id
            expense_amounts {
              __typename
              units
            }
            expense_detail
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (input) {
        gqlAPIServiceArguments.input = input;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createOverrideSettingMutation>response.data.createBudgetExpense;
    };
    return gql$(fn());
  }
  updateOverrideSetting$(input?: BudgetExpenseInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateOverrideSetting($input: BudgetExpenseInput) {
          updateBudgetExpense(input: $input) {
            __typename
            id
            category_id
            activity_id
            expense_amounts {
              __typename
              units
            }
            expense_detail
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (input) {
        gqlAPIServiceArguments.input = input;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateOverrideSettingMutation>response.data.updateBudgetExpense;
    };
    return gql$(fn());
  }
  listInvestigatorForecastContractedAmounts$(vendor_id: string) {
    const fn = async () => {
      const statement = gql`
        query listInvestigatorForecastContractedAmounts($vendor_id: String!) {
          listInvestigatorForecastContractedAmounts(vendor_id: $vendor_id) {
            __typename
            activity_name
            expense_type
            amount_type
            amount_curr
            amount
            contract_curr
            contract_amount
            source_last_updated
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        vendor_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listInvestigatorForecastContractedAmountsQuery>>(
        response.data.listInvestigatorForecastContractedAmounts
      );
    };
    return gql$(fn());
  }
  listSiteContractSettings$(vendor_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listSiteContractSettings($vendor_id: String) {
          listSiteContractSettings(organization_id: $vendor_id) {
            __typename
            source_last_updated
            last_updated
            site_count
            from_patient_curve
            total_forecasted_patients
            average_patient_cost
            average_patient_cost_curr
            current_average_patient_cost
            current_average_patient_cost_curr
            invoiceables_percent
            other_percent
            overhead_percent
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (vendor_id) {
        gqlAPIServiceArguments.vendor_id = vendor_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listSiteContractSettingsQuery>>response.data.listSiteContractSettings;
    };
    return gql$(fn());
  }
  createSiteContractSetting$(input: CreateSiteContractSettingInput) {
    const fn = async () => {
      const statement = gql`
        mutation createSiteContractSetting($input: CreateSiteContractSettingInput!) {
          createSiteContractSetting(input: $input) {
            __typename
            last_updated
            site_count
            from_patient_curve
            total_forecasted_patients
            average_patient_cost
            average_patient_cost_curr
            current_average_patient_cost
            current_average_patient_cost_curr
            invoiceables_percent
            other_percent
            overhead_percent
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createSiteContractSettingMutation>response.data.createSiteContractSetting;
    };
    return gql$(fn());
  }
  updateInvestigatorForecast$(input: UpdateInvestigatorForecastInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateInvestigatorForecast($input: UpdateInvestigatorForecastInput!) {
          updateInvestigatorForecast(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<updateInvestigatorForecastMutation>>response.data.updateInvestigatorForecast;
    };
    return gql$(fn());
  }
  listActualPatientVisitSettings$(organization_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listActualPatientVisitSettings($organization_id: String) {
          listActualPatientVisitSettings(organization_id: $organization_id) {
            __typename
            source_last_updated
            last_updated
            forecasted_remaining_visit_costs
            invoiceables_percent
            other_percent
            overhead_percent
            discontinued_percent
            current_discontinued_percent
            current_discontinued_amount
            current_enrolled_amount
            average_completed_patient_cost
            remaining_patients_to_discontinue_percent
            remaining_patients_to_discontinue_cost
            visit_costs_to_date
            current_invoiceables_percent
            invoicables_to_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (organization_id) {
        gqlAPIServiceArguments.organization_id = organization_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listActualPatientVisitSettingsQuery>>(
        response.data.listActualPatientVisitSettings
      );
    };
    return gql$(fn());
  }
  createActualPatientVisitSetting$(input: CreateActualPatientVisitSettingInput) {
    const fn = async () => {
      const statement = gql`
        mutation createActualPatientVisitSetting($input: CreateActualPatientVisitSettingInput!) {
          createActualPatientVisitSetting(input: $input) {
            __typename
            other_percent
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createActualPatientVisitSettingMutation>response.data.createActualPatientVisitSetting;
    };
    return gql$(fn());
  }
  listInvoices$() {
    const fn = async () => {
      const statement = gql`
        query listInvoices {
          listInvoices {
            __typename
            id
            accrual_period
            created_by
            create_date
            accrual_period
            purchase_order_id
            organization {
              __typename
              id
              name
              currency
            }
            invoice_no
            invoice_date
            payment_status
            payment_date
            invoice_status
            line_items
            ocr_line_items
            expense_amounts {
              __typename
              amount
              amount_curr
              amount_type
              contract_curr
              contract_amount
              exchange_rate {
                __typename
                base_currency
                month
                rate
                target_currency
              }
            }
            due_date
            data_source_id
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listInvoicesQuery>>response.data.listInvoices;
    };
    return gql$(fn());
  }
  listInvoicesForReconciliation$(input?: ListInvoicesInput) {
    const fn = async () => {
      const statement = gql`
        query listInvoicesForReconciliation($input: ListInvoicesInput) {
          listInvoices(input: $input) {
            __typename
            id
            accrual_period
            invoice_status
            payment_date
            organization {
              __typename
              id
            }
            expense_amounts {
              __typename
              amount
              amount_curr
              amount_type
              contract_amount
              contract_curr
              exchange_rate {
                __typename
                base_currency
                month
                rate
                target_currency
              }
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (input) {
        gqlAPIServiceArguments.input = input;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listInvoicesForReconciliationQuery>>response.data.listInvoices;
    };
    return gql$(fn());
  }
  getInvoice$(id: string) {
    const fn = async () => {
      const statement = gql`
        query getInvoice($id: String!) {
          getInvoice(id: $id) {
            __typename
            id
            created_by
            create_date
            approvals {
              __typename
              approver_name
              approval_time
              activity_details
              aux_user_id
              permission
            }
            accrual_period
            purchase_order_id
            organization {
              __typename
              id
              name
              currency
            }
            accrual_period
            invoice_no
            invoice_date
            invoice_status
            workflow_details {
              __typename
              id
              name
              properties
            }
            expense_amounts {
              __typename
              amount
              amount_curr
              amount_type
              contract_curr
              contract_amount
              exchange_rate {
                __typename
                base_currency
                month
                rate
                target_currency
              }
            }
            due_date
            reasons {
              __typename
              message
              note_type
            }
            line_items
            ocr_line_items
            data_source_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getInvoiceQuery>response.data.getInvoice;
    };
    return gql$(fn());
  }
  batchCreateInvoices$(input: Array<CreateInvoiceInput>) {
    const fn = async () => {
      const statement = gql`
        mutation batchCreateInvoices($input: [CreateInvoiceInput!]!) {
          batchCreateInvoices(input: $input) {
            __typename
            id
            accrual_period
            approvals {
              __typename
              approver_name
              approval_time
              activity_details
              aux_user_id
              permission
            }
            purchase_order_id
            organization {
              __typename
              id
              name
              currency
            }
            invoice_no
            invoice_date
            invoice_status
            workflow_details {
              __typename
              id
              name
              properties
            }
            expense_amounts {
              __typename
              amount
              amount_type
            }
            due_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchCreateInvoicesMutation>>response.data.batchCreateInvoices;
    };
    return gql$(fn());
  }
  createInvoice$(input: CreateInvoiceInput) {
    const fn = async () => {
      const statement = gql`
        mutation createInvoice($input: CreateInvoiceInput!) {
          createInvoice(input: $input) {
            __typename
            id
            accrual_period
            approvals {
              __typename
              approver_name
              approval_time
              activity_details
              aux_user_id
              permission
            }
            purchase_order_id
            organization {
              __typename
              id
              name
              currency
            }
            invoice_no
            invoice_date
            invoice_status
            workflow_details {
              __typename
              id
              name
              properties
            }
            expense_amounts {
              __typename
              amount
              amount_type
            }
            due_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createInvoiceMutation>response.data.createInvoice;
    };
    return gql$(fn());
  }
  batchUpdateWorkflowDetails$(input: Array<UpdateWorkflowInput>) {
    const fn = async () => {
      const statement = gql`
        mutation batchUpdateWorkflowDetails($input: [UpdateWorkflowInput!]!) {
          batchUpdateWorkflowDetails(input: $input) {
            __typename
            id
            update_date
            updated_by
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchUpdateWorkflowDetailsMutation>>response.data.batchUpdateWorkflowDetails;
    };
    return gql$(fn());
  }
  updateWorkflowDetail$(input: UpdateWorkflowInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateWorkflowDetail($input: UpdateWorkflowInput!) {
          updateWorkflowDetail(input: $input) {
            __typename
            id
            update_date
            updated_by
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateWorkflowDetailMutation>response.data.updateWorkflowDetail;
    };
    return gql$(fn());
  }
  batchUpdateInvoices$(input: Array<UpdateInvoiceInput>) {
    const fn = async () => {
      const statement = gql`
        mutation batchUpdateInvoices($input: [UpdateInvoiceInput!]!) {
          batchUpdateInvoices(input: $input) {
            __typename
            id
            due_date
            payment_date
            invoice_no
            invoice_date
            invoice_status
            accrual_period
            approvals {
              __typename
              approver_name
              approval_time
              activity_details
              aux_user_id
              permission
            }
            accrual_period
            purchase_order_id
            organization {
              __typename
              id
              name
              currency
            }
            reasons {
              __typename
              message
              note_type
            }
            expense_amounts {
              __typename
              amount
              amount_type
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchUpdateInvoicesMutation>>response.data.batchUpdateInvoices;
    };
    return gql$(fn());
  }
  updateInvoice$(input: UpdateInvoiceInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateInvoice($input: UpdateInvoiceInput!) {
          updateInvoice(input: $input) {
            __typename
            id
            due_date
            payment_date
            invoice_no
            invoice_date
            invoice_status
            accrual_period
            approvals {
              __typename
              approver_name
              approval_time
              activity_details
              aux_user_id
              permission
            }
            accrual_period
            purchase_order_id
            organization {
              __typename
              id
              name
              currency
            }
            reasons {
              __typename
              message
              note_type
            }
            expense_amounts {
              __typename
              amount
              amount_type
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateInvoiceMutation>response.data.updateInvoice;
    };
    return gql$(fn());
  }
  batchUpdateExpenseAmounts$(input: Array<ExpenseAmountInput>) {
    const fn = async () => {
      const statement = gql`
        mutation batchUpdateExpenseAmounts($input: [ExpenseAmountInput!]!) {
          batchUpdateExpenseAmounts(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchUpdateExpenseAmountsMutation>>response.data.batchUpdateExpenseAmounts;
    };
    return gql$(fn());
  }
  updateExpenseAmount$(input: ExpenseAmountInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateExpenseAmount($input: ExpenseAmountInput!) {
          updateExpenseAmount(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateExpenseAmountMutation>response.data.updateExpenseAmount;
    };
    return gql$(fn());
  }
  listInvoiceNumbers$() {
    const fn = async () => {
      const statement = gql`
        query listInvoiceNumbers {
          listInvoices {
            __typename
            id
            invoice_no
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listInvoiceNumbersQuery>>response.data.listInvoices;
    };
    return gql$(fn());
  }
  batchRemoveInvoices$(input: Array<string>) {
    const fn = async () => {
      const statement = gql`
        mutation batchRemoveInvoices($input: [String!]!) {
          batchRemoveInvoices(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchRemoveInvoicesMutation>>response.data.batchRemoveInvoices;
    };
    return gql$(fn());
  }
  removeInvoice$(input: RemoveInvoiceInput) {
    const fn = async () => {
      const statement = gql`
        mutation removeInvoice($input: RemoveInvoiceInput!) {
          removeInvoice(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeInvoiceMutation>response.data.removeInvoice;
    };
    return gql$(fn());
  }
  listOrganizations$() {
    const fn = async () => {
      const statement = gql`
        query listOrganizations {
          listOrganizations {
            __typename
            id
            name
            currency
            parent_organization {
              __typename
              id
              name
            }
            current_budget_versions(budget_type: BUDGET_PRIMARY) {
              __typename
              budget_type
              budget_name
              budget_version_id
              bucket_key
              manual_forecast
              entity_id
              entity_type
            }
            has_closed_months
            costs_included_in_parent_wo
            organization_type
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listOrganizationsQuery>>response.data.listOrganizations;
    };
    return gql$(fn());
  }
  listIdOrganizations$() {
    const fn = async () => {
      const statement = gql`
        query listIdOrganizations {
          listOrganizations {
            __typename
            id
            name
            organization_type
            currency
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listIdOrganizationsQuery>>response.data.listOrganizations;
    };
    return gql$(fn());
  }
  listOrganizationsWithInvestigatorCostCategories$() {
    const fn = async () => {
      const statement = gql`
        query listOrganizationsWithInvestigatorCostCategories {
          listOrganizationsWithInvestigatorCostCategories {
            __typename
            id
            name
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listOrganizationsWithInvestigatorCostCategoriesQuery>>(
        response.data.listOrganizationsWithInvestigatorCostCategories
      );
    };
    return gql$(fn());
  }
  listOrganizationsWithTotalBudgetAmount$(budget_type?: BudgetType) {
    const fn = async () => {
      const statement = gql`
        query listOrganizationsWithTotalBudgetAmount($budget_type: BudgetType) {
          listOrganizations {
            __typename
            id
            name
            currency
            organization_type
            current_budget_versions(budget_type: $budget_type) {
              __typename
              budget_type
              budget_name
              budget_version_id
              bucket_key
              manual_forecast
              entity_id
              entity_type
              total_budget_amount
            }
            baseline_budget_version {
              __typename
              budget_type
              budget_name
              budget_version_id
              bucket_key
              manual_forecast
              entity_id
              entity_type
            }
            has_closed_months
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (budget_type) {
        gqlAPIServiceArguments.budget_type = budget_type;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listOrganizationsWithTotalBudgetAmountQuery>>response.data.listOrganizations;
    };
    return gql$(fn());
  }
  listContacts$(entity_id: string, contact_type: ContactType) {
    const fn = async () => {
      const statement = gql`
        query listContacts($entity_id: String!, $contact_type: ContactType!) {
          listContacts(entity_id: $entity_id, contact_type: $contact_type) {
            __typename
            id
            given_name
            family_name
            email
            title
            phone_number
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        entity_id,
        contact_type,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listContactsQuery>>response.data.listContacts;
    };
    return gql$(fn());
  }
  createOrganization$(input: CreateOrganizationInput) {
    const fn = async () => {
      const statement = gql`
        mutation createOrganization($input: CreateOrganizationInput!) {
          createOrganization(input: $input) {
            __typename
            id
            name
            currency
            parent_organization {
              __typename
              id
              name
            }
            costs_included_in_parent_wo
            organization_type
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createOrganizationMutation>response.data.createOrganization;
    };
    return gql$(fn());
  }
  removeOrganization$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeOrganization($id: String!) {
          removeOrganization(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeOrganizationMutation>response.data.removeOrganization;
    };
    return gql$(fn());
  }
  updateOrganization$(input: UpdateOrganizationInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateOrganization($input: UpdateOrganizationInput!) {
          updateOrganization(input: $input) {
            __typename
            id
            name
            currency
            parent_organization {
              __typename
              id
              name
            }
            costs_included_in_parent_wo
            organization_type
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateOrganizationMutation>response.data.updateOrganization;
    };
    return gql$(fn());
  }
  createContact$(input: ContactInput) {
    const fn = async () => {
      const statement = gql`
        mutation createContact($input: ContactInput!) {
          createContact(input: $input) {
            __typename
            id
            given_name
            family_name
            email
            title
            phone_number
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createContactMutation>response.data.createContact;
    };
    return gql$(fn());
  }
  updateContact$(input: UpdateContactInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateContact($input: UpdateContactInput!) {
          updateContact(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateContactMutation>response.data.updateContact;
    };
    return gql$(fn());
  }
  listOrganizationNames$() {
    const fn = async () => {
      const statement = gql`
        query listOrganizationNames {
          listOrganizations {
            __typename
            id
            name
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listOrganizationNamesQuery>>response.data.listOrganizations;
    };
    return gql$(fn());
  }
  listVendorEstimateSummaries$(period: string) {
    const fn = async () => {
      const statement = gql`
        query listVendorEstimateSummaries($period: String!) {
          listVendorEstimateSummaries(period: $period) {
            __typename
            organization_id
            organization_name
            vendor_estimate_exists
            activities_exceeding_forecast
            activities_exceeding_amount_remaining
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        period,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listVendorEstimateSummariesQuery>>response.data.listVendorEstimateSummaries;
    };
    return gql$(fn());
  }
  listEntitySettings$(input: EntitySettingsInput) {
    const fn = async () => {
      const statement = gql`
        query listEntitySettings($input: EntitySettingsInput!) {
          listEntitySettings(input: $input) {
            __typename
            entity_id
            settings
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listEntitySettingsQuery>>response.data.listEntitySettings;
    };
    return gql$(fn());
  }
  updateEntitySettings$(input: EntitySettingsInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateEntitySettings($input: EntitySettingsInput!) {
          updateEntitySettings(input: $input) {
            __typename
            entity_id
            settings
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<updateEntitySettingsMutation>>response.data.updateEntitySettings;
    };
    return gql$(fn());
  }
  listJournalEntries$(from_date?: string, to_date?: string, vendor_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listJournalEntries($from_date: AWSDate, $to_date: AWSDate, $vendor_id: String) {
          listJournalEntries(from_date: $from_date, to_date: $to_date, vendor_id: $vendor_id) {
            __typename
            budget_version_id
            vendor_id
            vendor_name
            entry_name
            entry_type
            vendor_currency
            line_memo
            sponsor_name
            attributes
            reversal_date
            debit_amount
            credit_amount
            debit_contracted_amount
            credit_contracted_amount
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (from_date) {
        gqlAPIServiceArguments.from_date = from_date;
      }
      if (to_date) {
        gqlAPIServiceArguments.to_date = to_date;
      }
      if (vendor_id) {
        gqlAPIServiceArguments.vendor_id = vendor_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listJournalEntriesQuery>>response.data.listJournalEntries;
    };
    return gql$(fn());
  }
  listBudgetAttributes$(budget_version_id?: string, vendor_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listBudgetAttributes($budget_version_id: String, $vendor_id: String) {
          listBudgetAttributes(budget_version_id: $budget_version_id, vendor_id: $vendor_id) {
            __typename
            attribute
            attribute_index
            attribute_name
            attribute_value
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (budget_version_id) {
        gqlAPIServiceArguments.budget_version_id = budget_version_id;
      }
      if (vendor_id) {
        gqlAPIServiceArguments.vendor_id = vendor_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listBudgetAttributesQuery>>response.data.listBudgetAttributes;
    };
    return gql$(fn());
  }
  createPaymentMilestone$(input: CreatePaymentMilestoneInput) {
    const fn = async () => {
      const statement = gql`
        mutation createPaymentMilestone($input: CreatePaymentMilestoneInput!) {
          createPaymentMilestone(input: $input) {
            __typename
            id
            organization {
              __typename
              id
            }
            name
            amount
            target_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createPaymentMilestoneMutation>response.data.createPaymentMilestone;
    };
    return gql$(fn());
  }
  updatePaymentMilestone$(input: UpdatePaymentMilestoneInput) {
    const fn = async () => {
      const statement = gql`
        mutation updatePaymentMilestone($input: UpdatePaymentMilestoneInput!) {
          updatePaymentMilestone(input: $input) {
            __typename
            id
            organization {
              __typename
              id
            }
            name
            amount
            target_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updatePaymentMilestoneMutation>response.data.updatePaymentMilestone;
    };
    return gql$(fn());
  }
  listPaymentMilestones$() {
    const fn = async () => {
      const statement = gql`
        query listPaymentMilestones {
          listPaymentMilestones {
            __typename
            id
            organization {
              __typename
              id
              name
            }
            name
            amount
            target_date
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listPaymentMilestonesQuery>>response.data.listPaymentMilestones;
    };
    return gql$(fn());
  }
  removePaymentMilestone$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removePaymentMilestone($id: String!) {
          removePaymentMilestone(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removePaymentMilestoneMutation>response.data.removePaymentMilestone;
    };
    return gql$(fn());
  }
  listOrganizationWorkPerformed$(organization_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listOrganizationWorkPerformed($organization_id: String) {
          listOrganizationWorkPerformed(organization_id: $organization_id) {
            __typename
            organization {
              __typename
              id
            }
            amount
            period_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (organization_id) {
        gqlAPIServiceArguments.organization_id = organization_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listOrganizationWorkPerformedQuery>>response.data.listOrganizationWorkPerformed;
    };
    return gql$(fn());
  }
  listCashRequirements$(organization_id: string) {
    const fn = async () => {
      const statement = gql`
        query listCashRequirements($organization_id: String!) {
          listCashRequirements(organization_id: $organization_id) {
            __typename
            id
            organization {
              __typename
              id
            }
            expense_amount {
              __typename
              amount_type
              amount_curr
              amount
            }
            requirement_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        organization_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listCashRequirementsQuery>>response.data.listCashRequirements;
    };
    return gql$(fn());
  }
  listPaymentMilestoneSummary$(group_fields: Array<string>) {
    const fn = async () => {
      const statement = gql`
        query listPaymentMilestoneSummary($group_fields: [String!]!) {
          listPaymentMilestoneSummary(group_fields: $group_fields) {
            __typename
            milestone_name
            time_period
            total_amount
            vendor_name
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        group_fields,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listPaymentMilestoneSummaryQuery>>response.data.listPaymentMilestoneSummary;
    };
    return gql$(fn());
  }
  listInMonthExpenses$(input: ListInMonthExpensesInput) {
    const fn = async () => {
      const statement = gql`
        query listInMonthExpenses($input: ListInMonthExpensesInput!) {
          listInMonthExpenses(input: $input) {
            __typename
            activity_id
            activity_no
            activity_name
            activity_type
            display_label
            group0
            group1
            group2
            group3
            group4
            is_forecasted
            direct_cost {
              __typename
              amount
              unit_cost
              uom
              unit_num
            }
            forecast {
              __typename
              amount
              unit_cost
            }
            manual_adjustment {
              __typename
              id
              period
              amount
              unit_cost
              previous_amount
              updated_by
              update_date
              adjustment_type
            }
            historical_adjustment {
              __typename
              id
              period
              amount
              unit_cost
              previous_amount
              updated_by
              update_date
              adjustment_type
            }
            vendor_estimate {
              __typename
              amount
              unit_cost
            }
            work_performed {
              __typename
              amount
              unit_cost
            }
            work_performed_to_date {
              __typename
              amount
              unit_cost
            }
            prev_months_accruals {
              __typename
              amount
              unit_cost
            }
            prev_month_work_performed {
              __typename
              amount
              unit_cost
            }
            monthly_expense {
              __typename
              amount
              unit_cost
              expense_source
            }
            total_monthly_expense {
              __typename
              amount
              unit_cost
              expense_source
            }
            accrual {
              __typename
              amount
              unit_cost
              expense_source
            }
            notes {
              __typename
              id
              entity_id
              expense_note_types
              entity_type
              note_type
              message
              created_by
              create_date
            }
            documents {
              __typename
              id
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listInMonthExpensesQuery>>response.data.listInMonthExpenses;
    };
    return gql$(fn());
  }
  listDiscountExpenses$(input: ListInMonthExpensesInput) {
    const fn = async () => {
      const statement = gql`
        query listDiscountExpenses($input: ListInMonthExpensesInput!) {
          listInMonthExpenses(input: $input) {
            __typename
            activity_id
            activity_no
            manual_adjustment {
              __typename
              amount
              expense_detail
            }
            vendor_estimate {
              __typename
              amount
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listDiscountExpensesQuery>>response.data.listInMonthExpenses;
    };
    return gql$(fn());
  }
  listExpenseSourceSettings$(input: ListExpenseSourceSettingsInput) {
    const fn = async () => {
      const statement = gql`
        query listExpenseSourceSettings($input: ListExpenseSourceSettingsInput!) {
          listExpenseSourceSettings(input: $input) {
            __typename
            activity_id
            default
            period
            sources
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listExpenseSourceSettingsQuery>>response.data.listExpenseSourceSettings;
    };
    return gql$(fn());
  }
  batchOverrideExpenseSources$(input: BatchOverrideExpenseSourceInput) {
    const fn = async () => {
      const statement = gql`
        mutation batchOverrideExpenseSources($input: BatchOverrideExpenseSourceInput!) {
          batchOverrideExpenseSources(input: $input) {
            __typename
            activity_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchOverrideExpenseSourcesMutation>>response.data.batchOverrideExpenseSources;
    };
    return gql$(fn());
  }
  batchRemoveExpenseSourceOverrides$(input: BatchRemoveExpenseSourceOverridesInput) {
    const fn = async () => {
      const statement = gql`
        mutation batchRemoveExpenseSourceOverrides(
          $input: BatchRemoveExpenseSourceOverridesInput!
        ) {
          batchRemoveExpenseSourceOverrides(input: $input) {
            __typename
            activity_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchRemoveExpenseSourceOverridesMutation>>(
        response.data.batchRemoveExpenseSourceOverrides
      );
    };
    return gql$(fn());
  }
  batchRemoveBudgetExpenses$(input: Array<string>) {
    const fn = async () => {
      const statement = gql`
        mutation batchRemoveBudgetExpenses($input: [String!]!) {
          batchRemoveBudgetExpenses(input: $input) {
            __typename
            activity_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<batchRemoveBudgetExpensesMutation>>response.data.batchRemoveBudgetExpenses;
    };
    return gql$(fn());
  }
  listPO$() {
    const fn = async () => {
      const statement = gql`
        query listPO {
          listPurchaseOrders {
            __typename
            id
            invoice_summaries {
              __typename
              invoice_id
              invoice_status
              total_amount
            }
            po_amount
            po_number
            organization {
              __typename
              id
              name
              currency
            }
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listPOQuery>>response.data.listPurchaseOrders;
    };
    return gql$(fn());
  }
  createPO$(input: CreatePurchaseOrderInput) {
    const fn = async () => {
      const statement = gql`
        mutation createPO($input: CreatePurchaseOrderInput!) {
          createPurchaseOrder(input: $input) {
            __typename
            id
            po_amount
            po_number
            organization {
              __typename
              id
              name
              currency
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createPOMutation>response.data.createPurchaseOrder;
    };
    return gql$(fn());
  }
  updatePO$(input: UpdatePurchaseOrderInput) {
    const fn = async () => {
      const statement = gql`
        mutation updatePO($input: UpdatePurchaseOrderInput!) {
          updatePurchaseOrder(input: $input) {
            __typename
            organization {
              __typename
              id
              name
              currency
            }
            id
            po_amount
            po_number
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updatePOMutation>response.data.updatePurchaseOrder;
    };
    return gql$(fn());
  }
  listPONumbers$() {
    const fn = async () => {
      const statement = gql`
        query listPONumbers {
          listPurchaseOrders {
            __typename
            id
            po_number
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listPONumbersQuery>>response.data.listPurchaseOrders;
    };
    return gql$(fn());
  }
  removePO$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removePO($id: String!) {
          removePurchaseOrder(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removePOMutation>response.data.removePurchaseOrder;
    };
    return gql$(fn());
  }
  listScenarioModel$(trial_budget_version_id?: string, vendor_id?: string) {
    const fn = async () => {
      const statement = gql`
        query listScenarioModel($trial_budget_version_id: String, $vendor_id: String) {
          listScenarioModel(
            trial_budget_version_id: $trial_budget_version_id
            vendor_id: $vendor_id
          ) {
            __typename
            label
            model {
              __typename
              source
              data
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (trial_budget_version_id) {
        gqlAPIServiceArguments.trial_budget_version_id = trial_budget_version_id;
      }
      if (vendor_id) {
        gqlAPIServiceArguments.vendor_id = vendor_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <listScenarioModelQuery>response.data.listScenarioModel;
    };
    return gql$(fn());
  }
  listSites$(contact_type: ContactType) {
    const fn = async () => {
      const statement = gql`
        query listSites($contact_type: ContactType!) {
          listSites {
            __typename
            id
            site_no
            name
            address_line_1
            address_line_2
            address_line_3
            city
            state
            zip
            country
            contacts(contact_type: $contact_type) {
              __typename
              id
              given_name
              family_name
            }
            target_patients
            managed_by {
              __typename
              id
            }
            site_activation
            closeout_date
            currency
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        contact_type,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listSitesQuery>>response.data.listSites;
    };
    return gql$(fn());
  }
  listPatientGroups$(patient_group_types?: Array<PatientGroupType>) {
    const fn = async () => {
      const statement = gql`
        query listPatientGroups($patient_group_types: [PatientGroupType!]) {
          listPatientGroups(patient_group_types: $patient_group_types) {
            __typename
            id
            name
            description
            type
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (patient_group_types) {
        gqlAPIServiceArguments.patient_group_types = patient_group_types;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listPatientGroupsQuery>>response.data.listPatientGroups;
    };
    return gql$(fn());
  }
  patientGroupsOption$(patient_group_types?: Array<PatientGroupType>) {
    const fn = async () => {
      const statement = gql`
        query patientGroupsOption($patient_group_types: [PatientGroupType!]) {
          listPatientGroups(patient_group_types: $patient_group_types) {
            __typename
            id
            name
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (patient_group_types) {
        gqlAPIServiceArguments.patient_group_types = patient_group_types;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<patientGroupsOptionQuery>>response.data.listPatientGroups;
    };
    return gql$(fn());
  }
  createPatientGroup$(input: CreatePatientGroupInput) {
    const fn = async () => {
      const statement = gql`
        mutation createPatientGroup($input: CreatePatientGroupInput!) {
          createPatientGroup(input: $input) {
            __typename
            id
            name
            description
            type
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createPatientGroupMutation>response.data.createPatientGroup;
    };
    return gql$(fn());
  }
  updatePatientGroup$(input: UpdatePatientGroupInput) {
    const fn = async () => {
      const statement = gql`
        mutation updatePatientGroup($input: UpdatePatientGroupInput!) {
          updatePatientGroup(input: $input) {
            __typename
            id
            name
            description
            type
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updatePatientGroupMutation>response.data.updatePatientGroup;
    };
    return gql$(fn());
  }
  removePatientGroup$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removePatientGroup($id: String!) {
          removePatientGroup(id: $id) {
            __typename
            id
            name
            description
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removePatientGroupMutation>response.data.removePatientGroup;
    };
    return gql$(fn());
  }
  clonePatientProtocol$(input: ClonePatientProtocolInput) {
    const fn = async () => {
      const statement = gql`
        mutation clonePatientProtocol($input: ClonePatientProtocolInput!) {
          clonePatientProtocol(input: $input) {
            __typename
            id
            name
            patient_protocol_type
            order_by
            target_date_days_out
            target_tolerance_days_out
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<clonePatientProtocolMutation>>response.data.clonePatientProtocol;
    };
    return gql$(fn());
  }
  listSiteGroups$(site_group_types?: Array<SiteGroupType>) {
    const fn = async () => {
      const statement = gql`
        query listSiteGroups($site_group_types: [SiteGroupType!]) {
          listSiteGroups(site_group_types: $site_group_types) {
            __typename
            id
            name
            description
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (site_group_types) {
        gqlAPIServiceArguments.site_group_types = site_group_types;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listSiteGroupsQuery>>response.data.listSiteGroups;
    };
    return gql$(fn());
  }
  createSiteGroup$(input: CreateSiteGroupInput) {
    const fn = async () => {
      const statement = gql`
        mutation createSiteGroup($input: CreateSiteGroupInput!) {
          createSiteGroup(input: $input) {
            __typename
            id
            name
            description
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createSiteGroupMutation>response.data.createSiteGroup;
    };
    return gql$(fn());
  }
  updateSiteGroup$(input: UpdateSiteGroupInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateSiteGroup($input: UpdateSiteGroupInput!) {
          updateSiteGroup(input: $input) {
            __typename
            id
            name
            description
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateSiteGroupMutation>response.data.updateSiteGroup;
    };
    return gql$(fn());
  }
  removeSiteGroup$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeSiteGroup($id: String!) {
          removeSiteGroup(id: $id) {
            __typename
            id
            name
            description
            rank_order
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeSiteGroupMutation>response.data.removeSiteGroup;
    };
    return gql$(fn());
  }
  listSiteContacts$(entity_id: string, contact_type: ContactType) {
    const fn = async () => {
      const statement = gql`
        query listSiteContacts($entity_id: String!, $contact_type: ContactType!) {
          listContacts(entity_id: $entity_id, contact_type: $contact_type) {
            __typename
            id
            given_name
            family_name
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        entity_id,
        contact_type,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listSiteContactsQuery>>response.data.listContacts;
    };
    return gql$(fn());
  }
  listPatientProtocols$(
    patient_protocol_types: Array<PatientProtocolType>,
    patient_protocol_version_id?: string,
    return_all_ppv_protocols?: boolean
  ) {
    const fn = async () => {
      const statement = gql`
        query listPatientProtocols(
          $patient_protocol_types: [PatientProtocolType!]!
          $patient_protocol_version_id: String
          $return_all_ppv_protocols: Boolean
        ) {
          listPatientProtocols(
            patient_protocol_types: $patient_protocol_types
            patient_protocol_version_id: $patient_protocol_version_id
            return_all_ppv_protocols: $return_all_ppv_protocols
          ) {
            __typename
            id
            name
            order_by
            patient_group_id
            patient_protocol_type
            patient_protocol_sub_type {
              __typename
              id
              name
              patient_protocol_type
            }
            patient_protocol_frequency
            target_date_days_out
            target_tolerance_days_out
            patient_protocol_version {
              __typename
              id
              name
              is_current
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        patient_protocol_types,
      };
      if (patient_protocol_version_id) {
        gqlAPIServiceArguments.patient_protocol_version_id = patient_protocol_version_id;
      }
      if (return_all_ppv_protocols) {
        gqlAPIServiceArguments.return_all_ppv_protocols = return_all_ppv_protocols;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listPatientProtocolsQuery>>response.data.listPatientProtocols;
    };
    return gql$(fn());
  }
  listPatientProtocolSubTypes$(patient_protocol_types: Array<PatientProtocolType>) {
    const fn = async () => {
      const statement = gql`
        query listPatientProtocolSubTypes($patient_protocol_types: [PatientProtocolType!]!) {
          listPatientProtocolSubTypes(patient_protocol_types: $patient_protocol_types) {
            __typename
            id
            name
            patient_protocol_type
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        patient_protocol_types,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listPatientProtocolSubTypesQuery>>response.data.listPatientProtocolSubTypes;
    };
    return gql$(fn());
  }
  listSitePaymentSchedules$(
    patient_protocol_types: Array<PatientProtocolType>,
    site_id: string,
    patient_protocol_version_id?: string
  ) {
    const fn = async () => {
      const statement = gql`
        query listSitePaymentSchedules(
          $patient_protocol_types: [PatientProtocolType!]!
          $site_id: String!
          $patient_protocol_version_id: String
        ) {
          listSitePaymentSchedules(
            patient_protocol_types: $patient_protocol_types
            patient_protocol_version_id: $patient_protocol_version_id
            site_id: $site_id
          ) {
            __typename
            id
            patient_protocol {
              __typename
              id
              patient_protocol_type
            }
            expense_amount {
              __typename
              amount
            }
            note
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        patient_protocol_types,
        site_id,
      };
      if (patient_protocol_version_id) {
        gqlAPIServiceArguments.patient_protocol_version_id = patient_protocol_version_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listSitePaymentSchedulesQuery>>response.data.listSitePaymentSchedules;
    };
    return gql$(fn());
  }
  fetchTrialSitePaymentSchedules$(input: FetchTrialSitePaymentSchedulesInput) {
    const fn = async () => {
      const statement = gql`
        query fetchTrialSitePaymentSchedules($input: FetchTrialSitePaymentSchedulesInput!) {
          fetchTrialSitePaymentSchedules(input: $input) {
            __typename
            items {
              __typename
              id
              site_id
              patient_protocol {
                __typename
                id
                patient_protocol_type
              }
              expense_amount {
                __typename
                amount
                amount_curr
                contract_amount
                contract_curr
              }
              note
            }
            next_offset
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchTrialSitePaymentSchedulesQuery>response.data.fetchTrialSitePaymentSchedules;
    };
    return gql$(fn());
  }
  listSitePatientTrackerMonthlyAmounts$() {
    const fn = async () => {
      const statement = gql`
        query listSitePatientTrackerMonthlyAmounts {
          listSitePatientTrackerMonthlyAmounts {
            __typename
            vendor_id
            completion_month
            patient_amount
            other_amount
            overhead_amount
            total_amount
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listSitePatientTrackerMonthlyAmountsQuery>>(
        response.data.listSitePatientTrackerMonthlyAmounts
      );
    };
    return gql$(fn());
  }
  fetchSitePatientTrackersFlat$(input: FetchSitePatientTrackersFlatInput) {
    const fn = async () => {
      const statement = gql`
        query fetchSitePatientTrackersFlat($input: FetchSitePatientTrackersFlatInput!) {
          fetchSitePatientTrackersFlat(input: $input) {
            __typename
            items {
              __typename
              id
              completion_date
              patient_id
              external_patient_id
              create_date
              sps_id
              sps_note
              sps_expense_amount
              sps_expense_currency
              sps_contract_expense_amount
              sps_contract_expense_currency
              sps_site_id
              sps_site_target_patients
              sps_site_managed_by_id
              sps_site_total_payment_schedule_amount
              sps_pp_id
              sps_pp_name
              sps_pp_patient_protocol_type
              sps_pp_target_date_days_out
              sps_pp_target_tolerance_days_out
              sps_pp_order_by
              sps_total_contract_expense_amount
              sps_total_expense_amount
            }
            next_offset
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchSitePatientTrackersFlatQuery>response.data.fetchSitePatientTrackersFlat;
    };
    return gql$(fn());
  }
  createPatientProtocol$(input: CreatePatientProtocolInput) {
    const fn = async () => {
      const statement = gql`
        mutation createPatientProtocol($input: CreatePatientProtocolInput!) {
          createPatientProtocol(input: $input) {
            __typename
            id
            name
            order_by
            patient_group_id
            patient_protocol_type
            patient_protocol_sub_type {
              __typename
              id
              name
              patient_protocol_type
            }
            patient_protocol_frequency
            target_date_days_out
            target_tolerance_days_out
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createPatientProtocolMutation>response.data.createPatientProtocol;
    };
    return gql$(fn());
  }
  fetchPatientVisitSchedules$(input: FetchPatientVisitSchedulesInput) {
    const fn = async () => {
      const statement = gql`
        query fetchPatientVisitSchedules($input: FetchPatientVisitSchedulesInput!) {
          fetchPatientVisitSchedules(input: $input) {
            __typename
            items {
              __typename
              external_patient_id
              site_id
              site_no
              visit_schedule {
                __typename
                patient_protocol_id
                patient_protocol_type
                patient_protocol_name
                patient_protocol_version_id
                scheduled_date
                completed
                visit_index
                cost {
                  __typename
                  amount
                  amount_curr
                  contract_amount
                  contract_curr
                }
              }
            }
            meta_data {
              __typename
              total
              current_month_total
            }
            next_offset
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchPatientVisitSchedulesQuery>response.data.fetchPatientVisitSchedules;
    };
    return gql$(fn());
  }
  createPatientProtocolVersion$(input: CreatePatientProtocolVersionInput) {
    const fn = async () => {
      const statement = gql`
        mutation createPatientProtocolVersion($input: CreatePatientProtocolVersionInput!) {
          createPatientProtocolVersion(input: $input) {
            __typename
            id
            name
            is_current
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createPatientProtocolVersionMutation>response.data.createPatientProtocolVersion;
    };
    return gql$(fn());
  }
  updatePatientProtocol$(input: UpdatePatientProtocolInput) {
    const fn = async () => {
      const statement = gql`
        mutation updatePatientProtocol($input: UpdatePatientProtocolInput!) {
          updatePatientProtocol(input: $input) {
            __typename
            id
            name
            order_by
            patient_group_id
            patient_protocol_type
            patient_protocol_sub_type {
              __typename
              id
              name
              patient_protocol_type
            }
            patient_protocol_frequency
            target_date_days_out
            target_tolerance_days_out
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updatePatientProtocolMutation>response.data.updatePatientProtocol;
    };
    return gql$(fn());
  }
  removePatientProtocol$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removePatientProtocol($id: String!) {
          removePatientProtocol(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removePatientProtocolMutation>response.data.removePatientProtocol;
    };
    return gql$(fn());
  }
  createSitePaymentSchedule$(input: CreateSitePaymentScheduleInput) {
    const fn = async () => {
      const statement = gql`
        mutation createSitePaymentSchedule($input: CreateSitePaymentScheduleInput!) {
          createSitePaymentSchedule(input: $input) {
            __typename
            id
            site_id
            patient_protocol {
              __typename
              id
              patient_protocol_type
            }
            expense_amount {
              __typename
              amount
              amount_curr
              amount_type
            }
            note
            unscheduled
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createSitePaymentScheduleMutation>response.data.createSitePaymentSchedule;
    };
    return gql$(fn());
  }
  updateSitePaymentSchedule$(input: UpdateSitePaymentScheduleInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateSitePaymentSchedule($input: UpdateSitePaymentScheduleInput!) {
          updateSitePaymentSchedule(input: $input) {
            __typename
            id
            site_id
            patient_protocol {
              __typename
              id
              patient_protocol_type
            }
            expense_amount {
              __typename
              amount
              amount_curr
              amount_type
            }
            note
            unscheduled
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateSitePaymentScheduleMutation>response.data.updateSitePaymentSchedule;
    };
    return gql$(fn());
  }
  removeSitePaymentSchedule$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeSitePaymentSchedule($id: String!) {
          removeSitePaymentSchedule(id: $id) {
            __typename
            id
            site_id
            patient_protocol {
              __typename
              id
              patient_protocol_type
            }
            expense_amount {
              __typename
              amount
              amount_curr
              amount_type
            }
            note
            unscheduled
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeSitePaymentScheduleMutation>response.data.removeSitePaymentSchedule;
    };
    return gql$(fn());
  }
  createSite$(input: CreateSiteInput) {
    const fn = async () => {
      const statement = gql`
        mutation createSite($input: CreateSiteInput!) {
          createSite(input: $input) {
            __typename
            id
            name
            site_no
            address_line_1
            address_line_2
            address_line_3
            city
            state
            zip
            country
            target_patients
            managed_by {
              __typename
              id
            }
            site_activation
            closeout_date
            currency
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createSiteMutation>response.data.createSite;
    };
    return gql$(fn());
  }
  createDriverSiteDistribution$(input: CreateDriverSiteDistributionInput) {
    const fn = async () => {
      const statement = gql`
        mutation createDriverSiteDistribution($input: CreateDriverSiteDistributionInput!) {
          createDriverSiteDistribution(input: $input) {
            __typename
            id
            distribution_mode
            distribution_month
            sites_activated
            sites_closed
            net_sites_per_month
            net_sites_per_month_percentage
            sites_activated_percentage
            sites_closed_percentage
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createDriverSiteDistributionMutation>response.data.createDriverSiteDistribution;
    };
    return gql$(fn());
  }
  updateDriverSiteDistribution$(input: UpdateDriverSiteDistributionInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateDriverSiteDistribution($input: UpdateDriverSiteDistributionInput!) {
          updateDriverSiteDistribution(input: $input) {
            __typename
            id
            distribution_mode
            distribution_month
            sites_activated
            sites_closed
            net_sites_per_month
            net_sites_per_month_percentage
            sites_activated_percentage
            sites_closed_percentage
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateDriverSiteDistributionMutation>response.data.updateDriverSiteDistribution;
    };
    return gql$(fn());
  }
  removeDriverSiteDistribution$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeDriverSiteDistribution($id: String!) {
          removeDriverSiteDistribution(id: $id) {
            __typename
            id
            distribution_mode
            distribution_month
            sites_activated
            sites_closed
            net_sites_per_month
            net_sites_per_month_percentage
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeDriverSiteDistributionMutation>response.data.removeDriverSiteDistribution;
    };
    return gql$(fn());
  }
  removeSite$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeSite($id: String!) {
          removeSite(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeSiteMutation>response.data.removeSite;
    };
    return gql$(fn());
  }
  updateSite$(input: UpdateSiteInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateSite($input: UpdateSiteInput!) {
          updateSite(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateSiteMutation>response.data.updateSite;
    };
    return gql$(fn());
  }
  createSiteContact$(input: ContactInput) {
    const fn = async () => {
      const statement = gql`
        mutation createSiteContact($input: ContactInput!) {
          createContact(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createSiteContactMutation>response.data.createContact;
    };
    return gql$(fn());
  }
  listSiteNames$() {
    const fn = async () => {
      const statement = gql`
        query listSiteNames {
          listSites {
            __typename
            id
            name
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listSiteNamesQuery>>response.data.listSites;
    };
    return gql$(fn());
  }
  createDriverPatientDistribution$(input: CreateDriverPatientDistributionInput) {
    const fn = async () => {
      const statement = gql`
        mutation createDriverPatientDistribution($input: CreateDriverPatientDistributionInput!) {
          createDriverPatientDistribution(input: $input) {
            __typename
            distribution_month
            forecast {
              __typename
              id
              distribution_mode
              patients_enrolled
              patients_discontinued
              patients_complete
              net_patients_enrolled
              cumulative_enrollment_percentage
              total_patients_enrolled
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createDriverPatientDistributionMutation>response.data.createDriverPatientDistribution;
    };
    return gql$(fn());
  }
  updateDriverPatientDistribution$(input: UpdateDriverPatientDistributionInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateDriverPatientDistribution($input: UpdateDriverPatientDistributionInput!) {
          updateDriverPatientDistribution(input: $input) {
            __typename
            distribution_month
            forecast {
              __typename
              id
              distribution_mode
              patients_enrolled
              patients_discontinued
              patients_complete
              net_patients_enrolled
              cumulative_enrollment_percentage
              total_patients_enrolled
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateDriverPatientDistributionMutation>response.data.updateDriverPatientDistribution;
    };
    return gql$(fn());
  }
  removeDriverPatientDistribution$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeDriverPatientDistribution($id: String!) {
          removeDriverPatientDistribution(id: $id) {
            __typename
            distribution_month
            forecast {
              __typename
              id
              distribution_mode
              patients_enrolled
              patients_discontinued
              patients_complete
              net_patients_enrolled
              cumulative_enrollment_percentage
              total_patients_enrolled
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeDriverPatientDistributionMutation>response.data.removeDriverPatientDistribution;
    };
    return gql$(fn());
  }
  listSitePatientTrackerVersions$() {
    const fn = async () => {
      const statement = gql`
        query listSitePatientTrackerVersions {
          listSitePatientTrackerVersions {
            __typename
            id
            is_current
            create_date
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listSitePatientTrackerVersionsQuery>>(
        response.data.listSitePatientTrackerVersions
      );
    };
    return gql$(fn());
  }
  fetchInvestigatorTransactions$(input: FetchPatientTransactionsInput) {
    const fn = async () => {
      const statement = gql`
        query fetchInvestigatorTransactions($input: FetchPatientTransactionsInput!) {
          fetchPatientTransactions(input: $input) {
            __typename
            items {
              __typename
              activity_date
              source_created_date
              create_date
              exchange_rate {
                __typename
                rate
              }
              external_patient_id
              id
              patient_group_id
              patient_protocol_category
              site_payment_schedule {
                __typename
                expense_amount {
                  __typename
                  amount
                  amount_curr
                  contract_amount
                  contract_curr
                }
                country
                id
                patient_protocol {
                  __typename
                  id
                  name
                  patient_protocol_version {
                    __typename
                    id
                    name
                    is_current
                  }
                  patient_protocol_sub_type {
                    __typename
                    name
                  }
                }
                site_id
              }
            }
            next_offset
            meta_data {
              __typename
              total_cost
              total_count
            }
            latest_source_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchInvestigatorTransactionsQuery>response.data.fetchPatientTransactions;
    };
    return gql$(fn());
  }
  siteDictionary$() {
    const fn = async () => {
      const statement = gql`
        query siteDictionary {
          listSites {
            __typename
            id
            site_no
            country
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<siteDictionaryQuery>>response.data.listSites;
    };
    return gql$(fn());
  }
  listPatients$() {
    const fn = async () => {
      const statement = gql`
        query listPatients {
          listPatients {
            __typename
            external_patient_id
            id
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listPatientsQuery>>response.data.listPatients;
    };
    return gql$(fn());
  }
  listPatientProtocolList$(
    patient_protocol_types: Array<PatientProtocolType>,
    return_all_ppv_protocols?: boolean
  ) {
    const fn = async () => {
      const statement = gql`
        query listPatientProtocolList(
          $patient_protocol_types: [PatientProtocolType!]!
          $return_all_ppv_protocols: Boolean
        ) {
          listPatientProtocols(
            patient_protocol_types: $patient_protocol_types
            return_all_ppv_protocols: $return_all_ppv_protocols
          ) {
            __typename
            id
            name
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        patient_protocol_types,
      };
      if (return_all_ppv_protocols) {
        gqlAPIServiceArguments.return_all_ppv_protocols = return_all_ppv_protocols;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listPatientProtocolListQuery>>response.data.listPatientProtocols;
    };
    return gql$(fn());
  }
  listPatientProtocolVersions$() {
    const fn = async () => {
      const statement = gql`
        query listPatientProtocolVersions {
          listPatientProtocolVersions {
            __typename
            id
            name
            is_current
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listPatientProtocolVersionsQuery>>response.data.listPatientProtocolVersions;
    };
    return gql$(fn());
  }
  updatePatientProtocolVersion$(input: UpdatePatientProtocolVersionInput) {
    const fn = async () => {
      const statement = gql`
        mutation updatePatientProtocolVersion($input: UpdatePatientProtocolVersionInput!) {
          updatePatientProtocolVersion(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updatePatientProtocolVersionMutation>response.data.updatePatientProtocolVersion;
    };
    return gql$(fn());
  }
  removePatientProtocolVersion$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removePatientProtocolVersion($id: String!) {
          removePatientProtocolVersion(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removePatientProtocolVersionMutation>response.data.removePatientProtocolVersion;
    };
    return gql$(fn());
  }
  fetchInvestigatorSummaries$(input: FetchInvestigatorTransactionsInput) {
    const fn = async () => {
      const statement = gql`
        query fetchInvestigatorSummaries($input: FetchInvestigatorTransactionsInput!) {
          fetchInvestigatorSummaries(input: $input) {
            __typename
            items {
              __typename
              external_patient_id
              investigator_costs {
                __typename
                cost
                cost_type
                patient_group_id
                patient_group_name
                patient_group_rank
              }
              patient_id
              remaining_visit_costs
              site_id
            }
            next_offset
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchInvestigatorSummariesQuery>response.data.fetchInvestigatorSummaries;
    };
    return gql$(fn());
  }
  fetchInvestigatorDetail$(input: FetchInvestigatorDetailInput) {
    const fn = async () => {
      const statement = gql`
        query fetchInvestigatorDetail($input: FetchInvestigatorDetailInput!) {
          fetchInvestigatorDetail(input: $input) {
            __typename
            items {
              __typename
              completion_date
              create_date
              external_patient_id
              id
              patient_id
              patient_tracker_version_id
              sps_contract_expense_amount
              sps_contract_expense_currency
              sps_expense_amount
              sps_expense_currency
              sps_id
              sps_note
              sps_pp_id
              sps_pp_name
              sps_pp_order_by
              sps_pp_patient_protocol_type
              sps_pp_target_date_days_out
              sps_pp_target_tolerance_days_out
              sps_site_id
              sps_site_managed_by_id
              sps_site_target_patients
              sps_site_total_payment_schedule_amount
              sps_total_contract_expense_amount
              sps_total_expense_amount
            }
            next_offset
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <fetchInvestigatorDetailQuery>response.data.fetchInvestigatorDetail;
    };
    return gql$(fn());
  }
  getPatientCostSummary$(input: GetPatientCostSummaryInput) {
    const fn = async () => {
      const statement = gql`
        query getPatientCostSummary($input: GetPatientCostSummaryInput!) {
          getPatientCostSummary(input: $input) {
            __typename
            expected_patients_enrolled
            total_cost {
              __typename
              source_entity_id
              source
              source_entity_data
              average_patient_cost
              patients_enrolled
            }
            site_costs {
              __typename
              source_entity_id
              source
              source_entity_data
              average_patient_cost
              patients_enrolled
            }
            patient_group_costs {
              __typename
              source_entity_id
              source
              source_entity_data
              average_patient_cost
              patients_enrolled
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getPatientCostSummaryQuery>response.data.getPatientCostSummary;
    };
    return gql$(fn());
  }
  getTotalInvestigatorCosts$() {
    const fn = async () => {
      const statement = gql`
        query getTotalInvestigatorCosts {
          getTotalInvestigatorCosts {
            __typename
            cost
            patient_protocol_type
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<getTotalInvestigatorCostsQuery>>response.data.getTotalInvestigatorCosts;
    };
    return gql$(fn());
  }
  getScreenFailSummary$(input: GetScreenFailSummaryInput) {
    const fn = async () => {
      const statement = gql`
        query getScreenFailSummary($input: GetScreenFailSummaryInput!) {
          getScreenFailSummary(input: $input) {
            __typename
            screen_fail_total_cost
            screen_fails
            screen_fail_rate
            dropout_rate
            site_screen_fail_rates {
              __typename
              site_name
              primary_investigator {
                __typename
                given_name
                family_name
              }
              screen_fail_rate
            }
            site_screen_fail_totals {
              __typename
              site_name
              primary_investigator {
                __typename
                given_name
                family_name
              }
              screen_fails
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getScreenFailSummaryQuery>response.data.getScreenFailSummary;
    };
    return gql$(fn());
  }
  getSiteCostSummary$(input: GetSiteCostSummaryInput) {
    const fn = async () => {
      const statement = gql`
        query getSiteCostSummary($input: GetSiteCostSummaryInput!) {
          getSiteCostSummary(input: $input) {
            __typename
            sites_activated
            expected_sites_activated
            expected_sites_closed
            site_costs {
              __typename
              site_name
              primary_investigator {
                __typename
                given_name
                family_name
              }
              total_cost
              patients_enrolled
            }
            sites_lag {
              __typename
              site_name
              primary_investigator {
                __typename
                given_name
                family_name
              }
              latest_transaction
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getSiteCostSummaryQuery>response.data.getSiteCostSummary;
    };
    return gql$(fn());
  }
  checkPatientProtocolIsUnique$(input: CheckPatientProtocolIsUniqueInput) {
    const fn = async () => {
      const statement = gql`
        query checkPatientProtocolIsUnique($input: CheckPatientProtocolIsUniqueInput!) {
          checkPatientProtocolIsUnique(input: $input) {
            __typename
            is_unique
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <checkPatientProtocolIsUniqueQuery>response.data.checkPatientProtocolIsUnique;
    };
    return gql$(fn());
  }
  removeBudgetSnapshot$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeBudgetSnapshot($id: String!) {
          removeBudgetSnapshot(id: $id) {
            __typename
            id
            name
            budget_snapshot_type_id
            budget_version_ids
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeBudgetSnapshotMutation>response.data.removeBudgetSnapshot;
    };
    return gql$(fn());
  }
  updateBudgetSnapshot$(input: UpdateBudgetSnapshotInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateBudgetSnapshot($input: UpdateBudgetSnapshotInput!) {
          updateBudgetSnapshot(input: $input) {
            __typename
            id
            name
            budget_snapshot_type_id
            budget_version_ids
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateBudgetSnapshotMutation>response.data.updateBudgetSnapshot;
    };
    return gql$(fn());
  }
  listSpecificationSettings$() {
    const fn = async () => {
      const statement = gql`
        query listSpecificationSettings {
          listSpecificationSettings {
            __typename
            id
            name
            order
            specifications {
              __typename
              id
              input_type
              name
              settings {
                __typename
                id
                setting_key
                setting_value
                specification_type
              }
            }
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listSpecificationSettingsQuery>>response.data.listSpecificationSettings;
    };
    return gql$(fn());
  }
  createSpecification$(input: CreateSpecificationInput) {
    const fn = async () => {
      const statement = gql`
        mutation createSpecification($input: CreateSpecificationInput!) {
          createSpecification(input: $input) {
            __typename
            id
            name
            input_type
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createSpecificationMutation>response.data.createSpecification;
    };
    return gql$(fn());
  }
  createSpecificationSetting$(input: CreateSpecificationSettingInput) {
    const fn = async () => {
      const statement = gql`
        mutation createSpecificationSetting($input: CreateSpecificationSettingInput!) {
          createSpecificationSetting(input: $input) {
            __typename
            id
            specification_id
            setting_key
            setting_value
            specification_type
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createSpecificationSettingMutation>response.data.createSpecificationSetting;
    };
    return gql$(fn());
  }
  updateSpecification$(input: UpdateSpecificationInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateSpecification($input: UpdateSpecificationInput!) {
          updateSpecification(input: $input) {
            __typename
            id
            name
            input_type
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateSpecificationMutation>response.data.updateSpecification;
    };
    return gql$(fn());
  }
  updateSpecificationSetting$(input: UpdateSpecificationSettingInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateSpecificationSetting($input: UpdateSpecificationSettingInput!) {
          updateSpecificationSetting(input: $input) {
            __typename
            id
            specification_id
            setting_key
            setting_value
            specification_type
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateSpecificationSettingMutation>response.data.updateSpecificationSetting;
    };
    return gql$(fn());
  }
  getTimeline$() {
    const fn = async () => {
      const statement = gql`
        query getTimeline {
          getTimeline(timeline_type: TIMELINE_TRIAL) {
            __typename
            id
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <getTimelineQuery>response.data.getTimeline;
    };
    return gql$(fn());
  }
  getMilestonesAndCategories$() {
    const fn = async () => {
      const statement = gql`
        query getMilestonesAndCategories {
          listMilestoneCategories {
            __typename
            id
            name
            description
            grouping_order
            milestones {
              __typename
              id
              description
              name
            }
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<getMilestonesAndCategoriesQuery>>response.data.listMilestoneCategories;
    };
    return gql$(fn());
  }
  listTimelineMilestoneIds$(timeline_id: string) {
    const fn = async () => {
      const statement = gql`
        query listTimelineMilestoneIds($timeline_id: String!) {
          listTimelineMilestones(timeline_id: $timeline_id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        timeline_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listTimelineMilestoneIdsQuery>>response.data.listTimelineMilestones;
    };
    return gql$(fn());
  }
  listTimelineMilestones$(timeline_id: string) {
    const fn = async () => {
      const statement = gql`
        query listTimelineMilestones($timeline_id: String!) {
          listTimelineMilestones(timeline_id: $timeline_id) {
            __typename
            actual_end_date
            actual_start_date
            contract_end_date
            contract_start_date
            contract_month_difference
            id
            revised_end_date
            revised_start_date
            track_from_milestone {
              __typename
              id
            }
            milestone {
              __typename
              id
              name
              milestone_category_id
              milestone_category {
                __typename
                name
              }
              organizations_with_forecasts {
                __typename
                id
                name
              }
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        timeline_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listTimelineMilestonesQuery>>response.data.listTimelineMilestones;
    };
    return gql$(fn());
  }
  createTimelineMilestone$(input: CreateTimelineMilestoneInput) {
    const fn = async () => {
      const statement = gql`
        mutation createTimelineMilestone($input: CreateTimelineMilestoneInput!) {
          createTimelineMilestone(input: $input) {
            __typename
            actual_end_date
            actual_start_date
            contract_end_date
            contract_start_date
            id
            revised_end_date
            revised_start_date
            track_from_milestone {
              __typename
              id
            }
            milestone {
              __typename
              id
              name
              milestone_category_id
              milestone_category {
                __typename
                name
              }
              organizations_with_forecasts {
                __typename
                id
                name
              }
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createTimelineMilestoneMutation>response.data.createTimelineMilestone;
    };
    return gql$(fn());
  }
  removeTimelineItem$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeTimelineItem($id: String!) {
          removeTimelineItem(id: $id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeTimelineItemMutation>response.data.removeTimelineItem;
    };
    return gql$(fn());
  }
  updateTimelineMilestone$(input: UpdateTimelineMilestoneInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateTimelineMilestone($input: UpdateTimelineMilestoneInput!) {
          updateTimelineMilestone(input: $input) {
            __typename
            contract_end_date
            contract_start_date
            id
            track_from_milestone {
              __typename
              id
            }
            milestone {
              __typename
              id
              name
              milestone_category_id
              milestone_category {
                __typename
                name
              }
              organizations_with_forecasts {
                __typename
                id
                name
              }
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateTimelineMilestoneMutation>response.data.updateTimelineMilestone;
    };
    return gql$(fn());
  }
  createMilestone$(input: CreateMilestoneInput) {
    const fn = async () => {
      const statement = gql`
        mutation createMilestone($input: CreateMilestoneInput!) {
          createMilestone(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createMilestoneMutation>response.data.createMilestone;
    };
    return gql$(fn());
  }
  updateMilestone$(input: UpdateMilestoneInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateMilestone($input: UpdateMilestoneInput!) {
          updateMilestone(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateMilestoneMutation>response.data.updateMilestone;
    };
    return gql$(fn());
  }
  listTrials$() {
    const fn = async () => {
      const statement = gql`
        query listTrials {
          listTrials {
            __typename
            id
            nct_id
            name
            short_name
            onboarding_complete
            therapy_area
            auxilius_start_date
            title
            auxilius_start_date
            sponsor_organization {
              __typename
              id
              name
            }
            program
            project
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listTrialsQuery>>response.data.listTrials;
    };
    return gql$(fn());
  }
  createTrial$(input: CreateTrialInput) {
    const fn = async () => {
      const statement = gql`
        mutation createTrial($input: CreateTrialInput!) {
          createTrial(input: $input) {
            __typename
            id
            nct_id
            name
            short_name
            onboarding_complete
            title
            sponsor_organization {
              __typename
              id
              name
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createTrialMutation>response.data.createTrial;
    };
    return gql$(fn());
  }
  updateTrial$(input: UpdateTrialInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateTrial($input: UpdateTrialInput!) {
          updateTrial(input: $input) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateTrialMutation>response.data.updateTrial;
    };
    return gql$(fn());
  }
  setTrial$(trial_id: string) {
    const fn = async () => {
      const statement = gql`
        mutation setTrial($trial_id: String!) {
          setTrial(trial_id: $trial_id) {
            __typename
            primary_key
            sort_key
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        trial_id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <setTrialMutation>response.data.setTrial;
    };
    return gql$(fn());
  }
  notificationListener(
    sub: string
  ): Observable<SubscriptionResponse<Pick<__SubscriptionContainer, 'notification'>>> {
    const statement = gql`
      subscription notification($sub: String!) {
        notification(sub: $sub) {
          __typename
          data
          sub
          type
          status
          page
        }
      }
    `;
    const gqlAPIServiceArguments: any = {
      sub,
    };
    return API.graphql(graphqlOperation(statement, gqlAPIServiceArguments)) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'notification'>>
    >;
  }

  createManualTrial$(input: CreateManualTrialInput) {
    const fn = async () => {
      const statement = gql`
        mutation createManualTrial($input: CreateManualTrialInput!) {
          createManualTrial(input: $input) {
            __typename
            id
            nct_id
            name
            short_name
            onboarding_complete
            title
            auxilius_start_date
            sponsor_organization {
              __typename
              id
              name
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createManualTrialMutation>response.data.createManualTrial;
    };
    return gql$(fn());
  }
  listTrialNames$() {
    const fn = async () => {
      const statement = gql`
        query listTrialNames {
          listTrials {
            __typename
            id
            name
            short_name
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listTrialNamesQuery>>response.data.listTrials;
    };
    return gql$(fn());
  }
  listPortfolioSummaries$() {
    const fn = async () => {
      const statement = gql`
        query listPortfolioSummaries {
          listPortfolioSummaries {
            __typename
            baseline_budget_estimate
            current_budget_expenses {
              __typename
              amount
              expense_type
            }
            current_month_work_performed
            invoice_total
            current_month
            trial_id
            trial_short_name
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listPortfolioSummariesQuery>>response.data.listPortfolioSummaries;
    };
    return gql$(fn());
  }
  listPortfolioExpenses$() {
    const fn = async () => {
      const statement = gql`
        query listPortfolioExpenses {
          listPortfolioExpenses {
            __typename
            amount
            expense_type
            period
            source
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listPortfolioExpensesQuery>>response.data.listPortfolioExpenses;
    };
    return gql$(fn());
  }
  getTrialInformation$() {
    const fn = async () => {
      const statement = gql`
        query getTrialInformation {
          getTrialInformation {
            __typename
            budget_version_id
            trial_end_date
            trial_month_close
            trial_start_date
            vendor_id
            vendor_month_close
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<getTrialInformationQuery>>response.data.getTrialInformation;
    };
    return gql$(fn());
  }
  listUsers$(includeInactive: boolean) {
    const fn = async () => {
      const statement = gql`
        query listUsers($includeInactive: Boolean!) {
          listUsers(includeInactive: $includeInactive) {
            __typename
            given_name
            family_name
            email
            department
            organization {
              __typename
              id
              name
            }
            sub
            title
            role
            permissions {
              __typename
              permission_id
              permission_roles
              permission_type
            }
            last_login_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        includeInactive,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listUsersQuery>>response.data.listUsers;
    };
    return gql$(fn());
  }
  createUser$(input: UserInput) {
    const fn = async () => {
      const statement = gql`
        mutation createUser($input: UserInput!) {
          createUser(input: $input) {
            __typename
            sub
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createUserMutation>response.data.createUser;
    };
    return gql$(fn());
  }
  listUserCustomViews$(view_location?: ViewLocation) {
    const fn = async () => {
      const statement = gql`
        query listUserCustomViews($view_location: ViewLocation) {
          listUserCustomViews(view_location: $view_location) {
            __typename
            id
            user_id
            view_location
            name
            metadata
            is_custom
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (view_location) {
        gqlAPIServiceArguments.view_location = view_location;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listUserCustomViewsQuery>>response.data.listUserCustomViews;
    };
    return gql$(fn());
  }
  addUserToTrial$(input: UserTrialInput) {
    const fn = async () => {
      const statement = gql`
        mutation addUserToTrial($input: UserTrialInput!) {
          addUserToTrial(input: $input) {
            __typename
            role
            department
            permissions {
              __typename
              permission_id
              permission_roles
            }
            organization {
              __typename
              id
              name
            }
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <addUserToTrialMutation>response.data.addUserToTrial;
    };
    return gql$(fn());
  }
  removeUserFromTrial$(user_id: string, trial_id?: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeUserFromTrial($trial_id: String, $user_id: String!) {
          removeUserFromTrial(trial_id: $trial_id, user_id: $user_id) {
            __typename
            id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        user_id,
      };
      if (trial_id) {
        gqlAPIServiceArguments.trial_id = trial_id;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeUserFromTrialMutation>response.data.removeUserFromTrial;
    };
    return gql$(fn());
  }
  updateUserPermission$(input: UserPermissionInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateUserPermission($input: UserPermissionInput!) {
          updateUserPermission(input: $input) {
            __typename
            permission_id
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateUserPermissionMutation>response.data.updateUserPermission;
    };
    return gql$(fn());
  }
  listPermissions$() {
    const fn = async () => {
      const statement = gql`
        query listPermissions {
          listPermissions {
            __typename
            id
            permission_group
            permission
            permission_user_profile
            permission_type
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listPermissionsQuery>>response.data.listPermissions;
    };
    return gql$(fn());
  }
  listNotificationPreferences$() {
    const fn = async () => {
      const statement = gql`
        query listNotificationPreferences {
          listNotificationPreferences {
            __typename
            notification {
              __typename
              id
              event_type
              event_type_group
            }
            enabled
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listNotificationPreferencesQuery>>response.data.listNotificationPreferences;
    };
    return gql$(fn());
  }
  updateNotificationPreference$(input: UpdateNotificationPreferenceInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateNotificationPreference($input: UpdateNotificationPreferenceInput!) {
          updateNotificationPreference(input: $input) {
            __typename
            notification {
              __typename
              id
            }
            enabled
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateNotificationPreferenceMutation>response.data.updateNotificationPreference;
    };
    return gql$(fn());
  }
  listNotifications$() {
    const fn = async () => {
      const statement = gql`
        query listNotifications {
          listNotifications {
            __typename
            id
            event_type
            event_type_group
            is_admin
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listNotificationsQuery>>response.data.listNotifications;
    };
    return gql$(fn());
  }
  loggedInUser$() {
    const fn = async () => {
      const statement = gql`
        query loggedInUser {
          loggedInUser {
            __typename
            sub
            is_sys_admin
            trial_id
            roles
            permissions
            organization_id
            title
            given_name
            family_name
            department
            all_departments
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <loggedInUserQuery>response.data.loggedInUser;
    };
    return gql$(fn());
  }
  listUserNamesWithEmail$(includeInactive: boolean) {
    const fn = async () => {
      const statement = gql`
        query listUserNamesWithEmail($includeInactive: Boolean!) {
          listUsers(includeInactive: $includeInactive) {
            __typename
            sub
            given_name
            family_name
            email
            status
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        includeInactive,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listUserNamesWithEmailQuery>>response.data.listUsers;
    };
    return gql$(fn());
  }
  getTrialPreference$(preference_type: TrialPreferenceType) {
    const fn = async () => {
      const statement = gql`
        query getTrialPreference($preference_type: TrialPreferenceType!) {
          getTrialPreference(preference_type: $preference_type) {
            __typename
            primary_key
            sort_key
            value
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        preference_type,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <getTrialPreferenceQuery>response.data.getTrialPreference;
    };
    return gql$(fn());
  }
  setTrialPreference$(input: SetTrialPreferenceInput) {
    const fn = async () => {
      const statement = gql`
        mutation setTrialPreference($input: SetTrialPreferenceInput!) {
          setTrialPreference(input: $input) {
            __typename
            primary_key
            sort_key
            value
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <setTrialPreferenceMutation>response.data.setTrialPreference;
    };
    return gql$(fn());
  }
  listUserTasks$() {
    const fn = async () => {
      const statement = gql`
        query listUserTasks {
          listUserTasks {
            __typename
            create_date
            due_date
            organization_id
            organization_name
            task_header
            task_id
            trial_id
            trial_name
            user_task
          }
        }
      `;
      const response = (await API.graphql(graphqlOperation(statement))) as any;
      return <Array<listUserTasksQuery>>response.data.listUserTasks;
    };
    return gql$(fn());
  }
  createUserCustomView$(input: CreateUserCustomViewInput) {
    const fn = async () => {
      const statement = gql`
        mutation createUserCustomView($input: CreateUserCustomViewInput!) {
          createUserCustomView(input: $input) {
            __typename
            id
            user_id
            view_location
            name
            metadata
            is_custom
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <createUserCustomViewMutation>response.data.createUserCustomView;
    };
    return gql$(fn());
  }
  updateUserCustomView$(input: UpdateUserCustomViewInput) {
    const fn = async () => {
      const statement = gql`
        mutation updateUserCustomView($input: UpdateUserCustomViewInput!) {
          updateUserCustomView(input: $input) {
            __typename
            id
            user_id
            view_location
            name
            metadata
            is_custom
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <updateUserCustomViewMutation>response.data.updateUserCustomView;
    };
    return gql$(fn());
  }
  removeUserCustomView$(id: string) {
    const fn = async () => {
      const statement = gql`
        mutation removeUserCustomView($id: String!) {
          removeUserCustomView(id: $id) {
            __typename
            id
            user_id
            view_location
            name
            metadata
            is_custom
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        id,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <removeUserCustomViewMutation>response.data.removeUserCustomView;
    };
    return gql$(fn());
  }
  listWorkflowDetails$(input: ListWorkflowDetailsInput) {
    const fn = async () => {
      const statement = gql`
        query listWorkflowDetails($input: ListWorkflowDetailsInput!) {
          listWorkflowDetails(input: $input) {
            __typename
            id
            name
            order
            properties
            type
            step_type
            updated_by
            update_date
          }
        }
      `;
      const gqlAPIServiceArguments: any = {
        input,
      };
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listWorkflowDetailsQuery>>response.data.listWorkflowDetails;
    };
    return gql$(fn());
  }
  listClosingChecklistUpdates$(after_date?: string) {
    const fn = async () => {
      const statement = gql`
        query listClosingChecklistUpdates($after_date: AWSDateTime) {
          listClosingChecklistUpdates(after_date: $after_date) {
            __typename
            step
            update_date
            updated_by
          }
        }
      `;
      const gqlAPIServiceArguments: any = {};
      if (after_date) {
        gqlAPIServiceArguments.after_date = after_date;
      }
      const response = (await API.graphql(
        graphqlOperation(statement, gqlAPIServiceArguments)
      )) as any;
      return <Array<listClosingChecklistUpdatesQuery>>response.data.listClosingChecklistUpdates;
    };
    return gql$(fn());
  }
}
