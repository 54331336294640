import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { catchError, map, retryWhen, switchMap, tap } from 'rxjs/operators';
import { CreateManualTrialInput, GqlService } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';

import { TrialModel, TrialsStore } from './trials.store';
import { defer, firstValueFrom, of, throwError, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TrialsService {
  constructor(
    private trialsStore: TrialsStore,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {}

  get() {
    this.trialsStore.setLoading(true);
    return defer(() => this.gqlService.listTrials$()).pipe(
      tap((x) => {
        if (!x.success) {
          throw new Error('Failed to get list of trials');
        }
      }),
      retryWhen((err) => {
        let numRetries = 0;
        return err.pipe(
          switchMap(() => {
            if (numRetries < 3) {
              numRetries += 1;
              return timer(10 * 1000, 1e6);
            }
            return throwError('Max retry count reached');
          })
        );
      }),
      catchError(() => of({ data: [] })),
      map((x) => {
        this.trialsStore.set(x.data || []);
        this.trialsStore.setLoading(false);
        return x;
      })
    );
  }

  async add({
    nct_id,
    onboarding_complete,
    auxilius_start_date,
  }: {
    nct_id: string;
    onboarding_complete: boolean;
    auxilius_start_date: string;
  }): Promise<string | undefined> {
    this.trialsStore.setLoading(true);

    const { data, errors } = await firstValueFrom(
      this.gqlService.createTrial$({ nct_id, onboarding_complete, auxilius_start_date })
    );
    this.trialsStore.setLoading(false);

    if (data) {
      this.trialsStore.add(data);
      return data.id;
    }

    this.overlayService.error(errors);

    return undefined;
  }

  async createCustomTrial({
    sponsor_organization_name,
    name,
    short_name,
    title,
    therapy_area,
    auxilius_start_date,
    onboarding_complete,
    program,
    project,
  }: CreateManualTrialInput): Promise<string | undefined> {
    this.trialsStore.setLoading(true);

    const { data, errors } = await firstValueFrom(
      this.gqlService.createManualTrial$({
        sponsor_organization_name,
        name,
        short_name,
        title,
        therapy_area,
        auxilius_start_date,
        onboarding_complete,
        program,
        project,
      })
    );
    this.trialsStore.setLoading(false);

    if (data) {
      this.trialsStore.add(data);
      return data.id;
    }

    this.overlayService.error(errors);

    return undefined;
  }

  async update(id: string, trial: Partial<TrialModel>) {
    this.trialsStore.update(id, trial);
    const { success, errors } = await firstValueFrom(
      this.gqlService.updateTrial$({
        short_name: trial.short_name,
        auxilius_start_date: trial.auxilius_start_date || null,
        therapy_area: trial.therapy_area,
        program: trial.program || '',
        project: trial.project || '',
      })
    );
    if (!success && errors) {
      this.overlayService.error(errors);
      return false;
    }
    this.overlayService.success();
    return true;
  }

  async updateOnboarding(id: string, trial: Partial<TrialModel>) {
    const { success, errors } = await firstValueFrom(
      this.gqlService.updateTrial$({ onboarding_complete: true })
    );

    if (success) {
      this.trialsStore.update(id, { ...trial, onboarding_complete: true });
      return true;
    }
    // eslint-disable-next-line no-alert
    alert(errors);
    return false;
  }

  remove(id: ID) {
    this.trialsStore.remove(id);
  }
}
