import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { createInitialState, FormValuesState, FormValuesStore } from './form-values.store';

@Injectable({ providedIn: 'root' })
export class FormValuesQuery extends Query<FormValuesState> {
  constructor(protected store: FormValuesStore) {
    super(store);
  }

  getValuesByFormName(formName: keyof FormValuesState) {
    return this.getValue()[formName];
  }

  updateValues<T extends keyof FormValuesState>(data: Partial<FormValuesState[T]>, formName: T) {
    this.store.update((state) => {
      const formValues = {
        ...state,
        [formName]: { ...state[formName], ...data },
      };

      localStorage.setItem('formValues', JSON.stringify(formValues));

      return formValues;
    });
  }

  removeSiteFromPatientBudget(siteId: string) {
    const sites_ids = this.getValuesByFormName('patientBudget').site_ids;
    const updatedSites = sites_ids.filter((id) => id !== siteId);

    this.updateValues({ site_ids: updatedSites }, 'patientBudget');
  }

  resetStore() {
    localStorage.removeItem('formValues');
    this.store.update(createInitialState());
  }
}
