import { Component, ChangeDetectionStrategy } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationModel } from '@models/organization/organization.store';
import {
  CellValueChangedEvent,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { isEqual, isString } from 'lodash-es';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { EventService } from '@services/event.service';
import { CreatePaymentMilestoneInput, EventType } from '@services/gql.service';
import { RequireSome, Utils } from '@services/utils';
import { AgDatePickerComponent } from '@components/datepicker/ag-date-picker/ag-date-picker.component';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { TableConstants } from '@constants/table.constants';
import { MessagesConstants } from '@constants/messages.constants';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { PaymentMilestoneService } from './state/payment-milestone.service';
import { PaymentMilestoneQuery } from './state/payment-milestone.query';
import { PaymentMilestoneStore } from './state/payment-milestone.store';

import * as dayjs from 'dayjs';
import { AgSetColumnsVisible } from '@shared/utils';
import { Option } from '@components/components.type';
import { agFormatToNumberValueSetter } from '@shared/utils/ag-value-setter.utils';

interface PaymentMilestoneGridData {
  organization: OrganizationModel | null;
  amount?: number | undefined;
  name?: string | undefined;
  target_date?: string | undefined;
  id: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-payment-milestones',
  templateUrl: './payment-milestones.component.html',
  styles: [
    `
      ::ng-deep #paymentMilestonesGrid .has-error .ag-cell {
        background-color: #fee2e2 !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMilestonesComponent {
  private vendorOptions() {
    return (
      this.organizationQuery
        .getAllVendors()
        .map(({ id, name }) => ({ value: id, label: name || '' })) || []
    );
  }

  private vendorValueSetter = (params: ValueSetterParams) => {
    const newValue = isString(params.newValue)
      ? this.vendorOptions().find(({ label }) => label === params.newValue)
      : params.newValue;

    params.data.organization = {
      ...params.data.organization,
      name: newValue.label,
      id: newValue.value,
    };

    return true;
  };

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellRenderer: AgCellWrapperComponent,
      editable: () => {
        return this.editModeGrid$.getValue();
      },
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    enableRangeSelection: true,
    suppressCellFocus: false,
    suppressMenuHide: true,
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    rowClassRules: {
      'has-error': (params) => params.data.showError,
    },
    getRowId: ({ data }) => data?.id || data?.randomID,
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        hide: true,
      },
      {
        headerName: 'RANDOM ID',
        field: 'randomID',
        hide: true,
      },
      {
        ...TableConstants.DEFAULT_GRID_OPTIONS.ACTIONS_COL_DEF,
        cellRendererParams: {
          deleteClickFN: ({ rowNode }: { rowNode: IRowNode }) => {
            if (rowNode.data.id) {
              this.removedRows.push(rowNode.data.id);
            }
            this.gridAPI.applyTransaction({ remove: [rowNode.data] });
            this.checkChanges();
          },
        },
        hide: true,
      },
      {
        headerName: 'Milestone',
        field: 'name',
        minWidth: 200,
        resizable: true,
        cellClass: 'text-left',
        tooltipField: 'name',
      },
      {
        headerName: 'Payment ($)',
        field: 'amount',
        minWidth: 120,
        valueFormatter: Utils.agCurrencyFormatter,
        valueSetter: (params) => agFormatToNumberValueSetter(params, ['$', ','], 'amount'),
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      },
      {
        headerName: 'Target Date',
        field: 'target_date',
        minWidth: 150,
        cellEditor: AgDatePickerComponent,
        sort: 'asc',
        comparator: (date1, date2) => {
          const date1Number = date1 ? dayjs(date1).unix() : null;
          const date2Number = date2 ? dayjs(date2).unix() : null;
          if (date1Number === null && date2Number === null) {
            return 0;
          }
          if (date1Number === null) {
            return 1;
          }
          if (date2Number === null) {
            return -1;
          }
          return date1Number - date2Number;
        },
        valueFormatter: Utils.agDateFormatter,
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      },
      {
        headerName: 'Vendor',
        field: 'organization',
        minWidth: 200,
        resizable: true,
        cellClass: 'text-left',
        tooltipField: 'organization.name',
        valueGetter: (params: ValueGetterParams) => {
          return params.data?.organization?.name || '';
        },
        valueSetter: this.vendorValueSetter,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: () => {
          return {
            values: this.vendorOptions(),
            formatValue: (value?: Option | string) => {
              return isString(value) ? value : value?.label || '';
            },
          };
        },
        filterValueGetter: (params: ValueGetterParams) => {
          return params.data?.organization?.name;
        },
      },
    ],
  } as GridOptions;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  gridColumnApi!: ColumnApi;

  gridData$: Observable<PaymentMilestoneGridData[]> =
    this.paymentMilestoneQuery.selectAllMilestones();

  editedRows = new Set<string>();

  removedRows: string[] = [];

  newRowAdded = false;

  initialValues: PaymentMilestoneGridData[] = [];

  hasChanges = false;

  editModeGrid$ = new BehaviorSubject(false);

  constructor(
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private paymentMilestoneService: PaymentMilestoneService,
    private paymentMilestoneStore: PaymentMilestoneStore,
    public paymentMilestoneQuery: PaymentMilestoneQuery,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private eventService: EventService
  ) {
    combineLatest([this.paymentMilestoneService.get(), this.organizationService.get()])
      .pipe(untilDestroyed(this))
      .subscribe();

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.initialValues = gridData;
    });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initialValues = [];
        this.cancelEditMode();
      });
  }

  checkChanges() {
    const currentValues: PaymentMilestoneGridData[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push({ ...data, amount: Number(data.amount) });
    });

    this.hasChanges = !isEqual(this.initialValues, currentValues);
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  gridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    this.gridColumnApi$.next(columnApi);
    this.gridColumnApi = columnApi;
    this.sizeColumnsToFit();
    Utils.updateGridLayout(this.gridAPI, 'paymentMilestonesGrid');
  }

  onAddPaymentMilestone() {
    const ss = this.gridAPI.applyTransaction({
      add: [{ randomID: Utils.uuid() }],
      addIndex: this.gridAPI.getDisplayedRowCount(),
    });
    if (ss?.add.length && ss.add[0].rowIndex) {
      this.gridAPI.startEditingCell({ rowIndex: ss.add[0].rowIndex, colKey: 'name' });
    }
    this.newRowAdded = true;
    this.checkChanges();
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.id) {
      this.editedRows.add(event.data.id);
      this.checkChanges();
    }
  }

  showErrors() {
    const rowNodes: IRowNode[] = [];
    let isThereAnyInvalidRow = false;
    this.gridAPI.forEachNode((node) => {
      const { amount, name, organization, target_date } = node.data as PaymentMilestoneGridData;

      if (amount && name && organization && target_date) {
        if (node.data.showError) {
          // eslint-disable-next-line no-param-reassign
          node.data.showError = false;
          rowNodes.push(node);
        }
      } else {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
        rowNodes.push(node);
      }
    });

    this.gridAPI.redrawRows({ rowNodes });
    return isThereAnyInvalidRow;
  }

  onSaveAll = async () => {
    if (this.showErrors()) {
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
      return;
    }

    this.paymentMilestoneStore.setLoading(true);

    const upsertData: (CreatePaymentMilestoneInput & { id: string | null })[] = [];

    this.gridAPI.forEachNode((node) => {
      const { id, target_date, name, organization, amount } = node.data as RequireSome<
        PaymentMilestoneGridData,
        'organization' | 'name' | 'target_date' | 'amount' | 'id'
      >;

      if ((!id || this.editedRows.has(id)) && organization) {
        upsertData.push({
          id,
          name,
          amount,
          target_date,
          organization_id: organization.id,
        });
      }
    });

    let hasError = true;

    if (upsertData) {
      hasError = await this.paymentMilestoneService.upsert(upsertData);
    }

    if (this.removedRows.length) {
      hasError = await this.paymentMilestoneService.remove(this.removedRows);
    }

    if (!hasError) {
      this.overlayService.success();
    }

    this.cancelEditMode();
    this.paymentMilestoneStore.setLoading(false);
  };

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  editGrid(): void {
    this.editModeGrid$.next(true);
    AgSetColumnsVisible({
      columnApi: this.gridColumnApi,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: true,
    });
    this.sizeColumnsToFit();
    this.gridOptions.api?.redrawRows();
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      colKey: 'name',
    });
  }

  cancelEditMode(): void {
    this.gridAPI.stopEditing();
    this.editModeGrid$.next(false);
    AgSetColumnsVisible({
      columnApi: this.gridColumnApi,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: false,
    });
    this.sizeColumnsToFit();
    this.gridData$ = this.paymentMilestoneQuery.selectAllMilestones();
    this.resetChangeIndicators();
    this.gridAPI.redrawRows();
  }

  private resetChangeIndicators(): void {
    this.newRowAdded = false;
    this.editedRows.clear();
    this.removedRows = [];
    this.hasChanges = false;
  }
}
