import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';
import { ApiService, FileMetadata } from '@services/api.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { BudgetType, EntityType, EventType, GqlService } from '@services/gql.service';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.query';
import { formatDate } from '@angular/common';
import { batchPromises } from '@shared/utils';
import * as dayjs from 'dayjs';
import { Utils } from '@services/utils';
import { FormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';

Utils.extendDayjs();

@Component({
  selector: 'aux-quarter-close-add-upload',
  templateUrl: './add-vendor-estimate-upload.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddVendorEstimateUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  availableMonths$ = this.getAvailableMonths();

  selectedMonth = new FormControl(this.availableMonths$[0].date);

  selectedVendor = new FormControl('');

  shortName = new FormControl('');

  notes = new FormControl('');

  totalAmount = new FormControl('');

  constructor(
    public ref: CustomOverlayRef<unknown, { selectedVendor: string; selectedMonth: string }>,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private budgetQuery: BudgetQuery,
    public vendorsQuery: OrganizationQuery,
    private gqlService: GqlService
  ) {
    if (ref.data) {
      const { selectedVendor, selectedMonth } = ref.data;
      this.selectedVendor = new FormControl(selectedVendor || '');
      this.selectedMonth = new FormControl(selectedMonth || this.availableMonths$[0].date);
    }
  }

  pathFn: () => string = () => '';

  getFilePath(month: string) {
    // const date = formatDate(new Date(), 'MMM-YYYY', 'en-US');
    const formatted_current_month = formatDate(month, 'MMMM-y', 'en-US');
    const trialId = this.mainQuery.getValue().trialKey;
    // vendor/<vendor uuid>/in-month/<MON-YYYY>/<file name goes here>
    return `trials/${trialId}/vendors/vendor-estimate/${formatted_current_month}/`;
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!this.selectedVendor.value) {
        this.overlayService.error('Please select a vendor');
        return;
      }

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      this.loading$.next(true);

      let filePath;
      const { budget_info } = this.budgetQuery.getValue();
      const date = this.selectedMonth.value || budget_info[0].current_month;
      if (!date) {
        this.errorMessage = 'No in month found!';
        filePath = '';
      } else {
        filePath = this.getFilePath(date);
      }

      const fileUploadPromises = [];

      for (const file of files) {
        this.fileManager.fileStore.update(file.id, {
          ...file,
          bucket_key: `${filePath}${file.bucket_key}`,
        });

        const fileUploadPromise = firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.VENDOR_ESTIMATE_SUPPORTING_DOCUMENT_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: this.selectedVendor.value || '',
            bucket_key: `public/${filePath}${file.bucket_key}`,
            payload: JSON.stringify({
              budget_type: BudgetType.BUDGET_VENDOR_ESTIMATE,
              month: this.selectedMonth.value,
              amount: this.totalAmount.value,
              notes: this.notes.value,
              short_name: this.shortName.value,
            }),
          })
        );
        fileUploadPromises.push(fileUploadPromise);
      }

      const metadata: FileMetadata = { admin: '1' };
      const fileSuccess = await this.fileManager.fileService.uploadFiles(metadata);

      if (fileSuccess) {
        try {
          await batchPromises(fileUploadPromises, (p) => p);
          this.overlayService.success();
        } catch {
          for (const [i] of fileUploadPromises.entries()) {
            this.apiService.removeFile(`${filePath}${files[i].bucket_key}`);
          }

          this.overlayService.error('Error uploading files.');
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }

  removeFile(file: File) {
    if (this.fileManager) {
      this.fileManager.removeFile(file);
    }
  }

  getAvailableMonths() {
    const { budget_info } = this.budgetQuery.getValue();
    let date = dayjs(budget_info[0].current_month);
    const months = [];
    const current_quarter = date.quarter();

    while (date.quarter() === current_quarter) {
      months.push({ name: date.format('MMMM YYYY'), date: date.format('MM-DD-YYYY') });
      date = date.add(1, 'months');
    }

    return months;
  }
}
