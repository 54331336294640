import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EventType, listUserNamesWithEmailQuery } from '@services/gql.service';
import { Auth } from '@aws-amplify/auth';

export interface MainState {
  trialKey: string;
  fullPage: boolean;
  sideBar: boolean;
  processingEvents: Record<EventType, boolean> | Record<string, unknown>;
  currentOpenMonth: string;
  userList: listUserNamesWithEmailQuery[];
  numberOfCOsInPendingApproval: number;
  isTrialLoading: boolean;
}

export function createInitialState(): MainState {
  return {
    trialKey: '',
    fullPage: false,
    sideBar: true,
    processingEvents: {},
    currentOpenMonth: '',
    userList: [],
    numberOfCOsInPendingApproval: 0,
    isTrialLoading: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'main' })
export class MainStore extends Store<MainState> {
  constructor() {
    super(createInitialState());
  }

  setProcessingLoadingState(eventType: EventType, isLoading: boolean) {
    this.update((state) => ({
      processingEvents: { ...state.processingEvents, [eventType]: isLoading },
    }));
  }

  async getCognitoTrialKey(): Promise<string> {
    const session = await Auth.currentSession();
    const { trial_id } = session.getIdToken().decodePayload();

    return trial_id;
  }

  // Syncs Main Store to Cognito
  async setTrial(): Promise<void> {
    const trialId = await this.getCognitoTrialKey();
    const { trialKey } = this.getValue();

    if (trialId === trialKey) {
      return;
    }

    this.update({ trialKey: trialId });
  }
}
