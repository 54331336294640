import * as currency from 'currency.js';

const DEFAULT_EPSILON = 0.01;
const DEFAULT_MATH_PRECISION = 15;
const DEFAULT_ROUND_PRECISION = 2;

/*
  This class is to be used to avoid the possibility of JS math errors happening in math functions in the application.
  If you need a good list of numbers that will fail basic JS math, this repo offers good examples for all math functions
  : https://github.com/ellenaua/floating-point-error-examples/tree/master/examples"
*/

// use this method to display a decimal number rounded to desired precision and formatted as a simple decimal:
// '1.11' or -1.11
export function decimalRoundingToString(
  value: number,
  precision = DEFAULT_ROUND_PRECISION,
  increment = 0.005
) {
  if (Number.isNaN(value)) {
    value = 0;
    console.warn(
      'Invalid number entry for display set to 0 in floating-point-utils.decimalRoundingToString'
    );
  }

  return currency(value, {
    increment: increment,
    precision: precision,
    pattern: `# `,
    negativePattern: `-# `,
  }).format();
}
// use this method to round a number and get it back as a number for further use in code
export function decimalRoundingToNumber(
  value: number,
  precision = DEFAULT_ROUND_PRECISION,
  increment = 0.005
) {
  return currency(value, { increment: increment, precision: precision }).value;
}

/*
  This method will indicate equality within the range of the epsilon. For example, with the default amount
  of 0.01, this will return true if two values are within 0.01 of each another. Be aware this may look strange
  on rounded display values but still may be accurate to the correct degree. For example, 3.435 will be equal to 3.432
  because of the difference of .003 < .01, but the display values will be 3.44 and 3.43 with 2 precision roundings displayed
*/
export function decimalEquality(
  a: number,
  b: number,
  precision = DEFAULT_MATH_PRECISION,
  epsilon = DEFAULT_EPSILON
) {
  return (
    Math.abs(
      currency(a, { precision: precision }).subtract(currency(b, { precision: precision })).value
    ) < epsilon
  );
}

// standard JS safe subtraction, returns number
export function decimalDifference(a: number, b: number, precision = DEFAULT_MATH_PRECISION) {
  return currency(a, { precision: precision }).subtract(currency(b, { precision: precision }))
    .value;
}
// standard JS safe addiction, returns a number
export function decimalAdd(a: number, b: number, precision = DEFAULT_MATH_PRECISION) {
  return currency(a, { precision: precision }).add(currency(b, { precision: precision })).value;
}

export function decimalAddAll(precision = DEFAULT_MATH_PRECISION, ...args: number[]) {
  let total = 0;
  for (let i = 0; i < args.length; i++) {
    total = currency(total, { precision: precision }).add(
      currency(args[i], { precision: precision })
    ).value;
  }
  return total;
}

// standard JS safe division, returns a number
export function decimalDivide(a: number, b: number, precision = DEFAULT_MATH_PRECISION) {
  return currency(a, { precision: precision }).divide(currency(b, { precision: precision })).value;
}

// standard JS safe multiplication, returns a number
export function decimalMultiply(a: number, b: number, precision = DEFAULT_MATH_PRECISION) {
  return currency(a, { precision: precision }).multiply(currency(b, { precision: precision }))
    .value;
}

// The Number may be negative or positive but is close enough to zero based on the standard epsilon
export function isCloseEnoughToZero(a: number) {
  return decimalEquality(Math.abs(a), 0);
}

// Rather than rounding 0.5 and higher up, and 0.4 and lower down,
// bankers rounding rounds 0.5 to the nearest even number
export function evenRounding(num: number, precision = 2) {
  const d = precision || 0;
  const m = Math.pow(10, d);
  // Avoid rounding errors
  const n = +(d ? decimalMultiply(num, m) : num).toFixed(8);
  const i = Math.floor(n);
  const f = decimalDifference(n, i);
  const e = 1e-8; // Allow for rounding errors in f
  const r =
    f > decimalDifference(0.5, e) && f < decimalAdd(0.5, e)
      ? i % 2 == 0
        ? i
        : i + 1
      : Math.round(n);
  return d ? r / m : r;
}

/*
 A short example set of a long problem with floating point numbers: If you want to try this
 out, copy the below into a test.ts file and run via ts-node test.ts.
 Floating-math.ts was created to prevent these problems by using an integer based js library instead of
 standard js math which has issues like as follows

//start here
 console.log('--------------------------------------------------------');

console.log('rounding example');

const item = 0.705;
console.log('item value', item);
console.log('items come in groups of 3');
const groupSubTotal = item * 3;
console.log('group subtotal', groupSubTotal);
const rounded = Math.round(groupSubTotal * 100.0) / 100.0;
console.log('And we round with an expected rounded value: ', '2.12');
console.log('However, the actual rounded value of Math.round(groupSubTotal * 100.0) / 100.0 is:', rounded);
console.log('we just sold 1000 sets of items for:', rounded * 1000);
console.log('but we should have sold those items for:', 2.12 * 1000);
console.log('and we lost the difference of $', 2.12 * 1000 - rounded * 1000);
console.log('in this example we are losing $1 per 100');

console.log('--------------------------------------------------------');
console.log('other math issue examples');

let total = 0.0;
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 10; i++) {
  console.log('Adding .1 to', total);
  total += 0.1;
}
console.log('total', total);
// the problem with addition with relatively small numbers is less the missing $0.000000001 and more
 //the flow control issues that may occur without warning when expected values are missing

if (total === 1) {
  console.log('here we are doing the thing we 100% expect to happen');
} else {
  console.log('unexpected flow control because total = ', total, 'not 1');
}


console.log('alternatively other math functions may do unexpected things');
console.log('expected floor of (0.1 + 0.7) * 10 is 8,
actual floor of (0.1 + 0.7) * 10 is', Math.floor((0.1 + 0.7) * 10));

*/
