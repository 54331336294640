<ng-container
  *ngIf="{
    invoices: invoices$ | async,
    isPendingApproval: isPendingApproval$ | async,
    loading: loading$ | async,
    isInQueue: isInQueue$ | async,
    isPendingReview: isPendingReview$ | async,
    isApproved: isApproved$ | async,
    isDecline: isDecline$ | async,
    show_preview: show_preview$ | async,
    organization: organization$ | async,
    approvalBy: approvalBy$ | async,
    isInvoiceFinalized: (isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async),
    invoiceLockTooltip: (invoiceLockTooltip$ | async)!,
    trialMonthClose: (trialMonthClose$ | async)!,
    trialEndDate: (trialEndDate$ | async)!
  } as obj"
>
  <ng-container *ngIf="obj.loading">
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="!obj.loading">
    <ng-container *ngIf="!obj.invoices">NOT FOUND</ng-container>

    <ng-container *ngIf="obj.invoices">
      <div
        class="flex items-center text-aux-blue-light-200 mb-6 cursor-pointer max-w-max"
        [routerLink]="[invoicesLink]"
      >
        <aux-icon name="ArrowLeft" class="mr-2" />
        <div>Back to Invoices</div>
      </div>

      <div class="flex items-center justify-between">
        <div class="font-bold text-xl mb-4">
          {{ obj.organization?.name }} - Invoice {{ obj.invoices.invoice_no }}
        </div>
        <div></div>
      </div>

      <div
        class="border px-4 mb-4"
        [ngClass]="{
          'bg-aux-blue-light-50 text-aux-black':
            obj.isPendingApproval || obj.isInQueue || obj.isPendingReview,
          'bg-aux-green-dark': obj.isApproved,
          'bg-aux-red-dark pb-4': obj.isDecline,
          'text-white': obj.isApproved || obj.isDecline
        }"
      >
        <div class="flex items-center justify-between h-16">
          <div>
            <span>Status:</span>
            <span class="font-bold ml-2">{{ status$ | async }}</span>
            <ng-container
              *ngIf="
                (obj.isApproved || obj.isDecline) && obj.approvalBy && obj.approvalBy.approved_by
              "
              class="justify-start"
            >
              <span>
                by {{ obj.approvalBy.approved_by }} on
                {{ obj.approvalBy.approved_time | date }}</span
              >
            </ng-container>
          </div>

          <div class="space-x-2 flex items-center">
            <div [auxTooltip]="obj.invoiceLockTooltip">
              <button
                *auxAuthorize="{
                  permissions: ['PERMISSION_EDIT_INVOICE']
                }"
                class="h-9 btn btn--secondary bg-white"
                [disabled]="isPoInvalid() || obj.isInvoiceFinalized"
                (click)="onSave()"
              >
                <span *ngIf="(btnLoading$ | async)?.save === true" class="spinner w-5 h-5"></span>
                <aux-icon name="Pencil" [size]="16" *ngIf="(btnLoading$ | async)?.save === false" />
                <span class="ml-2">Save</span>
              </button>
            </div>

            <button
              *ngIf="!obj.isPendingReview && !obj.isInQueue"
              class="h-9 btn btn--secondary bg-white"
              (click)="onDownloadInv()"
            >
              <span *ngIf="(btnLoading$ | async)?.download === true" class="spinner w-5 h-5"></span>
              <aux-icon
                name="Download"
                [size]="20"
                *ngIf="(btnLoading$ | async)?.download === false"
              />
              <span class="ml-2">Download Invoice</span>
            </button>

            <ng-container *ngIf="obj.isPendingReview || obj.isInQueue">
              <div [auxTooltip]="obj.invoiceLockTooltip">
                <button
                  *auxAuthorize="{ sysAdminsOnly: true }"
                  class="h-9 btn btn--negative"
                  [disabled]="obj.isInvoiceFinalized"
                  (click)="onDelete()"
                >
                  <span
                    *ngIf="(btnLoading$ | async)?.delete === true"
                    class="spinner w-5 h-5"
                  ></span>
                  <aux-icon
                    name="Trash"
                    [size]="16"
                    *ngIf="(btnLoading$ | async)?.delete === false"
                  />
                  <span class="ml-2">Delete</span>
                </button>
              </div>
              <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadInv()">
                <span
                  *ngIf="(btnLoading$ | async)?.download === true"
                  class="spinner w-5 h-5"
                ></span>
                <aux-icon
                  name="Download"
                  [size]="20"
                  *ngIf="(btnLoading$ | async)?.download === false"
                />
                <span class="ml-2">Download Invoice</span>
              </button>
              <div [auxTooltip]="obj.invoiceLockTooltip">
                <button
                  *auxAuthorize="{ sysAdminsOnly: true }"
                  class="h-9 btn btn--success"
                  [disabled]="isPoInvalid() || obj.isInvoiceFinalized"
                  (click)="onSendApproval()"
                >
                  <span
                    *ngIf="(btnLoading$ | async)?.approve === true"
                    class="spinner w-5 h-5"
                  ></span>
                  <aux-icon
                    name="Check"
                    [size]="20"
                    *ngIf="(btnLoading$ | async)?.approve === false"
                  />
                  <span class="ml-2">Send for Approval</span>
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="obj.isPendingApproval">
              <ng-container>
                <div [auxTooltip]="obj.invoiceLockTooltip">
                  <button
                    *auxAuthorize="{ sysAdminsOnly: true }"
                    class="h-9 btn btn--secondary bg-white"
                    [disabled]="obj.isInvoiceFinalized"
                    (click)="onReturnToAdminReview()"
                  >
                    <span
                      *ngIf="(btnLoading$ | async)?.adminReview === true"
                      class="spinner w-5 h-5"
                    ></span>
                    <aux-icon
                      name="Pencil"
                      [size]="20"
                      *ngIf="(btnLoading$ | async)?.adminReview === false"
                    />
                    <span class="ml-2">Return to Admin Review</span>
                  </button>
                </div>

                <div [auxTooltip]="obj.invoiceLockTooltip">
                  <button
                    *auxAuthorize="{
                      sysAdminsOnly: true,
                      roles: ['ROLE_ADMIN']
                    }"
                    class="h-9 btn btn--secondary bg-white"
                    [disabled]="obj.isInvoiceFinalized"
                    (click)="onApprove('Force')"
                  >
                    <span
                      *ngIf="(btnLoading$ | async)?.forceApprove === true"
                      class="spinner w-5 h-5"
                    ></span>
                    <aux-icon
                      name="Check"
                      [size]="20"
                      *ngIf="(btnLoading$ | async)?.forceApprove === false"
                    />
                    <span class="ml-2">Force Approve</span>
                  </button>
                </div>
                <ng-container *ngIf="(authQuery.adminUser$ | async) === false">
                  <div [auxTooltip]="obj.invoiceLockTooltip">
                    <button
                      *auxAuthorize="{
                        permissions: ['PERMISSION_APPROVE_INVOICE']
                      }"
                      class="h-9 btn btn--negative"
                      [disabled]="obj.isInvoiceFinalized"
                      (click)="onDecline()"
                    >
                      <span
                        *ngIf="(btnLoading$ | async)?.decline === true"
                        class="spinner w-5 h-5"
                      ></span>
                      <aux-icon
                        name="X"
                        [size]="20"
                        *ngIf="(btnLoading$ | async)?.decline === false"
                      />
                      <span class="ml-2">Decline</span>
                    </button>
                  </div>
                  <div [auxTooltip]="obj.invoiceLockTooltip">
                    <button
                      *auxAuthorize="{
                        permissions: ['PERMISSION_APPROVE_INVOICE']
                      }"
                      class="h-9 btn btn--success"
                      [disabled]="obj.isInvoiceFinalized"
                      (click)="onApprove()"
                    >
                      <span
                        *ngIf="(btnLoading$ | async)?.forceApprove === true"
                        class="spinner w-5 h-5"
                      ></span>
                      <aux-icon
                        name="Check"
                        [size]="20"
                        *ngIf="(btnLoading$ | async)?.forceApprove === false"
                      />
                      <span class="ml-2">Approve Invoice</span>
                    </button>
                  </div>
                </ng-container>

                <div [auxTooltip]="obj.invoiceLockTooltip">
                  <button
                    *auxAuthorize="{ sysAdminsOnly: true }"
                    class="h-9 btn btn--negative"
                    [disabled]="obj.isInvoiceFinalized"
                    (click)="onDelete()"
                  >
                    <span
                      *ngIf="(btnLoading$ | async)?.delete === true"
                      class="spinner w-5 h-5"
                    ></span>
                    <aux-icon
                      name="Trash"
                      [size]="16"
                      *ngIf="(btnLoading$ | async)?.delete === false"
                    />
                    <span class="ml-2">Delete</span>
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div *ngIf="obj.isDecline" class="bg-aux-gray-light mt-4 p-2 text-aux-black">
          <div class="font-bold">Reason for Decline</div>
          <div *ngIf="obj.invoices.decline_reason">
            {{ obj.invoices.decline_reason }}
          </div>
        </div>
      </div>
      <ng-container *ngIf="obj.show_preview">
        <hr class="mt-6" />
        <div class="my-4 italic text-sm">
          This Invoice is being processed by Auxilius. You will receive a notification when it is
          <br />
          ready for your review.
        </div>
        <hr class="mb-6" />
      </ng-container>
      <ng-container
        *ngIf="
          (authQuery.adminUser$ | async) === true ||
          obj.isPendingApproval ||
          obj.isApproved ||
          obj.isDecline ||
          userHasEditInvoicePermission
        "
      >
        <aux-invoice
          #invoiceProc
          [trialMonthClose]="obj.trialMonthClose"
          [trialEndDate]="obj.trialEndDate"
          [invoiceData]="obj.invoices"
          [editModeData]="!!obj.isPendingReview"
        />
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
