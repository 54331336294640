<div *ngIf="ref.data?.message" class="mb-4" [innerHTML]="ref.data?.message"></div>

<div *ngIf="ref.data?.textarea as textareaConfig">
  <aux-input
    [label]="textareaConfig.label"
    [textArea]="true"
    [rows]="2"
    class="text-xs mb-4"
    [validators]="textareaConfig.required ? 'required' : ''"
    [formControl]="textarea"
  />
</div>

<div class="flex items-center space-x-4">
  <button
    type="button"
    class="btn btn--blue"
    [attr.disabled]="ref.data?.okBtnDisabled"
    [attr.data-id]="ref.data?.okBtnDataId"
    (click)="close(true)"
  >
    {{ ref.data?.okBtnText }}
  </button>

  <button type="button" class="aux-link" (click)="close(false)">
    {{ ref.data?.cancelBtnText }}
  </button>
</div>
