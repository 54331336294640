<div class="flex items-center space-x-5 text-xs mb-4 h-9">
  <div class="flex items-center space-x-2">
    <span class="font-bold">Vendor</span>
    <ng-select
      class="w-72 text-sm"
      [clearable]="false"
      [formControl]="selectedVendor"
      (change)="onVendorSelected($event)"
    >
      <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
        <ng-option *ngFor="let vendor of vendors" [value]="vendor.id">
          <span [title]="vendor.name">{{ vendor.name }}</span>
        </ng-option>
      </ng-container>
    </ng-select>
  </div>
  <div class="border-r h-9"></div>

  <ng-container *ngIf="(loading$ | async) === true">
    <div class="flex items-center justify-center w-full">
      <div class="m-auto spinner w-9 h-9 border-4"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="(loading$ | async) === false">
    <div *ngIf="budgetVersionList$ | async as fromList" class="flex items-center space-x-2">
      <span class="font-bold">Compare</span>
      <ng-select
        class="w-72 text-sm"
        [clearable]="false"
        [formControl]="selectedFrom"
        [items]="fromList"
        [searchable]="false"
      >
        <ng-template let-item="item" ng-label-tmp> {{ item.budget_name }} </ng-template>
        <ng-template let-item="item" ng-option-tmp>
          <div class="flex flex-col p-1">
            <div class="font-bold text-aux-blue">{{ item.budget_name }}</div>
            <div>Date Uploaded: {{ item.create_date }}</div>
            <div>Type: {{ item.budget_type }}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div *ngIf="budgetVersionToList$ | async as toList" class="flex items-center space-x-2">
      <span class="font-bold">to</span>
      <ng-select
        class="w-72 text-sm"
        [clearable]="false"
        [formControl]="selectedTo"
        [items]="toList"
        [searchable]="false"
      >
        <ng-template let-item="item" ng-label-tmp> {{ item.budget_name }} </ng-template>
        <ng-template let-item="item" ng-option-tmp>
          <div class="flex flex-col p-1">
            <div class="font-bold text-aux-blue">{{ item.budget_name }}</div>
            <div>Date Uploaded: {{ item.create_date }}</div>
            <div>Type: {{ item.budget_type }}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="flex items-center space-x-2">
      <button
        class="text-sm pl-3 btn btn--blue"
        [disabled]="!(selectedTo.value && selectedFrom.value)"
        (click)="compareBudget()"
      >
        <aux-icon name="Files" class="mr-2" [size]="16" />
        Compare
      </button>
    </div>
  </ng-container>
</div>
<div class="flex flex-col border-b h-1 w-full"></div>

<div *ngIf="showAnalyticsSection$ | async">
  <div class="mb-12 mt-8 grid grid-cols-4 gap-7.5">
    <div class="border rounded p-4 grid grid-cols-2">
      <div class="border-r flex flex-col justify-center">
        <div class="font-bold">
          Delta to Baseline<br />
          Budget ({{ currencySymbol }})
        </div>
      </div>
      <div class="pl-5 text-lg font-bold flex justify-center items-center tabular-nums">
        {{ totals.variance_total_cost_AC }}<br />{{ totals.variance_percentage_AC }}
      </div>
    </div>
    <div class="border rounded col-span-2 p-4 grid grid-cols-4 relative">
      <div class="border-r font-bold flex items-center">
        Delta by<br />
        Cost Type
      </div>
      <ng-container *ngIf="false">
        <div class="col-span-3 flex items-center justify-center">
          <div class="border-4 m-auto spinner"></div>
        </div>
      </ng-container>
      <ng-container>
        <div class="col-span-3 pl-5 flex flex-col justify-between tabular-nums">
          <div class="flex text-white font-bold text-xs w-full h-6">
            <!-- [ngClass]="{ 'rounded-r': 52 === 100 }" -->
            <div
              class="px-2 py-1 text-center bg-aux-blue rounded-l"
              [ngStyle]="{ flexGrow: totals.services_total_percent_AC }"
            >
              {{ totals.services_total_percent_AC_Formatted }}
            </div>
            <!-- [ngClass]="{ rounded: 32 === 100 }" -->
            <div
              class="px-2 py-1 text-center bg-aux-green"
              [ngStyle]="{ flexGrow: totals.investigator_total_percent_AC }"
            >
              {{ totals.investigator_total_percent_AC_Formatted }}
            </div>
            <!-- [ngClass]="{ 'rounded-l': 16 === 100 }" -->
            <div
              class="px-2 py-1 text-center bg-aux-blue-light rounded-r"
              [ngStyle]="{ flexGrow: totals.pass_through_total_percent_AC }"
            >
              {{ totals.pass_through_total_percent_AC_Formatted }}
            </div>
          </div>
          <div class="grid grid-cols-3 mt-2">
            <div>
              <div class="flex items-center mb-2">
                <div class="w-4 h-4 rounded-full bg-aux-blue mr-2"></div>
                <div class="text-xs">Services</div>
              </div>
              <div class="text-sm font-bold">{{ totals.services_total_AC }}</div>
            </div>
            <div>
              <div class="flex items-center mb-2">
                <div class="w-4 h-4 rounded-full bg-aux-green mr-2"></div>
                <div class="text-xs">Investigator</div>
              </div>
              <div class="text-sm font-bold">{{ totals.investigator_total_AC }}</div>
            </div>
            <div>
              <div class="flex items-center mb-2">
                <div class="w-4 h-4 rounded-full bg-aux-blue-light mr-2"></div>
                <div class="text-xs">Pass-through</div>
              </div>
              <div class="text-sm font-bold">{{ totals.pass_through_total_AC }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="border rounded p-4 grid grid-cols-2 relative">
      <div class="border-r flex flex-col justify-center">
        <div class="font-bold">Top Category Delta</div>
        <div class="text-sm font-medium flex items-center">
          Category<br />
          Total {{ currencySymbol }}
        </div>
      </div>
      <div class="pl-5 text-lg font-bold flex justify-center items-center tabular-nums">
        {{ totals.category_type_AC }}<br />
        {{ totals.category_amount_AC }}
      </div>
    </div>
  </div>
</div>

<div class="flex justify-between mb-4">
  <aux-checkbox
    id="hide_unchanged"
    class="mt-3 text-sm"
    (customChange)="compare.hideUnchangedActivities($event)"
  >
    Hide Unchanged
  </aux-checkbox>
  <aux-export-excel-button
    #excelExport
    [gridAPI]="compare.gridAPI"
    [excelOptions]="compare.excelOptions"
    [ignoreColsId]="['group1', 'group2', 'group3', 'group4', 'group5', 'changed', 'vendor_name']"
    [variant]="exportButtonVariant"
    [getDynamicExcelParamsCallback]="
      compare.getDynamicExcelParamsCallback(excelExport.getDashboardIDs())
    "
  />
</div>

<div class="mb-4">
  <aux-compare
    #compare
    [fromBudgetVersion]="fromBudgetVersion$ | async"
    [toBudgetVersion]="toBudgetVersion$ | async"
    (budgetData)="onBudgetData($event)"
  />
</div>
